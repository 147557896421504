import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {cacheDraftReducer} from './cache-draft/reducer/cache-draft.reducer';
import {indexationDocTypesReducer} from './doc-types/reducer/indexation-doc-types.reducer';
import {draftDatasReducer} from './draft-datas/reducer/draft-datas.reducer';
import {listIndexationReducer} from './list-indexation/reducer/list-indexation.reducer';
import {draftFilterReducer} from './draft-filter/reducer/draft-filter.reducer';


export const cacheDraftStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CACHE_DRAFT,
    reducer: cacheDraftReducer
  })
);

export const docTypesStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.INDEXATION_DOC_TYPES,
    reducer: indexationDocTypesReducer
  })
);

export const draftDataStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.DRAFT_DATAS,
    reducer: draftDatasReducer
  })
);

export const draftFilterStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.DRAFT_FILTERS,
    reducer: draftFilterReducer
  })
);

export const listIndexationStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.LIST_INDEXATION,
    reducer: listIndexationReducer
  })
);
