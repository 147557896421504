import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import { entityFilterReducer } from './entity-filter/reducer/entity-filter.reducer';
import {entitySearchResultReducer} from './entity-search-result/reducer/entity-search-result.reducer';
import {entityViewReducer} from './entity-view/reducer/entity-view.reducer';
import {entitySearchItemsReducer} from './entity-search-item/reducer/entity-search-items.reducer';


export const entityFilterStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ENTITY_FILTERS,
    reducer: entityFilterReducer
  })
);

export const entitySearchItemStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ENTITY_SEARCH_ITEMS,
    reducer: entitySearchItemsReducer
  })
);

export const entitySearchResultStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ENTITY_SEARCH_RESULT,
    reducer: entitySearchResultReducer
  })
);

export const entityViewStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ENTITY_VIEW,
    reducer: entityViewReducer
  })
);
