import { EsAuthor } from './EsAuthor';

export class CdxLocation {
  lat: number;
  lon: number;
  recreate?: boolean;
}

export class CdxReply {
  cdx_id: string;
  comment: string;
  cdx_author: EsAuthor;
  cdx_modification_author: EsAuthor;
  cdx_creation_date: number;
  cdx_modification_date: number;
  cdx_status: string;
  comment_items: CommentItem[];
  edit = false;
}

export enum CommentItemType {
  TEXT = 'text',
  USER = 'user'
}

export class CommentItem {
  notify: boolean;
  user: EsAuthor;

  constructor(
    public type: CommentItemType,
    public text: string
  ) {
  }
}

export class CdxComment {
  cdx_id: string;
  cdx_doc_id: string;
  cdx_author: EsAuthor;
  cdx_modification_author: EsAuthor;
  cdx_creation_date: number;
  cdx_modification_date: number;
  comment: string;
  comment_items: CommentItem[];
  page: number;
  location: CdxLocation;
  cdx_status: string;
  cdx_doc_version: number;
  replies: CdxReply[];
  order: number;

}


export enum ActionType { VALIDATE = 'VALIDATE', DISCARD = 'DISCARD', EDIT = 'EDIT', REPLY = 'REPLY' }

export class CommentAction {
  comment: string;
  action: ActionType;
}
