import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './components/menu/menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ButtonMenuComponent} from './components/button-menu/button-menu.component';
import {CapitalizeFirstLetterModule} from '../capitalize-firstLetter/capitalize-firstLetter.module';
import {ClickOutsideDirective} from "../../../shared-directives/click-outside.directive";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    MenuComponent,
    MenuItemComponent,
    ButtonMenuComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    CapitalizeFirstLetterModule,
    ClickOutsideDirective,
    TranslateModule
  ],
  exports: [
    MenuComponent,
    MenuItemComponent,
    ButtonMenuComponent
  ]
})
export class MenuModule {
}
