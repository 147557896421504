import {Injectable} from '@angular/core';
import {SearchItem} from '../../../../models/search-item';
import {createAction, props, Store} from '@ngrx/store';
import {ISearchItemsAction} from '../../../search-item/search-items-action-interface';

export class SearchItemsActionConstant {
  static readonly ADD_SEARCH_ITEM_START = 'ADD_SEARCH_ITEM_START';
  static readonly ADD_SEARCH_ITEM_SUCCEEDED = 'ADD_SEARCH_ITEM_SUCCEEDED';
  static readonly ADD_SEARCH_ITEM_FAILED = 'ADD_SEARCH_ITEM_FAILED';

  static readonly REMOVE_SEARCH_ITEM_START = 'REMOVE_SEARCH_ITEM_START';
  static readonly REMOVE_SEARCH_ITEM_SUCCEEDED = 'REMOVE_SEARCH_ITEM_SUCCEEDED';
  static readonly REMOVE_SEARCH_ITEM_FAILED = 'REMOVE_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ALL_SEARCH_ITEMS_SUCCEEDED = 'REMOVE_ALL_SEARCH_ITEMS_SUCCEEDED';
}

export const addSearchItemStartAction = createAction(
  SearchItemsActionConstant.ADD_SEARCH_ITEM_START
);

export const addSearchItemSucceededAction = createAction(
  SearchItemsActionConstant.ADD_SEARCH_ITEM_SUCCEEDED,
  props<{ payload: SearchItem }>()
);

export const addSearchItemFailedAction = createAction(
  SearchItemsActionConstant.ADD_SEARCH_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeSearchItemStartAction = createAction(
  SearchItemsActionConstant.REMOVE_SEARCH_ITEM_START
);

export const removeSearchItemSucceededAction = createAction(
  SearchItemsActionConstant.REMOVE_SEARCH_ITEM_SUCCEEDED,
  props<{ payload: SearchItem }>()
);

export const removeSearchItemFailedAction = createAction(
  SearchItemsActionConstant.REMOVE_SEARCH_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeAllSearchItemsSucceededAction = createAction(
  SearchItemsActionConstant.REMOVE_ALL_SEARCH_ITEMS_SUCCEEDED
);


@Injectable({
  providedIn: 'root'
})
export class SearchItemsAction implements ISearchItemsAction {

  constructor(private localStore: Store) {
  }

  public addSearchItemStarted() {
    this.localStore.dispatch(addSearchItemStartAction());
  }

  public addSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch(addSearchItemSucceededAction({payload: searchItem}));
  }

  public addSearchItemFailed(error: Error) {
    this.localStore.dispatch(addSearchItemFailedAction({error}));
  }

  public removeSearchItemStarted() {
    this.localStore.dispatch(removeSearchItemStartAction());
  }

  public removeSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch(removeSearchItemSucceededAction({payload: searchItem}));
  }

  public removeSearchItemFailed(error: Error) {
    this.localStore.dispatch(removeSearchItemFailedAction({error}));
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch(removeAllSearchItemsSucceededAction());
  }
}
