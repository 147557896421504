import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {DomainState} from '../reducer/domain.reducer';


export const selectDomainsFeature: MemoizedSelector<object, DomainState> = createFeatureSelector<DomainState>(StoreKeys.DOMAINS);

export const selectDomainsDatas = createSelector(selectDomainsFeature, (domains: DomainState) => domains?.datas);

export const selectDomainItemsDatas = createSelector(selectDomainsFeature, state => state?.datas);

