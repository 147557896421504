import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { selectDomainsDatas } from './core/redux/domain/selectors/domain.selectors';
import { MenuService } from './core/redux/menu/service/menu.service';
import { selectSearchResultPaginationDatas } from './core/redux/search-result-pagination/search-result-pagination-selectors';
import { selectAuthenticationAuthenticated } from './modules/authentication/authentication.selectors';
import { AuthenticationService } from './modules/authentication/authentication.service';
import { ConfigurationService } from './modules/configuration/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{
  configReady$ = this.configService.applicationConfigReady$;

  workspaceItemsList$ = this.store.pipe(select(selectDomainsDatas));

  workspaceItemsLoaded$ = this.workspaceItemsList$.pipe(map(data => data.length > 0));

  searchResultPaginationDatas$ = this.store.select(selectSearchResultPaginationDatas);

  searchResultPaginationDataLoaded$ = this.searchResultPaginationDatas$.pipe(map(datas => datas != null));

  authenticated$ = this.store.select(selectAuthenticationAuthenticated);

  // private appIsReady$ = forkJoin([
  //   this.i18nService.i18nLoaded$.pipe(logInDev('')),
  //   this.menuService.menuItemsLoaded$.pipe(tap(() => console.log(`MenuItems loaded`))),
  //   this.workspaceItemsLoaded$.pipe(tap(() => console.log(`workspaceItemsLoaded`))),
  //   this.searchResultPaginationDataLoaded$.pipe(tap(() => console.log(`searchResultPaginationDataLoaded`)))
  // ]).pipe(tap(values => console.log(`loaded values :`, values)),
  //   map(loadedValues => loadedValues.every(isOk => isOk)
  //  )
  // );


  constructor(
    private menuService: MenuService,
    private store: Store,
    private configService: ConfigurationService,
    private authService: AuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // console.log(`app guard`)
    return this.configReady$.pipe(
      switchMap(() => this.authenticated$),
      switchMap(authenticated => iif(() => !authenticated, this.authService.authenticate(state.url), of(authenticated))),
      takeWhile(authenticated => authenticated),
      // tap(result => console.log(`(auth guard result = )`, result))
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
