import {createReducer, on} from '@ngrx/store';
import {ActivityDiff} from '../../../../models/cdx-activity';
import {CdxAttachment} from '../../../../models/cdx-attachment';
import {CdxComment} from '../../../../models/cdx-comment';
import {DocumentDetails} from '../../../../models/document-details';
import {Utils} from '../../../../utils/utils';
import {
  addCommentFailedAction,
  addCommentStartAction,
  addReplyFailedAction,
  addReplyStartAction,
  deleteAttachmentFailedAction,
  deleteAttachmentStartAction,
  deleteAttachmentSucceededAction,
  deleteCommentFailedAction,
  deleteCommentStartAction,
  deleteReplyStartAction,
  loadAttachmentFailedAction,
  loadAttachmentStartAction,
  loadAttachmentSucceededAction,
  loadCommentFailedAction,
  loadCommentStartAction,
  loadCommentSucceededAction,
  loadCurrentActivityDiffFailedAction,
  loadCurrentActivityDiffStartAction,
  loadCurrentActivityDiffSucceededAction,
  loadDetailsFailedAction,
  loadDetailsStartAction,
  loadDetailsSucceededAction,
  loadDisplayBlockSucceededAction,
  loadDocDetailsFailedAction,
  loadDocDetailsStartAction,
  loadDocDetailsSucceededAction,
  loadHistoryFailedAction,
  loadHistoryStartAction,
  loadHistorySucceededAction,
  loadMetaFailedAction,
  loadMetaStartAction,
  loadMetaSucceededAction,
  removeAllSucceededAction,
  updateAttachmentFailedAction,
  updateAttachmentStartAction,
  updateAttachmentSucceededAction,
  updateCommentStartAction,
  updateReplyFailedAction,
  updateReplyStartAction,
  uploadAttachmentFailedAction,
  uploadAttachmentStartAction,
  uploadAttachmentSucceededAction,
  updateTokenAction
} from '../../action/document/document-details.action';

export class DocumentDetailsState {
  datas: DocumentDetails;
  displayInfoBlock: boolean;
  loading: boolean;
  error: any;
  loadPdf: boolean;
}

export const CURRENT_DOCUMENT_INITIAL_STATE: DocumentDetailsState = {
  datas: null,
  displayInfoBlock: false,
  loading: false,
  error: null,
  loadPdf: true
};


export const documentDetailsReducer = createReducer(
  CURRENT_DOCUMENT_INITIAL_STATE,

  on(
    loadDetailsStartAction,
    loadDocDetailsStartAction,
    loadMetaStartAction,
    loadCurrentActivityDiffStartAction,
    addCommentStartAction,
    updateCommentStartAction,
    loadCommentStartAction,
    deleteCommentStartAction,
    addReplyStartAction,
    updateReplyStartAction,
    deleteReplyStartAction,
    loadHistoryStartAction,
    loadAttachmentStartAction,
    uploadAttachmentStartAction,
    deleteAttachmentStartAction,
    updateAttachmentStartAction,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    loadDetailsFailedAction,
    loadDocDetailsFailedAction,
    loadMetaFailedAction,
    loadCurrentActivityDiffFailedAction,
    addCommentFailedAction,
    loadCommentFailedAction,
    deleteCommentFailedAction,
    addReplyFailedAction,
    updateReplyFailedAction,
    loadHistoryFailedAction,
    loadAttachmentFailedAction,
    uploadAttachmentFailedAction,
    deleteAttachmentFailedAction,
    updateAttachmentFailedAction,
    (state, {error}) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    loadDetailsSucceededAction,
    (state, {payload}) => {
      const copy = {
        ...state.datas,
        details: payload.details,
        comments: Utils.setCommentOrder(payload.comments),
        attachments: payload.attachments,
        activities: payload.activities,
        token: payload.details.token,
        loadPdf: true,
        currentActivityDiff: new ActivityDiff(),
      };
      return {
        ...state,
        datas: copy,
        loading: false,
        error: null,
      };
    }
  ),
  on(
    loadDocDetailsSucceededAction,
    (state, {payload}) => ({
      ...state,
      datas: {
        ...state.datas,
        details: payload.details,
        loadPdf: payload.loadPdf,
      },
      loading: false,
      error: null,
    })
  ),
  on(
    loadMetaSucceededAction,
    (state, {payload}) => ({
      ...state,
      datas: {
        ...state.datas,
        details: {
          ...state.datas.details,
          metadata: payload.details.metadata,
        },
      },
      loading: false,
      error: null,
    })
  ),
  on(
    loadCurrentActivityDiffSucceededAction,
    (state, {payload}) => ({
      ...state,
      datas: {
        ...state.datas,
        currentActivityDiff: payload.currentActivityDiff,
        loadPdf: false,
      },
      loading: false,
      error: null,
    })
  ),
  on(
    loadCommentSucceededAction,
    (state, {payload}) => {
      const upsertComment: CdxComment = payload.comments[0];
      let foundCom = false;
      let commentss: CdxComment[] = state.datas.comments.map((comm) => {
        if (comm.cdx_id === upsertComment.cdx_id) {
          foundCom = true;
          return upsertComment;
        }
        return comm;
      });
      if (!foundCom) {
        commentss = commentss.concat(upsertComment);
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          comments: Utils.setCommentOrder(commentss),
        },
        loading: false,
        error: null,
      };
    }
  ),
  on(
    loadHistorySucceededAction,
    (state, {payload}) => ({
      ...state,
      datas: {
        ...state.datas,
        activities: payload.activities,
      },
      loading: false,
      error: null,
    })
  ),
  on(
    loadAttachmentSucceededAction,
    (state, {payload}) => {
      const attachmentIdToSearchFor =
        payload.attachments.length > 1
          ? payload.attachments[1].cdx_id
          : payload.attachments[0].cdx_id;
      const upsertAttachement: CdxAttachment = payload.attachments[0];
      let found = false;
      let attachments: CdxAttachment[] = state.datas.attachments.map((att) => {
        if (att.cdx_id === attachmentIdToSearchFor) {
          found = true;
          return upsertAttachement;
        }
        return att;
      });
      if (!found) {
        attachments = attachments.concat(upsertAttachement);
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments,
        },
        loading: false,
        error: null,
      };
    }
  ),
  on(
    uploadAttachmentSucceededAction,
    (state) => ({
      ...state,
      loading: false,
      error: null,
    })
  ),
  on(
    deleteAttachmentSucceededAction,
    (state, {payload}) => ({
      ...state,
      datas: {
        ...state.datas,
        attachments: state.datas.attachments.filter(
          (att) => att.cdx_id !== payload.attachments[0].cdx_id
        ),
      },
      loading: false,
      error: null,
    })
  ),
  on(
    updateAttachmentSucceededAction,
    (state, {payload}) => {
      const toReplace = state.datas.attachments
        .map((att) => att.cdx_id)
        .indexOf(payload.attachments[0].cdx_id);
      const updated = payload.attachments[0];
      updated.cdx_thumbnail = state.datas.attachments[toReplace].cdx_thumbnail;
      state.datas.attachments.splice(toReplace, 1, updated);
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments: state.datas.attachments,
        },
        loading: false,
        error: null,
      };
    }
  ),
  on(
    removeAllSucceededAction,
    (state) => ({
      ...state,
      datas: null,
      displayInfoBlock: false,
      loading: false,
      error: null,
    })
  ),
  on(
    updateTokenAction,
    (state, {payload}) => {
      // state.datas.details.token = payload.token;
      return {
        ...state,
        datas: {
          ...state.datas,
          details: {
            ...state.datas.details,
            token: payload.token
          }
        },
        loading: false,
        error: null,
      };
    }
  ),
  on(
    loadDisplayBlockSucceededAction,
    (state, {payload}) => ({
      ...state,
      displayInfoBlock: payload.displayInfoBlock,
    })
  )
);
