import {PayloadAction} from '../../../../../redux/payload-action';
import {ActivityDiff} from '../../../../models/cdx-activity';
import {TaskDetails} from '../../../../models/task-details';
import {Utils} from '../../../../utils/utils';
import {TaskDetailsConstant} from '../../action/task-details-constants';

export class TaskDetailsState {
  datas: TaskDetails;
  displayInfoBlock: boolean;
  loading: boolean;
  error: any;
  loadPdf: boolean;
}

export const CURRENT_TASK_INITIAL_STATE: TaskDetailsState = {
  datas: null,
  displayInfoBlock: false,
  loading: false,
  error: null,
  loadPdf: true
};

export const taskDetailsReducer = (state: TaskDetailsState = CURRENT_TASK_INITIAL_STATE, action: PayloadAction<TaskDetails>) => {
  switch (action.type) {
    case TaskDetailsConstant.LOAD_DETAILS_START:
    case TaskDetailsConstant.LOAD_TASK_DETAILS_START:
    case TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START:
    case TaskDetailsConstant.ADD_COMMENT_START:
    case TaskDetailsConstant.UPDATE_COMMENT_START:
    case TaskDetailsConstant.DELETE_COMMENT_START:
    case TaskDetailsConstant.ADD_REPLY_START:
    case TaskDetailsConstant.UPDATE_REPLY_START:
    case TaskDetailsConstant.DELETE_REPLY_START:
    case TaskDetailsConstant.LOAD_ATTACHMENT_START:
    case TaskDetailsConstant.LOAD_HISTORY_START:
    case TaskDetailsConstant.UPLOAD_ATTACHMENT_START:
    case TaskDetailsConstant.DELETE_ATTACHMENT_START:
    case TaskDetailsConstant.UPDATE_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskDetailsConstant.LOAD_DETAILS_SUCCEEDED:
    {
      const copyDetailsSucceeded = {
        ...state.datas,
        details: action.payload.details,
        comments: Utils.setCommentOrder(action.payload.comments),
        attachments: action.payload.attachments,
        activities: action.payload.activities,
        token: action.payload.details.token,
        loadPdf: true,
        currentActivityDiff: new ActivityDiff()
      };
      return {
        ...state,
        datas: copyDetailsSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.LOAD_TASK_DETAILS_SUCCEEDED:
    {
      const copyTaskDetailsSucceeded = {
        ...state.datas,
        details: action.payload.details,
        loadPdf: action.payload.loadPdf
      };
      return {
        ...state,
        datas: copyTaskDetailsSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED:
    {
      const copyCurrentActivityDiffSucceeded = {
        ...state.datas,
        currentActivityDiff: action.payload.currentActivityDiff,
        loadPdf: false
      };
      return {
        ...state,
        datas: copyCurrentActivityDiffSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.LOAD_DETAILS_FAILED:
    case TaskDetailsConstant.LOAD_TASK_DETAILS_FAILED:
    case TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED:
    case TaskDetailsConstant.LOAD_COMMENT_FAILED:
    case TaskDetailsConstant.ADD_COMMENT_FAILED:
    case TaskDetailsConstant.UPDATE_COMMENT_FAILED:
    case TaskDetailsConstant.DELETE_COMMENT_FAILED:
    case TaskDetailsConstant.ADD_REPLY_FAILED:
    case TaskDetailsConstant.UPDATE_REPLY_FAILED:
    case TaskDetailsConstant.DELETE_REPLY_FAILED:
    case TaskDetailsConstant.LOAD_ATTACHMENT_FAILED:
    case TaskDetailsConstant.LOAD_HISTORY_FAILED:
    case TaskDetailsConstant.UPLOAD_ATTACHMENT_FAILED:
    case TaskDetailsConstant.DELETE_ATTACHMENT_FAILED:
    case TaskDetailsConstant.UPDATE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TaskDetailsConstant.LOAD_HISTORY_SUCCEEDED:
    {
      const copyLoadHistorySucceeded = {
        ...state.datas,
        activities: action.payload.activities,
      };
      return {
        ...state,
        datas: copyLoadHistorySucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.LOAD_COMMENT_SUCCEEDED:
    {
      const upsertComment = action.payload.comments[0];
      let foundCom = false;
      let commentss = state.datas.comments.map(comm => {
        if (comm.cdx_id === upsertComment.cdx_id) {
          foundCom = true;
          return upsertComment;
        }
        return comm;
      });
      if (!foundCom) {
        commentss = commentss.concat(upsertComment);
      }
      const copyLoadCommentSucceeded = {
        ...state.datas,
        comments: Utils.setCommentOrder(commentss),
      };
      return {
        ...state,
        datas: copyLoadCommentSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED:
    {
      const copyUploadAttachmentSucceeded = {
        ...state.datas,
        attachments: state.datas.attachments ? state.datas.attachments.concat(action.payload.attachments) : action.payload.attachments,
      };
      return {
        ...state,
        datas: copyUploadAttachmentSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED:
    {
      const copyDeleteAttachmentSucceeded = {
        ...state.datas,
        attachments: state.datas.attachments.filter(att => att.cdx_id !== action.payload.attachments[0].cdx_id)
      };
      return {
        ...state,
        datas: copyDeleteAttachmentSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED:
    {
      const toReplace = state.datas.attachments.map(att => att.cdx_id).indexOf(action.payload.attachments[0].cdx_id);
      const updated = action.payload.attachments[0];
      updated.cdx_thumbnail = state.datas.attachments[toReplace].cdx_thumbnail;
      state.datas.attachments.splice(toReplace, 1, updated);
      const copyUpdateAttachmentSucceeded = {
        ...state.datas,
        attachments: state.datas.attachments
      };
      return {
        ...state,
        datas: copyUpdateAttachmentSucceeded,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.REMOVE_ALL_SUCCEEDED:
    {
      return {
        ...state,
        datas: null,
        displayInfoBlock: false,
        loading: false,
        error: null,
      };
    }
    case TaskDetailsConstant.UPDATE_TOKEN:
    {
      state.datas.details.token = action.payload.token;
      const copyUpdateToken = {
        ...state,
        datas: state.datas
      };
      return copyUpdateToken;
    }
    case TaskDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED:
    {
      const copyLoadDisplayBlockSucceeded = {
        ...state,
        displayInfoBlock: action.payload.displayInfoBlock
      };
      return copyLoadDisplayBlockSucceeded;
    }
  }
  return state;
};
