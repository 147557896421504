import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {Aggregate} from '../../../../models/aggregate';
import {SearchItem} from '../../../../models/search-item';


export class DraftFilterActionConstant {
  static readonly ADD_FILTER_ITEMS_START = '[DraftFilter].ADD_FILTER_ITEMS_START';
  static readonly ADD_FILTER_ITEMS_SUCCEEDED = '[DraftFilter].ADD_FILTER_ITEMS_SUCCEEDED';
  static readonly ADD_FILTER_ITEMS_FAILED = '[DraftFilter].ADD_FILTER_ITEMS_FAILED';

  static readonly UPDATE_FILTER_ITEMS_START = '[DraftFilter].UPDATE_FILTER_ITEMS_START';
  static readonly UPDATE_FILTER_ITEMS_SUCCEEDED = '[DraftFilter].UPDATE_FILTER_ITEMS_SUCCEEDED';
  static readonly UPDATE_FILTER_ITEMS_FAILED = '[DraftFilter].UPDATE_FILTER_ITEMS_FAILED';

  static readonly REMOVE_FILTER_ITEM_STARTED = '[DraftFilter].REMOVE_FILTER_ITEM_STARTED';
  static readonly REMOVE_FILTER_ITEM_SUCCEEDED = '[DraftFilter].REMOVE_FILTER_ITEM_SUCCEEDED';
  static readonly REMOVE_FILTER_ITEM_FAILED = '[DraftFilter].REMOVE_FILTER_ITEM_FAILED';

  static readonly REMOVE_ALL_FILTER_ITEMS_SUCCEEDED = '[DraftFilter].REMOVE_ALL_FILTER_ITEMS_SUCCEEDED';
  static readonly REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED = '[DraftFilter].REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED';
}

export const addDraftFilterStartedAction = createAction(
  DraftFilterActionConstant.ADD_FILTER_ITEMS_START
);

export const addDraftFilterSucceededAction = createAction(
  DraftFilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const addDraftFilterFailedAction = createAction(
  DraftFilterActionConstant.ADD_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const updateDraftFilterStartedAction = createAction(
  DraftFilterActionConstant.UPDATE_FILTER_ITEMS_START
);

export const updateDraftFilterSucceededAction = createAction(
  DraftFilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const updateDraftFilterFailedAction = createAction(
  DraftFilterActionConstant.UPDATE_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const removeDraftFilterStartedAction = createAction(
  DraftFilterActionConstant.REMOVE_FILTER_ITEM_STARTED
);

export const removeDraftFilterSucceededAction = createAction(
  DraftFilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: null } }>()
);

export const removeDraftFilterFailedAction = createAction(
  DraftFilterActionConstant.REMOVE_FILTER_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeDraftFiltersAndKeepLastAggSucceededAction = createAction(
  DraftFilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED
);

export const removeAllDraftFiltersSucceededAction = createAction(
  DraftFilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED
);

@Injectable({
  providedIn: 'root'
})
export class DraftFilterAction {

  constructor(private localStore: Store) {
  }

  public addFilterStarted(): void {
    this.localStore.dispatch(addDraftFilterStartedAction());
  }

  public addFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    this.localStore.dispatch(addDraftFilterSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: agg
      }
    }));
  }

  public addFilterFailed(error: Error) {
    this.localStore.dispatch(addDraftFilterFailedAction({error}));
  }

  public updateFilterStarted(): void {
    this.localStore.dispatch(updateDraftFilterStartedAction());
  }

  public updateFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    this.localStore.dispatch(updateDraftFilterSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: agg
      }
    }));
  }

  public updateFilterFailed(error: Error) {
    this.localStore.dispatch(updateDraftFilterFailedAction({error}));
  }

  public removeFilterStarted(): void {
    this.localStore.dispatch(removeDraftFilterStartedAction());
  }

  public removeFilterSucceeded(filterItem: SearchItem): void {
    this.localStore.dispatch(removeDraftFilterSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: null
      }
    }));
  }

  public removeFilterFailed(error: Error) {
    this.localStore.dispatch(removeDraftFilterFailedAction({error}));
  }

  public removeFiltersAndKeepLastAgg(): void {
    this.localStore.dispatch(removeDraftFiltersAndKeepLastAggSucceededAction());
  }

  public removeAllFiltersSucceeded(): void {
    this.localStore.dispatch(removeAllDraftFiltersSucceededAction());
  }
}

