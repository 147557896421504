import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../../../modules/configuration/configuration.service';
import { Url } from '../../../models/url';
import { View } from '../../../models/view';
import { ViewAction } from '../action/view.action';
import { selectViewFeature } from '../view-selectors';
import { Store } from '@ngrx/store';
import { ViewState } from '../reducer/view.reducer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  /* tslint: disablenext-line */
  private viewState: ViewState;

  constructor(
    private viewAction: ViewAction,
    private httpClient: HttpClient,
    private configAction: ConfigurationService,
    private store: Store
  ) {
    this.store.select(selectViewFeature)
      .pipe(takeUntilDestroyed())
      .subscribe( viewState => this.viewState = viewState);
  }

  public getRecapView(docType: string, force = false): void {
    try {
      if (!force && this.get(docType)) {
        return;
      }
      this.viewAction.loadViewStart();
      this.httpClient.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.VIEW + Url.RECAP + docType)
        .subscribe((view: View) => {
          const views: {[key: string]: View} = {};
          views[view.documentTypeCode] = view;
          this.viewAction.loadViewSucceeded(views);
      },
          (error: HttpErrorResponse) =>
          this.viewAction.loadViewFailed(error)
          );
    } catch (err) {
      console.error('error', err);
      this.viewAction.loadViewFailed(err);
    }
  }

  public get(documentType: string) {
    if (!!this.viewState && !!this.viewState.datas) {
      return this.viewState.datas[documentType] && !!this.viewState.datas[documentType].disposition && this.viewState.datas[documentType].disposition.length > 0;
    }
    return null;
  }

}
