import {
  addCacheFormFailedAction,
  addCacheFormStartAction,
  addCacheFormSucceededAction,
  removeCacheFormSucceededAction,
  updateCacheFormSucceededAction
} from '../action/cache-form.action';
import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

export class CacheFormState {
  datas: { [key: string]: any };
  loading: boolean;
  error: any;
}

const CACHE_FORM_INITIAL_STATE: CacheFormState = {
  datas: null,
  loading: false,
  error: null,
};

/*
export const cacheFormReducerOld = (state: CacheFormState = CACHE_FORM_INITIAL_STATE, action: PayloadAction<{
  [key: string]: any
}>): CacheFormState => {
  switch (action.type) {
    case CacheFormConstant.ADD_CACHE_FORM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CacheFormConstant.ADD_CACHE_FORM_SUCCEEDED:
      const formToAddKey: string = Object.keys(action.payload)[0];
      let datasCopy;
      if ( state.datas === null) {
        datasCopy = action.payload;
      } else {
        datasCopy = _.cloneDeep(state.datas);
        datasCopy[formToAddKey] = action.payload[formToAddKey];
      }

      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };

    case CacheFormConstant.REMOVE_CACHE_FORM_SUCCEEDED:
      const formToDeleteKey: string = Object.keys(action.payload)[0];
      if (formToDeleteKey in state.datas) {
        delete state.datas[formToDeleteKey];
      }
      return {
        ...state,
        datas: {
          ...state.datas
        },
        loading: false,
        error: null,
      };

    case CacheFormConstant.UPDATE_CACHE_FORM_SUCCEEDED:
      const formToUpdateKey: string = Object.keys(action.payload)[0];
      const curFormCache: any = state.datas[formToUpdateKey];
      const dataToUpdateKeys: string[] = Object.keys(action.payload[formToUpdateKey]);
      dataToUpdateKeys.forEach((dataToUpdateKey: string) => {
        if (action.payload[formToUpdateKey][dataToUpdateKey] instanceof Array) {
          curFormCache[dataToUpdateKey] = !!curFormCache[dataToUpdateKey] ? [...action.payload[formToUpdateKey][dataToUpdateKey], ...curFormCache[dataToUpdateKey]] : action.payload[formToUpdateKey][dataToUpdateKey];
        } else {
          curFormCache[dataToUpdateKey] = action.payload[formToUpdateKey][dataToUpdateKey];
        }
      });
      // TO IMPLEMENT
      return {
        ...state,
        datas: {
          ...state.datas
        },
        loading: false,
        error: null,
      };

    case CacheFormConstant.ADD_CACHE_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
*/

export const cacheFormReducer = createReducer(
  // Initial State
  CACHE_FORM_INITIAL_STATE,

  // Start Action
  on(addCacheFormStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Succeeded Actions
  on(addCacheFormSucceededAction, (state, { payload }) => {
    const formToAddKey: string = Object.keys(payload)[0];
    let datasCopy;
    if ( state.datas === null) {
      datasCopy = payload;
    } else {
      datasCopy = _.cloneDeep(state.datas);
      datasCopy[formToAddKey] = payload[formToAddKey];
    }
      return {
        ...state,
        datas: datasCopy,
        loading: false,
        error: null,
      };
    }
  ),

  on(removeCacheFormSucceededAction, (state, { payload }) => {
    const formToDeleteKey: string = Object.keys(payload)[0];
    const state_datas = _.cloneDeep(state.datas);
    if (formToDeleteKey in state_datas ) {
      delete state_datas[formToDeleteKey];
    }
    return {
      ...state,
      datas: {
        ...state_datas
      },
      loading: false,
      error: null,
    };
  }),

  on(updateCacheFormSucceededAction, (state, { payload }) => {
    const formToUpdateKey: string = Object.keys(payload)[0];
    const curFormCache: any = state.datas[formToUpdateKey];
    const dataToUpdateKeys: string[] = Object.keys(payload[formToUpdateKey]);
    dataToUpdateKeys.forEach((dataToUpdateKey: string) => {
      if (payload[formToUpdateKey][dataToUpdateKey] instanceof Array) {
        curFormCache[dataToUpdateKey] = !!curFormCache[dataToUpdateKey] ? [...payload[formToUpdateKey][dataToUpdateKey], ...curFormCache[dataToUpdateKey]] : payload[formToUpdateKey][dataToUpdateKey];
      } else {
        curFormCache[dataToUpdateKey] = payload[formToUpdateKey][dataToUpdateKey];
      }
    });
    // TO IMPLEMENT
    return {
      ...state,
      datas: {
        ...state.datas
      },
      loading: false,
      error: null,
    };
  }),

  // Failed Action
  on(addCacheFormFailedAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
