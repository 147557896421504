import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngrx/store';
import {ToastaService} from 'ngx-toasta';
import {Observable} from 'rxjs';
import {ConfigurationService} from '../../../../../modules/configuration/configuration.service';
import {Field} from '../../../../models/field';
import {Url} from '../../../../models/url';
import {GedCfgFieldAction} from '../../action/ged/ged-cfg-field.action';
import {selectGedCfgFieldFeature} from '../../field-selectors';
import {GedFieldState} from '../../reducer/ged/ged-cfg-field.reducer';
import {AbstractCfgFieldService} from '../abstract-cfg-field.service';

@Injectable({
  providedIn: 'root'
})
export class GedCfgFieldService extends AbstractCfgFieldService {

  private gedCfgFields$ = this.store.select(selectGedCfgFieldFeature);
  private gedCfgField: GedFieldState;

  constructor(
    http: HttpClient,
    configAction: ConfigurationService,
    toastaService: ToastaService,
    private store: Store,
    private gedCfgFieldAction: GedCfgFieldAction
  ) {
    super(http, configAction, toastaService);
    this.gedCfgFields$.pipe(takeUntilDestroyed()).subscribe(cfgField => this.gedCfgField = cfgField);
  }

  public get(fieldCode: string): Field {
    const fieldsState: GedFieldState = this.getCfgFieldStoreState();
    return this._getStoreFieldByCode(fieldsState, fieldCode);
  }

  protected getApiBaseUrl(): string {
    return Url.getProtectedApiBaseUrl(this.configService) + Url.FIELDS;
  }

  protected getCfgFieldStore(): Observable<GedFieldState> {
    return this.gedCfgFields$;
  }

  protected cfgFieldActionLoadStarted(): void {
    this.gedCfgFieldAction.loadStarted();
  }

  protected cfgFieldActionLoadSucceeded(val: { [key: string]: Field }): void {
    this.gedCfgFieldAction.loadSucceeded(val);
  }

  protected cfgFieldActionLoadFailed(error: any): void {
    this.gedCfgFieldAction.loadFailed(error);
  }

  protected cfgFieldActionRemoveAllStarted(): void {
    this.gedCfgFieldAction.removeAllStarted();
  }

  protected cfgFieldActionRemoveAllSucceeded(): void {
    this.gedCfgFieldAction.removeAllSucceeded();
  }

  protected getCfgFieldStoreState(): GedFieldState {
    return this.gedCfgField;
  }
}
