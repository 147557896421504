import {Injectable} from '@angular/core';
import {CdxComment} from '../../../models/cdx-comment';
import {createAction, props, Store} from '@ngrx/store';
import {CurrentCommentContainer} from '../../../models/current-comment.container';

export class CurrentCommentConstant {
  static readonly LOAD_CURRENT_COMMENT_START = '[CdxComment]: LOAD_CURRENT_COMMENT_START';
  static readonly LOAD_CURRENT_COMMENT_SUCCEEDED = '[CdxComment]: LOAD_CURRENT_COMMENT_SUCCEEDED';
  static readonly LOAD_CURRENT_COMMENT_FAILED = '[CdxComment]: LOAD_CURRENT_COMMENT_FAILED';
  static readonly REMOVE_CURRENT_COMMENT_SUCCEEDED = '[CdxComment]: REMOVE_SUCCEEDED';
}

export const loadCurrentCommentStartAction = createAction(CurrentCommentConstant.LOAD_CURRENT_COMMENT_START);
export const loadCurrentCommentSucceededAction = createAction(CurrentCommentConstant.LOAD_CURRENT_COMMENT_SUCCEEDED, props<{payload: CurrentCommentContainer}>());
export const loadCurrentCommentFailedAction = createAction(CurrentCommentConstant.LOAD_CURRENT_COMMENT_FAILED, props<{error: Error}>());
export const removeCurrentCommentSucceededAction = createAction(CurrentCommentConstant.REMOVE_CURRENT_COMMENT_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class CurrentCommentAction {
  constructor(private store: Store) {
  }

  public loadCurrentCommentStart(): void {
    this.store.dispatch(loadCurrentCommentStartAction());
  }

  public loadCurrentCommentSucceeded(comment: CdxComment, isFromError: boolean): void {
    if (!comment) {
      this.loadCurrentCommentFailed('comment cannot be null or undefined');
      return;
    }
    const payload: CurrentCommentContainer = new CurrentCommentContainer(comment, isFromError);
    this.store.dispatch(loadCurrentCommentSucceededAction({payload}));
  }

  public loadCurrentCommentFailed(error: any) {
    this.store.dispatch(loadCurrentCommentFailedAction({error}));
  }

  public removeComment() {
    this.store.dispatch(removeCurrentCommentSucceededAction());
  }


}
