import { Injectable } from '@angular/core';
import { Team } from '../../../../models/team';
import { createAction, props, Store } from '@ngrx/store';

export class CgfTeamConstantAction {
  static readonly ADD_MULTIPLE_CFG_TEAM_START = 'ADD_MULTIPLE_CFG_TEAM_START';
  static readonly ADD_MULTIPLE_CFG_TEAM_SUCCEEDED = 'ADD_MULTIPLE_CFG_TEAM_SUCCEEDED';
  static readonly ADD_MULTIPLE_CFG_TEAM_FAILED = 'ADD_MULTIPLE_CFG_TEAM_FAILED';

  static readonly REMOVE_ALL_CFG_TEAM_SUCCEEDED = 'REMOVE_ALL_CFG_TEAM_SUCCEEDED';
}

export const addMultipleCfgTeamStartAction = createAction(CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_START);
export const addMultipleCfgTeamSuceedAction = createAction(CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_SUCCEEDED, props<{payload:Team[]}>());
export const addMultipleCfgTeamFailedAction = createAction(CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_FAILED, props<{error:Error}>());
export const removeAllCfgTeamAction = createAction(CgfTeamConstantAction.REMOVE_ALL_CFG_TEAM_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class CfgTeamAction {

  constructor( private localStore: Store ) {
  }

  public loadCfgTeamsStarted(): void {
    this.localStore.dispatch(addMultipleCfgTeamStartAction());
  }

  public loadCfgTeamsSucceeded(payload: Team[]): void {
    this.localStore.dispatch(addMultipleCfgTeamSuceedAction({payload}));
  }

  public loadCfgTeamsFailed(error: any) {
    this.localStore.dispatch(addMultipleCfgTeamFailedAction({error}));
  }

  public removeAllCfgTeamsSucceeded(): void {
    this.localStore.dispatch(removeAllCfgTeamAction());
  }
}
