import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../redux/payload-action';
import {
  CurrentTeamConstantAction,
  loadCurrentTeamFailedAction,
  loadCurrentTeamStartAction,
  loadCurrentTeamSucceededAction,
  removeCurrentTeamAction
} from '../action/current-team.action';
import { createReducer, on } from '@ngrx/store';

export class CurrentTeamState {
  datas: Team;
  loading: boolean;
  error: any;
}

export const CURRENT_TEAM_INITIAL_STATE: CurrentTeamState = {
  datas: null,
  loading: false,
  error: null,
};



export const currentTeamReducerOld = (state: CurrentTeamState = CURRENT_TEAM_INITIAL_STATE, action: PayloadAction<Team>) => {
  switch (action.type) {
    case CurrentTeamConstantAction.LOAD_CURRENT_TEAM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CurrentTeamConstantAction.LOAD_CURRENT_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CurrentTeamConstantAction.LOAD_CURRENT_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CurrentTeamConstantAction.REMOVE_CURRENT_TEAM:
      return {
        ...state,
        datas: null,
        loading: false,
        error: action.error,
      };
  }
  return state;
};


export const currentTeamReducer = createReducer(
  CURRENT_TEAM_INITIAL_STATE,
  on(loadCurrentTeamStartAction,(state)=>({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCurrentTeamSucceededAction,(state,{payload})=>({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(loadCurrentTeamFailedAction,(state, {error})=>({
    ...state,
    loading: false,
    error: error,
  })),
  on(removeCurrentTeamAction,(state)=>({
    ...state,
    datas: null,
    loading: false,
  })),
);
