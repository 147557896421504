import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {CacheDraft} from '../../../../models/CacheDraft';
import {DraftDocument} from '../../../../models/DraftDocument';

export class CacheDraftConstant {
  static readonly ADD_CACHE_DRAFT_DATA_START = '[CacheDraft].ADD_CACHE_DRAFT_DATA_START';
  static readonly ADD_CACHE_DRAFT_DATA_SUCCEEDED = '[CacheDraft].ADD_CACHE_DRAFT_DATA_SUCCEEDED';
  static readonly ADD_CACHE_DRAFT_DATA_FAILED = '[CacheDraft].ADD_CACHE_DRAFT_DATA_FAILED';
  static readonly ADD_CACHE_DRAFT_ATTRIBUTE_START = '[CacheDraft].ADD_CACHE_DRAFT_ATTRIBUTE_START';
  static readonly ADD_CACHE_DRAFT_ATTRIBUTE_FAILED = '[CacheDraft].ADD_CACHE_DRAFT_ATTRIBUTE_FAILED';
  static readonly ADD_CACHE_DRAFT_INDEXING_NEXT_DOC_SUCCEEDED = '[CacheDraft].ADD_CACHE_DRAFT_INDEXING_NEXT_DOC_SUCCEEDED';
  static readonly SET_CACHE_DRAFT_APPLY_CACHE_SUCCEEDED = '[CacheDraft].SET_CACHE_DRAFT_APPLY_CACHE_SUCCEEDED';
}

export const addCacheDraftDataStartedAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_DATA_START
);

export const addCacheDraftDataSucceededAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_DATA_SUCCEEDED,
  props<{ payload: CacheDraft }>()
);

export const addCacheDraftDataFailedAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_DATA_FAILED,
  props<{ error: Error }>()
);

export const addCacheDraftIndexingNextDocSucceededAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_INDEXING_NEXT_DOC_SUCCEEDED,
  props<{ payload: CacheDraft }>()
);


export const setCacheDraftApplyCacheSucceededAction = createAction(
  CacheDraftConstant.SET_CACHE_DRAFT_APPLY_CACHE_SUCCEEDED,
  props<{ payload: CacheDraft }>()
);


export const addCacheDraftAttributeStartedAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_ATTRIBUTE_START
);

export const addCacheDraftAttributeFailedAction = createAction(
  CacheDraftConstant.ADD_CACHE_DRAFT_ATTRIBUTE_FAILED,
  props<{ error: Error }>()
);

@Injectable({
  providedIn: 'root'
})
export class CacheDraftAction {

  constructor(private localStore: Store) {}

  public addCacheDraftDataStarted(): void {
    this.localStore.dispatch(addCacheDraftDataStartedAction());
  }

  public addCacheDraftDataSucceeded(draftData: DraftDocument): void {
    const payload = new CacheDraft();
    payload.draftData = draftData;
    this.localStore.dispatch(addCacheDraftDataSucceededAction({ payload }));
  }

  public addCacheDraftDataFailed(error: Error) {
    this.localStore.dispatch(addCacheDraftDataFailedAction({ error }));
  }

  public addIndexingNextDocSucceeded(indexing: boolean): void {
    const payload = new CacheDraft();
    payload.indexNextDoc = indexing;
    this.localStore.dispatch(addCacheDraftIndexingNextDocSucceededAction({ payload }));
  }

  public setApplyCacheSucceeded(applyCache: boolean): void {
    const payload = new CacheDraft();
    payload.applyCache = applyCache;
    this.localStore.dispatch(setCacheDraftApplyCacheSucceededAction({ payload }));
  }

  public addAttributeStarted(): void {
    this.localStore.dispatch(addCacheDraftAttributeStartedAction());
  }

  public addAttributeFailed(error: Error) {
    this.localStore.dispatch(addCacheDraftAttributeFailedAction({ error }));
  }
}
