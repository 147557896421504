import {Injectable} from '@angular/core';
import {Field} from '../../../../models/field';
import {
  AbstractCfgFieldAction
} from '../abstract-cfg-field.action';
import {
  wkfAddMultipleCfgFieldFailedAction,
  wkfAddMultipleCfgFieldStartAction,
  wkfAddMultipleCfgFieldSucceededAction,
  wkfRemoveAllCfgFieldFailedAction,
  wkfRemoveAllCfgFieldStartAction,
  wkfRemoveAllCfgFieldSucceededAction
} from '../const-field-actions';
import { Store} from '@ngrx/store';


@Injectable({
  providedIn: 'root'
})
export class WkfCfgFieldAction extends AbstractCfgFieldAction {

  constructor(
    store: Store
  ) {
    super(store);
  }

  public loadStarted(): void {
    this._dispatchType(wkfAddMultipleCfgFieldStartAction());
  }

  public loadSucceeded(mapField: { [key: string]: Field }): void {
    this._dispatchSuccess(wkfAddMultipleCfgFieldSucceededAction({ payload: mapField }));
  }

  public loadFailed(error: any): void {
    this._dispatchFail(wkfAddMultipleCfgFieldFailedAction({ error }));
  }

  public removeAllStarted(): void {
    this._dispatchType(wkfRemoveAllCfgFieldStartAction());
  }

  public removeAllSucceeded(): void {
    this._dispatchType(wkfRemoveAllCfgFieldSucceededAction());
  }

  public removeAllFailed(error: any): void {
    this._dispatchFail(wkfRemoveAllCfgFieldFailedAction({ error }));
  }

}
