import { Injectable } from '@angular/core';
import { EsAuthor } from '../../../models/EsAuthor';
import { createAction, props, Store } from '@ngrx/store';

export class UsersActionConstant {
  static readonly LOAD_USERS_STARTED = '[UserObjects]: LOAD_USERS_STARTED';
  static readonly LOAD_USERS_SUCCEEDED = '[UserObjects]: LOAD_USERS_SUCCEEDED';
  static readonly LOAD_USERS_FAILED = '[UserObjects]: LOAD_USERS_FAILED';
}

export const loadUsersStartedAction = createAction(UsersActionConstant.LOAD_USERS_STARTED);
export const loadUsersSucceedAction = createAction(UsersActionConstant.LOAD_USERS_SUCCEEDED, props<{payload: EsAuthor[]}>());
export const loafUsersFailedAction = createAction(UsersActionConstant.LOAD_USERS_FAILED, props<{error: Error}>());

@Injectable({
  providedIn: 'root'
})
export class UsersAction {
  public static readonly _ALL = '_all';

  constructor(private localStore: Store ) {
  }

  public loadUsersStart(): void {
    this.localStore.dispatch(loadUsersStartedAction());
  }

  public loadUsersSucceeded(payload: EsAuthor[]): void {
    this.localStore.dispatch(loadUsersSucceedAction({payload}));
  }

  public loadUsersFailed(error: any) {
    this.localStore.dispatch(loafUsersFailedAction({error}));
  }
}
