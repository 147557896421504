import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';

export class IndexationDocTypesConstant {
  static readonly LOAD_INDEXATION_DOC_TYPES_START = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_START';
  static readonly LOAD_INDEXATION_DOC_TYPES_SUCCEEDED = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_SUCCEEDED';
  static readonly LOAD_INDEXATION_DOC_TYPES_FAILED = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_FAILED';
}

export const loadIndexationDocTypesStartAction = createAction(
  IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_START
);

export const loadIndexationDocTypesSucceededAction = createAction(
  IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_SUCCEEDED,
  props<{ payload: Map<string, string[]> }>()
);

export const loadIndexationDocTypesFailedAction = createAction(
  IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_FAILED,
  props<{ error: Error }>()
);


@Injectable({
  providedIn: 'root'
})
export class IndexationDocTypesAction {

  constructor(private localStore: Store) {}

  public loadStarted(): void {
    this.localStore.dispatch(loadIndexationDocTypesStartAction());
  }

  public loadSucceeded(indexationDocTypes: Map<string, string[]>): void {
    this.localStore.dispatch(loadIndexationDocTypesSucceededAction({ payload: indexationDocTypes }));
  }

  public loadFailed(error: Error) {
    this.localStore.dispatch(loadIndexationDocTypesFailedAction({ error }));
  }
}
