import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {currentCommentReducer} from './reducer/current-comment.reducer';


export const currentCommentStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CURRENT_COMMENT,
    reducer: currentCommentReducer
  })
)
