import {createReducer, on} from '@ngrx/store';
import {
  loadCurrentCommentFailedAction,
  loadCurrentCommentStartAction,
  loadCurrentCommentSucceededAction,
  removeCurrentCommentSucceededAction
} from '../action/current-comment.action';
import {CurrentCommentContainer} from '../../../models/current-comment.container';

export class CurrentCommentState {
  datas: CurrentCommentContainer;
  loading: boolean;
  error: any;
}

export const CURRENT_COMMENT_INITIAL_STATE: CurrentCommentState = {
  datas: null,
  loading: false,
  error: null,
};


// const currentCommentReducerOld = (state: CurrentCommentState = CURRENT_COMMENT_INITIAL_STATE, action: PayloadAction<CurrentCommentContainer>) => {
//   switch (action.type) {
//     case CurrentCommentConstant.LOAD_CURRENT_COMMENT_START:
//       return {
//         ...state,
//         loading: true,
//         error: null,
//       };
//     case CurrentCommentConstant.LOAD_CURRENT_COMMENT_SUCCEEDED:
//       return {
//         ...state,
//         datas: action.payload,
//         loading: false,
//         error: null,
//       };
//     case CurrentCommentConstant.LOAD_CURRENT_COMMENT_FAILED:
//       return {
//         ...state,
//         loading: false,
//         error: action.error,
//       };
//     case CurrentCommentConstant.REMOVE_CURRENT_COMMENT_SUCCEEDED:
//       return {
//         ...state,
//         datas: null,
//         loading: false,
//         error: null,
//       };
//   }
//   return state;
// };

export const currentCommentReducer = createReducer(CURRENT_COMMENT_INITIAL_STATE,
  on(loadCurrentCommentStartAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCurrentCommentSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(loadCurrentCommentFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(removeCurrentCommentSucceededAction, (state) => ({
    ...state,
    datas: null,
    loading: false,
    error: null,
  }))
);
