import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {Utils} from '../../../utils/utils';
import {CriticalDataAction} from '../action/critical-data.action';
import {selectCriticalDataDatas} from '../critical-data-selectors';

import {CriticalData} from '../../../models/critical.data';

@Injectable({
  providedIn: 'root'
})
export class CriticalDataService implements OnDestroy {

  private criticalDatas$ = this.store.select(selectCriticalDataDatas);
  private criticalDatas: CriticalData;
  private subs: Subscription[] = [];

  constructor(
    private criticalDataAction: CriticalDataAction,
    private store: Store,
  ) {
    this.subs.push(this.criticalDatas$.subscribe(datas => this.criticalDatas = datas));
  }

  public addCriticalFieldToStore(criticalFieldCode: string): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addCriticalFieldToStore(criticalFieldCode);
  }

  public addViewCriticalFieldsToStore(viewCriticalFields: string[]): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addViewCriticalFieldsToStore(viewCriticalFields);
  }

  public addAllToVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.loadCriticalStart();
    let visibleCriticalFields: string[] = [];
    const criticalData: CriticalData = this.criticalDatas;
    if (criticalData.viewCriticalFields && criticalData.viewCriticalFields.length) {
      visibleCriticalFields = criticalData.viewCriticalFields;
    }
    this.criticalDataAction.addAllToVisibleCriticalFieldsStore(visibleCriticalFields);
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addVisibleCriticalPdfToStore(isVisible);
  }

  public removeAllFromVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.removeAllFromVisibleCriticalFieldsStore();
  }

  public removeViewCriticalFieldsStore(): void {
    this.criticalDataAction.removeViewCriticalFieldsStore();
  }

  public unsetVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.unsetVisibleCriticalFieldsStore();
  }

  public updateReadCriticalFieldValueAllDone(criticalData: CriticalData): boolean {
    if (!criticalData) {
      return false;
    }
    if (!criticalData.viewCriticalFields || !criticalData.viewCriticalFields.length) {
      return Utils.notNullAndNotUndefined(criticalData.visibleCriticalPdf) ? criticalData.visibleCriticalPdf : true;
    }
    if (!criticalData.visibleCriticalFields || !criticalData.visibleCriticalFields.length) {
      return false;
    }
    let allVisible = true;
    if (Utils.notNullAndNotUndefined(criticalData.visibleCriticalPdf)) {
      criticalData.viewCriticalFields.forEach((viewCritFieldCode: string) => {
        if (!allVisible) {
          return;
        }
        allVisible = criticalData.visibleCriticalFields.some((visibleCritFieldCode: string) => visibleCritFieldCode === viewCritFieldCode) && criticalData.visibleCriticalPdf;
      });
      return allVisible;
    }
    criticalData.viewCriticalFields.forEach((viewCritFieldCode: string) => {
      if (!allVisible) {
        return;
      }
      allVisible = criticalData.visibleCriticalFields.some((visibleCritFieldCode: string) => visibleCritFieldCode === viewCritFieldCode);
    });
    return allVisible;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
