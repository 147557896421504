import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../core/models/store-keys';
import {authenticationReducer} from './authentication.reducer';
import {withSessionStoragePersistence} from "../../redux/reducer-utils/with-session-storage-persistence";

export const authenticationStoreConfig = StoreModule
  .forFeature(createFeature({
    name: StoreKeys.AUTHENTICATION,
    reducer: withSessionStoragePersistence(StoreKeys.AUTHENTICATION, authenticationReducer)
  }))
