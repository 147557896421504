import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {taskFilterReducer} from './reducer/task-filter.reducer';

export const taskFilterStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TASK_FILTERS,
    reducer: taskFilterReducer
  })
);
