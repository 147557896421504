import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';

import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { LoadableThesaurusService } from '../../service/loadable-thesaurus.service';
import { TreeService } from '../../service/tree.service';

import { Status } from '../../../../models/status';
import { LoadableThesaurus } from '../../../../models/loadable-thesaurus';
import { FlatTreeNode } from '../../../../models/flat-tree';
import { TreeDataSource } from '../../tree.datasource';
import { RegionItemI18n } from '../../../../models/region-item-i18n';
import { ExchangeAction, ThesaurusModalExchangeData } from '../../modal/thesaurus-modal/thesaurus-modal.component';
import { InternalRoutes } from '../../../../models/internal-routes';
import { ThesaurusService } from '../../../../services/thesaurus/thesaurus.service';
import { Field } from '../../../../models/field';
import { EsPage } from '../../../../models/es-page';
import { CdxDocument } from '../../../../models/cdx-document';
import { Entity } from '../../../../models/Entity';
import { CdxTask } from '../../../../models/cdx-task';
import { DataVisibility } from '../../../../models/data-visibility';

export class SearchIsLoading {
  public loading: boolean;
  public nodeValue: string;
  constructor(loading: boolean, nodeValue: string) {
    this.loading = loading;
    this.nodeValue = nodeValue;
  }
}

export class SearchResultEmitter {
  public currentPage: string;
  public nodeLabel: string;
  public closeModal: boolean;
  constructor(currentPage: string, nodeLabel: string, closeModal: boolean) {
    this.currentPage = currentPage;
    this.nodeLabel = nodeLabel;
    this.closeModal = closeModal;
  }
}

@Component({
  selector: 'app-thesaurus-tree',
  templateUrl: './thesaurus-tree.component.html',
  styleUrls: ['./thesaurus-tree.component.scss']
})
export class ThesaurusTreeComponent implements OnInit, OnChanges {

  @Input()
  thesaurusRootCode: string;

  @Input()
  thesaurusEntrypointCode: string;

  @Input()
  maxSelection: number;

  @Input()
  autopostage = false;

  @Input()
  visibility: DataVisibility;

  @Input()
  showSearchBtn = false;

  @Input()
  currentField: Field;

  @Input()
  regionPath: RegionItemI18n[][];

  @Output()
  simpleSelection: EventEmitter<FlatTreeNode> = new EventEmitter<FlatTreeNode>();

  @Output()
  multipleSelection: EventEmitter<ThesaurusModalExchangeData> = new EventEmitter<ThesaurusModalExchangeData>();

  @Output()
  searchResultEmitter: EventEmitter<SearchResultEmitter> = new EventEmitter<SearchResultEmitter>();

  constructor(
    private loadableThesaurusService: LoadableThesaurusService,
    private router: Router,
    private treeService: TreeService,
    private thesaurusService: ThesaurusService
  ) {
    this.calcCurrentUrl(router.url);
  }

  public isLoading = true;
  public searchIsLoading: SearchIsLoading = new SearchIsLoading(false, null);
  Status = Status;

  private loadableThesaurus: LoadableThesaurus = null;
  private rootFlatNode: FlatTreeNode = null;
  public currentPage: string;
  treeControl: FlatTreeControl<FlatTreeNode>;
  dataSource: TreeDataSource;

  private calcCurrentUrl(eventUrl: string) {
    const urlTree: UrlTree = this.router.parseUrl(eventUrl);
    if (urlTree) {
      urlTree.queryParams = {};
      const currentUrl = urlTree.toString();
      const urlPaths: string[] = currentUrl.split('/');
      if (urlPaths.indexOf(InternalRoutes.ENTITIES) !== -1) {
        this.currentPage = InternalRoutes.ENTITIES;
      } else if (urlPaths.indexOf(InternalRoutes.DOCUMENTS) !== -1) {
        this.currentPage = InternalRoutes.DOCUMENTS;
      } else if (urlPaths.indexOf(InternalRoutes.WORKFLOW) !== -1) {
        this.currentPage = InternalRoutes.WORKFLOW;
      } else {
        this.currentPage = undefined;
      }
    }
  }

  ngOnInit() {
    console.log(this.currentPage);
    this.treeControl = new FlatTreeControl<FlatTreeNode>(this.getLevel, this.isExpandable);

    this.loadableThesaurusService.getThesaurusSubTree(this.thesaurusRootCode, this.thesaurusEntrypointCode).then(
      (subTree) => {
        this.loadableThesaurus = subTree;

        const dataSource: TreeDataSource = this.createDataSource();
        this.updateDataSource(dataSource);
      }
    ).catch((err) => {
      alert('Err in loading \n' + err); // FIXME
    });
  }

  private createDataSource(): TreeDataSource {
    const dataSource = new TreeDataSource(this.loadableThesaurus, this.treeControl, this.loadableThesaurusService, this.treeService);

    this.rootFlatNode = this.treeService.fromThesaurus(this.loadableThesaurus);
    dataSource.data = [this.rootFlatNode];
    return dataSource;
  }

  private updateDataSource(dataSource: TreeDataSource): void {
    if (this.regionPath.length && this.regionPath[0].length) {
      // console.log('Path to open', this.data.pathToOpen);
      this.openPath(dataSource);
    } else {
      // shows Level 1 @ start
      dataSource.toggleNode(this.rootFlatNode, true).then(() => {
        this.dataSource = dataSource;
        this.isLoading = false;
      });
    }
  }

  private openPath(dataSource: TreeDataSource, i = 0, j = 0): void {
    const fromItem = this.loadableThesaurusService.getThesaurusRootName(this.loadableThesaurus);
    for (; j < this.regionPath[i].length; j++) {
      if (this.regionPath[i][j].value === fromItem) {
        break;
      }
    }
    dataSource.openPath(this.regionPath[i], j).then(() => {
      if (i < this.regionPath.length - 1) {
        i++;
        this.openPath(dataSource, i);
      } else {
        this.dataSource = dataSource;
        this.isLoading = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.regionPath && !changes.regionPath.isFirstChange()) {
      this.treeControl = new FlatTreeControl<FlatTreeNode>(this.getLevel, this.isExpandable);
      const dataSource: TreeDataSource = this.createDataSource();
      this.updateDataSource(dataSource);
    }
  }

  // ------------------------------------------------

  public searchByThesaurusValue(node: FlatTreeNode): void {
    this.searchIsLoading = new SearchIsLoading(true, node.value);
    this.thesaurusService.searchDocumentsOrTasksOrEntitiesByThesaurusNodeValue(this.currentPage, this.currentField.code, node.value).subscribe((esPage: EsPage<CdxDocument | CdxTask | Entity>) => {
      console.log('esPage', esPage);
      this.searchIsLoading = new SearchIsLoading(false, null);
      if (!esPage.content.length) {
        this.searchResultEmitter.emit(new SearchResultEmitter(this.currentPage, node.label, false));
      } else {
        this.searchResultEmitter.emit(new SearchResultEmitter(null, null, true));
      }
    },
      (error) => {
      console.error(error);
      this.searchIsLoading = new SearchIsLoading(false, null);
      }
    );
  }

  public nodeToggled(node: FlatTreeNode, event: MatCheckboxChange): void {
    const exchangeData: ThesaurusModalExchangeData = new ThesaurusModalExchangeData(event.checked ? ExchangeAction.ADD : ExchangeAction.REMOVE, [], null);
    exchangeData.treeNode = node;
    this.multipleSelection.emit(exchangeData);
  }

  public isLastNodeChecked(node): boolean {
    let isCheckedLastNode = false;
    this.regionPath.forEach((regionItems: RegionItemI18n[]) => {
      if (isCheckedLastNode) {
        return;
      }
      if (regionItems.length) {
        isCheckedLastNode = regionItems[regionItems.length - 1].value === node.value;
      }
    });
    return isCheckedLastNode;
  }

  public isNodeChecked(node): boolean {
    let isChecked = false;
    if (this.autopostage) {
       this.regionPath.forEach((regionItems: RegionItemI18n[]) => {
          if (isChecked) {
            return;
          }
         if (regionItems.length) {
           isChecked = regionItems.some((regionItem: RegionItemI18n) => regionItem.value === node.value);
         }
       });
    } else {
      isChecked = this.isLastNodeChecked(node);
    }
    return isChecked;
  }

  public ifAutopostageAndMultiSelection(node: FlatTreeNode): boolean {
    if ( this.visibility === DataVisibility.READONLY ) {
      return false;
    }

    if (this.maxSelection <= 1) {
      return true;
    }
    let isVisible = false;
    if (!this.autopostage) {
      isVisible = true;
    } else {
      if (this.isLastNodeChecked(node) || !this.isNodeChecked(node)) {
        isVisible = true;
      }
    }
    return isVisible;
  }

  public onSimpleSelection(node: FlatTreeNode): void {
    this.simpleSelection.emit(node);
  }

  public loadNextPage(loadMoreNode: FlatTreeNode): void {
    this.dataSource.loadMoreChilds(loadMoreNode);
  }

  public getLevel = (node: FlatTreeNode) => node.level;
  public isExpandable = (node: FlatTreeNode) => ( node.expandable );
  public hasChild = (num: number, node: FlatTreeNode) => ( node.expandable );
  public isLoadNextPage = (num: number, node: FlatTreeNode) => node.value === TreeDataSource.LOAD_MORE_NODE;
}
