import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PdfComponent} from './components/pdf/pdf.component';
import {PdfThumbnailComponent} from './components/pdf-thumbnail/pdf-thumbnail.component';
import {PdfViewerCustomComponent} from './components/pdf-viewer-custom/pdf-viewer-custom.component';
import {PdfViewerExtendComponent} from './components/pdf-viewer-extend/pdf-viewer-extend.component';
import {TranslateModule} from "@ngx-translate/core";

export * from './components/pdf-viewer-custom/typings';

@NgModule({
  declarations: [PdfComponent, PdfThumbnailComponent, PdfViewerCustomComponent, PdfViewerExtendComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [PdfComponent, PdfThumbnailComponent, PdfViewerCustomComponent, PdfViewerExtendComponent]
})
export class PdfModule {
}
