import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';

import { Page } from '../../../../models/page';
import { TeamUser } from '../../../../models/team-user';

export class CurrentTeamUsersPageConstantAction {
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_START = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_START';
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED';
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_FAILED = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_FAILED';
}

export const loadCurrentTeamUsersPageStartAction = createAction(CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_START);
export const loadCurrentTeamUsersPageSuceededAction = createAction(CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED,props<{payload:Page<TeamUser>}>());
export const loadCurrentTeamUsersPageFailedAction = createAction(CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_FAILED,props<{error: Error}>());

@Injectable({
  providedIn: 'root'
})
export class CurrentTeamUsersPageAction {

  constructor( private localStore: Store ) {
  }

  public loadCurrentTeamUsersPageStarted(): void {
    this.localStore.dispatch(loadCurrentTeamUsersPageStartAction());

  }

  public loadCurrentTeamUsersPageSucceeded(teamPage: Page<TeamUser>): void {
    this.localStore.dispatch(loadCurrentTeamUsersPageSuceededAction({payload:teamPage}));
  }

  public loadCurrentTeamUsersPageFailed(error: any) {
    this.localStore.dispatch(loadCurrentTeamUsersPageFailedAction({error}));
  }
}
