import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CapitalizeFirstLetterModule } from '../capitalize-firstLetter/capitalize-firstLetter.module';
import { UserSearchComponent } from './user-search/user-search.component';
import {ClickOutsideDirective} from "../../../shared-directives/click-outside.directive";

@NgModule({
  declarations: [
    UserSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    CapitalizeFirstLetterModule,
    ClickOutsideDirective
  ],
  exports : [
    UserSearchComponent
  ]
})
export class UserModule { }
