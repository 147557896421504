import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {MenuItem} from '../../../models/menu-item';

export class MenuActionConstant {
  static readonly LOAD_MENU_ITEMS_START = 'LOAD_MENU_ITEMS_START';
  static readonly LOAD_MENU_ITEMS_SUCCEEDED = 'LOAD_MENU_ITEMS_SUCCEEDED';
  static readonly LOAD_MENU_ITEMS_FAILED = 'LOAD_MENU_ITEMS_FAILED';
}
export const loadMenuItemsStartAction = createAction(MenuActionConstant.LOAD_MENU_ITEMS_START);

export const loadMenuItemsSucceededAction = createAction(MenuActionConstant.LOAD_MENU_ITEMS_SUCCEEDED, props<{ payload: MenuItem[] }>());

export const loadMenuItemsFailedAction = createAction(MenuActionConstant.LOAD_MENU_ITEMS_FAILED, props<{ error: Error }>());

@Injectable({
  providedIn: 'root'
})
export class MenuAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadStarted(): void {
    this.localStore.dispatch(loadMenuItemsStartAction());
  }

  public loadSucceeded(payload: MenuItem[]): void {
    this.localStore.dispatch(loadMenuItemsSucceededAction({payload}));
  }

  public loadFailed(error: any) {
    this.localStore.dispatch(loadMenuItemsFailedAction({error}));
  }
}
