import {PayloadAction} from '../../../../../redux/payload-action';
import { Access } from '../../../../models/access';
import {
  IndexationDocTypesConstant, loadIndexationDocTypesFailedAction,
  loadIndexationDocTypesStartAction,
  loadIndexationDocTypesSucceededAction
} from '../action/indexation-doc-types.action';
import {createReducer, on} from '@ngrx/store';

export class IndexationDocTypesState {
  datas: Access[];
  loading: boolean;
  error: any;
}

export const INDEXATION_DOC_TYPES_INITIAL_STATE: IndexationDocTypesState = {
  datas: [],
  loading: false,
  error: null,
};

export const indexationDocTypesReducerOld = (state: IndexationDocTypesState = INDEXATION_DOC_TYPES_INITIAL_STATE, action: PayloadAction<Access[]>) => {
  switch (action.type) {
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const indexationDocTypesReducer = createReducer(
  // Initial State
  {
    datas: new Map<string, string[]>(),
    loading: false,
    error: null,
  },

  // Start Action
  on(loadIndexationDocTypesStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Succeeded Action
  on(loadIndexationDocTypesSucceededAction, (state, { payload }) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  // Failed Action
  on(loadIndexationDocTypesFailedAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
