import { Injectable } from '@angular/core';
import { EsPage } from '../../../../models/es-page';
import { Aggregate } from '../../../../models/aggregate';
import { CdxTask } from '../../../../models/cdx-task';
import { createAction, props, Store } from '@ngrx/store';

export class TaskSearchResultConstantAction {
  static readonly LOAD_RESULT_ITEMS_START = '[TasksSearch]: LOAD_RESULTS_START';
  static readonly LOAD_RESULT_ITEMS_SUCCEEDED = '[TasksSearch]: LOAD_RESULTS_SUCCEEDED';
  static readonly LOAD_RESULT_ITEMS_FAILED = '[TasksSearch]: LOAD_RESULTS_FAILED';
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_AGGREGATE_START = '[TasksSearch]: LOAD_AGGREGATE_START';
  static readonly LOAD_AGGREGATE_SUCCEEDED = '[TasksSearch]: LOAD_AGGREGATE_SUCCEEDED';
  static readonly LOAD_AGGREGATE_FAILED = '[TasksSearch]: LOAD_AGGREGATE_FAILED';

  static readonly UPDATE_TASKS_START = '[TasksSearch]: UPDATE_TASKS_START';
  static readonly UPDATE_TASKS_SUCCEEDED = '[TasksSearch]: UPDATE_TASKS_SUCCEEDED';
  static readonly UPDATE_TASKS_FAILED = '[TasksSearch]: UPDATE_TASKS_FAILED';
}

export const loadResultItemsStartAction = createAction(TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_START);
export const loadResultItemsSucceededAction = createAction(TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_SUCCEEDED,props<{payload:EsPage<CdxTask>}>());
export const loadResultItemsFailedAction = createAction(TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_FAILED,props<{error:Error}>());
export const loadAggregateStartAction = createAction(TaskSearchResultConstantAction.LOAD_AGGREGATE_START);
export const loadAggregateSucceededAction = createAction(TaskSearchResultConstantAction.LOAD_AGGREGATE_SUCCEEDED,props<{payload:EsPage<CdxTask>}>());
export const loadAggregateFailedAction = createAction(TaskSearchResultConstantAction.LOAD_AGGREGATE_FAILED,props<{error:Error}>());
export const updateTasksStartAction = createAction(TaskSearchResultConstantAction.UPDATE_TASKS_START);
export const updateTasksSucceededAction = createAction(TaskSearchResultConstantAction.UPDATE_TASKS_SUCCEEDED,props<{payload:EsPage<CdxTask>}>());
export const updateTasksFailedAction = createAction(TaskSearchResultConstantAction.UPDATE_TASKS_FAILED,props<{error:Error}>());


@Injectable({
  providedIn: 'root'
})
export class TaskSearchResultAction {


  constructor( private localStore: Store ) {
  }

  public loadTasksStarted() {
    this.localStore.dispatch(loadResultItemsStartAction());
  }

  public loadTasksSucceeded(payload: EsPage<CdxTask>) {
    this.localStore.dispatch(loadResultItemsSucceededAction({payload}));
  }

  public loadTasksFailed(error: any) {
    this.localStore.dispatch(loadResultItemsFailedAction({error}));
  }



  public loadAggregateStart() {
    this.localStore.dispatch(loadAggregateStartAction());
  }

  public loadAggregateFailed(error: any) {
    this.localStore.dispatch(loadAggregateFailedAction({error}));
  }

  public loadAggregateSucceeded(agg: Aggregate) {
    const payload: EsPage<CdxTask> = new EsPage<CdxTask>(0, [], 0, 0, [agg], false);
    this.localStore.dispatch(loadAggregateSucceededAction({payload}));
  }

  public updateTasksStarted() {
    this.localStore.dispatch(updateTasksStartAction());
  }

  public updateTasksSucceeded(payload: EsPage<CdxTask>) {
    this.localStore.dispatch(updateTasksSucceededAction({payload}));
  }

  public updateTasksFailed(error: any) {
    this.localStore.dispatch(updateTasksFailedAction({error}));
  }

}
