import { Injectable } from '@angular/core';
import { SearchItem } from '../../../../models/search-item';
import { Aggregate } from '../../../../models/aggregate';

import { createAction, props, Store } from '@ngrx/store';
import {IFilterAction} from '../../../filter/filter-action-interface';

export class TaskFilterActionConstantAction {
  static readonly ADD_FILTER_ITEMS_START = '[FilterTask].ADD_FILTER_ITEMS_START';
  static readonly ADD_FILTER_ITEMS_SUCCEEDED = '[FilterTask].ADD_FILTER_ITEMS_SUCCEEDED';
  static readonly ADD_FILTER_ITEMS_FAILED = '[FilterTask].ADD_FILTER_ITEMS_FAILED';

  static readonly UPDATE_FILTER_ITEMS_START = '[FilterTask].UPDATE_FILTER_ITEMS_START';
  static readonly UPDATE_FILTER_ITEMS_SUCCEEDED = '[FilterTask].UPDATE_FILTER_ITEMS_SUCCEEDED';
  static readonly UPDATE_FILTER_ITEMS_FAILED = '[FilterTask].UPDATE_FILTER_ITEMS_FAILED';

  static readonly REMOVE_FILTER_ITEM_STARTED = '[FilterTask].REMOVE_FILTER_ITEM_STARTED';
  static readonly REMOVE_FILTER_ITEM_SUCCEEDED = '[FilterTask].REMOVE_FILTER_ITEM_SUCCEEDED';
  static readonly REMOVE_FILTER_ITEM_FAILED = '[FilterTask].REMOVE_FILTER_ITEM_FAILED';

  static readonly REMOVE_ALL_FILTER_ITEMS_SUCCEEDED = 'REMOVE_ALL_FILTER_ITEMS_SUCCEEDED';
  static readonly REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED = 'REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED';

}

export const addFilterItemsStartAction = createAction(TaskFilterActionConstantAction.ADD_FILTER_ITEMS_START);
export const addFilterItemsSucceededAction = createAction(TaskFilterActionConstantAction.ADD_FILTER_ITEMS_SUCCEEDED, props< {payload : { filterItems : SearchItem[], lastAgg: Aggregate }}>());
export const addFilterItemsFailedAction = createAction(TaskFilterActionConstantAction.ADD_FILTER_ITEMS_FAILED, props<{error: Error}>());

export const updateFilterItemsStartAction= createAction(TaskFilterActionConstantAction. UPDATE_FILTER_ITEMS_START);
export const updateFilterItemsSucceededAction = createAction(TaskFilterActionConstantAction.UPDATE_FILTER_ITEMS_SUCCEEDED, props<{payload :{ filterItems : SearchItem[], lastAgg: Aggregate }}>());
export const updateFilterItemsFailedAction = createAction(TaskFilterActionConstantAction.UPDATE_FILTER_ITEMS_FAILED, props<{error: Error}>());

export const removeFilterItemStartedAction = createAction(TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_STARTED);
export const removeFilterItemSucceededAction = createAction(TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_SUCCEEDED, props<{payload :{ filterItems : SearchItem[], lastAgg: Aggregate }} >() );
export const removeFilterItemFailedAction = createAction(TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_FAILED, props<{error: Error}>());
export const removeAllFilterItemsSucceededAction = createAction(TaskFilterActionConstantAction.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED);
export const removeFiltersAndKeepLastAggSucceededAction = createAction(TaskFilterActionConstantAction.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED);


@Injectable({
  providedIn: 'root'
})
export class TaskFilterAction implements IFilterAction {

  constructor( private localStore: Store ) {
  }

  public addFilterStarted(): void {
    this.localStore.dispatch( addFilterItemsStartAction());
  }

  public addFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    const payload: { filterItems : SearchItem[], lastAgg: Aggregate }  = {
      filterItems: [filterItem],
      lastAgg: agg
    };

    this.localStore.dispatch(addFilterItemsSucceededAction( {payload} ));

  }

  public addFilterFailed(error: any) {
    this.localStore.dispatch( addFilterItemsFailedAction( {error}) );
  }

  public removeFilterStarted(): void {
    this.localStore.dispatch( removeFilterItemStartedAction());
  }

  public removeFilterSucceeded(filterItem: SearchItem) {
    const payload: { filterItems : SearchItem[], lastAgg: Aggregate }  = {
      filterItems: [filterItem],
      lastAgg: null}

    this.localStore.dispatch(removeFilterItemSucceededAction( {payload} ));

  }

  public removeFiltersAndKeepLastAgg(): void {
    this.localStore.dispatch(removeFiltersAndKeepLastAggSucceededAction());
  }

  public removeFilterFailed(error: any) {
    this.localStore.dispatch(removeFilterItemFailedAction({error}));

  }


  public removeAllFiltersSucceeded() {
    this.localStore.dispatch( removeAllFilterItemsSucceededAction());
  }


  updateFilterStarted() {
    this.localStore.dispatch( updateFilterItemsStartAction());
  }

  updateFilterSucceeded(filterItem: SearchItem, curAggregate: Aggregate) {
    const payload : { filterItems : SearchItem[], lastAgg: Aggregate } ={
      filterItems: [filterItem],
      lastAgg: curAggregate
    };

    this.localStore.dispatch(updateFilterItemsSucceededAction( {payload} ) );
  }

  updateFilterFailed(error: any) {
    this.localStore.dispatch( updateFilterItemsFailedAction(error));
  }
}
