import {Injectable} from '@angular/core';
import {MaintenanceInfo} from '../../../models/maintenance-info';
import {MaintenanceInfoAction} from '../action/maintenance-info.action';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInfoService {

  constructor(
    private maintenanceInfoAction: MaintenanceInfoAction,
  ) {
  }

  public loadMaintenanceInfo(maintenanceInfo: MaintenanceInfo): void {
    this.maintenanceInfoAction.loadMaintenanceInfoSucceeded(maintenanceInfo);
  }

  public removeMaintenanceInfo(): void {
    this.maintenanceInfoAction.removeMaintenanceInfoSucceeded();
  }
}
