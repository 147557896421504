import {SearchItem} from '../../../../models/search-item';
import {Aggregate} from '../../../../models/aggregate';
import {createReducer, on} from '@ngrx/store';
import {
  addEntityFilterItemsFailedAction,
  addEntityFilterItemsStartAction,
  addEntityFilterItemsSucceededAction,
  removeAllEntityFilterItemsSucceededAction,
  removeEntityFilterItemFailedAction,
  removeEntityFilterItemStartedAction,
  removeEntityFilterItemSucceededAction,
  removeEntityFiltersAndKeepLastAggSucceededAction,
  updateEntityFilterItemsFailedAction,
  updateEntityFilterItemsStartAction,
  updateEntityFilterItemsSucceededAction
} from '../action/entity-filter.action';


export interface EntityFilterDatas {
  filterItems: SearchItem[];
  lastAgg: Aggregate;
}

export interface EntityFilterState {
  datas: EntityFilterDatas;
  loading: boolean;
  error: any;

}

export const FILTER_ITEMS_INITIAL_STATE: EntityFilterState = {
  datas: {
    filterItems: [],
    lastAgg: null
  },
  loading: false,
  error: null
};

export const entityFilterReducer = createReducer(
  // Initial State
  FILTER_ITEMS_INITIAL_STATE,

  // Start Actions
  on(
    addEntityFilterItemsStartAction,
    updateEntityFilterItemsStartAction,
    removeEntityFilterItemStartedAction,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),

  // Succeeded Actions
  on(updateEntityFilterItemsSucceededAction, (state, {payload}) => {
    const toReplace: SearchItem = payload.filterItems[0];
    let found = false;
    let searchItems = state.datas.filterItems.map(aFilterItem => {
      if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
        found = true;
        return toReplace;
      } else {
        return aFilterItem;
      }
    });
    if (!found) {
      searchItems = [...searchItems, toReplace];
    }
    return {
      ...state,
      datas: {
        ...state.datas,
        filterItems: searchItems,
        lastAgg: payload.lastAgg
      },
      loading: false,
      error: null,
    };
  }),

  on(addEntityFilterItemsSucceededAction, (state, {payload}) => {
    const add = state.datas;
    if (!payload.filterItems[0].operator) {
      add.filterItems = add.filterItems.concat(payload.filterItems[0]);
    } else {
      const existingFilterItemRange = add.filterItems.find(existingFilterItem => existingFilterItem.operator === payload.filterItems[0].operator && existingFilterItem.path === payload.filterItems[0].path);
      if (!existingFilterItemRange) {
        add.filterItems = add.filterItems.concat(payload.filterItems[0]);
      } else {
        add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = payload.filterItems[0];
      }
    }

    add.lastAgg = payload.lastAgg;
    return {
      ...state,
      datas: add,
      loading: false,
      error: null,
    };
  }),

  // Failed Actions
  on(
    addEntityFilterItemsFailedAction,
    removeEntityFilterItemFailedAction,
    updateEntityFilterItemsFailedAction,
    (state, {error}) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  // Remove All Filter Items Succeeded Action
  on(removeAllEntityFilterItemsSucceededAction, (state) => ({
    ...state,
    datas: {
      filterItems: [],
      lastAgg: null
    },
    loading: false,
    error: null,
  })),

  // Remove Filters and Keep Last Agg Succeeded Action
  on(removeEntityFiltersAndKeepLastAggSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      filterItems: []
    },
    loading: false,
    error: null,
  })),

  // Remove Filter Item Succeeded Action
  on(removeEntityFilterItemSucceededAction, (state, {payload}) => {
    const filterItem: SearchItem = payload.filterItems[0];
    let oldLastAgg = state.datas.lastAgg;
    let remove: SearchItem[];
    if (!filterItem.operator) {
      remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.value !== filterItem.value);
    } else {
      remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.operator !== item.operator);
    }
    if (remove.length === 0) {
      oldLastAgg = null;
    }
    return {
      ...state,
      datas: {
        filterItems: remove,
        lastAgg: oldLastAgg
      },
      loading: false,
      error: null,
    };
  })
);
