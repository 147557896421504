import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of, OperatorFunction, pipe, Subject} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ToastaService} from 'ngx-toasta';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {SocketService} from '../../../services/socket/socket.service';
import {AuthenticationService} from '../../../../modules/authentication/authentication.service';
import {CriticalDataService} from '../../critical-data/service/critical-data.service';
import {CurrentCommentService} from '../../current-comment/service/current-comment.service';
import {CdxDocument} from '../../../models/cdx-document';
import {Url} from '../../../models/url';
import {CdxTask} from '../../../models/cdx-task';
import {Entity} from '../../../models/Entity';
import {CdxComment, CdxReply} from '../../../models/cdx-comment';
import {UserSocketHttpResponse} from '../../../models/user-socket-http-response';
import {EsPage} from '../../../models/es-page';
import {ActivityDiff, CdxActivity} from '../../../models/cdx-activity';
import {CdxAttachment} from '../../../models/cdx-attachment';
import {CdxFile} from '../../../models/cdx-file';
import {Utils} from '../../../utils/utils';
import {UserCredentials} from '../../../models/UserCredentials';
import {EsAuthor} from '../../../models/EsAuthor';
import {CriticalDataAction} from '../../critical-data/action/critical-data.action';
import {Metadata, MetadataCodes} from '../../../models/metadata';
import {Lock, LockInformation} from '../../../models/lock';
import {AccessFunction} from '../../../models/access';

export enum DELETE_MODE {
  SOFT = 'soft',
  HARD = 'hard'
}

export function getFunction(deleteMode: any) {
  if (deleteMode === DELETE_MODE.SOFT) {
    return AccessFunction.DELETE;
  } else if (deleteMode === DELETE_MODE.HARD) {
    return AccessFunction.HARDDELETE;
  } else {
    return;
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDetailsService {

  public static readonly PAGE_SIZE = 24;
  public static readonly MAX_SIZE = 200;
  public static readonly LOCK_ARCHIVE = 'lock-archive';
  public static readonly UNLOCK_ARCHIVE = 'unlock-archive';
  public static readonly UPDATE_LOCK_ARCHIVE_INFO = 'update-lock-archive-info';

  constructor(
    protected httpClient: HttpClient,
    protected configAction: ConfigurationService,
    protected toastaService: ToastaService,
    protected translateService: TranslateService,
    protected socketService: SocketService,
    protected authenticationService: AuthenticationService,
    protected location: Location,
    protected router: Router,
    protected criticalDataService: CriticalDataService,
    protected currentCommentService: CurrentCommentService,
  ) {
  }

  protected static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  // l'appel à l'affichage de tous les champs critique a déjà été fait, ils sont tous dans le store
  public showAllCriticalFieldsValue(): void {
    this.criticalDataService.addAllToVisibleCriticalFieldsStore();
  }

  public addViewCriticalFieldsToStore(viewCriticalFields: string[]): void {
    this.criticalDataService.addViewCriticalFieldsToStore(viewCriticalFields);
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    this.criticalDataService.addVisibleCriticalPdfToStore(isVisible);
  }

  public hideAllCriticalFieldsValue(): void {
    this.criticalDataService.removeAllFromVisibleCriticalFieldsStore();
  }

  public removeViewCriticalFieldsStore(): void {
    this.criticalDataService.removeViewCriticalFieldsStore();
  }

  // FIXME passer le token dans le header
  public restoreVersion(nexiaObjectId: string, numVersion: string): Observable<boolean> {
    const url: string = Url.getProtectedApiBaseUrl(this.configAction) + this.getRestoreVersionUrl(nexiaObjectId, numVersion);
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    const headers = new HttpHeaders();

    this.socketService.put(
      url, null,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      })
      .subscribe( {
        next : () => {
          // this.updateToken(userSocketHttpResponse.httpResponse.headers, docId);
          booleanSubject$.next(true);
          booleanSubject$.complete();
          this.router.navigate(
            this.getInternalRouteDetailsUrl(nexiaObjectId),
            {replaceUrl: true, queryParams: {restoreTime: new Date().getTime()}});
        },
        error : () => {
          booleanSubject$.next(true); // close modal dialog
          booleanSubject$.complete();
        }});
    return booleanSubject$.asObservable();
  }

  /*START getting urls*/
  protected abstract getNexiaObjectDetailsUrl(nexiaObjectId: string): string;

  protected abstract getInternalRouteDetailsUrl(nexiaObjectId: string): string[];

  protected abstract getPostPutCommentUrl(comment: CdxComment): string;

  protected abstract getLoadDeleteCommentUrl(nexiaObjectId: string, comId: string): string;

  protected abstract getPostPutReplyUrl(nexiaObjectidId: string, commentId: string): string;

  protected abstract getDeleteReplyUrl(nexiaObjectId: string, commentId: string, replyId: string): string;

  protected abstract getHistoryUrl(nexiaObjectId: string): string;

  protected abstract getLoadDeleteDownloadAttachmentUrl(nexiaObjectId: string, attachmentId: string): string;

  protected abstract getUpdateUploadAttachmentUrl(nexiaObjectId: string): string;

  protected abstract getRestoreVersionUrl(nexiaObjectId: string, numVersion: string): string;

  /*END getting urls*/

  protected abstract getCurrentActivityDiffUrl(nexiaObjectId: string, evtId: number, entityType: string): string;

  protected abstract getUpdateTeamsUrl(nexiaObjectId: string): string;

  protected abstract getDeleteNexiaObjectUrl(nexiaObjectId: string): string;

  protected abstract getHardDeleteNexiaObjectsUrl(): string;

  protected abstract getUpdateArchiveUrl(): string;

  protected abstract getMetadataUrl(docId: string): string;

  /*START calls to details actions*/
  protected abstract detailsActionLoadCommentStart(): void;

  protected abstract detailsActionLoadCommentSucceeded(comment: CdxComment): void;

  protected abstract detailsActionLoadCommentFailed(error: any): void;

  protected abstract detailsActionLoadHistoryStart(): void;

  protected abstract detailsActionLoadHistorySucceeded(activities: EsPage<CdxActivity>): void;

  protected abstract detailsActionLoadHistoryFailed(error: any): void;

  protected abstract detailsActionLoadAttachmentStart(): void;

  protected abstract detailsActionLoadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string): void;

  protected abstract detailsActionLoadAttachmentFailed(error: any): void;

  protected abstract detailsActionUploadAttachmentSucceeded(tempAttachment: CdxAttachment): void;

  /*END calls to details actions*/

  protected abstract detailsActionLoadCurrentActivityDiffStart(): void;

  protected abstract detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void;

  protected abstract detailsActionLoadCurrentActivityDiffFailed(error: any): void;

  protected abstract detailsActionLoadMetaStart(): void;

  protected abstract detailsActionLoadMetaSucceeded(meta: Metadata): void;

  protected abstract detailsActionLoadMetaFailed(error: any): void;

  protected abstract searchResultServiceUpdateDocInPage(docId: string, meta: Metadata): void;

  protected abstract updateToken(headers: HttpHeaders, id: string): void;

  /*START methods called from abstract && extenders*/
  protected _addVisibleCriticalField(criticalFieldCode: string): void {
    this.criticalDataService.addCriticalFieldToStore(criticalFieldCode);
  }

  protected _loadNexiaObjectDetailsVersion(id: string, versionNumber: number): Observable<CdxDocument | Entity | CdxTask> {
    try {
      return AbstractDetailsService.query().pipe(
        this.setVersionNumber(versionNumber),
        this.requestNexiaObjectDetails(id)
      );
    } catch (error) {
      return of(error);
    }
  }

  protected _loadReadCriticalFieldValue(id: string, fieldcode: string, versionNumber: number = null): Observable<CdxDocument | Entity | CdxTask> {
    return AbstractDetailsService.query().pipe(
      this.setReadCritical(fieldcode),
      this.setVersionNumber(versionNumber),
      this.requestNexiaObjectDetails(id)
    );
  }

  protected _addComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutCommentUrl(comment), comment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _updateComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutCommentUrl(comment), comment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _loadComment(nexiaOnjectId: string, comId: string): void {
    try {
      this.detailsActionLoadCommentStart();
      this.httpClient.get<CdxComment>(Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteCommentUrl(nexiaOnjectId, comId))
        .subscribe(comment =>
            this.detailsActionLoadCommentSucceeded(comment),
          (error: HttpErrorResponse) => this.detailsActionLoadCommentFailed(error));
    } catch (error) {
      this.detailsActionLoadCommentFailed(error);
    }
  }

  protected _deleteComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteCommentUrl(comment.cdx_doc_id, comment.cdx_id),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _addReply(nexiaOnjectId: string, commentId: string, token: string, reply: CdxReply): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutReplyUrl(nexiaOnjectId, commentId), reply,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _updateReply(nexiaOnjectId: string, commentId: string, token: string, reply: CdxReply): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutReplyUrl(nexiaOnjectId, commentId), reply,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _deleteReply(nexiaOnjectId: string, commentId: string, token: string, replyId: string): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getDeleteReplyUrl(nexiaOnjectId, commentId, replyId),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _loadNexiaObjectHistory(nexiaObjectId: string): void {
    try {
      this.detailsActionLoadHistoryStart();
      this.httpClient.get<EsPage<CdxActivity>>(Url.getProtectedApiBaseUrl(this.configAction) + this.getHistoryUrl(nexiaObjectId))
        .subscribe((activities: EsPage<CdxActivity>) =>
            this.detailsActionLoadHistorySucceeded(activities),
          (error: HttpErrorResponse) => this.detailsActionLoadHistoryFailed(error));
    } catch (error) {
      this.detailsActionLoadHistoryFailed(error);
    }
  }

  protected _loadAttachment(nexiaObjectId: string, attId: string, tempAttachmentId: string = null): void {
    try {
      this.detailsActionLoadAttachmentStart();
      this.httpClient.get<CdxAttachment>(Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(nexiaObjectId, attId))
        .subscribe(attachment => this.detailsActionLoadAttachmentSucceeded(attachment, tempAttachmentId),
          (error: HttpErrorResponse) => this.detailsActionLoadAttachmentFailed(error));
    } catch (error) {
      this.detailsActionLoadAttachmentFailed(error);
    }
  }

  protected _deleteAttachment(attachment: CdxAttachment, token: string): Observable<UserSocketHttpResponse> {
    const headers = Utils.objectTokenHeader(token);
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(attachment.cdx_doc_id, attachment.cdx_id),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _downloadAttachment(nexiaObjectId: string, attId: string, token: string, isThumbnail: boolean): Observable<CdxFile> {
    try {
      const url = isThumbnail ? Url.THUMB : Url.FILE;
      return this.httpClient.get(
        Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(nexiaObjectId, attId) + '/' + url,
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(token)
        })
        .pipe(map((response: HttpResponse<any>) => {
          this.updateToken(response.headers, nexiaObjectId);
          // this.documentDetailsAction.updateToken(response.headers);
          return Utils.httpResponseToCdxFile(response);
        }, (error: HttpErrorResponse) => {
          console.error('error 1', error);
          return of(error);
        }));
    } catch (err) {
      console.error('error 2', err);
      return of(err);
    }
  }

  protected _uploadAttachment(nexiaObjectId: string, file: File, token: string): void {
    if (file != null) {
      const tempAttachment = this.createTempAttachment(nexiaObjectId, file);
      this.detailsActionUploadAttachmentSucceeded(tempAttachment);
      const formData = new FormData();
      formData.append('file', file, file.name);
      const attachment = new CdxAttachment();
      attachment.cdx_doc_id = nexiaObjectId;
      formData.append('attachment', JSON.stringify(attachment));
      const headers = Utils.objectTokenHeader(token);
      this.socketService.post(
        Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateUploadAttachmentUrl(nexiaObjectId),
        formData,
        {
          responseType: 'text',
          headers: headers,
          observe: 'response'
        })
        .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this._loadAttachment(nexiaObjectId, userSocketHttpResponse.id, tempAttachment.cdx_id);
          this._loadNexiaObjectHistory(nexiaObjectId);
          this.updateToken(userSocketHttpResponse.httpResponse.headers, nexiaObjectId);
        });
    }
  }

  protected _updateAttachment(attachment: CdxAttachment, token: string): void {
    const headers = Utils.objectTokenHeader(token);
    this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateUploadAttachmentUrl(attachment.cdx_doc_id), attachment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      })
      .subscribe(
        {next: (userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, attachment.cdx_doc_id);
          this._loadAttachment(attachment.cdx_doc_id, attachment.cdx_id);
          this._loadNexiaObjectHistory(attachment.cdx_doc_id);
        },
        error : () => {
          this._loadAttachment(attachment.cdx_doc_id, attachment.cdx_id);
        }});
  }

  protected _updateMetaArchive(meta: Metadata, action: string, objectId: string, token: string, updateLock: boolean): Observable<boolean> {
    const updateMetaSucceeded$: Subject<boolean> = new Subject<boolean>();
    const headers = token ? Utils.objectTokenHeader(token) : new HttpHeaders();
    if (updateLock) {
      this.detailsActionLoadMetaStart();
    }
    try {
      let request: Observable<any>;
      if (action === AbstractDetailsService.LOCK_ARCHIVE) {
        request = this._lockArchive(headers, objectId);
      }
      if (action === AbstractDetailsService.UNLOCK_ARCHIVE) {
        request = this._unlockArchive(headers, objectId);
      }
      if (action === AbstractDetailsService.UPDATE_LOCK_ARCHIVE_INFO) {
        const lockInfo: LockInformation = (meta[MetadataCodes.ARCHIVE_LOCK] as Lock).lock_information;
        request = this._updateLockInfo(lockInfo, headers, objectId);
      }
      request.pipe(
        switchMap(() => {
          if (updateLock) {
            return this.getMetadata(objectId);
          } else {
            return of(meta);
          }
        })
      ).subscribe({
          next: (metadata: Metadata) => {
            if (updateLock) {
              meta = metadata;
              this.detailsActionLoadMetaSucceeded(meta);
              this.searchResultServiceUpdateDocInPage(objectId, meta);
            }
            updateMetaSucceeded$.next(true);
            updateMetaSucceeded$.complete();
          },
          error: (error: HttpErrorResponse) => {
            updateMetaSucceeded$.next(false);
            updateMetaSucceeded$.complete();
            if (updateLock) {
              this.detailsActionLoadMetaFailed(error);
            }
          }
        }
      );
    } catch (e) {
      updateMetaSucceeded$.next(false);
      updateMetaSucceeded$.complete();
      if (updateLock) {
        this.detailsActionLoadMetaFailed(e);
      }
    }
    return updateMetaSucceeded$.asObservable();
  }

  protected _loadMetadata(objectId: string): Observable<boolean> {
    const loadMetaSucceeded$: Subject<boolean> = new Subject<boolean>();
    try {
      this.detailsActionLoadMetaStart();
      this.getMetadata(objectId)
        .subscribe(metadata => {
          this.detailsActionLoadMetaSucceeded(metadata);
          loadMetaSucceeded$.next(true);
        });
    } catch (error) {
      this.detailsActionLoadMetaFailed(error);
      loadMetaSucceeded$.next(false);
    }
    return loadMetaSucceeded$;
  }

  protected getMetadata(objectId: string): Observable<Metadata> {
    return this.httpClient.get<Metadata>(Url.getProtectedApiBaseUrl(this.configAction) + this.getMetadataUrl(objectId));
  }

  /**
   * Récupère le document sur office et crée une révision en mode patch
   * @param docId
   * @param file
   * @protected
   */
  protected updateArchive(docId: string, file: File): Observable<boolean> {
    const updateDone$: Subject<boolean> = new Subject<boolean>();
    const formData = new FormData();
    formData.append('file', file, file.name);
    const doc: CdxDocument = new CdxDocument();
    doc.cdx_id = docId;
    delete doc.cdx_datas;
    formData.append('document', JSON.stringify(doc));
    formData.append('mode', 'PATCH');
    try {
      this.socketService.post(
        Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateArchiveUrl(),
        formData,
        {
          responseType: 'text',
          observe: 'response'
        })
        .subscribe({
          next : () => {
            updateDone$.next(true);
            updateDone$.complete();
          },
          error : () => {
            updateDone$.error(false);
            updateDone$.complete();
          }
        });
    } catch (error) {
      updateDone$.next(false);
      updateDone$.complete();
    }
    return updateDone$.asObservable();
  }

  /*END methods called from abstract && extenders*/

  protected _loadCurrentActivityDiff(nexiaObjectId: string, evtId: number, isJustOpen: boolean, entityType?: string): void {
    console.log("_loadCurrentActivityDiff entityType:"+ entityType);
    this.detailsActionLoadCurrentActivityDiffStart();
    try {
      if (isJustOpen) {
        this.httpClient.get<ActivityDiff>(Url.getProtectedApiBaseUrl(this.configAction) + this.getCurrentActivityDiffUrl(nexiaObjectId, evtId, entityType)).subscribe(
          (currentActivityDiff: ActivityDiff) => {
            this.detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff);
          },
          (error: HttpErrorResponse) => this.detailsActionLoadCurrentActivityDiffFailed(error));
      } else {
        this.detailsActionLoadCurrentActivityDiffSucceeded(new ActivityDiff());
      }
    } catch (error) {
      this.detailsActionLoadCurrentActivityDiffFailed(error);
    }
  }

  protected _updateTeams(nexiaObjectId: string, token: string, teamIds: string[]): Observable<UserSocketHttpResponse> {
    if (!teamIds) {
      teamIds = [];
    }

    const headers = Utils.objectTokenHeader(token);
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateTeamsUrl(nexiaObjectId), teamIds,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  // FIXME passer le token
  protected _deleteNexiaObject(nexiaObjectId: string, mode: DELETE_MODE): Observable<UserSocketHttpResponse> {
    const params: HttpParams = new HttpParams().set('mode', mode);
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getDeleteNexiaObjectUrl(nexiaObjectId),
      {
        observe: 'response',
        params: params
      });
  }

  /*START methods called from abstract*/
  protected setVersionNumber(versionNumber: number): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (!!versionNumber) {
          params = params.append('revision', '' + versionNumber);
        }
        return params;
      })
    );
  }

  /*END methods called from abstract*/

  protected requestNexiaObjectDetails(nexiaObjectId: string): OperatorFunction<HttpParams, (CdxDocument | Entity | CdxTask)> {
    return switchMap(params => {
      return this.httpClient.get<CdxDocument | Entity | CdxTask>(Url.getProtectedApiBaseUrl(this.configAction) + this.getNexiaObjectDetailsUrl(nexiaObjectId), {params});
    });
  }

  protected setReadCritical(readCritical: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      return params
        .set('readCritical', readCritical !== CriticalDataAction._ALL ? 'cdx_datas.' + readCritical : readCritical);
    });
  }

  private _lockArchive(headers: HttpHeaders, objectId: string): Observable<any> {
    return this.socketService.post(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE, null,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  private _unlockArchive(headers: HttpHeaders, objectId: string): Observable<any> {
    return this.socketService.delete(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE, {
      headers: headers,
      observe: 'response'
    });
  }

  private _updateLockInfo(lockInformation: LockInformation, headers: HttpHeaders, objectId: string): Observable<any> {
    return this.socketService.put(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE + Url.DETAILS,
      lockInformation,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  private createTempAttachment(entityId: string, file: File): CdxAttachment {
    const userCredentials: UserCredentials = this.authenticationService.getUserCredentials();
    const attachment: CdxAttachment = new CdxAttachment();
    attachment.cdx_creation_date = new Date().getTime();
    attachment.cdx_doc_id = entityId;
    attachment.cdx_id = '' + Math.random().toString(36).substr(2, 16);
    attachment.cdx_author = new EsAuthor();
    attachment.cdx_author.firstname = userCredentials.firstname;
    attachment.cdx_author.lastname = userCredentials.lastname;
    attachment.cdx_title = file.name;
    return attachment;
  }
}
