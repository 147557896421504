import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  public static readonly FR = 'fr';
  public static readonly EN = 'en';

  constructor(private translateService: TranslateService) { }

  public getLocale(): string {
    const currentLanguage: string = this.translateService.currentLang;
    switch (currentLanguage) {
      case LocaleService.EN: {
        console.log('Application Locale Set to English');
        return LocaleService.EN;
      }
      case LocaleService.FR: {
        console.log('Application Locale Set to French');
        return LocaleService.FR;
      }
      default: {
        console.log('Application Locale Set to French');
        return LocaleService.FR;
      }
    }
  }
}
