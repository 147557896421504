import {CdxComment} from './cdx-comment';

export class CurrentCommentContainer {
  currentComment: CdxComment;
  inError: boolean;

  constructor(currentComment: CdxComment, inError: boolean) {
    this.currentComment = currentComment;
    this.inError = inError;
  }
}
