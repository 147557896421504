import {createReducer, on} from '@ngrx/store';
import {PayloadAction} from '../../../../../redux/payload-action';
import {CacheDraft} from '../../../../models/CacheDraft';
import {
  setCacheDraftApplyCacheSucceededAction,
  addCacheDraftAttributeFailedAction,
  addCacheDraftAttributeStartedAction, addCacheDraftDataFailedAction,
  addCacheDraftDataStartedAction, addCacheDraftDataSucceededAction, addCacheDraftIndexingNextDocSucceededAction,
  CacheDraftConstant
} from '../action/cache-draft.action';

export class CacheDraftState {
  datas: CacheDraft;
  loading: boolean;
  error: any;
}

export const CACHE_DRAFT_INITIAL_STATE: CacheDraftState = {
  datas: new CacheDraft(),
  loading: false,
  error: null,
};

export const cacheDraftReducerOld = (state: CacheDraftState = CACHE_DRAFT_INITIAL_STATE, action: PayloadAction<CacheDraft>): CacheDraftState => {
  switch (action.type) {
    case CacheDraftConstant.ADD_CACHE_DRAFT_ATTRIBUTE_START:
    case CacheDraftConstant.ADD_CACHE_DRAFT_DATA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CacheDraftConstant.ADD_CACHE_DRAFT_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          draftData: action.payload.draftData
        },
        loading: false,
        error: null,
      };

    case CacheDraftConstant.ADD_CACHE_DRAFT_ATTRIBUTE_FAILED:
    case CacheDraftConstant.ADD_CACHE_DRAFT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CacheDraftConstant.ADD_CACHE_DRAFT_INDEXING_NEXT_DOC_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          indexNextDoc: action.payload.indexNextDoc
        },
        loading: false,
        error: null,
      };
  }
  return state;
};



export const cacheDraftReducer = createReducer(
  // Initial State
  CACHE_DRAFT_INITIAL_STATE,

  // Start Actions
  on(addCacheDraftAttributeStartedAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(addCacheDraftDataStartedAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Succeeded Actions
  on(addCacheDraftDataSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      draftData: payload.draftData
    },
    loading: false,
    error: null,
  })),

  on(addCacheDraftAttributeFailedAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(addCacheDraftDataFailedAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(addCacheDraftIndexingNextDocSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      indexNextDoc: payload.indexNextDoc
    },
    loading: false,
    error: null,
  })),

  on(setCacheDraftApplyCacheSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      applyCache: payload.applyCache
    },
    loading: false,
    error: null,
  }))

);
