import {Injectable} from '@angular/core';
import {Field} from '../../../../models/field';
import {
  AbstractCfgFieldAction
} from '../abstract-cfg-field.action';
import { Store} from '@ngrx/store';
import {
  addMultipleCfgFieldFailedAction,
  addMultipleCfgFieldStartAction,
  addMultipleCfgFieldSucceededAction,
  removeAllCfgFieldFailedAction,
  removeAllCfgFieldStartAction,
  removeAllCfgFieldSucceededAction
} from '../const-field-actions';
@Injectable({
  providedIn: 'root'
})
export class GedCfgFieldAction extends AbstractCfgFieldAction {

  constructor(store: Store) {
    super(store);
  }
  public loadStarted(): void {
    this._dispatchType(addMultipleCfgFieldStartAction());
  }

  public loadSucceeded(mapField: { [key: string]: Field }): void {
    this._dispatchSuccess(addMultipleCfgFieldSucceededAction({ payload: mapField }));
  }

  public loadFailed(error: any): void {
    this._dispatchFail(addMultipleCfgFieldFailedAction({ error }));
  }

  public removeAllStarted(): void {
    this._dispatchType(removeAllCfgFieldStartAction());
  }

  public removeAllSucceeded(): void {
    this._dispatchType(removeAllCfgFieldSucceededAction());
  }

  public removeAllFailed(error: any): void {
    this._dispatchFail(removeAllCfgFieldFailedAction({ error }));
  }

}
