import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from './comment/comment.component';
import { ReplyComponent } from './reply/reply.component';
import { SinceModule } from '../since/since.module';
import { Txt2htmlModule } from '../txt2html/txt2html.module';
import { UserModule } from '../user/user.module';
import { TextInputHighlightModule } from '../text-input-highlight/text-input-highlight.module';

@NgModule({
  declarations: [CommentComponent, ReplyComponent],
  imports: [
    FormsModule,
    CommonModule,
    SinceModule,
    Txt2htmlModule,
    UserModule,
    TextInputHighlightModule,
    TranslateModule
  ],
  exports: [CommentComponent, ReplyComponent]
})
export class CommentsModule { }
