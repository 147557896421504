import { createAction, props } from '@ngrx/store';
import { Field } from '../../../models/field';

/* GED begin */

export class GedCfgFieldConstant {
    static readonly ADD_MULTIPLE_CFG_FIELD_START = '[GED]ADD_MULTIPLE_CFG_FIELD_START';
    static readonly ADD_MULTIPLE_CFG_FIELD_SUCCEEDED = '[GED]ADD_MULTIPLE_CFG_FIELD_SUCCEEDED';
    static readonly ADD_MULTIPLE_CFG_FIELD_FAILED = '[GED]ADD_MULTIPLE_CFG_FIELD_FAILED';

    static readonly REMOVE_ALL_CFG_FIELD_START = '[GED]REMOVE_ALL_CFG_FIELD_START';
    static readonly REMOVE_ALL_CFG_FIELD_SUCCEEDED = '[GED]REMOVE_ALL_CFG_FIELD_SUCCEEDED';
    static readonly REMOVE_ALL_CFG_FIELD_FAILED = '[GED]REMOVE_ALL_CFG_FIELD_FAILED';
}

export const addMultipleCfgFieldStartAction = createAction(GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_START);

export const addMultipleCfgFieldSucceededAction = createAction(
    GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_SUCCEEDED,
    props<{ payload: { [key: string]: Field } }>()
);

export const addMultipleCfgFieldFailedAction = createAction(
    GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_FAILED,
    props<{ error: Error }>()
);

export const removeAllCfgFieldStartAction = createAction(GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_START);

export const removeAllCfgFieldSucceededAction = createAction(GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_SUCCEEDED);

export const removeAllCfgFieldFailedAction = createAction(
    GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_FAILED,
    props<{ error: Error }>()
);

export class WkfCfgFieldConstant {
    static readonly ADD_MULTIPLE_CFG_FIELD_START = '[WKF]ADD_MULTIPLE_CFG_FIELD_START';
    static readonly ADD_MULTIPLE_CFG_FIELD_SUCCEEDED = '[WKF]ADD_MULTIPLE_CFG_FIELD_SUCCEEDED';
    static readonly ADD_MULTIPLE_CFG_FIELD_FAILED = '[WKF]ADD_MULTIPLE_CFG_FIELD_FAILED';

    static readonly REMOVE_ALL_CFG_FIELD_START = '[WKF]REMOVE_ALL_CFG_FIELD_START';
    static readonly REMOVE_ALL_CFG_FIELD_SUCCEEDED = '[WKF]REMOVE_ALL_CFG_FIELD_SUCCEEDED';
    static readonly REMOVE_ALL_CFG_FIELD_FAILED = '[WKF]REMOVE_ALL_CFG_FIELD_FAILED';
}
/* GED end */

/* WKF begin */
export const wkfAddMultipleCfgFieldStartAction = createAction(WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_START);

export const wkfAddMultipleCfgFieldSucceededAction = createAction(
    WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_SUCCEEDED,
    props<{ payload: { [key: string]: Field } }>()
);

export const wkfAddMultipleCfgFieldFailedAction = createAction(
    WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_FAILED,
    props<{ error: any }>()
);

export const wkfRemoveAllCfgFieldStartAction = createAction(WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_START);

export const wkfRemoveAllCfgFieldSucceededAction = createAction(WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_SUCCEEDED);

export const wkfRemoveAllCfgFieldFailedAction = createAction(
    WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_FAILED,
    props<{ error: any }>()
);

/* WKF end */
