import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject,  Observable, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {Url} from '../../models/url';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  private _ready = new BehaviorSubject<boolean>(false);
  public i18nLoaded$ = this._ready;

  constructor(
    private configurationService: ConfigurationService,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public loadI18n(): Observable<any> {
    return this.loadTranslations(this.translateService.currentLang)
  }

  public reloadI18nOnLangChange(): Observable<void> {
    return this.translateService.onLangChange.pipe(
      switchMap((params: LangChangeEvent) => this.loadTranslations(params.lang)),
      tap(() => this._ready.next(true))
    );
  }

  private loadTranslations(lang: string): Observable<any> {
    if (!lang) {
      return this.configurationService
        .whenReady(() => this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configurationService) + 'languages', {observe: 'response'})
          .pipe(
            tap(response => {
              let defaultLanguage = 'fr';
              response.body.forEach((languageItem: any) => {
                if (languageItem.defaultLanguage) {
                  defaultLanguage = languageItem.code;
                }
              });
              const currentLanguage: string = response.headers.get('content-language');
              this.translateService.setDefaultLang(defaultLanguage);
              this.translateService.use(currentLanguage);
              this.document.documentElement.lang = currentLanguage;
            }),
            catchError((error) => {
              console.error(`Erreur de chargement de la langue par défaut`, error);
              return throwError(error);
            })
          ));
    } else {
      return this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configurationService) + 'i18n/' + lang, {observe: 'response'})
        .pipe(
          tap(response => {
            this.translateService.setTranslation(lang, response.body, true);
          }),
          catchError(error => {
            console.error(`Erreur de chargement de la langue ${lang}`, error);
            return throwError(error);
          })
        );
    }
  }
}
