import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {currentTeamReducer} from './reducer/current-team.reducer';

export const currentTeamStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CURRENT_TEAM,
    reducer: currentTeamReducer
  })
);
