import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouterModule} from '@angular/router';
import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {Store} from '@ngrx/store';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as Hammer from 'hammerjs';
import {combineLatest, EMPTY, Subscription} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {CoreModule} from './core/core.module';
import {MaintenanceType} from './core/models/maintenance-info';
import {DomainService} from './core/redux/domain/service/domain.service';
import {selectMaintenanceInfoType} from './core/redux/maintenance-info/maintenance-info-selectors';
import {MenuService} from './core/redux/menu/service/menu.service';
import {RouterPathService} from './core/redux/router-path/service/router-path.service';
import {
  SearchResultPaginationAction
} from './core/redux/search-result-pagination/action/search-result-pagination.action';
import {I18nService} from './core/services/i18n/i18n.service';
import {
  ModuleFromRouteDefinerService
} from './core/services/module-from-route-definer/module-from-route-definer.service';
import {SocketService} from './core/services/socket/socket.service';
import {ErrorComponent} from './core/ui/error/error.component';
import {ToasterModule} from './core/ui/toaster/toaster.module';
import {Utils} from './core/utils/utils';
import {AlertModalModule} from './modules/alert-message/alert-modal.module';
import {AuthenticationInterceptor} from './modules/authentication/authentication-interceptor';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {selectAuthenticationAuthenticated} from './modules/authentication/authentication.selectors';
import {ConfigurationService} from './modules/configuration/configuration.service';
import {HttpErrorInterceptor} from './modules/http-error-interceptor/http-error-interceptor';
import {NexiaStoreModule} from './redux/nexia-store/nexia-store.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {selectDomainsDatas} from './core/redux/domain/selectors/domain.selectors';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': {direction: Hammer.DIRECTION_ALL}
  };
}

/** Interceptors list */
const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }
];

const azureMsalProtectedResourceMap = new Map();
const azureMsalGraphApiUrl = Utils.getApplicationConfigData('AZURE_MSAL_GRAPH_API_URL');
if (Utils.notNullAndNotUndefined(azureMsalGraphApiUrl) && azureMsalGraphApiUrl.length > 0) {
  azureMsalProtectedResourceMap.set(azureMsalGraphApiUrl, ['user.read', 'Mail.ReadWrite']);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false
    }),
    NexiaStoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    AuthenticationModule,
    ToasterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AlertModalModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_CLIENTID'), // Application (client) ID from the app registration ex : '64881562-4553-46af-ac8a-89aa603ea1fb'
        // 'https://login.microsoftonline.com/4ca5ac27-3da0-450c-9ddb-b3e19aeccf21', // 'https://login.microsoftonline.com/TENANTID' ex : 'https://login.microsoftonline.com/4ca5ac27-3da0-450c-9ddb-b3e19aeccf21'
        authority: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_AUTHORITY'),
        redirectUri: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_REDIRECT_URI') // 'http://localhost:4200' // This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read', 'Mail.ReadWrite', 'Mail.Send']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: azureMsalProtectedResourceMap
    })
  ],
  providers: [
    ...interceptors,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {

  subs: Subscription[] = [];
  private authenticated$ = this.store.select(selectAuthenticationAuthenticated);
  private authenticatedIsTrue$ = this.authenticated$.pipe(filter(isAuthenticated => isAuthenticated), take(1))

  constructor(
    private store: Store,
    configurationService: ConfigurationService,
    socketService: SocketService,
    routerPathService: RouterPathService,
    router: Router,
    private configService: ConfigurationService,
    private i18nService: I18nService,
    private moduleFromRouteDefinerService: ModuleFromRouteDefinerService,
    private menuService: MenuService,
    private domainService: DomainService,
    private searchResultPaginationAction: SearchResultPaginationAction,
  ) {
    configurationService.loadConfiguration()
      .pipe(
        tap(() => routerPathService.onInit()),
        switchMap(() => this.authenticatedIsTrue$),
        filter(authenticated => authenticated),
        catchError((err: any) => {
          router.navigate(['error', {error: err}]).then();
          return EMPTY;
        })
      )
      .subscribe(() => {
        socketService.init();
      });

    this.subs.push(this.authenticatedIsTrue$.pipe(tap(() => {
      console.log(`AUTHENTIFICATION OK`)
    }), switchMap(() => this.i18nService.loadI18n())).subscribe());
    this.subs.push(this.authenticatedIsTrue$.pipe(switchMap(() => this.i18nService.reloadI18nOnLangChange())).subscribe());
    this.subs.push(this.moduleFromRouteDefinerService.watchAndDefineModuleFromRoute().subscribe());
    this.subs.push(this.authenticatedIsTrue$.pipe(switchMap(() => this.menuService.loadMenuItems())).subscribe());
    this.loadWorkspaceItemsIfNotAlreadyLoaded()
    this.subs.push(this.configService.whenReady(() => this.reset_after_maintenance()).subscribe());
    this.searchResultPaginationAction.loadSearchResultPagination();
  }

  private loadWorkspaceItemsIfNotAlreadyLoaded() {
    const noDomainsAreLoaded$ = this.store.select(selectDomainsDatas).pipe(
      filter(datas => !datas || datas.length == 0), take(1),
    )
    this.subs.push(this.authenticatedIsTrue$.pipe(
      switchMap(() => noDomainsAreLoaded$),
      switchMap(() => this.domainService.loadWorkspaceItems())
    ).subscribe());

  }

  private reset_after_maintenance() {
    return this.store.select(selectMaintenanceInfoType).pipe(
      filter(type => type === MaintenanceType.MAINTENANCE_OFF),
      tap(() => () => this.store.dispatch({type: 'RESET_AFTER_MAINTENANCE'})),
      switchMap(() => combineLatest([
        this.i18nService.loadI18n().pipe(take(1)),
        this.menuService.loadMenuItems(),
        this.domainService.loadWorkspaceItems()
      ])),
      tap(() => this.searchResultPaginationAction.loadSearchResultPagination())
    );
  }
}
