import { Injectable } from '@angular/core';
import { SearchItem } from '../../../../models/search-item';

import { createAction, props, Store } from '@ngrx/store';
import {ISearchItemsAction} from '../../../search-item/search-items-action-interface';

export class TaskSearchItemsActionConstantAction {
  static readonly ADD_TASK_SEARCH_ITEM_START = 'ADD_TASK_SEARCH_ITEM_START';
  static readonly ADD_TASK_SEARCH_ITEM_SUCCEEDED = 'ADD_TASK_SEARCH_ITEM_SUCCEEDED';
  static readonly ADD_TASK_SEARCH_ITEM_FAILED = 'ADD_TASK_SEARCH_ITEM_FAILED';

  static readonly REMOVE_TASK_SEARCH_ITEM_START = 'REMOVE_TASK_SEARCH_ITEM_START';
  static readonly REMOVE_TASK_SEARCH_ITEM_SUCCEEDED = 'REMOVE_TASK_SEARCH_ITEM_SUCCEEDED';
  static readonly REMOVE_TASK_SEARCH_ITEM_FAILED = 'REMOVE_TASK_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED = 'REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED';
}

export const addTaskSearchItemStartAction           = createAction(TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_START);
export const addTaskSearchItemSucceededAction       = createAction(TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_SUCCEEDED, props<{payload:SearchItem}>());
export const addTaskSearchItemFailedAction          = createAction(TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_FAILED, props<{error:Error}>());
export const removeTaskSearchItemStartAction        = createAction(TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_START);
export const removeTaskSearchItemSucceededAction    = createAction(TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_SUCCEEDED, props<{payload:SearchItem}>());
export const removeTaskSearchItemFailedAction       = createAction(TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_FAILED, props<{error:Error}>());
export const removeAllTaskSearchItemsSucceededAction = createAction(TaskSearchItemsActionConstantAction.REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class TaskSearchItemsAction implements ISearchItemsAction {

  constructor( private localStore: Store ) {
  }

  public addSearchItemStarted() {
    this.localStore.dispatch(addTaskSearchItemStartAction());
  }

  public addSearchItemSucceeded(payload: SearchItem) {
    this.localStore.dispatch(addTaskSearchItemSucceededAction({payload}));
  }

  public addSearchItemFailed(error: any) {
    this.localStore.dispatch( addTaskSearchItemFailedAction({error}))

  }

  public removeSearchItemStarted() {
    this.localStore.dispatch(removeTaskSearchItemStartAction());
  }

  public removeSearchItemSucceeded(payload: SearchItem) {
    this.localStore.dispatch(removeTaskSearchItemSucceededAction({payload}));
  }

  public removeSearchItemFailed(error: any) {
    this.localStore.dispatch(removeTaskSearchItemFailedAction({error}));
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch(removeAllTaskSearchItemsSucceededAction());
  }
}
