import { CdxDocument } from './cdx-document';
import { Entity } from './Entity';
import { CdxComment } from './cdx-comment';
import { ActivityDiff, CdxActivity } from './cdx-activity';
import { CdxAttachment } from './cdx-attachment';
import { EsPage } from './es-page';

export class EntityDetails {
  details: Entity;
  comments: CdxComment[];
  attachments: CdxAttachment[];
  activities: EsPage<CdxActivity>;
  docEsPage: EsPage<CdxDocument>;
  token: string;
  currentActivityDiff: ActivityDiff;
  loadPdf?: boolean;
}
