import {PayloadAction} from '../../../../redux/payload-action';
import {GroupObjectLink, NexiaObjectLinkLocationKey} from '../../../models/nexia-object-link';
import {
  loadNexiaObjectLinksFailed, loadNexiaObjectLinksStart,
  loadNexiaObjectLinksSucceeded,
  NexiaObjectLinksActionConstantAction
} from '../action/nexia-object-links.action';
import { createReducer, on } from '@ngrx/store';

export interface NexiaObjectLinksState {
  datas: { [key: string]: { -readonly [key in NexiaObjectLinkLocationKey]?: GroupObjectLink[] } };
  loading: boolean;
  error: any;
}

export const NEXIA_OBJECT_LINKS_INITIAL_STATE: NexiaObjectLinksState = {
  datas: {},
  loading: false,
  error: null,
};



export const nexiaObjectLinksReducerOld = (
  state: NexiaObjectLinksState = NEXIA_OBJECT_LINKS_INITIAL_STATE,
  action: PayloadAction<{ [key: string]: { -readonly [key in NexiaObjectLinkLocationKey]?: GroupObjectLink[] } }>) => {
  switch (action.type) {
    case NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};


export const nexiaObjectLinksReducer = createReducer(
  NEXIA_OBJECT_LINKS_INITIAL_STATE,
  on(loadNexiaObjectLinksStart, (state) => { return {
    ...state,
    loading: true,
    error: null,
  }}),
  on(loadNexiaObjectLinksSucceeded,(state, {payload}) => { return {
    ...state,
    datas: payload,
    loading: false,
    error: null,
  }}),
  on(loadNexiaObjectLinksFailed,(state, {error}) => { return {
    ...state,
    loading: false,
    error: error,
  }}),
);
