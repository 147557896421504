import {Injectable} from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {RouterPathInfo} from '../../../models/router-path-info';

export class RouterPathConstantAction {
  static readonly LOAD_ROUTER_PATH_START = 'LOAD_ROUTER_PATH_START';
  static readonly LOAD_ROUTER_PATH_SUCCEEDED = 'LOAD_ROUTER_PATH_SUCCEEDED';
  static readonly LOAD_ROUTER_PATH_FAILED = 'LOAD_ROUTER_PATH_FAILED';
  static readonly REMOVE_ALL_SUCCEEDED = 'REMOVE_ALL_SUCCEEDED';
}

export const loadRouterPathStartAction = createAction(RouterPathConstantAction.LOAD_ROUTER_PATH_START);
export const loadRouterPathSucceededAction = createAction(RouterPathConstantAction.LOAD_ROUTER_PATH_SUCCEEDED, props<{payload : RouterPathInfo}>());
export const loadRouterPathFailedAction = createAction(RouterPathConstantAction.LOAD_ROUTER_PATH_FAILED, props<{error:Error}>());
export const removeAllSucceededAction = createAction(RouterPathConstantAction.REMOVE_ALL_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class RouterPathAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadRouterPathStart(): void {
    this.localStore.dispatch(loadRouterPathStartAction());
  }

  public loadRouterPathSucceeded(payload: RouterPathInfo): void {
    if (!payload.history[0]) {
      this.loadRouterPathFailed('path cannot be null or undefined');
      return;
    }
    this.localStore.dispatch(loadRouterPathSucceededAction( { payload }));

  }

  public loadRouterPathFailed(error: any) {
    this.localStore.dispatch(loadRouterPathFailedAction({error}));
  }

  public removeAll() {
    this.localStore.dispatch(removeAllSucceededAction());
  }

}
