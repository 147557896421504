import {Injectable} from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {NexiaBasketObject, NexiaBasketObjectKeys} from '../../../models/nexia-basket-object';


export class NexiaBasketActionConstantAction {
  static readonly LOAD_NEXIA_BASKET_ITEMS_START = 'LOAD_NEXIA_BASKET_ITEMS_START';
  static readonly LOAD_NEXIA_BASKET_ITEMS_SUCCEEDED = 'LOAD_NEXIA_BASKET_ITEMS_SUCCEEDED';
  static readonly LOAD_NEXIA_BASKET_ITEMS_DETAILS_SUCCEEDED = 'LOAD_NEXIA_BASKET_ITEMS_DETAILS_SUCCEEDED';
  static readonly LOAD_NEXIA_BASKET_ITEMS_FAILED = 'LOAD_NEXIA_BASKET_ITEMS_FAILED';
  static readonly REMOVE_ERROR_FROM_STORE = 'REMOVE_ERROR_FROM_STORE';
  static readonly EMPTY_NEXIA_BASKET_ITEMS_SUCCEEDED = 'EMPTY_NEXIA_BASKET_ITEMS_SUCCEEDED';
}

export const loadNexiaBasketItemsStart = createAction(NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_START);
export const loadNexiaBasketItemsSucceeded = createAction(NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_SUCCEEDED, props<{payload:{ [key: string]: NexiaBasketObject[] }}>());
export const loadNexiaBasketItemsDetailsSucceeded = createAction(NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_DETAILS_SUCCEEDED, props<{payload: { [key: string]: NexiaBasketObject[] } }>());
export const loadNexiaBasketItemsFailed = createAction(NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_FAILED, props<{error:Error}>());
export const removeErrorFromStore = createAction(NexiaBasketActionConstantAction.REMOVE_ERROR_FROM_STORE);
export const emptyNexiaBasketItemsSucceeded = createAction(NexiaBasketActionConstantAction.EMPTY_NEXIA_BASKET_ITEMS_SUCCEEDED, props<{payload:{ [key: string]: NexiaBasketObject[] }}>());

@Injectable({
  providedIn: 'root'
})
export class NexiaBasketAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadStarted(): void {
    this.localStore.dispatch(loadNexiaBasketItemsStart());
  }

  public loadSucceeded(payload: { [key: string]: NexiaBasketObject[] }): void {
    this.localStore.dispatch(loadNexiaBasketItemsSucceeded({payload}));
  }

  public loadFailed(error: any): void {
    this.localStore.dispatch( loadNexiaBasketItemsFailed({error}));
  }

  public removeErrorFromStore(): void {
    this.localStore.dispatch(removeErrorFromStore());
  }

  public setNexiaBasketObjectsDetails(payload: { [key: string]: NexiaBasketObject[] }): void {
    this.localStore.dispatch(loadNexiaBasketItemsDetailsSucceeded({payload}));
  }

  public emptyNexiaBasketObjects(nexiaBasketObjectKey: NexiaBasketObjectKeys): void {
    const payload: { [key: string]: NexiaBasketObject[] } = {};
    payload[nexiaBasketObjectKey] = [];
    this.localStore.dispatch(emptyNexiaBasketItemsSucceeded({payload}));
  }
}
