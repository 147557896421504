import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {usersReducer} from './reducer/user.reducer';

export const userStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.USERS,
    reducer: usersReducer
  })
);

