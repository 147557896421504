import {DOCUMENT} from '@angular/common';
import {Component, HostBinding, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SubscriptionLike} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CurrentModule} from './core/models/current-module';
import {selectCurrentContextFeature} from './core/redux/current-context/current-context-selectors';
import {CurrentContextState} from './core/redux/current-context/reducer/current-context.reducer';
import {ScreenWidthService} from './core/services/responsive/screen-width.service';
import {TitleService} from './core/services/title/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  currentContext$ = this.store.select(selectCurrentContextFeature);

  @HostBinding('class.mobile') mobile = false;
  @HostBinding('class.desktop') desktop = false;

  title = 'Nexia';
  public screenWidth: number;
  private subs: SubscriptionLike[] = [];

  constructor(
    @Inject(DOCUMENT) public document,
    private titleService: TitleService,
    private screenWidthService: ScreenWidthService,
    public translate: TranslateService,
    private store: Store
  ) {
    // translate.setDefaultLang('fr');
    // translate.use('fr');
  }

  ngOnInit() {
    // sets the Browser Page title
    this.subs.push(this.titleService.title.subscribe((title) => {
      this.document.title = title;
    }));

    this.subs.push(this.screenWidthService.screenWidth$.subscribe((screenWidth) => {
      let desktop = false;
      let mobile = false;
      this.screenWidth = screenWidth;
      if (this.screenWidth > 1023) {
        desktop = true;
      } else {
        mobile = true;
      }
      this.desktop = desktop;
      this.mobile = mobile;
    }));
    // this.subs.push(this.translate.get('GLOBAL.app_title').subscribe((response: string) => {
    //   this.title = response;
    //   this.titleService.setTitle(this.title);
    // }));

    this.subs.push(this.translate.onLangChange.pipe(
      filter((langChangeEvent: LangChangeEvent) => !!langChangeEvent.lang && !!langChangeEvent.translations)
    ).subscribe(() => {
      this.title = this.translate.instant('GLOBAL.app_title');
      this.titleService.setTitle(this.title);
    }));

    this.subs.push(this.currentContext$.pipe(
      filter((currentContext: CurrentContextState) => !!currentContext && !!currentContext.datas && !!currentContext.datas.currentModule && !currentContext.loading),
    ).subscribe((currentContextState: CurrentContextState) => {
      let color = '';
      let rgb_accent_color = '';
      switch (currentContextState.datas.currentModule) {
        case CurrentModule.GED:
          color = '#ff6255';
          rgb_accent_color = '255, 98, 85';
          break;
        case CurrentModule.WORKFLOW:
          color = '#35bab8';
          rgb_accent_color = '53, 186, 184';
          break;
      }
      document.documentElement.style.setProperty('--accent_color', color);
      document.documentElement.style.setProperty('--rgb_accent_color', rgb_accent_color);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: SubscriptionLike) => sub.unsubscribe());
  }

}
