import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {DraftDatas} from '../../../../models/DraftDatas';

export class DraftDatasConstant {

  // static readonly LOAD_DRAFT_DOCUMENT_START = '[DraftDatas].LOAD_DRAFT_DOCUMENT_START';
  // static readonly LOAD_DRAFT_DOCUMENT_SUCCEEDED = '[DraftDatas].LOAD_DRAFT_DOCUMENT_SUCCEEDED';
  // static readonly LOAD_DRAFT_DOCUMENT_FAILED = '[DraftDatas].LOAD_DRAFT_DOCUMENT_FAILED';

  static readonly LOAD_DRAFT_DATAS_START = '[DraftDatas].LOAD_DRAFT_DATAS_START';
  static readonly LOAD_DRAFT_DATAS_SUCCEEDED = '[DraftDatas].LOAD_DRAFT_DATAS_SUCCEEDED';
  static readonly LOAD_DRAFT_DATAS_FAILED = '[DraftDatas].LOAD_DRAFT_DATAS_FAILED';
  static readonly REMOVE_DRAFT_DATAS_SUCCEEDED = '[DraftDatas].REMOVE_DRAFT_DATAS_SUCCEEDED';

  static readonly ADD_DRAFT_OPTIONS = '[DraftDatas].ADD_DRAFT_OPTIONS';
  static readonly REMOVE_DRAFT_OPTIONS = '[DraftDatas].REMOVE_DRAFT_OPTIONS';

}

export const loadDraftDatasStartedAction = createAction(
  DraftDatasConstant.LOAD_DRAFT_DATAS_START
);

export const loadDraftDatasSucceededAction = createAction(
  DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED,
  props<{ payload: DraftDatas }>()
);

export const loadDraftDatasFailedAction = createAction(
  DraftDatasConstant.LOAD_DRAFT_DATAS_FAILED,
  props<{ error: Error }>()
);

export const removeDraftDatasSucceededAction = createAction(
  DraftDatasConstant.REMOVE_DRAFT_DATAS_SUCCEEDED
);

export const addDraftOptionsAction = createAction(
  DraftDatasConstant.ADD_DRAFT_OPTIONS,
  props<{ payload: DraftDatas }>()
);

export const removeDraftOptionsAction = createAction(
  DraftDatasConstant.REMOVE_DRAFT_OPTIONS,
  props<{ payload: DraftDatas }>()
);


@Injectable({
  providedIn: 'root'
})
export class DraftDatasAction {

  constructor(private localStore: Store) {}

  public loadDraftDatasStarted(): void {
    this.localStore.dispatch(loadDraftDatasStartedAction());
  }

  public loadDraftDatasSucceeded(draftDatas: DraftDatas): void {
    this.localStore.dispatch(loadDraftDatasSucceededAction({ payload: draftDatas }));
  }

  public loadDraftDatasFailed(error: Error) {
    this.localStore.dispatch(loadDraftDatasFailedAction({ error }));
  }

  public removeSucceeded(): void {
    this.localStore.dispatch(removeDraftDatasSucceededAction());
  }

  public addOptions(draftDatas: DraftDatas) {
    this.localStore.dispatch(addDraftOptionsAction({ payload: draftDatas }));
  }

  public removeOptions(draftDatas: DraftDatas) {
    this.localStore.dispatch(removeDraftOptionsAction({ payload: draftDatas }));
  }
}
