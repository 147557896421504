import { SearchItem } from '../../../../models/search-item';
import { Aggregate } from '../../../../models/aggregate';
import { createReducer, on } from '@ngrx/store';
import {
  addFilterItemsFailedAction,
  addFilterItemsStartAction,
  addFilterItemsSucceededAction,
  removeAllFilterItemsSucceededAction,
  removeFilterItemFailedAction,
  removeFilterItemStartedAction,
  removeFilterItemSucceededAction,
  removeFiltersAndKeepLastAggSucceededAction,
  updateFilterItemsFailedAction,
  updateFilterItemsStartAction,
  updateFilterItemsSucceededAction
} from '../action/filter.action';
import * as _ from 'lodash';


export interface FilterDatas {
  filterItems: SearchItem[];
  lastAgg: Aggregate;
}
export interface FilterState {
  datas: FilterDatas;
  loading: boolean;
  error: any;

}

export const FILTER_ITEMS_INITIAL_STATE: FilterState = {
  datas: {
    filterItems: [],
    lastAgg: null
  },
  loading: false,
  error: null
};

/*
export const filterReducerOld = (state: FilterState = FILTER_ITEMS_INITIAL_STATE, action: PayloadAction<FilterDatas>) => {
  switch (action.type) {
    case FilterActionConstant.ADD_FILTER_ITEMS_START:
    case FilterActionConstant.UPDATE_FILTER_ITEMS_START:
    case FilterActionConstant.REMOVE_FILTER_ITEM_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED:
      const add = state.datas;
      if (!action.payload.filterItems[0].operator) {
        add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
      } else {
        const existingFilterItemRange = add.filterItems.find(existingFilterItem => existingFilterItem.operator === action.payload.filterItems[0].operator && existingFilterItem.path === action.payload.filterItems[0].path);
        if (!existingFilterItemRange) {
          add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
        } else {
          add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = action.payload.filterItems[0];
        }
      }
      add.lastAgg = action.payload.lastAgg;
      return {
        ...state,
        datas: add,
        loading: false,
        error: null,
      };
    case FilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED:
      const toReplace: SearchItem = action.payload.filterItems[0];
      let found = false;
      let searchItems = state.datas.filterItems.map(aFilterItem => {
        if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
          found = true;
          return toReplace;
        } else {
          return aFilterItem;
        }
      });
      if (!found) {
        searchItems = [...searchItems, toReplace];
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: searchItems,
          lastAgg: action.payload.lastAgg
        },
        loading: false,
        error: null,
      };
      case FilterActionConstant.ADD_FILTER_ITEMS_FAILED:
      case FilterActionConstant.UPDATE_FILTER_ITEMS_FAILED:
      case FilterActionConstant.REMOVE_FILTER_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: {
          filterItems: [],
          lastAgg: null
        },
        loading: false,
        error: null,
      };
    case FilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: []
        },
        loading: false,
        error: null
      };
    case FilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED:
      const filterItem: SearchItem = action.payload.filterItems[0];
      let oldLastAgg = state.datas.lastAgg;
      let remove: SearchItem[];
      if (!filterItem.operator) {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.value !== filterItem.value);
      } else {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.operator !== filterItem.operator);
      }
      if (remove.length === 0) {
        oldLastAgg = null;
      }
      return {
        ...state,
        datas: {
          filterItems: remove,
          lastAgg: oldLastAgg
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const filterReducer = createReducer(
  // Initial State
  FILTER_ITEMS_INITIAL_STATE,

  // Start Actions
  on(
    addFilterItemsStartAction,
    updateFilterItemsStartAction,
    removeFilterItemStartedAction,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),

  // Succeeded Actions
  on(addFilterItemsSucceededAction, (state, { payload }) => {
    const add = _.cloneDeep(state.datas);
    const toAdd = payload.filterItems[0];

    if (!toAdd.operator) {
      add.filterItems = add.filterItems.concat(toAdd);
    } else {
      const existingFilterItemRange = add.filterItems.find(
        (existingFilterItem) =>
          existingFilterItem.operator === toAdd.operator &&
          existingFilterItem.path === toAdd.path
      );

      if (!existingFilterItemRange) {
        add.filterItems = add.filterItems.concat(toAdd);
      } else {
        add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = toAdd;
      }
    }

    add.lastAgg = payload.lastAgg;

    return {
      ...state,
      datas: add,
      loading: false,
      error: null,
    };
  }),

  on(updateFilterItemsSucceededAction, (state, { payload }) => {
    const toReplace: SearchItem = payload.filterItems[0];
    let found = false;
    let searchItems = state.datas.filterItems.map((aFilterItem) => {
      if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
        found = true;
        return toReplace;
      } else {
        return aFilterItem;
      }
    });

    if (!found) {
      searchItems = [...searchItems, toReplace];
    }

    return {
      ...state,
      datas: {
        ...state.datas,
        filterItems: searchItems,
        lastAgg: payload.lastAgg,
      },
      loading: false,
      error: null,
    };
  }),

  on(removeFilterItemSucceededAction, (state, { payload }) => {
    const filterItem: SearchItem = payload.filterItems[0];
    let oldLastAgg = state.datas.lastAgg;
    let remove: SearchItem[];

    if (!filterItem.operator) {
      remove = state.datas.filterItems.filter(
        (item) => item.path !== filterItem.path || item.value !== filterItem.value
      );
    } else {
      remove = state.datas.filterItems.filter(
        (item) => item.path !== filterItem.path || item.operator !== filterItem.operator
      );
    }

    if (remove.length === 0) {
      oldLastAgg = null;
    }

    return {
      ...state,
      datas: {
        filterItems: remove,
        lastAgg: oldLastAgg,
      },
      loading: false,
      error: null,
    };
  }),

  on(removeFiltersAndKeepLastAggSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      filterItems: [],
    },
    loading: false,
    error: null,
  })),

  on(removeAllFilterItemsSucceededAction, (state) => ({
    ...state,
    datas: {
      filterItems: [],
      lastAgg: null,
    },
    loading: false,
    error: null,
  })),

  // Failed Actions
  on(
    addFilterItemsFailedAction,
    updateFilterItemsFailedAction,
    removeFilterItemFailedAction,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
