import { Component, ViewEncapsulation } from '@angular/core';
import { ToastaConfig } from 'ngx-toasta';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToasterComponent {

  constructor(
    private toastaConfig: ToastaConfig
  ) {
    this.toastaConfig.timeout = 4000;
    this.toastaConfig.position = 'bottom-left';
  }

}
