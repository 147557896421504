import {PayloadAction} from '../../../../redux/payload-action';
import {MaintenanceInfo} from '../../../models/maintenance-info';
import {createReducer, on} from '@ngrx/store';
import {
  loadMaintenanceInfoSucceededAction,
  MaintenanceInfoActionConstant,
  removeMaintenanceInfoSucceededAction
} from '../action/maintenance-info.action';


export interface MaintenanceInfoState {
  datas: MaintenanceInfo;
  loading: boolean;
  error: any;
}

export const MAINTENANCE_INFO_INITIAL_STATE: MaintenanceInfoState = {
  datas: {
    type: null,
    date: null,
    ttl: null,
    message: null
  },
  loading: false,
  error: null,
};


export const maintenanceInfoReducerOld = (state: MaintenanceInfoState = MAINTENANCE_INFO_INITIAL_STATE, action: PayloadAction<MaintenanceInfo>) => {
  switch (action.type) {
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MaintenanceInfoActionConstant.REMOVE_MAINTENANCE_INFO_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};

export const maintenanceInfoReducer = createReducer(
  // Initial State
  MAINTENANCE_INFO_INITIAL_STATE,

  // Load Maintenance Info Succeeded Action
  on(loadMaintenanceInfoSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  // Remove Maintenance Info Succeeded Action
  on(removeMaintenanceInfoSucceededAction, (state) => ({
    ...state,
    datas: null,
    loading: false,
    error: null,
  }))
);
