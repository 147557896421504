import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {Observable, of, OperatorFunction, Subject} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {TeamsFilterData} from '../../../models/filter-data';
import {ObjectStatus} from '../../../models/object-status';
import {Page} from '../../../models/page';
import {StoreKeys} from '../../../models/store-keys';
import {Team, TeamType} from '../../../models/team';
import {TeamUser} from '../../../models/team-user';
import {Url} from '../../../models/url';
import {TableFilterAction} from '../../table-filter/action/table-filter.action';
import {CfgTeamAction} from '../cfg-teams/action/cfg-team.action';
import {selectCfgTeamFeature} from '../cfg-teams/cfg-team-selectors';
import {CfgTeamState} from '../cfg-teams/reducer/cfg-team.reducer';
import {CurrentTeamUsersPageAction} from '../current-team-users-page/action/current-team-users-page.action';
import {CurrentTeamAction} from '../current-team/action/current-team.action';
import {TeamsPageAction} from '../teams-page/action/teams-page.action';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  public static readonly TEAM_PAGE_SIZE = 24;
  public static readonly TEAM_USERS_PAGE_SIZE = 100;
  public static readonly TEAM_MAX_SIZE = 200;
  public static readonly DEFAULT_TEAM_SORT = 'label';
  public static readonly DEFAULT_TEAM_USERS_SORT = 'firstname';
  public static readonly DEFAULT_SORT_ORDER = 'asc';
  cfgTeams$ = this.store.select(selectCfgTeamFeature);

  constructor(
    private teamsPageAction: TeamsPageAction,
    private currentTeamUsersPageAction: CurrentTeamUsersPageAction,
    private tableFilterAction: TableFilterAction,
    private currentTeamAction: CurrentTeamAction,
    private httpClient: HttpClient,
    private configAction: ConfigurationService,
    private cfgTeamAction: CfgTeamAction,
    private store: Store
  ) {
  }

  private static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  public getPageableTeams(
    type: TeamType = null,
    domain: string = null,
    pageNumber = 0,
    size = TeamsService.TEAM_PAGE_SIZE,
    sortBy = TeamsService.DEFAULT_TEAM_SORT,
    order: 'asc' | 'desc' | '' = 'asc',
    pattern: string = null,
    status: ObjectStatus = null,
    forMyTeams = false,
    members: number[] = null,
    excludes: Team[] = null,
    modifTableFilter = false
  ): void {
    this.teamsPageAction.loadTeamsPageStarted();
    if (modifTableFilter) {
      this.tableFilterAction.loadTableFilterStarted();
    }
    try {
      this.getPageableTeamsAsObservable(
        type,
        domain,
        pageNumber,
        size,
        sortBy,
        order,
        pattern,
        status,
        forMyTeams,
        members,
        excludes
      )
        .subscribe(
          {
            next : (page: Page<Team>) => {
              if (modifTableFilter) {
                const tableFilterKey: string = domain ? StoreKeys.DOMAIN_TEAMS : StoreKeys.INDEXATION_TEAMS;
                this.tableFilterAction.loadTableFilterSucceeded({[tableFilterKey]: new TeamsFilterData(pattern, sortBy, order, page.size, page.number, status, members)});
              }
              this.teamsPageAction.loadTeamsPageSucceeded(page);
            },

            error: (error: HttpErrorResponse) => {
              if (modifTableFilter) {
                this.tableFilterAction.loadTableFilterFailed();
              }
              this.teamsPageAction.loadTeamsPageFailed(error);
            }
        });
    } catch (error) {
      if (modifTableFilter) {
        this.tableFilterAction.loadTableFilterFailed();
      }
      this.teamsPageAction.loadTeamsPageFailed(error);
    }
  }

  public getPageableTeamsAsObservable(
    type: TeamType = null,
    domain: string = null,
    pageNumber = 0,
    size = TeamsService.TEAM_PAGE_SIZE,
    sortBy = TeamsService.DEFAULT_TEAM_SORT,
    order: 'asc' | 'desc' | '' = 'asc',
    pattern: string = null,
    status: ObjectStatus = null,
    forMyTeams = false,
    members: number[] = null,
    excludes: Team[] = null
  ): Observable<Page<Team>> {
    return TeamsService.query()
      .pipe(
        this.page(pageNumber, size),
        this.sort(sortBy, order),
        this.addPattern(pattern),
        this.addType(type),
        this.addDomain(domain),
        this.addStatus(status),
        this.addMembers(members),
        this.addExcludes(excludes),
        this.requestTeamsPage(forMyTeams)
      );
  }

  public getPageableTeamUsers(
    teamCode: string,
    pageNumber = 0,
    size = TeamsService.TEAM_USERS_PAGE_SIZE,
    sortBy = TeamsService.DEFAULT_TEAM_USERS_SORT,
    order: 'asc' | 'desc' | '' = 'asc',
    pattern: string = null,
    roles: string = null
  ): void {
    this.currentTeamUsersPageAction.loadCurrentTeamUsersPageStarted();
    try {
      TeamsService.query()
        .pipe(
          this.page(pageNumber, size),
          this.sort(sortBy, order),
          this.addPattern(pattern),
          this.addRoles(roles),
          this.requestTeamUsersPage(teamCode)
        )
        .subscribe((page: Page<TeamUser>) => {
          this.currentTeamUsersPageAction.loadCurrentTeamUsersPageSucceeded(page);
        }, (error: HttpErrorResponse) => {
          this.currentTeamUsersPageAction.loadCurrentTeamUsersPageFailed(error);
        });
    } catch (error) {
      this.currentTeamUsersPageAction.loadCurrentTeamUsersPageFailed(error);
    }
  }

  public getPageableUsers(
    teamCode: string,
    pattern: string = null,
    pageNumber = 0,
    size = TeamsService.TEAM_PAGE_SIZE,
    sortBy = TeamsService.DEFAULT_TEAM_USERS_SORT,
    order: 'asc' | 'desc' | '' = 'asc'
  ): Observable<Page<TeamUser>> {
    return TeamsService.query()
      .pipe(
        this.page(pageNumber, size),
        this.sort(sortBy, order),
        this.addPattern(pattern),
        this.requestUsersPage(teamCode)
      );
  }

  public getTeamById(teamId: string): void {
    this.currentTeamAction.loadCurrentTeamStarted();
    try {
      this._getTeamById(teamId)
        .subscribe(
          (currentTeam) => {
            // tslint:disable-next-line:max-line-length
            this.currentTeamAction.loadCurrentTeamSucceeded(currentTeam);
          },
          (error: HttpErrorResponse) => this.currentTeamAction.loadCurrentTeamFailed(error));
    } catch (error) {
      this.currentTeamAction.loadCurrentTeamFailed(error);
    }
  }

  public upsertTeam(team: Team, isUpdate: boolean, teamIdToDuplicate: string = null): Observable<Team> {
    const response: Subject<Team> = new Subject<Team>();
    let request$: Observable<HttpResponse<Team>>;
    const url: string = Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS;
    this.currentTeamAction.loadCurrentTeamStarted();
    try {
      if (isUpdate) {
        request$ = this.httpClient.put<Team>(
          // tslint:disable-next-line:max-line-length
          url + team.code, team, {observe: 'response'});
      } else {
        if (!teamIdToDuplicate) {
          request$ = this.httpClient.post<Team>(
            url, team, {observe: 'response'});
        } else {
          request$ = this.httpClient.post<Team>(
            url + teamIdToDuplicate + '/' + Url.DUPLICATE, team, {observe: 'response'});
        }
      }
      request$.subscribe(
        (currentTeamResponse) => {
          // tslint:disable-next-line:max-line-length
          this.currentTeamAction.loadCurrentTeamSucceeded(currentTeamResponse.body);
          response.next(currentTeamResponse.body);
          response.complete();
        },
        (error: HttpErrorResponse) => {
          this.currentTeamAction.loadCurrentTeamFailed(error);
          response.next(null);
          response.complete();
        });
    } catch (error) {
      this.currentTeamAction.loadCurrentTeamFailed(error);
      response.next(null);
      response.complete();
    }
    return response.asObservable();
  }

  public upsertUserInTeam(teamId: string, user: TeamUser): Observable<TeamUser> {
    return this.httpClient.post<TeamUser>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + teamId + '/' + Url.USERS, user, {observe: 'response'}).pipe(
      map((response: HttpResponse<TeamUser>) => {
        this.getPageableTeamUsers(teamId);
        return response.body;
      })
    );
  }

  public deleteTeamUser(teamId: string, userId: number): void {
    this.httpClient.delete<TeamUser>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + teamId + '/' + Url.USERS + userId, {observe: 'response'})
      .subscribe(() => {
          this.getPageableTeamUsers(teamId);
        },
        () => console.log('ERROR on deletetin team user', userId)
      );
  }

  public getTeamsByCodes(teamCodes: string[]): Observable<Team[]> {
    return TeamsService.query().pipe(
      this.addTeamCodesToSearchFor(teamCodes),
      this.requestTeamsList()
    );
  }

  public loadCfgTeamsFromBucketKeys(teamCodesToSearchFor: string[]): void {
    if (!teamCodesToSearchFor.length) {
      return;
    }
    this.cfgTeams$.pipe(
      filter((state: CfgTeamState) => undefined === state || (!state.loading && state.error === null)),
      take(1)
    ).subscribe((state: CfgTeamState) => {
      if (state === undefined) {
        this.cfgTeamAction.loadCfgTeamsStarted();
        this.getTeamsByCodes(teamCodesToSearchFor).subscribe((teamsList: Team[]) => {
          this.cfgTeamAction.loadCfgTeamsSucceeded(teamsList);
        }, (error) => this.cfgTeamAction.loadCfgTeamsFailed(error));
      } else {
        let existingTeams: Team[] = _.cloneDeep(state.datas);
        const unknowTeams: string[] = this.calcUnknownTeams(existingTeams, teamCodesToSearchFor);
        if (unknowTeams.length > 0) {
          this.cfgTeamAction.loadCfgTeamsStarted();
          this.getTeamsByCodes(unknowTeams).subscribe((teamsFromRequests: Team[]) => {
            existingTeams = [...existingTeams, ...teamsFromRequests];
            this.cfgTeamAction.loadCfgTeamsSucceeded(existingTeams);
          }, (error) => this.cfgTeamAction.loadCfgTeamsFailed(error));
        }
      }
    });
  }

  private _getTeamById(teamId: string): Observable<Team> {
    return this.httpClient.get<Team>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + teamId);
  }

  private calcUnknownTeams(existingTeams: Team[], teamCodesToSearchFor: string[]): string[] {
    const unknowTeams: string[] = [];
    teamCodesToSearchFor.forEach((teamCodeToSearchFor: string) => {
      if (!existingTeams.some((team: Team) => team.code === teamCodeToSearchFor)) {
        unknowTeams.push(teamCodeToSearchFor);
      }
    });
    return unknowTeams;
  }

  private addExcludes(teams: Team[]): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!teams) {
        let excludeExpr = '';
        for (const team of teams) {
          excludeExpr += team.code + ',';
        }
        if (excludeExpr.endsWith(',')) {
          excludeExpr = excludeExpr.substring(0, excludeExpr.length - 1);
        }
        params = params.append('_exclude', excludeExpr);
      }
      return params;
    });
  }

  private addPattern(pattern: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!pattern) {
        params = params.append('_pattern', pattern);
      }
      return params;
    });
  }

  private addTeamCodesToSearchFor(teamCodes: string[] = []): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!teamCodes && teamCodes.length) {
        params = params.append('_teamCodes', '' + teamCodes);
      }
      return params;
    });
  }

  private addType(type: TeamType): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      console.log('type', type);
      if (!!type) {
        params = params.append('_type', type);
      }
      return params;
    });
  }

  private addDomain(domain: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!domain) {
        params = params.append('_domain', domain);
      }
      return params;
    });
  }

  private addStatus(status: ObjectStatus): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!status) {
        params = params.append('_status', status);
      }
      return params;
    });
  }

  private addMembers(members: number[]): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!members) {
        params = params.append('_members', members + '');
      }
      return params;
    });
  }

  private sort(sort: any, order: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!sort) {
        if (!!order) {
          sort = sort + ',' + order;
        }
        return params.append('sort', sort);
      }
      return params;
    });
  }

  private page(pageNumber = 0, size: number = TeamsService.TEAM_PAGE_SIZE): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      return params
        .set('size', '' + Math.min(size, TeamsService.TEAM_MAX_SIZE))
        .set('page', '' + pageNumber);
    });
  }

  private addRoles(roles: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!roles) {
        params = params.append('_roles', roles);
      }
      return params;
    });
  }

  private requestTeamsPage(isMyTeams: boolean): OperatorFunction<HttpParams, Page<Team>> {
    return switchMap(params => {
      if (isMyTeams) {
        return this.httpClient.get<Page<Team>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + Url.MY_TEAMS, {params});
      } else {
        return this.httpClient.get<Page<Team>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS, {params});
      }
    });
  }

  private requestTeamsList(): OperatorFunction<HttpParams, Team[]> {
    return switchMap(params => {
      return this.httpClient.get<Team[]>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + Url.LIST, {params});
    });
  }

  private requestTeamUsersPage(teamCode: string): OperatorFunction<HttpParams, Page<TeamUser>> {
    return switchMap(params => {
      return this.httpClient.get<Page<TeamUser>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + teamCode + '/' + Url.USERS, {params});
    });
  }

  private requestUsersPage(teamCode: string): OperatorFunction<HttpParams, Page<TeamUser>> {
    return switchMap(params => {
      return this.httpClient.get<Page<TeamUser>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.TEAMS + teamCode + '/' + Url.NEXIA_USERS, {params});
    });
  }
}
