import { UserCredentials } from './UserCredentials';
import { UserWithFunctions } from './UserWithFunctions';
export class EsAuthor {

  constructor(
    public id?: number,
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public profiles?: string[]
  ) {
  }


  static getUserLabel(author: EsAuthor | UserCredentials, isReply = false): string {
    if (!isReply) {
      return author.firstname + ' ' + author.lastname;
    } else {
      return author.firstname + '.' + author.lastname;
    }
  }

  static fromUserWithFunctions(userWithFunctions: UserWithFunctions) {
    return new EsAuthor(
      userWithFunctions.id,
      userWithFunctions.firstname,
      userWithFunctions.lastname,
      userWithFunctions.email,
      undefined
    );
  }
}
