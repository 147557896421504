import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {taskSearchItemsReducer} from './reducer/task-search-items.reducer';
import {withSessionStoragePersistence} from "../../../../redux/reducer-utils/with-session-storage-persistence";

export const taskSearchItemStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TASK_SEARCH_ITEMS,
    reducer: withSessionStoragePersistence(StoreKeys.TASK_SEARCH_ITEMS, taskSearchItemsReducer)
  })
);
