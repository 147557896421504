import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {TooltipModule} from '../tooltip/tooltip.module';
import {MobileSearchFiltersComponent} from './components/mobile-search-filters.component';
import {ClickOutsideDirective} from "../../../shared-directives/click-outside.directive";
import {PerfectScrollbarDirective} from "../../../shared-directives/perfect-scrollbar.directive";

@NgModule({
  declarations: [
    MobileSearchFiltersComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    RouterModule,
    ClickOutsideDirective,
    PerfectScrollbarDirective
  ],
  exports: [
    MobileSearchFiltersComponent
  ]
})
export class MobileSearchFiltersModule {
}
