import { NgModule } from '@angular/core';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

@NgModule({
  declarations: [AuthCallbackComponent],
  imports: [],
  providers: []
})
export class AuthenticationModule {
}
