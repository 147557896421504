import { PayloadAction } from '../../../../redux/payload-action';
import {
  CurrentContextActionConstant, loadCurrentDomainFailedAction,
  loadCurrentDomainSucceededAction, loadCurrentEntityTypeFailedAction,
  loadCurrentEntityTypeSucceededAction, loadCurrentModuleFailedAction,
  loadCurrentModuleSucceededAction,
  removeCurrentDomainSucceededAction,
  removeCurrentEntityTypeSucceededAction,
  removeCurrentModuleSucceededAction
} from '../action/current-context.action';
import {createReducer, on} from '@ngrx/store';

export class CurrentContext {
  currentEntityType: string;
  currentDomain: string;
  currentModule: string;
}

export class CurrentContextState {
  datas: CurrentContext;
  loading: boolean;
  error: any;
}

export const CURRENT_CONTEXT_INITIAL_STATE: CurrentContextState = {
  datas: {
    currentEntityType: null,
    currentDomain: null,
    currentModule: null
  },
  loading: false,
  error: null,
};



export const currentContextReducerOld = (state: CurrentContextState = CURRENT_CONTEXT_INITIAL_STATE, action: PayloadAction<string>) => {
  switch (action.type) {
    case CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentEntityType: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentDomain: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_MODULE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentModule: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_FAILED:
    case CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_FAILED:
    case CurrentContextActionConstant.LOAD_CURRENT_MODULE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentEntityType: null
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_DOMAIN_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentDomain: null
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_MODULE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentModule: null
        },
        loading: false,
        error: null,
      };
  }
  return state;
};

export const currentContextReducer = createReducer(
  // Initial State
  {
    datas: {
      currentEntityType: null,
      currentDomain: null,
      currentModule: null,
    },
    loading: false,
    error: null,
  },

  // Load Succeeded Actions
  on(loadCurrentEntityTypeSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      currentEntityType: payload,
    },
    loading: false,
    error: null,
  })),

  on(loadCurrentDomainSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      currentDomain: payload,
    },
    loading: false,
    error: null,
  })),

  on(loadCurrentModuleSucceededAction, (state, { payload }) => ({
    ...state,
    datas: {
      ...state.datas,
      currentModule: payload,
    },
    loading: false,
    error: null,
  })),

  // Load Failed Actions
  on(
    loadCurrentEntityTypeFailedAction,
    loadCurrentDomainFailedAction,
    loadCurrentModuleFailedAction,
    (state) => ({
      ...state,
      loading: false,
      error: true,
    })
  ),

  // Remove Succeeded Actions
  on(removeCurrentEntityTypeSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      currentEntityType: null,
    },
    loading: false,
    error: null,
  })),

  on(removeCurrentDomainSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      currentDomain: null,
    },
    loading: false,
    error: null,
  })),

  on(removeCurrentModuleSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      currentModule: null,
    },
    loading: false,
    error: null,
  }))
);
