import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {ConfigurationService, whenConfReady} from '../../../../modules/configuration/configuration.service';
import {DomainItem} from '../../../models/domain-item';
import {Url} from '../../../models/url';
import {I18nService} from '../../../services/i18n/i18n.service';
import {CfgDocumentInfoService} from '../../cfg-document-info/service/cfg-document-info.service';
import {selectCurrentContextDatasCurrentDomain} from '../../current-context/current-context-selectors';
import {CurrentContextService} from '../../current-context/service/current-context.service';
import {DocumentDetailsService} from '../../details/service/document/document-details.service';
import {TaskDetailsService} from '../../details/service/task/task-details.service';
import {DocumentFilterService} from '../../filter/service/document-filter.service';
import {EntityFilterService} from '../../filter/service/entity-filter.service';
import {TaskFilterService} from '../../filter/service/task-filter.service';
import {MenuService} from '../../menu/service/menu.service';
import {DocumentSearchItemsService} from '../../search-item/service/document-search-item.service';
import {EntitySearchItemsService} from '../../search-item/service/entity-search-items.service';
import {TaskSearchItemsService} from '../../search-item/service/task-search-item.service';
import {SearchResultPaginationService} from '../../search-result-pagination/service/search-result-pagination.service';
import {DomainAction} from '../action/domain.action';
import {DomainState} from '../reducer/domain.reducer';
import {selectDomainsFeature} from '../selectors/domain.selectors';

@Injectable({
  providedIn: 'root'
})
export class DomainService {


  current: DomainItem;
  private domains$ = this.store.select(selectDomainsFeature).pipe(takeUntilDestroyed(), whenConfReady);
  private currentDomain$ = this.store.select(selectCurrentContextDatasCurrentDomain).pipe(takeUntilDestroyed(), whenConfReady);
  private domainsState: DomainState;
  private currentDomain: string;

  constructor(
    private documentFilterService: DocumentFilterService,
    private entityFilterService: EntityFilterService,
    private taskFilterService: TaskFilterService,
    private domainAction: DomainAction,
    private menuService: MenuService,
    private currentContextService: CurrentContextService,
    private httpClient: HttpClient,
    private documentDetailsService: DocumentDetailsService,
    private documentSearchItemsService: DocumentSearchItemsService,
    private entitySearchItemsService: EntitySearchItemsService,
    private taskSearchItemsService: TaskSearchItemsService,
    private taskDetailsService: TaskDetailsService,
    private cfgDocumentInfoService: CfgDocumentInfoService,
    private searchResultServicePagination: SearchResultPaginationService,
    private configService: ConfigurationService,
    private translateService: TranslateService,
    private store: Store,
    private i18nService: I18nService
  ) {
    this.domains$.subscribe(domains => this.domainsState = domains);
    this.currentDomain$.subscribe(currentDomain => this.currentDomain = currentDomain);
  }

  public loadWorkspaceItems(): Observable<boolean> {
    console.log(`CHARGEMENT des workspace items...`);
    this.domainAction.loadWorkspaceStarted();

    const whenReady$ = this.configService.whenReady(() => this.httpClient.get(Url.getProtectedApiBaseUrl(this.configService) + Url.DOMAINS));

    const observable = combineLatest([whenReady$, this.i18nService.i18nLoaded$.pipe(filter(ready => ready))]);
    return observable
      .pipe(
        map(([response ]) => {
          return response;
        }),
        tap((response: any[]) => {
          const wsItems: DomainItem[] = [];
          response.forEach(element => {
            const item = this.createDomainItem(element);
            wsItems.push(item);
          });
          let currentDomainCode: string = this.currentContextService.getCurrentDomain();
          if (!currentDomainCode && wsItems.length > 0) {
            currentDomainCode = wsItems[0].code;
            this.currentContextService.setCurrentDomain(currentDomainCode);
          }
          this.menuService.calcAccessToDomainTeamsByCurrentDomain(currentDomainCode);
          this.domainAction.loadWorkspaceSucceeded(wsItems);

        }),
        map(() => true),
        catchError(error => {
          console.error(error);
          this.domainAction.loadWorkspaceFailed(error);
          return of(false);
        }),
      );
  }

  public setCurrentWorkspace(...workspaceItems: DomainItem[]) {
    this.resetStore();
    this.currentContextService.setCurrentDomain(workspaceItems[0].code);
    this.menuService.calcAccessToDomainTeamsByCurrentDomain(workspaceItems[0].code);
  }

  public getDomains(): DomainItem[] {
    const domainState: DomainState = this.domainsState;
    if (!domainState) {
      return [];
    }
    return domainState.datas;
  }

  /**
   * @deprecated
   */
  getCurrentDomain(): DomainItem {
    const domainState: DomainState = this.domainsState;
    const currentDomainCode: string = this.currentDomain;
    domainState.datas.forEach(domainItem => {
      if (domainItem.code === currentDomainCode) {
        this.current = domainItem;
        return;
      }
    });
    return this.current;
  }

  private createDomainItem(responseItem: any): DomainItem {
    const domainItem: DomainItem = new DomainItem();
    domainItem.code = responseItem.code;
    domainItem.label = this.translateService.instant('domain.' + responseItem.code + '.label');
    domainItem.text = this.translateService.instant('domain.' + responseItem.code + '.text');
    domainItem.entityType = responseItem.entityType.code;
    domainItem.region = responseItem.region;
    domainItem.entrypoint = responseItem.entrypoint;
    return domainItem;
  }

  private resetStore(): void {
    this.documentFilterService.removeAllFilters();
    this.entityFilterService.removeAllFilters();
    this.taskFilterService.removeAllFilters();
    this.documentSearchItemsService.removeAllSearchItems();
    this.entitySearchItemsService.removeAllSearchItems();
    this.taskSearchItemsService.removeAllSearchItems();
    this.documentDetailsService.remove();
    this.taskDetailsService.remove();
    this.cfgDocumentInfoService.removeAllCfgDocumentInfo();
    this.searchResultServicePagination.reinitSearchResultPagination();
  }

}
