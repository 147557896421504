import {createReducer, on} from '@ngrx/store';
import {DomainItem} from '../../../models/domain-item';
import {
  loadDomainItemsFailedAction,
  loadDomainItemsStartAction,
  loadDomainItemsSucceededAction
} from '../action/domain.action';

export interface DomainState {
  datas: DomainItem[];
  loading: boolean;
  error: any;
}

export const DOMAIN_INITIAL_STATE: DomainState = {
  datas: [],
  loading: false,
  error: null
};

export const domainReducer = createReducer(DOMAIN_INITIAL_STATE,
  on(loadDomainItemsStartAction, state => ({
    ...state,
    test: '',
    loading: true,
    error: null,
  })),
  on(loadDomainItemsSucceededAction, (state, action) => ({
    ...state,
    datas: [...action.payload],
    test: '',
    loading: false,
    error: null,
  })),
  on(loadDomainItemsFailedAction, (state, action) => ({
    ...state,
    datas: [],
    test: '',
    loading: false,
    error: action.error,
  })),
)
