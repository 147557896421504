import {Injectable} from '@angular/core';
import {EsPage} from '../../../../models/es-page';
import {Aggregate} from '../../../../models/aggregate';
import {Entity} from '../../../../models/Entity';
import {createAction, props, Store} from '@ngrx/store';

export class EntitySearchResultConstant {
  static readonly LOAD_RESULT_ITEMS_START = '[EntitySearch]: LOAD_RESULTS_START';
  static readonly LOAD_RESULT_ITEMS_SUCCEEDED = '[EntitySearch]: LOAD_RESULTS_SUCCEEDED';
  static readonly LOAD_RESULT_ITEMS_FAILED = '[EntitySearch]: LOAD_RESULTS_FAILED';
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_AGGREGATE_START = '[EntitySearch]: LOAD_AGGREGATE_START';
  static readonly LOAD_AGGREGATE_SUCCEEDED = '[EntitySearch]: LOAD_AGGREGATE_SUCCEEDED';
  static readonly LOAD_AGGREGATE_FAILED = '[EntitySearch]: LOAD_AGGREGATE_FAILED';

  static readonly UPDATE_ENTITIES_START = '[EntitySearch]: UPDATE_ENTITIES_START';
  static readonly UPDATE_ENTITIES_SUCCEEDED = '[EntitySearch]: UPDATE_ENTITIES_SUCCEEDED';
  static readonly UPDATE_ENTITIES_FAILED = '[EntitySearch]: UPDATE_ENTITIES_FAILED';
}

export const loadEntitiesStartAction = createAction(
  EntitySearchResultConstant.LOAD_RESULT_ITEMS_START
);

export const loadEntitiesSucceededAction = createAction(
  EntitySearchResultConstant.LOAD_RESULT_ITEMS_SUCCEEDED,
  props<{ payload: EsPage<Entity> }>()
);

export const loadEntitiesFailedAction = createAction(
  EntitySearchResultConstant.LOAD_RESULT_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const updateEntitiesStartAction = createAction(
  EntitySearchResultConstant.UPDATE_ENTITIES_START
);

export const updateEntitiesSucceededAction = createAction(
  EntitySearchResultConstant.UPDATE_ENTITIES_SUCCEEDED,
  props<{ payload: EsPage<Entity> }>()
);

export const updateEntitiesFailedAction = createAction(
  EntitySearchResultConstant.UPDATE_ENTITIES_FAILED,
  props<{ error: Error }>()
);

export const loadEntityAggregateStartAction = createAction(
  EntitySearchResultConstant.LOAD_AGGREGATE_START
);

export const loadEntityAggregateFailedAction = createAction(
  EntitySearchResultConstant.LOAD_AGGREGATE_FAILED,
  props<{ error: Error }>()
);

export const loadEntityAggregateSucceededAction = createAction(
  EntitySearchResultConstant.LOAD_AGGREGATE_SUCCEEDED,
  props<{ payload: EsPage<Entity> }>()
);

@Injectable({
  providedIn: 'root'
})
export class EntitySearchResultAction {

  constructor(private localStore: Store) {
  }

  public loadEntitiesStarted() {
    this.localStore.dispatch(loadEntitiesStartAction());
  }

  public loadEntitiesSucceeded(page: EsPage<Entity>) {
    this.localStore.dispatch(loadEntitiesSucceededAction({payload: page}));
  }

  public loadEntitiesFailed(error: Error) {
    this.localStore.dispatch(loadEntitiesFailedAction({error}));
  }

  public updateEntitiesStarted() {
    this.localStore.dispatch(updateEntitiesStartAction());
  }

  public updateEntitiesSucceeded(page: EsPage<Entity>) {
    this.localStore.dispatch(updateEntitiesSucceededAction({payload: page}));
  }

  public updateEntitiesFailed(error: Error) {
    this.localStore.dispatch(updateEntitiesFailedAction({error}));
  }

  public loadAggregateStart() {
    this.localStore.dispatch(loadEntityAggregateStartAction());
  }

  public loadAggregateFailed(error: Error) {
    this.localStore.dispatch(loadEntityAggregateFailedAction({error}));
  }

  public loadAggregateSucceeded(agg: Aggregate) {
    const page: EsPage<Entity> = new EsPage<Entity>(0, [], 0, 0, [agg], false);
    this.localStore.dispatch(loadEntityAggregateSucceededAction({payload: page}));
  }
}
