import {Injectable} from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {GroupObjectLink, NexiaObjectLinkLocationKey} from '../../../models/nexia-object-link';


export class NexiaObjectLinksActionConstantAction {
  static readonly LOAD_NEXIA_OBJECT_LINKS_START = 'LOAD_NEXIA_OBJECT_LINKS_START';
  static readonly LOAD_NEXIA_OBJECT_LINKS_SUCCEEDED = 'LOAD_NEXIA_OBJECT_LINKS_SUCCEEDED';
  static readonly LOAD_NEXIA_OBJECT_LINKS_FAILED = 'LOAD_NEXIA_OBJECT_LINKS_FAILED';
}

export const loadNexiaObjectLinksStart = createAction(NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_START);
export const loadNexiaObjectLinksSucceeded = createAction(NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_SUCCEEDED, props<{payload:{ [key: string]: { -readonly [key in NexiaObjectLinkLocationKey]?: GroupObjectLink[] } }}>());
export const loadNexiaObjectLinksFailed = createAction(NexiaObjectLinksActionConstantAction.LOAD_NEXIA_OBJECT_LINKS_FAILED, props<{error:Error}>());

@Injectable({
  providedIn: 'root'
})
export class NexiaObjectLinksAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadStarted(): void {
    this.localStore.dispatch(loadNexiaObjectLinksStart());
  }

  public loadSucceeded(payload: { [key: string]: { -readonly [key in NexiaObjectLinkLocationKey]?: GroupObjectLink[] } }): void {
    this.localStore.dispatch(loadNexiaObjectLinksSucceeded({payload}));
  }

  public loadFailed(error: any) {
    this.localStore.dispatch(loadNexiaObjectLinksFailed({error}));
  }
}
