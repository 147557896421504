import { Page } from '../../../../models/page';
import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../redux/payload-action';
import {
  loadTeamsPageFailedAction,
  loadTeamsPageStartAction,
  loadTeamsPageSucceedAction,
  TeamsPageConstantAction
} from '../action/teams-page.action';
import { createReducer, on } from '@ngrx/store';

export class TeamsPageState {
  datas: Page<Team>;
  loading: boolean;
  error: any;
}

export const TEAMS_PAGE_INITIAL_STATE: TeamsPageState = {
  datas: null,
  loading: false,
  error: null,
};



export const teamsPageReducerOld = (state: TeamsPageState = TEAMS_PAGE_INITIAL_STATE, action: PayloadAction<Page<Team>>) => {
  switch (action.type) {
    case TeamsPageConstantAction.LOAD_TEAMS_PAGE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TeamsPageConstantAction.LOAD_TEAMS_PAGE_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case TeamsPageConstantAction.LOAD_TEAMS_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const teamsPageReducer = createReducer(
  TEAMS_PAGE_INITIAL_STATE,
  on(loadTeamsPageStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadTeamsPageSucceedAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
    })),
  on(loadTeamsPageFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),
);
