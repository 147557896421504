import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {SearchItem} from '../../../../models/search-item';
import {Aggregate} from '../../../../models/aggregate';
import {IFilterAction} from '../../../filter/filter-action-interface';

export class FilterActionConstant {
  static readonly ADD_FILTER_ITEMS_START = 'ADD_FILTER_ITEMS_START';
  static readonly ADD_FILTER_ITEMS_SUCCEEDED = 'ADD_FILTER_ITEMS_SUCCEEDED';
  static readonly ADD_FILTER_ITEMS_FAILED = 'ADD_FILTER_ITEMS_FAILED';

  static readonly UPDATE_FILTER_ITEMS_START = 'UPDATE_FILTER_ITEMS_START';
  static readonly UPDATE_FILTER_ITEMS_SUCCEEDED = 'UPDATE_FILTER_ITEMS_SUCCEEDED';
  static readonly UPDATE_FILTER_ITEMS_FAILED = 'UPDATE_FILTER_ITEMS_FAILED';

  static readonly REMOVE_FILTER_ITEM_STARTED = 'REMOVE_FILTER_ITEM_STARTED';
  static readonly REMOVE_FILTER_ITEM_SUCCEEDED = 'REMOVE_FILTER_ITEM_SUCCEEDED';
  static readonly REMOVE_FILTER_ITEM_FAILED = 'REMOVE_FILTER_ITEM_FAILED';

  static readonly REMOVE_ALL_FILTER_ITEMS_SUCCEEDED = 'REMOVE_ALL_FILTER_ITEMS_SUCCEEDED';
  static readonly REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED = 'REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED';

}

export const addFilterItemsStartAction = createAction(
  FilterActionConstant.ADD_FILTER_ITEMS_START
);

export const addFilterItemsSucceededAction = createAction(
  FilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const addFilterItemsFailedAction = createAction(
  FilterActionConstant.ADD_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const removeFilterItemStartedAction = createAction(
  FilterActionConstant.REMOVE_FILTER_ITEM_STARTED
);

export const removeFilterItemSucceededAction = createAction(
  FilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: null } }>()
);

export const removeFiltersAndKeepLastAggSucceededAction = createAction(
  FilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED
);

export const removeFilterItemFailedAction = createAction(
  FilterActionConstant.REMOVE_FILTER_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeAllFilterItemsSucceededAction = createAction(
  FilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED
);

export const updateFilterItemsStartAction = createAction(
  FilterActionConstant.UPDATE_FILTER_ITEMS_START
);

export const updateFilterItemsSucceededAction = createAction(
  FilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const updateFilterItemsFailedAction = createAction(
  FilterActionConstant.UPDATE_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);

@Injectable({
  providedIn: 'root'
})
export class FilterAction implements IFilterAction {

  constructor(private store: Store) {
  }

  public addFilterStarted(): void {
    this.store.dispatch(addFilterItemsStartAction());
  }

  public addFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    this.store.dispatch(addFilterItemsSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: agg
      }
    }));
  }

  public addFilterFailed(error: Error) {
    this.store.dispatch(addFilterItemsFailedAction({error}));
  }

  public removeFilterStarted(): void {
    this.store.dispatch(removeFilterItemStartedAction());
  }

  public removeFilterSucceeded(filterItem: SearchItem) {
    this.store.dispatch(removeFilterItemSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: null
      }
    }));
  }

  public removeFiltersAndKeepLastAgg(): void {
    this.store.dispatch(removeFiltersAndKeepLastAggSucceededAction());
  }

  public removeFilterFailed(error: Error) {
    this.store.dispatch(removeFilterItemFailedAction({error}));
  }

  public removeAllFiltersSucceeded() {
    this.store.dispatch(removeAllFilterItemsSucceededAction());
  }

  updateFilterStarted() {
    this.store.dispatch(updateFilterItemsStartAction());
  }

  updateFilterSucceeded(filterItem: SearchItem, curAggregate: Aggregate) {
    this.store.dispatch(updateFilterItemsSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: curAggregate
      }
    }));
  }

  updateFilterFailed(error: Error) {
    this.store.dispatch(updateFilterItemsFailedAction({error}));
  }
}
