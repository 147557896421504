import { PayloadAction } from '../../../../redux/payload-action';
import { TaskForm } from '../../../models/task-form';
import {
  addTaskformFailedAction,
  addTaskformStartAction, addTaskformSucceededAction,
  removeTaskFormSucceededAction,
  TaskFormConstantAction
} from '../action/task-form.action';
import { createReducer, on } from '@ngrx/store';

export interface TaskFormState {
  datas: TaskForm;
  loading: boolean;
  error: any;
}

export const TASK_FORM_INITIAL_STATE: TaskFormState = {
  datas: null,
  loading: false,
  error: null,
};



export const taskFormReducerOld = (state: TaskFormState = TASK_FORM_INITIAL_STATE, action: PayloadAction<TaskForm>): TaskFormState => {
  switch (action.type) {
    case TaskFormConstantAction.ADD_TASK_FORM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskFormConstantAction.ADD_TASK_FORM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case TaskFormConstantAction.ADD_TASK_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TaskFormConstantAction.REMOVE_TASK_FORM_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};


export const taskFormReducer= createReducer(
    TASK_FORM_INITIAL_STATE,
    on(addTaskformStartAction, (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }) ,
    on(addTaskformSucceededAction, (state, {payload}) => {
      return {
        ...state,
        datas: payload,
        loading: false,
        error: null,
      }
    }) ,
    on(addTaskformFailedAction,  (state, {error}) => {
      return {
        ...state,
        loading: false,
        error: error,
      }
    }) ,
    on(removeTaskFormSucceededAction,     (state) => {
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      }
    })
);
