import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {maintenanceInfoReducer} from './reducer/maintenance-info.reducer';


export const maintenanceInfoStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.MAINTENANCE_INFO,
    reducer: maintenanceInfoReducer
  })
);
