import {StringUtils} from '../../../utils/string-utils';


const DESCRIPTION_ERREUR = 'Erreur de ';


export class ErrorDetails {
  rubriques: {titre: string; listeErreurs: string[]}[] = [];
  addError(key: string, values: string[]) {
    if (values.length) {
      this.rubriques.push({titre: key, listeErreurs: values});
    }
  }
}

export class ErrorMessageFormater {

  public static extractErrors(errorDetails: object): ErrorDetails {
    let rubriqueErreur = '';
    const result: ErrorDetails = new ErrorDetails();
    Object.entries(errorDetails).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        rubriqueErreur = StringUtils.capitalizeFirst(key);
        const listeErreurs = value.map(item => `- ${item}`);
        result.addError(rubriqueErreur, listeErreurs);
      } else if (typeof value === 'object') {
        rubriqueErreur = DESCRIPTION_ERREUR + key;
        const listeErreurs = Object.entries<string[]>(value).map(([subKey, subValue]) => `- ${subKey}: ${subValue.join(', ')}`);
        result.addError(rubriqueErreur, listeErreurs);
      }
    });
    return result;
  }

  public static getErrorTitleFromUrl(url: string): string {
    // todo: renseigner ce titre côté back...
    const urlSegments = url.split('/');
    const titleIndex = urlSegments.indexOf('rest');
    if (titleIndex !== 0) {
      return '';
    }
    if (urlSegments.length > 1) {
      const subtitle = urlSegments[titleIndex + 1];
      return StringUtils.capitalizeFirst(subtitle);
    }
  }
}
