import { Namespace } from '../services/socket/socket.service';

export enum SocketDataAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  INDEX = 'INDEX',
  REVIEW = 'REVIEW',
  FILE_GENERATE = 'FILE_GENERATE'
}

export enum SocketDataStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export enum SocketDataType {
  ATTACHMENT = 'ATTACHMENT',
  COMMENT = 'COMMENT',
  REPLY = 'REPLY',
  ENTITY = 'ENTITY',
  DRAFT = 'DRAFT',
  TEAMS = 'TEAMS',
  DOCUMENT = 'DOCUMENT',
  MAINTENANCE_PRE = 'MAINTENANCE_PRE',
  MAINTENANCE_ON = 'MAINTENANCE_ON',
  MAINTENANCE_OFF = 'MAINTENANCE_OFF'
}
export class SocketData {

  public status: SocketDataStatus;
  public type: SocketDataType;
  public userId: number;
  public datas: any;
  public action: SocketDataAction;

  /**
   * @deprecated passer par le builder
   */
  constructor(
    public namespace: string = '',
    public room: string = '',
    public id: string = null, // id
      /**
     * @deprecated utiliser datas (plutôt que data.datas)
     */
    public data: any = null, // socket datas from socket.on('message', fn(room, datas))

  ) {
    this.datas = this.data.datas;
    this.action = SocketDataAction[this.data.action  as keyof typeof SocketDataAction];
    this.status = this.data.status;
    this.type = this.data.type;
    this.userId = this.data.userId;
  }

  public static build(nsp: Namespace, room: string, data: any): SocketData {
    return new SocketData(nsp, room, data.id, data);
  }
}
