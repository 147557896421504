import { SearchItem } from '../../../../models/search-item';
import { Aggregate } from '../../../../models/aggregate';
import {
  addFilterItemsFailedAction,
  addFilterItemsStartAction,
  addFilterItemsSucceededAction,
  removeAllFilterItemsSucceededAction,
  removeFilterItemFailedAction,
  removeFilterItemStartedAction,
  removeFilterItemSucceededAction,
  removeFiltersAndKeepLastAggSucceededAction,
  updateFilterItemsFailedAction,
  updateFilterItemsStartAction,
  updateFilterItemsSucceededAction
} from '../action/task-filter.action';
import { createReducer, on } from '@ngrx/store';


export interface TaskFilterDatas {
  filterItems: SearchItem[];
  lastAgg: Aggregate;
}
export interface TaskFilterState {
  datas: TaskFilterDatas;
  loading: boolean;
  error: any;

}

export const TASK_FILTER_INITIAL_STATE: TaskFilterState = {
  datas: {
    filterItems: [],
    lastAgg: null
  },
  loading: false,
  error: null
};


/*
export const taskFilterReducerOld = (state: TaskFilterState = TASK_FILTER_INITIAL_STATE, action: PayloadAction<TaskFilterDatas>) => {
  switch (action.type) {
    case TaskFilterActionConstantAction.ADD_FILTER_ITEMS_START:
    case TaskFilterActionConstantAction.UPDATE_FILTER_ITEMS_START:
    case TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskFilterActionConstantAction.ADD_FILTER_ITEMS_SUCCEEDED:
      const add = state.datas;
      if (!action.payload.filterItems[0].operator) {
        add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
      } else {
        const existingFilterItemRange = add.filterItems.find(existingFilterItem => existingFilterItem.operator === action.payload.filterItems[0].operator && existingFilterItem.path === action.payload.filterItems[0].path);
        if (!existingFilterItemRange) {
          add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
        } else {
          add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = action.payload.filterItems[0];
        }
      }
      add.lastAgg = action.payload.lastAgg;
      return {
        ...state,
        datas: add,
        loading: false,
        error: null,
      };
    case TaskFilterActionConstantAction.UPDATE_FILTER_ITEMS_SUCCEEDED:
      const toReplace: SearchItem = action.payload.filterItems[0];
      let found = false;
      let searchItems = state.datas.filterItems.map(aFilterItem => {
        if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
          found = true;
          return toReplace;
        } else {
          return aFilterItem;
        }
      });
      if (!found) {
        searchItems = [...searchItems, toReplace];
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: searchItems,
          lastAgg: action.payload.lastAgg
        },
        loading: false,
        error: null,
      };
      case TaskFilterActionConstantAction.ADD_FILTER_ITEMS_FAILED:
      case TaskFilterActionConstantAction.UPDATE_FILTER_ITEMS_FAILED:
      case TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TaskFilterActionConstantAction.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: {
          filterItems: [],
          lastAgg: null
        },
        loading: false,
        error: null,
      };
    case TaskFilterActionConstantAction.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: []
        },
        loading: false,
        error: null
      };
    case TaskFilterActionConstantAction.REMOVE_FILTER_ITEM_SUCCEEDED:
      const filterItem: SearchItem = action.payload.filterItems[0];
      let oldLastAgg = state.datas.lastAgg;
      let remove: SearchItem[];
      if (!filterItem.operator) {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.value !== filterItem.value);
      } else {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.operator !== filterItem.operator);
      }
      if (remove.length === 0) {
        oldLastAgg = null;
      }
      return {
        ...state,
        datas: {
          filterItems: remove,
          lastAgg: oldLastAgg
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const taskFilterReducer = createReducer(
  TASK_FILTER_INITIAL_STATE,
  on(addFilterItemsStartAction, (state) => { return ({
    ...state,
    loading: true,
    error: null,
  })}),
  on(addFilterItemsSucceededAction, (state, {payload} ) => {
    const add = state.datas;
    if (!payload.filterItems[0].operator) {
      add.filterItems = add.filterItems.concat(payload.filterItems[0]);
    } else {
      const existingFilterItemRange = add.filterItems.find(existingFilterItem => existingFilterItem.operator === payload.filterItems[0].operator && existingFilterItem.path === payload.filterItems[0].path);
      if (!existingFilterItemRange) {
        add.filterItems = add.filterItems.concat(payload.filterItems[0]);
      } else {
        add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = payload.filterItems[0];
      }
    }
    add.lastAgg = payload.lastAgg;
    return {
      ...state,
      datas: add,
      loading: false,
      error: null,
    }
  }),
  on(addFilterItemsFailedAction, (state, {error} ) => { return ({
    ...state,
    loading: false,
    error: error,
  })}),
  on(updateFilterItemsStartAction, (state) => { return ({
    ...state,
    loading: true,
    error: null,
  })}),
  on(updateFilterItemsSucceededAction, (state, {payload}) => {
    const toReplace: SearchItem = payload.filterItems[0];
    let found = false;
    let searchItems = state.datas.filterItems.map(aFilterItem => {
      if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
        found = true;
        return toReplace;
      } else {
        return aFilterItem;
      }
    });
    if (!found) {
      searchItems = [...searchItems, toReplace];
    }
    return {
      ...state,
      datas: {
        ...state.datas,
        filterItems: searchItems,
        lastAgg: payload.lastAgg
      },
      loading: false,
      error: null,
    }
  }),
  on(updateFilterItemsFailedAction, (state, {error} ) => { return ({
    ...state,
    loading: false,
    error: error,
  })}),
  on(removeFilterItemStartedAction, (state) => { return ({
    ...state,
    loading: true,
    error: null,
  })}),
  on(removeFilterItemSucceededAction, (state, {payload}) => {
    const filterItem: SearchItem = payload.filterItems[0];
    let oldLastAgg = state.datas.lastAgg;
    let remove: SearchItem[];
    if (!filterItem.operator) {
      remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.value !== filterItem.value);
    } else {
      remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.operator !== filterItem.operator);
    }
    if (remove.length === 0) {
      oldLastAgg = null;
    }
    return {
      ...state,
      datas: {
        filterItems: remove,
        lastAgg: oldLastAgg
      },
      loading: false,
      error: null,
    };
  }),
  on(removeFilterItemFailedAction, (state, {error} ) => { return ({
    ...state,
    loading: false,
    error: error,
  })}),
  on(removeAllFilterItemsSucceededAction, (state) => { return ({
    ...state,
    datas: {
      filterItems: [],
      lastAgg: null
    },
    loading: false,
    error: null,
  })}),
  on(removeFiltersAndKeepLastAggSucceededAction, (state) => { return ({
    ...state,
    datas: {
      ...state.datas,
      filterItems: []
    },
    loading: false,
    error: null
  })}),

);
