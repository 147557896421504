import {Injectable} from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {FilterData} from '../../../models/filter-data';


export class TableFilterConstantAction {
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_TABLE_FILTER_START = '[TableFilter].LOAD_TABLE_FILTER_START';
  static readonly LOAD_TABLE_FILTER_SUCCEEDED = '[TableFilter].LOAD_TABLE_FILTER_SUCCEEDED';
  static readonly LOAD_TABLE_FILTER_FAILED = '[TableFilter].LOAD_TABLE_FILTER_FAILED';
  static readonly REMOVE_TABLE_FILTER = '[TableFilter].REMOVE_TABLE_FILTER';
  static readonly REMOVE_TABLE_FILTER_BY_KEY = '[TableFilter].REMOVE_TABLE_FILTER_BY_KEY';

}

export const loadTableFilterStartAction     = createAction(TableFilterConstantAction.LOAD_TABLE_FILTER_START);
export const loadTableFilterSucceededAction = createAction(TableFilterConstantAction.LOAD_TABLE_FILTER_SUCCEEDED, props<{payload : { [key: string]: FilterData }}>());
export const loadTableFilterFailedAction    = createAction(TableFilterConstantAction.LOAD_TABLE_FILTER_FAILED );
export const removeTableFilterAction        = createAction(TableFilterConstantAction.REMOVE_TABLE_FILTER);
export const removeTableFilterByKeyAction   = createAction(TableFilterConstantAction.REMOVE_TABLE_FILTER_BY_KEY, props<{payload:string}>() );

@Injectable({
  providedIn: 'root'
})
export class TableFilterAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadTableFilterStarted(): void {
    this.localStore.dispatch( loadTableFilterStartAction());
  }

  public loadTableFilterSucceeded(payload: { [key: string]: FilterData }): void {
    this.localStore.dispatch( loadTableFilterSucceededAction({payload}));
  }

  public loadTableFilterFailed() {
    this.localStore.dispatch( loadTableFilterFailedAction());

  }

  public removeTableFilter() {
    this.localStore.dispatch(removeTableFilterAction());
  }

  public removeTableFilterByKey(payload: string) {
    this.localStore.dispatch(removeTableFilterByKeyAction({payload}));
  }
}
