import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';

import {CriticalData} from '../../../models/critical.data';

export class CriticalDataActionConstant {
  static readonly LOAD_CRITICAL_STARTED = '[CriticalData]: LOAD_CRITICAL_STARTED';
  static readonly LOAD_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_DATA_SUCCEEDED';
  static readonly LOAD_VIEW_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: LOAD_VIEW_CRITICAL_DATA_SUCCEEDED';
  static readonly LOAD_CRITICAL_DATA_ALL_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_DATA_ALL_SUCCEEDED';
  static readonly LOAD_CRITICAL_PDF_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_PDF_SUCCEEDED';
  static readonly REMOVE_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: REMOVE_CRITICAL_DATA_SUCCEEDED';
  static readonly REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED';
  static readonly SET_CRITICAL_DATA_TO_NULL_SUCCEEDED = '[CriticalData]: SET_CRITICAL_DATA_TO_NULL_SUCCEEDED';

}

export const loadCriticalStartedAction = createAction(CriticalDataActionConstant.LOAD_CRITICAL_STARTED);
export const loadCriticalDataSucceededAction = createAction(CriticalDataActionConstant.LOAD_CRITICAL_DATA_SUCCEEDED, props<{payload: CriticalData}>());
export const loadViewCriticalDataSucceededAction = createAction(CriticalDataActionConstant.LOAD_VIEW_CRITICAL_DATA_SUCCEEDED, props<{ payload: CriticalData }>());
export const loadCriticalDataAllSucceededAction = createAction(CriticalDataActionConstant.LOAD_CRITICAL_DATA_ALL_SUCCEEDED, props<{ payload: CriticalData }>());
export const loadCriticalPdfSucceededAction = createAction(CriticalDataActionConstant.LOAD_CRITICAL_PDF_SUCCEEDED, props<{ payload: CriticalData }>());
export const removeCriticalDataSucceededAction = createAction(CriticalDataActionConstant.REMOVE_CRITICAL_DATA_SUCCEEDED);
export const removeViewCriticalDataSucceededAction = createAction(CriticalDataActionConstant.REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED);
export const setCriticalDataToNullSucceededAction = createAction(CriticalDataActionConstant.SET_CRITICAL_DATA_TO_NULL_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class CriticalDataAction {
  public static readonly _ALL = '_all';

  constructor(private localStore: Store) {
  }

  public loadCriticalStart(): void {
    this.localStore.dispatch(loadCriticalStartedAction());
  }

  public addCriticalFieldToStore(criticalFieldCode: string): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalFields = [criticalFieldCode];
    this.localStore.dispatch(loadCriticalDataSucceededAction({payload}));
  }

  public addViewCriticalFieldsToStore(viewCriticalFieldsCode: string[]): void {
    const payload: CriticalData = new CriticalData();
    payload.viewCriticalFields = viewCriticalFieldsCode;
    this.localStore.dispatch(loadViewCriticalDataSucceededAction({payload}));
  }

  public addAllToVisibleCriticalFieldsStore(criticalFields: string[]): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalFields = criticalFields;
    this.localStore.dispatch(loadCriticalDataAllSucceededAction({payload}));
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalPdf = isVisible;
    payload.viewCriticalPdf = true;
    this.localStore.dispatch(loadCriticalPdfSucceededAction({payload}));
  }

  public removeAllFromVisibleCriticalFieldsStore(): void {
    this.localStore.dispatch(removeCriticalDataSucceededAction());
  }

  public removeViewCriticalFieldsStore(): void {
    this.localStore.dispatch(removeViewCriticalDataSucceededAction());
  }

  public unsetVisibleCriticalFieldsStore(): void {
    this.localStore.dispatch(setCriticalDataToNullSucceededAction());
  }
}
