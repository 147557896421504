import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from './components/avatar/avatar.component';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
  imports: [
    CommonModule,
    TooltipModule,
    MatMenuModule,
    TranslateModule
  ]
})
export class AvatarModule { }
