import {Aggregate} from '../../../../models/aggregate';
import {EsPage} from '../../../../models/es-page';
import {Entity} from '../../../../models/Entity';
import {
  loadEntitiesFailedAction,
  loadEntitiesStartAction,
  loadEntitiesSucceededAction,
  loadEntityAggregateFailedAction,
  loadEntityAggregateStartAction,
  loadEntityAggregateSucceededAction,
  updateEntitiesFailedAction,
  updateEntitiesStartAction,
  updateEntitiesSucceededAction
} from '../action/entity-search-result.action';
import {createReducer, on} from '@ngrx/store';

const ENTITY_RESULT_INITIAL_STATE: EntitySearchResultState = {
  datas: null,
  loading: false,
  loadAgg: false,
  error: null,
};

export interface EntitySearchResultState {
  datas: EsPage<Entity>;
  loading: boolean;
  loadAgg: boolean;
  error: any;
}

export const entitySearchResultReducer = createReducer(
  // Initial State
  ENTITY_RESULT_INITIAL_STATE,

  // Start Actions
  on(
    loadEntitiesStartAction,
    updateEntitiesStartAction,
    loadEntityAggregateStartAction,
    (state) => ({
      ...state,
      loading: true,
      loadAgg: true,
      error: 'ERROR_START', // Remplir avec la valeur correcte si nécessaire
    })
  ),

  // Succeeded Actions
  on(loadEntitiesSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  on(loadEntityAggregateSucceededAction, (state, {payload}) => {
    const newAgg: Aggregate = payload.aggs[0];
    let aggs: Aggregate[] = state.datas.aggs;
    let added = false;

    aggs = aggs.map(agg => {
      if (agg.path === newAgg.path) {
        added = true;
        return newAgg;
      }
      return agg;
    });

    if (!added) {
      aggs.push(newAgg);
    }

    const copy = {...state.datas, aggs: aggs};

    return {
      ...state,
      datas: copy,
      loading: false,
      loadAgg: false,
      error: null,
    };
  }),

  on(updateEntitiesSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      content: payload.content,
      numberOfElements: payload.numberOfElements,
      size: payload.size,
      page: payload.page,
    },
    loading: false,
    error: null,
  })),

  // Failed Actions
  on(
    loadEntitiesFailedAction,
    updateEntitiesFailedAction,
    loadEntityAggregateFailedAction,
    (state, {error}) => ({
      ...state,
      loading: false,
      loadAgg: false,
      error,
    })
  )
);
