import { ToastaService } from 'ngx-toasta';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AuthenticationActionConstants } from './authentication.reducer';

import {Store} from '@ngrx/store';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationAction {


  constructor(
    private translateService: TranslateService,
    private toastaService: ToastaService,
    private store: Store
  ) {
  }

  public loadAuthenticationStart(): void {
    this.store.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_START
    });
  }

  public loadAuthenticationSucceeded(): void {
    this.store.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_SUCCEEDED,
      payload: { role: undefined }
    });
  }

  public loadAuthenticationFailed(error: any) {
    this.store.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_FAILED,
      error: error
    });
    this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.ERROR'));
  }

  public logoutSucceeded(): void {
    this.store.dispatch({
      type: AuthenticationActionConstants.LOGOUT_SUCCEEDED
    });
  }
}
