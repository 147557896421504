import { Location } from '@angular/common';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ToastaService} from 'ngx-toasta';
import {combineLatest, Observable, of, OperatorFunction, pipe, Subject, throwError} from 'rxjs';
import {catchError, filter, map, startWith, switchMap, take, takeWhile, tap, timeoutWith} from 'rxjs/operators';
import {ApplicationConfigVarNames} from '../../../../modules/configuration/application-config-var-names';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {Aggregate} from '../../../models/aggregate';
import {AggregateOptions} from '../../../models/aggregate-request-params';
import {Block, BlockType} from '../../../models/block';
import {Bucket} from '../../../models/bucket';
import {CdxFile} from '../../../models/cdx-file';
import {DomainItem} from '../../../models/domain-item';
import {DraftDatas} from '../../../models/DraftDatas';
import {DraftDocument} from '../../../models/DraftDocument';
import {EsPage} from '../../../models/es-page';
import {FamilyPath} from '../../../models/family-path';
import {Field, FieldType} from '../../../models/field';
import {FieldFormat, FormatEditionType, PhoneValue} from '../../../models/field-format';
import {FieldListValue} from '../../../models/field-list-value';
import {InternalRoutes} from '../../../models/internal-routes';
import {ObjectType} from '../../../models/ObjectType';
import {Privacy} from '../../../models/privacy';
import {RegionItemI18n} from '../../../models/region-item-i18n';
import {SearchItem} from '../../../models/search-item';
import {SocketData, SocketDataAction, SocketDataStatus} from '../../../models/SocketData';
import {StepType} from '../../../models/step';
import {Team} from '../../../models/team';
import {UploadFilesData} from '../../../models/UploadFilesData';
import {Url} from '../../../models/url';
import {UserSocketHttpResponse} from '../../../models/user-socket-http-response';

import {View} from '../../../models/view';
import {SocketService} from '../../../services/socket/socket.service';
import {ThesaurusService} from '../../../services/thesaurus/thesaurus.service';
import {UploadService} from '../../../ui/tech-upload/service/upload.service';
import {ErrorTypes, Utils} from '../../../utils/utils';
import {CriticalDataAction} from '../../critical-data/action/critical-data.action';
import {CriticalDataService} from '../../critical-data/service/critical-data.service';
import {selectDomainsDatas} from '../../domain/selectors/domain.selectors';
import {GedCfgFieldService} from '../../field/service/ged/ged-cfg-field.service';
import {selectCacheFormFeature} from '../../form/cache-selectors';
import {CacheFormState} from '../../form/cache/reducer/cache-form.reducer';
import {CacheFormService} from '../../form/service/cache-form.service';
import {AbstractSearchResultService} from '../../search-result/service/abstract-search-result.service';
import {TeamsService} from '../../teams/service/teams.service';
import {IndexationDocTypesAction} from '../doc-types/action/indexation-doc-types.action';
import {DraftDatasAction} from '../draft-datas/action/draft-datas.action';
import {DraftFilterDatas, DraftFilterState} from '../draft-filter/reducer/draft-filter.reducer';
import {selectDraftDatasDatas, selectDraftFilterDatas, selectDraftFilterFeature, selectListIndexationsDatas, selectListIndexationsDatasAggs} from '../indexation-selectors';
import {ListIndexationAction} from '../list-indexation/action/list-indexation.action';
import {CdxDocument} from '../../../models/cdx-document';
import {omit} from 'lodash';

export enum DraftState {
  NEW = 'new',
  RECAP = 'recap',
  IN_PROGRESS = 'in_progress',
  LAST_STEP = 'last'
}

export enum TypeTransform {
  FILE = 'file',
  CDX_FILE = 'cdx_file'
}

@Injectable({
  providedIn: 'root'
})
export class IndexationService extends AbstractSearchResultService {

  public static readonly PIPER_ERRORS_TIMEOUT = 'PIPER_ERRORS.TIMEOUT';
  public static readonly PIPER_ERRORS = 'PIPER_ERRORS.';
  public static readonly TIMEOUT = 20000;
  public static readonly ID_NEW = 'new';
  public static readonly RECAP = 'recap';
  public static readonly VALIDATE = 'validate';
  public static readonly VALIDATE_NEXT = 'validate_next';
  public static readonly DELETE = 'delete';
  public static readonly INDEXATION_PAGE_SIZE = 24;
  public static readonly AGGREGATE_DEFAULT_SIZE = 5;
  public static readonly AGGREGATE_MAX_SIZE = 50;
  public static readonly AGGREGATE_SIZE_STEP = 5;
  public static readonly BUCKETS_SIZE = 20;
  public static readonly STEP_RECAP = '__RECAP__';
  public static readonly STEP_NO_TYPE_DOC = '__NO_TYPE_DOC__';
  public static readonly BLOC_ID = '__BLOC_ID__';
  public static readonly AGGS_DELIMITTER = '|';
  public draftTimeout;


  private draftFilters$ = this.store.select(selectDraftFilterFeature).pipe(takeUntilDestroyed());
  private draftDatasDatas$ = this.store.select(selectDraftDatasDatas).pipe(takeUntilDestroyed());

  private draftFilterDatas$ = this.store.select(selectDraftFilterDatas);
  private indexationList$ = this.store.select(selectListIndexationsDatas);

  private listeIndexationDatas$ = this.store.select(selectListIndexationsDatas).pipe(takeUntilDestroyed());
  private listeIndexationDatasAgg$ = this.store.select(selectListIndexationsDatasAggs).pipe(takeUntilDestroyed());

  private cacheForm$ = this.store.select(selectCacheFormFeature).pipe(takeUntilDestroyed());
  private domainDatas$ = this.store.select(selectDomainsDatas).pipe(takeUntilDestroyed());

  private draftFilters: DraftFilterState;
  private draftDatasDatas: DraftDatas;
  private indexationDatasAggs: Aggregate[];
  private listeIndexationDatas: EsPage<DraftDocument>;
  private cacheForm: CacheFormState;
  private domainDatas: DomainItem[];

  constructor(
    httpClient: HttpClient,
    fieldService: GedCfgFieldService,
    thesaurusService: ThesaurusService,
    private listIndexationAction: ListIndexationAction,
    private indexationDocTypesAction: IndexationDocTypesAction,
    private draftDatasAction: DraftDatasAction,
    private configService: ConfigurationService,
    private location: Location,
    private router: Router,
    private teamsService: TeamsService,
    private uploadService: UploadService,
    private toastaService: ToastaService,
    private socketService: SocketService,
    private translateService: TranslateService,
    private criticalDataService: CriticalDataService,
    private cacheFormService: CacheFormService,
    store: Store
  ) {
    super(
      httpClient,
      fieldService,
      thesaurusService,
      store
    );
    this.configService.applicationConfigReady$.subscribe(() => this.init())
  }

  private static createBloc(blocCode: string, blocType: BlockType, datas: any[]): Block {
    const bloc: Block = new Block();
    bloc.code = blocCode;
    bloc.type = blocType;
    bloc.datas = datas;
    return bloc;
  }

  private static buildState(views: View[], options: string[]): DraftState {
    const len = views.length;
    for (let i = len - 1; i > -1; i--) {
      const view: View = views[i];
      if (!view.disposition) {
        if (view.type === StepType.STEP || (view.type === StepType.OPTION && options.indexOf(view.option.code) > -1)) {
          return DraftState.IN_PROGRESS;
        }
      } else {
        if (view.code === IndexationService.STEP_RECAP) {
          return DraftState.RECAP;
        }
        return DraftState.LAST_STEP;
      }
    }
  }

  // private static addItemPath(path: string, itemPath: string[]): string {
  //   return path + ':' + itemPath.toString();
  //   // return path + ':' + JSON.stringify(itemPath);
  // }

  init() {
    const configDraftTimeout = +this.configService.getConfigVariable(ApplicationConfigVarNames.DRAFT_TIMEOUT);
    this.draftTimeout = !!configDraftTimeout ? configDraftTimeout : IndexationService.TIMEOUT;
    this.draftFilters$.subscribe(df => this.draftFilters = df);
    this.draftDatasDatas$.subscribe(datas => this.draftDatasDatas = datas);
    this.listeIndexationDatasAgg$.subscribe(agg => this.indexationDatasAggs = agg);
    this.listeIndexationDatas$.subscribe(datas => this.listeIndexationDatas = datas);
    this.cacheForm$.subscribe(cacheForm => this.cacheForm = cacheForm);
    this.domainDatas$.subscribe(domainDatas => this.domainDatas = domainDatas);
  }

  public getServiceType(): InternalRoutes {
    return InternalRoutes.INDEXATION;
  }

  public getObjectType(): ObjectType {
    return ObjectType.DRAFT;
  }

  public getSearchItem$(): Observable<SearchItem[]> {
    return of([]);
  }

  public getFilterDatas$(): Observable<DraftFilterDatas> {
    return this.draftFilterDatas$;
  }

  public getSearchListResult$(): Observable<EsPage<DraftDocument>> {
    return this.indexationList$;
  }

  public getSearchBaseUrl(): string {
    return Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT;
  }

  public getAggBaseUrl(): string {
    return Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + Url.AGGS;
  }

  public getEmptyEspage(): EsPage<DraftDocument> {
    return new EsPage<DraftDocument>(0, [], 0, 0, [], false);
  }

  public gotoPage(pageNum: number): void {
    this.searchDrafts(pageNum, true);
  }

  public search(): void {
    this.searchDrafts();
  }

  public searchDrafts(pageNumber = 0, keepLastAgg = false): void {
    this.listIndexationAction.loadStarted();
    try {
      this.query()
        .pipe(
          this.filterItemsParams(),
          this.page(pageNumber),
          this.currentAggregateParams(),
          this.sortByKeyAndDirection(),
          this.requestIndexationList()
        )
        .subscribe((result: EsPage<DraftDocument>) => {
            const teamAggregate: Aggregate = result.aggs.find((aggregate: Aggregate) => aggregate.path === 'cdx_classification.teams');
            if (!!teamAggregate) {
              const bucketKeys: string[] = teamAggregate.buckets.map((bucket: Bucket) => bucket.key);
              this.teamsService.loadCfgTeamsFromBucketKeys(bucketKeys);
            }
            this.cfgFieldService.loadFieldsFromPath(...result.aggs
              .filter(item => !item.path.endsWith('cdx_id'))
              .map((agg: Aggregate) => agg.path));
            const filterDatasState: DraftFilterState = this.draftFilters;
            if (!!filterDatasState && !!filterDatasState.datas.lastAgg && keepLastAgg) {
              result.aggs = result.aggs.map((aggregate: Aggregate) => {
                if (aggregate.path === filterDatasState.datas.lastAgg.path) {
                  return filterDatasState.datas.lastAgg;
                }
                return aggregate;
              });
            }
            this.listIndexationAction.loadSucceeded(result);
          },
          (error: HttpErrorResponse) => {
            this.listIndexationAction.loadFailed(error);
            const filterDatasState: DraftFilterState = this.draftFilters;
            if (!!filterDatasState && !!filterDatasState.datas.lastAgg && keepLastAgg) {
              this.listIndexationAction.loadStarted();
              const emptyPage: EsPage<DraftDocument> = this.getEmptyEspage();
              emptyPage.aggs.push(filterDatasState.datas.lastAgg);
              this.listIndexationAction.loadSucceeded(emptyPage);
            }
          });
    } catch (error) {
      this.listIndexationAction.loadFailed(error);
    }
  }

  public loadAggregate(path: string, opts: AggregateOptions, checkPath = false) {
    console.log('checkPath:'+checkPath);
    this.listIndexationAction.loadAggregateStart();
    try {
      // size = Math.min(size, IndexationService.AGGREGATE_MAX_SIZE);
      this.query().pipe(
        this.filterItemsParams(path),
        // this.coordParams(size),
        // this.log(),
        this.requestAggregate(path, opts)
      ).subscribe((agg: Aggregate) => {
          // this.fieldService.loadFieldsFromPath(path);
          this.listIndexationAction.loadAggregateSucceeded(agg);
        },
        (error: HttpErrorResponse) => this.listIndexationAction.loadAggregateFailed(error));
    } catch (e) {
      this.listIndexationAction.loadAggregateFailed(e);
    }
  }

  // public getAllLots(fn: string = null): Observable<Entity[]> {
  //   return this.query()
  //     .pipe(
  //       map((param: HttpParams) => {
  //         if (!!fn) {
  //           param = param.set('_function', fn);
  //         }
  //         return param;
  //       }),
  //       this.page(0, IndexationService.INDEXATION_AGGREGATE_PAGE_SIZE),
  //       this.requestLots()
  //     );
  // }

  public loadStoresToSetDocType(draft: string | DraftDocument = null): void {
    try {
      this.indexationDocTypesAction.loadStarted();
      this.draftDatasAction.loadDraftDatasStarted();
      const draftDatas: DraftDatas = new DraftDatas();

      const requests$ = [this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + Url.INDEXATION_DOC_TYPE)];
      if (!draft) {
        // new draft
        draftDatas.state = DraftState.NEW;
      } else if (typeof draft === 'string') {
        // existing draft, we want to change doctype
        draftDatas.state = DraftState.IN_PROGRESS;
        requests$.push(this.httpClient.get<DraftDocument>(Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + draft));
      } else {
        // existing draft, but document type is not set
        draftDatas.state = DraftState.IN_PROGRESS;
        requests$.push(of(draft));
      }

      combineLatest(requests$)
        // this.httpClient.get<any>(Url.getApiBaseUrl(this.configAction) + Url.INDEXATION + Url.WS_DOC_TYPE)
        .subscribe((response: [any, DraftDocument]) => {
            this.indexationDocTypesAction.loadSucceeded(response[0]);
            if (!!draft) {
              draftDatas.draftDocument = response[1];
              draftDatas.id = response[1].cdx_id;
            }
            this.draftDatasAction.loadDraftDatasSucceeded(draftDatas);
          },
          (error: HttpErrorResponse) => {
            this.indexationDocTypesAction.loadFailed(error);
            this.draftDatasAction.loadDraftDatasFailed(error);
          });
    } catch (err) {
      console.error('error', err);
      this.indexationDocTypesAction.loadFailed(err);
      this.draftDatasAction.loadDraftDatasFailed(err);
    }
  }

  downloadThumb(draft: DraftDocument, typeTransform: TypeTransform, multiple = false): Observable<File | CdxFile> {
    try {
      const headers = Utils.objectTokenHeader(draft.token);
      let url = `${Url.getProtectedApiBaseUrl(this.configService)}${Url.INDEXATION}`;
      if (multiple) {
        url = url + `${Url.MULTIPLE}${draft.cdx_id}/${Url.THUMB}${draft.cdx_file.cdx_id}`;
      } else {
        url = url + `${draft.cdx_id}/${Url.THUMB}${draft.cdx_file.cdx_id}`;
      }
      return this.httpClient.get(
        url,
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: headers
        })
        .pipe(map((response: HttpResponse<any>) => {
          if (typeTransform === TypeTransform.FILE) {
            return Utils.createFile(response);
          } else if (typeTransform === TypeTransform.CDX_FILE) {
            return Utils.httpResponseToCdxFile(response);
          }
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public uploadFile(file: File, transactionId: string): Observable<UploadFilesData> {
    const url: string = Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + Url.FILE;
    let thumbGenerated = false;
    const socketMsg$: Observable<SocketData> = this.socketService.currentUserEvent$.pipe(
      // FIXME : ce timeout devrait être lancé une fois le fichier déposé === une fois le POST terminé. Comment ???
      // timeoutWith(IndexationService.TIMEOUT, throwError(new Error(IndexationService.PIPER_ERRORS_TIMEOUT))),
      startWith(<SocketData>null),
      filter(socketData => null == socketData || socketData.action === SocketDataAction.FILE_GENERATE),
      filter(socketData => null == socketData || socketData.data.datas.transactionId === transactionId),
      take(2),
      map(socketData => {
        if (!!socketData) {
          switch (socketData.data.status) {
            case SocketDataStatus.COMPLETED:
              return socketData;
            case SocketDataStatus.FAILED:
              throw new Error(IndexationService.PIPER_ERRORS + socketData.data.type + '.' + socketData.data.action);
          }
        }
      }),
      catchError(() => of(null))
    );
    return combineLatest([socketMsg$, this.uploadService.uploadFile(file, transactionId, url)])
      .pipe(
        filter((val: [SocketData, UploadFilesData]) => !!val[1]),
        tap((val: [SocketData, UploadFilesData]) => {
          if (!!val[0]) {
            thumbGenerated = true;
          }
        }),
        filter((val: [SocketData, UploadFilesData]) => {
          if (val[1].loadedSize !== 100) {
            return true;
          } else {
            // upload done, wait for thumbnail
            return thumbGenerated;
          }
        }),
        map((val: [SocketData, UploadFilesData]) => val[1]),
        takeWhile((val: UploadFilesData) => !val[0] && val.loadedSize <= 100, true)
      );
  }

  public getDraftPdf(draftId: string, token: string): Observable<HttpResponse<any> | string> {
    if (draftId != null) {
      try {
        // const headers = new HttpHeaders();
        const urlGetPdf = Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + draftId + '/' + Url.INDEXATION_PDF;
        return this.httpClient.get(
          urlGetPdf,
          {
            headers: Utils.objectTokenHeader(token),
            responseType: 'arraybuffer',
            observe: 'response'
          })
          .pipe(
            map((response: HttpResponse<any>) => {
              return response;
            }),
            catchError((error: HttpErrorResponse) => {
              console.warn(error);
              // throwError(error);
              return of(error.error.message);
              // return of(error);
            }));
      } catch (err) {
        console.error(err);
        return of(err);
      }
    }
  }

  downloadDraftFile(draftId: string, path: string, token: string, readCritical = null): Observable<CdxFile> {
    try {
      return this.query().pipe(
        this.readCriticalParams(readCritical),
        this.getDraftFile(draftId, path, token),
        map((response: HttpResponse<any>) => {
          return Utils.httpResponseToCdxFile(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public addViewCriticalFieldsToStore(viewCriticalFields: string[]): void {
    this.criticalDataService.addViewCriticalFieldsToStore(viewCriticalFields);
  }

  public showAllCriticalFieldsValue(): void {
    this.criticalDataService.addAllToVisibleCriticalFieldsStore();
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    this.criticalDataService.addVisibleCriticalPdfToStore(isVisible);
  }

  public hideAllCriticalFieldsValue(): void {
    this.criticalDataService.removeAllFromVisibleCriticalFieldsStore();
  }

  public removeViewCriticalFieldsStore(): void {
    this.criticalDataService.removeViewCriticalFieldsStore();
  }

  public addVisibleCriticalField(criticalFieldCode: string): void {
    this.criticalDataService.addCriticalFieldToStore(criticalFieldCode);
  }

  public loadReadCriticalFieldValue(draftId: string, fieldcode: string): void {
    try {
      const storeDraftDatas: DraftDatas = _.cloneDeep(this.draftDatasDatas);

      // traitement de vérification d'existence debut
      if (fieldcode !== CriticalDataAction._ALL && !!storeDraftDatas.draftDocument.cdx_datas[fieldcode]) {
        // champ déjà récupéré
        this.addVisibleCriticalField(fieldcode);
        return;
      }
      // traitement de vérification d'existence fin

      this.draftDatasAction.loadDraftDatasStarted();
      this.query().pipe(
        this.readCriticalParams(fieldcode),
        this.getDraftDocument(draftId)
      )
        .subscribe(
          (draftDocument) => {
            if (!!fieldcode) {
              if (fieldcode !== CriticalDataAction._ALL) {
                storeDraftDatas.draftDocument.cdx_datas[fieldcode] = draftDocument.cdx_datas[fieldcode];
                this.addVisibleCriticalField(fieldcode);
              } else {
                storeDraftDatas.draftDocument.cdx_datas = draftDocument.cdx_datas;
                this.showAllCriticalFieldsValue();
              }
            }
            this.draftDatasAction.loadDraftDatasSucceeded(this.buildDraftDatas(storeDraftDatas.views, storeDraftDatas.draftDocument));
          },
          (error: HttpErrorResponse) => this.draftDatasAction.loadDraftDatasFailed(error));
    } catch (error) {
      this.draftDatasAction.loadDraftDatasFailed(error);
    }
  }

  public convertFileToPdf(file: File): Observable<Uint8Array | string> {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      return this.httpClient.post(
        Url.getProtectedApiBaseUrl(this.configService) + Url.UTILS + Url.TO_PDF, formData, {
          observe: 'response',
          responseType: 'arraybuffer'
        })
        .pipe(map((response: HttpResponse<any>) => {
            const uint8View: Uint8Array = new Uint8Array(response.body);
            return uint8View;
          }),
          catchError((error: HttpErrorResponse) => {
            console.warn(error);
            // throwError(error);
            return of(error.error.message);
          }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public navigateToDraftList(): void {
    this.router.navigate(['/indexation'], {replaceUrl: true});
  }

  public navigateToDraftEdition(draftId: string, step: string = null): void {
    const queryParams = {};
    let replaceUrl = false;
    if (step) {
      queryParams['step'] = step;
      replaceUrl = true;
    }
    this.router.navigate(['/indexation', draftId], {queryParams: queryParams, replaceUrl: replaceUrl});
  }

  private navigateToDraftByReplacingUrl(draftId: string): void {
    this.router.navigate(['/indexation', draftId], { replaceUrl: true });
  }

  private navigateToDraftRecap(draftId: string): void {
    this.router.navigate(['/indexation/', draftId, IndexationService.RECAP], {replaceUrl: true});
  }

  private navigateToDocument(draftId: string): void {
    // on back btn click from doc-header, to go to indexationList
    this.location.replaceState('/indexation');
    this.router.navigate(['/documents', draftId]);
  }

  // public navigateTo(draftId: string, step?: string, rooting?: string, nextDraftId: string = null) {
  //   if (step !== undefined && step !== null) {
  //   // this.router.navigate(['/indexation', draftId], { queryParams: { step: step }, replaceUrl: true });
  //     this.navigateToDraftEdition(draftId, step);
  //   } else if (!!rooting) {
  //     if (rooting === IndexationService.DELETE) {
  //       // this.router.navigate(['/indexation'], {replaceUrl: true});
  //       this.navigateToDraftList();
  //     } else if (rooting === IndexationService.VALIDATE) {
  //       // this.router.navigate(['/documents', draftId], {replaceUrl: true});
  //       this.navigateToDocument(draftId);
  //     } else if (rooting === IndexationService.VALIDATE_NEXT) {
  //       // this.router.navigate(nextDraftId ? ['/indexation/', nextDraftId] : ['/indexation/'], {replaceUrl: true});
  //       if (!!nextDraftId) {
  //         this.navigateToDraftEdition(nextDraftId);
  //       } else {
  //         this.navigateToDraftList();
  //       }
  //     } else {
  //       this.navigateToDraftRecap(draftId);
  //       // this.router.navigate(['/indexation/', draftId, rooting], {replaceUrl: true});
  //     }
  //   } else {
  //     // this.router.navigate(['/indexation/', draftId], {replaceUrl: true});
  //     this.navigateToDraftEdition(draftId);
  //   }
  // }

  public setDocType(docType: string, draftId?: string, file = null): Observable<boolean> {
    draftId = draftId === IndexationService.ID_NEW ? null : draftId;
    if (docType !== null) {
      const url = draftId ? draftId + '/' + Url.INDEXATION_DOC_TYPE : Url.INDEXATION_DOC_TYPE;
      const formData = new FormData();
      if (!!file) {
        formData.append('file', file, file.name);
      }
      formData.append('docType', docType);

      return this.socketService.post(
        Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + url, formData,
        {
          responseType: 'text',
          timeout: this.draftTimeout
        })
        .pipe(map((userSocketHttpResponse: UserSocketHttpResponse) => {
          // this.navigateTo(userSocketHttpResponse.id);
          this.navigateToDraftByReplacingUrl(userSocketHttpResponse.id);
          return true;
      },
          error => {
            console.error(error);
            return of(false);
          }));
    }
  }

  public createDraft(draft: string | CdxDocument): Observable<boolean> {
    let strDraft: string;
    if (typeof draft === 'string') {
      strDraft = draft;
    } else {
      strDraft = this.documentToString(draft);
    }
    const formData = new FormData();
    formData.set('draft', strDraft);
    const url = Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT;
    return this.socketService.post(
      url, formData,
      {
        responseType: 'text',
        observe: 'response'
      })
      .pipe(
        map((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.location.replaceState('/indexation');
          this.navigateToDraftEdition(userSocketHttpResponse.id);
          return true;
        }),
        catchError(err => {
          console.error(err)
          return throwError(err)
        })
      );
  }

  public multipleIndexation(draftList: DraftDocument[], teams: Team[], qrDetect: boolean, qrShink: boolean): Observable<boolean> {
    const isReady$: Subject<boolean> = new Subject<boolean>();
    const createdDraft = [];
    try {
      this.socketService.currentUserEvent$
        .pipe(
          timeoutWith(this.draftTimeout, throwError(new Error(IndexationService.PIPER_ERRORS_TIMEOUT))),
          filter((socketData: SocketData) => socketData.action === 'CREATE'),
          tap((socketData: SocketData) => {
            switch (socketData.data.status) {
              case SocketDataStatus.COMPLETED:
                for (let i = 0; i < draftList.length; i++) {
                  if (socketData.id === draftList[i].cdx_id && createdDraft.indexOf(socketData.id) < 0) {
                    createdDraft.push(socketData.id);
                    break;
                  }
                }
                break;
              case SocketDataStatus.FAILED:
                throw new Error(IndexationService.PIPER_ERRORS + socketData.data.type + '.' + socketData.data.action);
            }
          }),
          filter(()=> draftList.length === createdDraft.length),
          take(1)
        )
        .subscribe({
          next: () => {
            this.location.back();
            isReady$.next(true);
            isReady$.complete();
          },
          error: (error) => {
            console.error(error);
            isReady$.next(true);
            isReady$.complete();
          }
       });

      let params = new HttpParams();

      if (!!teams) {
        let teamsExpr = '';
        for (const team of teams) {
          teamsExpr += team.code + ',';
        }
        if (teamsExpr.endsWith(',')) {
          teamsExpr = teamsExpr.substring(0, teamsExpr.length - 1);
        }
        params = params.set('_teams', teamsExpr);
      }

      params = params.set('qrDetect', qrDetect + '');
      params = params.set('qrShrink', qrShink + '');
      this.httpClient.post(Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + Url.MULTIPLE, draftList, {params: params})
        .subscribe({
          //next: ()=> { },
          error : (error: HttpErrorResponse) => {
            this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.[DraftDetails].MULTIPLE_INDEXATION_FAILED'));
            console.warn('error', error.message);
            isReady$.next(true);
            isReady$.complete();
          }
        });
    } catch (err) {
      isReady$.next(true);
      isReady$.complete();
      console.error('error', err);
    }
    return isReady$.asObservable();
  }

  public getSteps(draftId: string, step: string = null): void {
    try {
      // this.indexationViewAction.loadStarted();
      this.draftDatasAction.loadDraftDatasStarted();
      let params = new HttpParams();
      if (step !== null) {
        params = params.set('step', step);
      }
      const views$: Observable<View[]> = this.httpClient
        .get<View[]>(Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + draftId, {params: params});
      const draft$: Observable<DraftDocument> = this.httpClient
        .get<DraftDocument>(Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + draftId);
      combineLatest([views$, draft$])
        .subscribe((value: [View[], DraftDocument]) => {
            if (!value[1].cdx_type || !value[1].cdx_type.code) {
              this.loadStoresToSetDocType(value[1]);
              return;
            }
            const fieldsFromViews: string[] = this.getFieldsFromViews(value[0]);
            if (fieldsFromViews.length) {
              this.cfgFieldService.loadFields(...fieldsFromViews).subscribe((storeFields: { [key: string]: Field }) => {
                  const viewCriticalFields: string[] = [];
                  fieldsFromViews.forEach((fieldView: string) => {
                    if (storeFields && storeFields[fieldView] && storeFields[fieldView].privacy === Privacy.CRITICAL) {
                      viewCriticalFields.push(fieldView);
                    }
                  });
                  this.addViewCriticalFieldsToStore(viewCriticalFields);
                }
              );
            }
            this.draftDatasAction.loadDraftDatasSucceeded(this.buildDraftDatas(value[0], value[1]));
            // this.indexationViewAction.loadSucceeded(value[0]);
          },
          (error: HttpErrorResponse) => this.draftDatasAction.loadDraftDatasFailed(error));
      // .subscribe((response: View[]) => {
      //     this.fieldService.loadFields(...this.getFieldsFromViews(response));
      //     this.indexationViewAction.loadSucceeded(response);
      //   },
      //   (error: HttpErrorResponse) => this.indexationViewAction.loadFailed(error)
      // );
    } catch (err) {
      console.error('error', err);
      this.draftDatasAction.loadDraftDatasFailed(err);
    }
  }

  public buildCacheDraftDatas(views: View[], draftDocument: DraftDocument): DraftDatas {
    return this._buildDraftDatas(views, draftDocument, true, true);
  }

  public buildDraftDatas(views: View[], draftDocument: DraftDocument): DraftDatas {
    return this._buildDraftDatas(views, draftDocument);
  }

  public discardIndexationViews(): void {
    this.draftDatasAction.removeSucceeded();
  }

  public addDraftId(draftId: string): void {
    try {
      this.draftDatasAction.loadDraftDatasStarted();
      if (draftId === undefined || draftId === null) {
        throw Utils.createErrorEvent(ErrorTypes.DRAFT_ID);
      }
      const draftDatas = new DraftDatas();
      draftDatas.id = draftId;
      // draftDatas.isLastStep = false;
      this.draftDatasAction.loadDraftDatasSucceeded(draftDatas);
    } catch (error) {
      console.log(error);
      this.draftDatasAction.loadDraftDatasFailed(error.message);
    }
  }

  removeDraftDatas(): void {
    this.draftDatasAction.removeSucceeded();
  }

  public addOption(option: string): void {
    let draftDatas: DraftDatas = this.draftDatasDatas;
    const allOptions = [...draftDatas.options, option].filter((value, index, array) => array.indexOf(value) === index);
    const state = IndexationService.buildState(draftDatas.views, allOptions);
    draftDatas = new DraftDatas();
    draftDatas.options = allOptions;
    draftDatas.state = state;

    this.draftDatasAction.addOptions(draftDatas);
  }

  public removeOption(option: string): void {
    let draftDatas: DraftDatas = this.draftDatasDatas;
    const allOptions = draftDatas.options.filter(value => value !== option);
    const state = IndexationService.buildState(draftDatas.views, allOptions);
    draftDatas = new DraftDatas();
    draftDatas.options = allOptions;
    draftDatas.state = state;
    this.draftDatasAction.removeOptions(draftDatas);
  }

  public updateDraftTeams(draftId: string, teamIds: string[]): Observable<boolean> {
    if (!teamIds) {
      teamIds = [];
    }
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + draftId + '/' + Url.TEAMS, teamIds,
      {
        responseType: 'text',
        observe: 'response'
      }).pipe(
      map(() => {
        this.navigateToDraftEdition(draftId);
        return true;
      }),
      catchError(err => {
        console.error(err);
        return of(false);
      })

    );
  }

  public saveView(draftId: string,
                  draftVersion: number,
                  view: string,
                  step: string = null,
                  file = null,
                  rooting: string = null,
                  nextDraftId: string = null,
                  isSimpleSave = false,
                  onlyHttpRequest = false): Observable<boolean> {

    let result: Observable<boolean>;
    const formData = new FormData();
    formData.append('simpleSave', '' + isSimpleSave);
    formData.append('version', draftVersion + '');
    if (!!file) {
      formData.append('file', file, file.name);
    }
    if (!!rooting && rooting === IndexationService.VALIDATE || rooting === IndexationService.VALIDATE_NEXT) {
      formData.append('validate', 'true');
    }
    if (!!view) {
      formData.append('view', view);
    }
    const postUrl = Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + draftId;
    if(!onlyHttpRequest) {
      result = this.socketService.post(
        postUrl,
        formData,
        {
          responseType: 'text',
          observe: 'response',
          timeout: this.draftTimeout
        } )
        .pipe(
          map( ( userSocketHttpResponse: UserSocketHttpResponse ) => {
            if ( !!step ) {
              this.navigateToDraftEdition( userSocketHttpResponse.id, step );
              return true;
            }
            if ( !!rooting ) {
              switch ( rooting ) {
                case IndexationService.VALIDATE:
                  this.navigateToDocument( userSocketHttpResponse.id );
                  break;
                case IndexationService.VALIDATE_NEXT:
                  if ( !!nextDraftId ) {
                    this.navigateToDraftByReplacingUrl( nextDraftId );
                  } else {
                    this.navigateToDraftList();
                  }
                  break;
                case IndexationService.RECAP:
                  this.navigateToDraftRecap( userSocketHttpResponse.id );
                  break;
              }
              return true;
            }
            this.navigateToDraftEdition( userSocketHttpResponse.id );
            return true;
          } ),
          catchError( err => {
            this.errorManagement( err );
            return of( true );
          } )
        );
    } else {
      const headers = new HttpHeaders();

      result = this.httpClient.post(
        postUrl,
        formData,
        {
          responseType: 'text',
          observe: 'response',
          headers: headers
        } ).pipe(
        map(() => {
          if ( !!rooting && rooting===IndexationService.VALIDATE_NEXT) {
            if ( !!nextDraftId ) {
              this.navigateToDraftByReplacingUrl( nextDraftId );
            } else {
              this.navigateToDraftList();
            }
            return true;
          } else {
            console.error('appel http direct ne devrait pas se faire ce cas de figure');
            return false;
          }
        }),
        catchError((response: HttpErrorResponse) => { this.errorManagement( response );return of(false);})
      );
    }

    return result;
  }

  private errorManagement(error: Error): void {
    console.log(error);
    if (!(error instanceof HttpErrorResponse)) {
      this.toastaService.error(this.translateService.instant(error.message));
    }
  }

  public deleteDraft(draftId: string): Observable<boolean> {
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + draftId,
      {
        observe: 'response'
      })
      .pipe(
        map(() => true),
        catchError(err => {
          console.error(err);
          return of(false);
        }
      ));
  }

  public addValuesToDisposition(view: View, form: UntypedFormGroup, cfgFields: {
    [key: string]: Field;
  }) {
    const formValues: { [key: string]: any } = this.getDirtyFormValues(form);
    view.disposition.forEach(block => {
      let blockType: string;
      switch (block.type) {
        case 'FIELD':
          blockType = 'cdx_datas.' + block.code;
          break;
        case 'LINK':
          blockType = 'cdx_links.' + block.code;
          break;
        case 'OPTION':
          blockType = 'options.' + block.code;
          break;
      }
      block.datas.forEach((row, indexRow) => {
        row.forEach((column /*, indexColumn*/) => {
          if (formValues[blockType] === undefined || formValues[blockType][column.code] === undefined) {
            block.datas[indexRow] = block.datas[indexRow].filter((existingColumn) => existingColumn.code !== column.code);
            return;
          }
          const blocData = formValues[blockType][column.code];
          if (Utils.notNullAndNotUndefined(blocData) && blocData._isAMomentObject) {
            column.value = blocData._d.getTime();
          } else if (blocData instanceof Date) {
            column.value = blocData.getTime();
          } else {
            if (block.type === BlockType.FIELD) {
              if ((cfgFields[column.code].type === FieldType.LIST || cfgFields[column.code].type === FieldType.THESAURUS)) {
                if (_.isArray(blocData)) {
                  if (blocData.length) {
                    if (cfgFields[column.code].type === FieldType.LIST) {
                      const listValues: string[] = [];
                      blocData.forEach((listValue: FieldListValue) => {
                        if (listValue.value) {
                          listValues.push(listValue.value);
                        }
                      });
                      column.value = !!listValues.length ? listValues : null;
                    }
                    if (cfgFields[column.code].type === FieldType.THESAURUS) {
                      const lastThesaurusFieldValue: string = (blocData[blocData.length - 1] as RegionItemI18n).value;
                      column.value = !!lastThesaurusFieldValue ? [lastThesaurusFieldValue] : null;
                    }
                  } else {
                    column.value = null;
                  }
                } else {
                  column.value = [blocData.value];
                }
              } else {
                let value: any = blocData;
                // specific to phone format
                if (cfgFields[column.code].type === FieldType.STRING) {
                  const fieldFormat: FieldFormat = cfgFields[column.code].format;
                  if (!!fieldFormat && !!fieldFormat.edition && !!fieldFormat.edition.validation && fieldFormat.edition.validation.type === FormatEditionType.PHONE && !!blocData) {
                    const phoneValue: PhoneValue = blocData;
                    if (Utils.notNullAndNotUndefined(phoneValue) && !!phoneValue.e164Number) {
                      value = phoneValue.e164Number;
                    }
                  }
                }
                column.value = value;
              }
            } else {
              if (block.type === BlockType.LINK && !!formValues[blockType]['region']) {
                column.region = formValues[blockType]['region'];
              }
              column.value = blocData;
            }
          }

          // if (column.value === '') {
          //   column.value = null;
          // }
        });
      });
      block.datas = block.datas.filter((datas: any[]) => datas.length);
      if (!block.datas.length) {
        block.datas[0] = [];
      }
      // block.datas.forEach((row, indexRow) => {
      //   if (!block.datas[indexRow].length) {
      //     // block.datas.splice(indexRow, 1);
      //     block.datas[indexRow] = block.datas[indexRow].filter((datas: any[]) => datas.length);
      //   }
      // });
      block.code = block.code.substr(0, block.code.indexOf(IndexationService.BLOC_ID));
    });
    (<unknown>view.disposition as string) = JSON.stringify(view.disposition);
    return view;
  }

  coordParams(limit: number = IndexationService.INDEXATION_PAGE_SIZE, offset = 0): OperatorFunction<HttpParams, HttpParams> {
    return map(params => this.addPageCoord(params, limit, offset));
  }

  public addStoreDrafts(socketData: SocketData) {
    const lotId: string = socketData.data.datas.lotId;
    const aggregate: Aggregate = this.indexationDatasAggs.find((agg: Aggregate) => agg.path === 'cdx_index_links.cdx_lot.cdx_id');
    if (!!aggregate) {
      const bucket: Bucket = aggregate.buckets.find((oneBucket: Bucket) => oneBucket.key.cdx_id === lotId);
      if (bucket) {
        this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.[DraftDetails].INSERT_DRAFT_TO_LOT', {lot: bucket.key.cdx_title}));
      }
    }
  }

  public updateStoreDrafts(socketData: SocketData /*, action: string*/) {
    const draftsList: EsPage<DraftDocument> = this.listeIndexationDatas;
    if (!draftsList) {
      return;
    }
    const cloneDraftsList = _.cloneDeep(draftsList);
    const updatedDraft: DraftDocument = cloneDraftsList.content.filter((draft: DraftDocument) => draft.cdx_id === socketData.data.id)[0];
    if (!!updatedDraft) {
      this.httpClient.get<DraftDocument>(Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + socketData.data.id).subscribe((draft: DraftDocument) => {
        cloneDraftsList.content.splice(cloneDraftsList.content.map(oneDraft => oneDraft.cdx_id).indexOf(draft.cdx_id), 1, draft);
        this.listIndexationAction.loadSucceeded(cloneDraftsList);
        this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.[DraftDetails].DELETE_DRAFT_SUCCEEDED'));
      });
    }
  }

  public deleteStoreDrafts(socketData: SocketData, action: string) {
    const draftsList: EsPage<DraftDocument> = this.listeIndexationDatas;
    if (!draftsList) {
      return;
    }
    const cloneDraftsList = _.cloneDeep(draftsList);
    const deletedDraft: DraftDocument = cloneDraftsList.content.filter((draft: DraftDocument) => draft.cdx_id === socketData.data.id)[0];
    if (!!deletedDraft) {
      cloneDraftsList.numberOfElements -= 1;
      cloneDraftsList.content = cloneDraftsList.content.filter((draft: DraftDocument) => draft.cdx_id !== deletedDraft.cdx_id);
      this.listIndexationAction.loadSucceeded(cloneDraftsList);
      if (action === 'INDEX') {
        this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.[DraftDetails].VALIDATE_DRAFT_SUCCEEDED'));
      } else if (action === 'DELETE') {
        this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.[DraftDetails].VALIDATE_DRAFT_SUCCEEDED'));

      }
    }
  }


  setNewPageToStore(page: EsPage<any>) {
    console.log("indexationService setNewPageToStore() page:", page);
  }

  public filter(): void { console.log("indexationService filter()"); }

  private readCriticalParams(readCritical: string): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (!!readCritical) {
          params = params.append('readCritical', readCritical !== CriticalDataAction._ALL ? 'cdx_datas.' + readCritical : readCritical);
        }
        return params;
      })
    );
  }

  private getDraftFile(draftId: string, path: string, token: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    const headers = Utils.objectTokenHeader(token);
    return switchMap(params => {
      return this.httpClient.get(
        Url.getProtectedApiBaseUrl(this.configService) + Url.INDEXATION + draftId + '/' + path,
        {
          observe: 'response',
          responseType: 'arraybuffer',
          headers: headers,
          params: params
        });
    });
  }

  private getDraftDocument(draftId: string): OperatorFunction<HttpParams, DraftDocument> {
    return switchMap(params => {
      return this.httpClient.get<DraftDocument>(Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT + draftId, {params: params});
    });
  }

  private addDefaultValueToCacheForm(fieldCode: string, fieldValue: any): void {
    let defaultCacheFormData: { [key: string]: any };
    let cacheFormByUrl: { [key: string]: { [key: string]: any } } = {};
    const cacheFormState: CacheFormState = this.cacheForm;
    if (!!cacheFormState && !!cacheFormState.datas && Object.keys(cacheFormState.datas) && Object.keys(cacheFormState.datas).length) {
      cacheFormByUrl = _.cloneDeep(cacheFormState.datas);
    }
    defaultCacheFormData = cacheFormByUrl[CacheFormService.CACHE_DEFAULT_VALUES];
    if (!defaultCacheFormData) {
      cacheFormByUrl[CacheFormService.CACHE_DEFAULT_VALUES] = {};
      defaultCacheFormData = {};
    }
    defaultCacheFormData[fieldCode] = fieldValue;
    cacheFormByUrl[CacheFormService.CACHE_DEFAULT_VALUES] = defaultCacheFormData;
    this.cacheFormService.addDataToCacheForm(cacheFormByUrl);
  }

  private mergeLinkWithViewData(draftDocument: DraftDocument, data: any, razDefaultValue: boolean): any {
    if (!!draftDocument && !!draftDocument.cdx_links && !!draftDocument.cdx_links[data.code]) {
      data.value = draftDocument.cdx_links[data.code]; // TODO charger la (les) valeur par défaut d'un bloc lien
    } else {
      if (razDefaultValue) {
        data.value = [];
      }
    }
    if (!!draftDocument && !!draftDocument.cdx_classification && !!draftDocument.cdx_classification.familyPaths) {
      const familyPath: FamilyPath = this.getFamilyPathFromDraftClassification(draftDocument, data.code);
      if (!!familyPath && !!familyPath.path) {
        data.region = familyPath.path;
      }
    } else {
      if (razDefaultValue && data.region) {
        data.region = [];
      }
    }
    return data;
  }

  private mergeOptionWithViewData(draftDocument: DraftDocument, data: any, razDefaultValue: boolean): any {
    if (!!draftDocument && !!draftDocument.options && Utils.notNullAndNotUndefined(draftDocument.options[data.code])) {
      if (typeof draftDocument.options[data.code] === 'boolean') {
        data.value = draftDocument.options[data.code]; // TODO charger la (les) valeur par défaut d'un bloc lien
      } else if (typeof data.value !== 'boolean') {
        data.value = false;
      }
    } else {
      if (razDefaultValue) {
        data.value = null;
      }
    }
    return data;
  }

  private mergeFieldWithViewData(draftDocument: DraftDocument, data: any, razDefaultValue: boolean): any {
    let addDefaultValueInCacheFormStore = true;
    if (!!draftDocument && !!draftDocument.cdx_datas && draftDocument.cdx_datas[data.code] !== undefined) {
      data.value = draftDocument.cdx_datas[data.code];
      addDefaultValueInCacheFormStore = false;
    } else {
      if (razDefaultValue) {
        data.value = undefined;
        addDefaultValueInCacheFormStore = false;
      }
    }
    if (addDefaultValueInCacheFormStore && Utils.notNullAndNotUndefined(data.value)) {
      this.addDefaultValueToCacheForm(data.code, data.value);
    }
    return data;
  }

  // public addValuesToDisposition(view: View, formValues, cfgFields: {[key: string]: Field}, status: boolean = false ) {
  //   view.disposition.forEach(block => {
  //     let blockType: string;
  //     switch (block.type) {
  //       case 'FIELD':
  //         blockType = 'cdx_datas.' + block.code;
  //         break;
  //       case 'LINK':
  //         blockType = 'cdx_links.' + block.code;
  //         break;
  //       case 'OPTION':
  //         blockType = 'options.' + block.code;
  //         break;
  //     }
  //     block.datas.forEach(row => {
  //       row.forEach(column => {
  //         const blocData = formValues[blockType][column.code];
  //         // if (Object.prototype.toString.call(formValues[blockType][column.code]) === '[object Date]' && !isNaN(formValues[blockType][column.code])) {
  //         if (!!blocData && blocData._isAMomentObject) {
  //           column.value = blocData._d.getTime();
  //         } else if (blocData instanceof Date) {
  //           column.value = blocData.getTime();
  //         } else {
  //           if (block.type === BlockType.FIELD) {
  //             if (!!blocData && (cfgFields[column.code].type === FieldType.LIST || cfgFields[column.code].type === FieldType.THESAURUS)) {
  //               if (_.isArray(blocData)) {
  //                 if (blocData.length) {
  //                   if (cfgFields[column.code].type === FieldType.LIST) {
  //                     const listValues: string[] = [];
  //                     blocData.forEach((listValue: FieldListValue) => {
  //                       if (listValue.value) {
  //                         listValues.push(listValue.value);
  //                       }
  //                     });
  //                     column.value = !!listValues.length ? listValues : null;
  //                   }
  //                   if (cfgFields[column.code].type === FieldType.THESAURUS) {
  //                     const lastThesaurusFieldValue: string = (blocData[blocData.length - 1] as RegionItemI18n).value;
  //                     column.value = !!lastThesaurusFieldValue ? [lastThesaurusFieldValue] : null;
  //                   }
  //                 } else {
  //                   column.value = null;
  //                 }
  //               } else {
  //                 column.value = [blocData.value];
  //               }
  //             } else {
  //               column.value = blocData;
  //             }
  //           } else {
  //             if (block.type === BlockType.LINK && !!formValues[blockType]['region']) {
  //               column.region = formValues[blockType]['region'];
  //             }
  //             column.value = blocData;
  //           }
  //         }
  //
  //         // if (column.value === '') {
  //         //   column.value = null;
  //         // }
  //       });
  //     });
  //     block.code = block.code.substr(0, block.code.indexOf(IndexationService.BLOC_ID));
  //   });
  //   (<unknown> view.disposition as string) = JSON.stringify(view.disposition);
  //   return view;
  // }

  private mergeOptionsWithDraftDataOptions(draftDocument: DraftDocument, options: string[]) {
    if (!!draftDocument && !!draftDocument.options) {
      Object.entries(draftDocument.options).forEach(([option, activate]) => {
        if (activate) {
          options.push(option);
        }
      });
    }
    return options;
  }

  private getFamilyPathFromDraftClassification(draftDocument: DraftDocument, entityType: string): FamilyPath {
    if (!!draftDocument.cdx_classification && !!draftDocument.cdx_classification.familyPaths) {
      const domainItems = this.domainDatas;
      const searchedDomainByEntityType: DomainItem = domainItems?.find((domainItem: DomainItem) => domainItem.entityType === entityType);
      if (!!searchedDomainByEntityType && !!searchedDomainByEntityType.region) {
        return draftDocument.cdx_classification.familyPaths.find((draftDocumentFamilyPath: FamilyPath) => draftDocumentFamilyPath.domain === searchedDomainByEntityType.code);
      }
    }
    return null;
  }

  private _buildDraftDatas(views: View[], draftDocument: DraftDocument, razDefaultValue = false, updateViewData = false): DraftDatas {
    let copyOfDraftDocument: DraftDocument;
    const options: string[] = [];
    if (updateViewData && !!draftDocument) {
      copyOfDraftDocument = _.cloneDeep(draftDocument);
    }
    views.forEach((view: View) => {
      if (!view.disposition) {
        return;
      }
      view.disposition.forEach((block: Block, index) => {
        if (!updateViewData) {
          block.code = block.code + IndexationService.BLOC_ID + index;
        }
        block.datas.forEach(rows => {
          rows.forEach(column => {
            switch (block.type) {
              case BlockType.LINK:
                column = this.mergeLinkWithViewData(draftDocument, column, razDefaultValue);
                if (!!copyOfDraftDocument) {
                  if (Utils.notNullAndNotUndefined(column.value)) {
                    copyOfDraftDocument.cdx_links[column.code] = null;
                  }
                  if (Utils.notNullAndNotUndefined(column.region)) {
                    const familyPathToRemove: FamilyPath = this.getFamilyPathFromDraftClassification(copyOfDraftDocument, column.code);
                    if (!!familyPathToRemove) {
                      copyOfDraftDocument.cdx_classification.familyPaths = copyOfDraftDocument.cdx_classification.familyPaths.filter((familyPath: FamilyPath) => familyPath.domain !== familyPathToRemove.domain);
                    }
                  }
                }
                // if (Utils.notNullAndNotUndefined(column.regionEntrypoint)) {
                //   column = this.mergeLinkRegionWithViewData(draftDocument, column, razDefaultValue);
                // }
                break;
              case BlockType.OPTION:
                column = this.mergeOptionWithViewData(draftDocument, column, razDefaultValue);
                if (column.value) {
                  options.push(column.code);
                }
                if (!!copyOfDraftDocument && Utils.notNullAndNotUndefined(column.value)) {
                  copyOfDraftDocument.options[column.code] = null;
                }
                break;
              case BlockType.FIELD:
                column = this.mergeFieldWithViewData(draftDocument, column, razDefaultValue);
                if (!!copyOfDraftDocument && Utils.notNullAndNotUndefined(column.value)) {
                  copyOfDraftDocument.cdx_datas[column.code] = null;
                }
                break;
            }
          }); // end foreach column
        }); // end foreach rows
      }); // end foreach block
    }); // end foreach views

    if (!!copyOfDraftDocument) {
      const viewToBeUpdated: View = views.find((view: View) => !!view.disposition);
      this.updateView(viewToBeUpdated, copyOfDraftDocument);
    }

    const draftDatas = new DraftDatas();
    draftDatas.views = views;
    draftDatas.draftDocument = draftDocument;
    draftDatas.options = this.mergeOptionsWithDraftDataOptions(draftDocument, options);
    draftDatas.state = IndexationService.buildState(draftDatas.views, options);
    return draftDatas;
  }

  private updateView(view: View, draftDocument: DraftDocument): void {
    if (!view || !draftDocument.cdx_datas) {
      return;
    }
    const fieldDatas: any[] = [];
    Object.keys(draftDocument.cdx_datas).forEach((fieldKey: string) => {
      if (Utils.notNullAndNotUndefined(draftDocument.cdx_datas[fieldKey])) {
        fieldDatas.push([{code: fieldKey, value: draftDocument.cdx_datas[fieldKey]}]);
      }
    });

    const linkDatas: any[] = [];
    Object.keys(draftDocument.cdx_links).forEach((linkKey: string) => {
      if (Utils.notNullAndNotUndefined(draftDocument.cdx_links[linkKey])) {
        linkDatas.push([{code: linkKey, value: draftDocument.cdx_links[linkKey]}]);
      }
    });

    const optionDatas: any[] = [];
    Object.keys(draftDocument.options).forEach((optionKey: string) => {
      if (Utils.notNullAndNotUndefined(draftDocument.options[optionKey])) {
        optionDatas.push([{code: optionKey, value: draftDocument.options[optionKey]}]);
      }
    });

    if (fieldDatas.length) {
      const fieldBloc = IndexationService.createBloc('bloc_remaining_fields', BlockType.FIELD, fieldDatas);
      view.disposition.push(fieldBloc);
    }

    if (linkDatas.length) {
      const linkBloc = IndexationService.createBloc('bloc_remaining_links', BlockType.LINK, linkDatas);
      view.disposition.push(linkBloc);
    }

    if (optionDatas.length) {
      const optionBloc = IndexationService.createBloc('bloc_remaining_options', BlockType.OPTION, optionDatas);
      view.disposition.push(optionBloc);
    }
  }

  private getFieldsFromViews(views: View[]): string[] {
    return views.filter((view: View) => !!view.disposition)
      .reduce((arr, view) => (arr.push(...view.disposition), arr), []) // flatmap
      .filter((block: Block) => block.type === BlockType.FIELD)
      .reduce((arr, block) => (arr.push(...block.datas), arr), []) // flatmap
      // .map((arrData: any) => (arrData as any[]))
      .reduce((arr, v) => (arr.push(...v), arr), []) // flatmap
      .map((data: any) => data.code as string);
  }

  private getDirtyFormValues(form: UntypedFormGroup): { [key: string]: any } {
    const formValues: { [key: string]: any } = {};
    Object.keys(form.controls).forEach((formGroupKey: string) => {
      const formGroup: UntypedFormGroup = form.controls[formGroupKey] as UntypedFormGroup;
      Object.keys(formGroup.controls).forEach((formControlKey: string) => {
        const formControl: UntypedFormControl = formGroup.controls[formControlKey] as UntypedFormControl;
        if (formControl.dirty) {
          if (!formValues[formGroupKey]) {
            formValues[formGroupKey] = {};
          }
          if (!formValues[formGroupKey][formControlKey]) {
            formValues[formGroupKey][formControlKey] = {};
          }
          formValues[formGroupKey][formControlKey] = form.value[formGroupKey][formControlKey];
        }
      });
    });
    return formValues;
  }

  private requestIndexationList(): OperatorFunction<HttpParams, EsPage<DraftDocument>> {
    return switchMap(params => {
      return this.httpClient.get<EsPage<DraftDocument>>(Url.getProtectedApiBaseUrl(this.configService) + Url.DRAFT, {params: params});
    });
  }

  public documentToString(document: CdxDocument): string {
    const docToSave: CdxDocument = _.cloneDeep(document);
    docToSave.cdx_status = null;
    const docWithoutMetadata: Omit<CdxDocument, "metadata"> = omit(docToSave, ["metadata"]);
    return JSON.stringify(docWithoutMetadata);
  }
}
