import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, OperatorFunction, pipe, throwError} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {AbstractSearchService} from '../../../modules/search/service/search/abstract-search.service';
import {GedSearchService} from '../../../modules/search/service/search/ged/ged-search.service';
import {WkfSearchService} from '../../../modules/search/service/search/wkf/wkf-search.service';
import {CdxDocument} from '../../models/cdx-document';
import {CdxTask} from '../../models/cdx-task';
import {Entity} from '../../models/Entity';
import {EsPage} from '../../models/es-page';
import {InternalRoutes} from '../../models/internal-routes';
import {RegionItemI18n} from '../../models/region-item-i18n';
import {RegionPage} from '../../models/region-page';
import {SearchItem} from '../../models/search-item';
import {SearchPath} from '../../models/search-path';
import {Url} from '../../models/url';
import {selectCurrentContextDatasCurrentDomain} from '../../redux/current-context/current-context-selectors';
import {CurrentContextService} from '../../redux/current-context/service/current-context.service';
import {AbstractSearchItemsService} from '../../redux/search-item/service/abstract-search-items.service';
import {DocumentSearchItemsService} from '../../redux/search-item/service/document-search-item.service';
import {EntitySearchItemsService} from '../../redux/search-item/service/entity-search-items.service';
import {TaskSearchItemsService} from '../../redux/search-item/service/task-search-item.service';
import {AbstractSearchResultService} from '../../redux/search-result/service/abstract-search-result.service';
import {DocumentSearchResultService} from '../../redux/search-result/service/document-search-result.service';
import {EntitySearchResultService} from '../../redux/search-result/service/entity-search-result.service';
import {TaskSearchResultService} from '../../redux/search-result/service/task-search-result.service';

@Injectable({
  providedIn: 'root'
})
export class ThesaurusService {
  public static readonly THESAURUS_PAGE_SIZE = 24;
  public static readonly THESAURUS_MAX_SIZE = 200;
  currentDomain$ = this.store.select(selectCurrentContextDatasCurrentDomain);
  private entitySearchResultService: EntitySearchResultService;
  private documentSearchResultService: DocumentSearchResultService;
  private taskSearchResultService: TaskSearchResultService;

  constructor(
    private httpClient: HttpClient,
    private configAction: ConfigurationService,
    private currentContextService: CurrentContextService,
    private router: Router,
    private documentSearchItemService: DocumentSearchItemsService,
    private entitySearchItemsService: EntitySearchItemsService,
    private taskSearchItemService: TaskSearchItemsService,
    private gedSearchService: GedSearchService,
    private wkfSearchService: WkfSearchService,
    protected translateService: TranslateService,
    private store: Store
  ) {
  }

  private static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  public searchPaths(
    thesaurusCode: string,
    thesaurusEntrypointCode: string,
    term: string,
    pageNumber = 0,
    size: number = ThesaurusService.THESAURUS_PAGE_SIZE): Observable<RegionPage<RegionItemI18n>> {
    try {
      return ThesaurusService.query().pipe(
        this.page(pageNumber, size),
        this.addPattern(term),
        this.requestThesaurusEntrypointPage(thesaurusCode, thesaurusEntrypointCode),
        catchError((error: HttpErrorResponse) => {
          console.log('test');
          return throwError(error as HttpErrorResponse);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  public getThesaurusItemsWithStatusByLastThesaurusItemCode(thesaurusCode: string, thesaurusItemCode: string): Observable<RegionItemI18n[]> {
    try {
      return this.httpClient.get<RegionItemI18n[]>(Url.getProtectedApiBaseUrl(this.configAction) + Url.THESAURUS + thesaurusCode + '/' + Url.ITEMS + thesaurusItemCode);
    } catch (error) {
      return of(error);
    }
  }

  public getEntrypointByThesaurusCodeAndEntrypointCode(thesaurusCode: string, thesaurusEntrypointCode: string): Observable<any> {
    return this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configAction) + Url.THESAURUS + thesaurusCode + '/' + 'entrypoints/' + escape(thesaurusEntrypointCode));
  }

  public getThesaurusItemsLabels(thesaurusCode: string, thesaurusItemsValues: string[]): Observable<RegionItemI18n[]> {
    try {
      return ThesaurusService.query().pipe(
        this.addThesaurusItemsValue(thesaurusItemsValues),
        this.requestThesaurusItemsByItemsValue(thesaurusCode, thesaurusItemsValues),
        catchError((error: HttpErrorResponse) => {
          return throwError(error as HttpErrorResponse);
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  public requestThesaurusItemsByItemsValue(thesaurusCode: string, thesaurusItemsValues: string[]): OperatorFunction<HttpParams, RegionItemI18n[]> {
    return switchMap(params => {
      return this.httpClient.get<{ [key: string]: RegionItemI18n[] }>(Url.getProtectedApiBaseUrl(this.configAction) + Url.THESAURUS + thesaurusCode + '/items', {params: params}).pipe(
        map((httpResponse: { [key: string]: RegionItemI18n[] }) => {
          const response: RegionItemI18n[] = [];
          thesaurusItemsValues.forEach((thesaurusItemValue: string) => {
            response.push(httpResponse[thesaurusItemValue][httpResponse[thesaurusItemValue].length - 1]);
          });
          return response;
        })
      );
    });
  }

  public searchDocumentsOrTasksOrEntitiesByThesaurusNodeValue(currentPage: string, fieldCode: string, nodeValue: string, pageNumber = 0): Observable<EsPage<CdxDocument | CdxTask | Entity>> {
    try {
      return ThesaurusService.query().pipe(
        this.workspaceParams(),
        this.searchItemsParams(fieldCode, nodeValue),
        this.searchPage(pageNumber),
        this.searchData(currentPage),
        map((result: EsPage<CdxDocument | Entity>) => {
          if (result.content.length) {
            this.redirectTo(result, currentPage, fieldCode, nodeValue);
          }
          return result;
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  // public getThesaurusItemsLabels(thesaurusCode: string, thesaurusItemsValues: string[]): Observable<{[key: string]: RegionItemI18n}> {
  //   const result$: Subject<{[key: string]: RegionItemI18n}> = new Subject<{[key: string]: RegionItemI18n}>();
  //   setTimeout(() => {
  //     const values: {[key: string]: RegionItemI18n} = {};
  //     thesaurusItemsValues.forEach((thesaurusItemValue: string) => {
  //       values[thesaurusItemValue] = new RegionItemI18n(thesaurusItemValue, 'fr', thesaurusItemValue, Status.ACTIVE);
  //     });
  //     result$.next(values);
  //     result$.complete();
  //   }, 100);
  //   return result$.asObservable();
  // }

  public setEntitySearchResultService(entitySearchResultService: EntitySearchResultService): void {
    this.entitySearchResultService = entitySearchResultService;
  }

  public setDocumentSearchResultService(documentSearchResultService: DocumentSearchResultService): void {
    this.documentSearchResultService = documentSearchResultService;
  }

  public setTaskSearchResultService(taskSearchResultService: TaskSearchResultService): void {
    // TODO do we need the
    this.taskSearchResultService = taskSearchResultService;
  }

  private page(pageNumber = 0, size: number = ThesaurusService.THESAURUS_PAGE_SIZE): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      return params
        .set('size', '' + Math.min(size, ThesaurusService.THESAURUS_MAX_SIZE))
        .set('page', '' + pageNumber);
    });
  }

  private addPattern(pattern: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!pattern) {
        params = params.append('pattern', pattern);
      }
      return params;
    });
  }

  private requestThesaurusEntrypointPage(thesaurusCode: string, thesaurusEntryPointCode: string): OperatorFunction<HttpParams, any> {
    return switchMap(params => {
      return this.httpClient.get<RegionPage<RegionItemI18n>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.THESAURUS + thesaurusCode + '/' + thesaurusEntryPointCode, {params});
    });
  }

  private addThesaurusItemsValue(thesaurusItemsValues: string[]): OperatorFunction<HttpParams, HttpParams> {
    return map(params => {
      thesaurusItemsValues.forEach((thesaurusItemValue: string) => {
        params = params.append('value', thesaurusItemValue);
      });
      return params;
    });
  }

  private addPageCoord(params: HttpParams, limit: number = ThesaurusService.THESAURUS_PAGE_SIZE, offset = 0): HttpParams {
    return params
      .set('_limit', '' + Math.min(limit, ThesaurusService.THESAURUS_MAX_SIZE))
      .set('_offset', '' + offset);
  }

  private searchPage(pageNumber = 0): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      const limit = ThesaurusService.THESAURUS_PAGE_SIZE;
      const offset = limit * Math.floor(pageNumber);
      return this.addPageCoord(params, limit, offset);
    });
  }

  private workspaceParams(): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      withLatestFrom(this.currentDomain$),
      map(([params, currentDomainCode]) => {
        if (!!currentDomainCode) {
          params = params.append('_domains', currentDomainCode);
        }
        return params;
      })
    );
  }

  private searchItemsParams(fieldCode: string, nodeValue: string): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params) => {
        const path: string = SearchPath.PREFIX_CDX_DATAS + SearchPath.PATH_PART_SEPARATOR + fieldCode + SearchPath.PATH_PART_SEPARATOR + SearchPath.SUFFIX_SEARCH_ON_ITEM + SearchPath.PATH_PART_SEPARATOR + SearchPath.SUFFIX_VALUE;
        params = params.append(path, nodeValue);
        return params;
      })
    );
  }

  private searchData(currentPage: string): OperatorFunction<HttpParams, any> {
    return switchMap(params => {
      switch (currentPage) {
        case InternalRoutes.DOCUMENTS:
          return this.httpClient.get<EsPage<CdxDocument>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + Url.SEARCH, {params: params});
        case InternalRoutes.ENTITIES:
          return this.httpClient.get<EsPage<Entity>>(Url.getProtectedApiBaseUrl(this.configAction) + Url.LINKS + this.currentContextService.getCurrentEntityType(), {params: params});
        case InternalRoutes.TASKS:
          return of(new EsPage<CdxTask>(0, [], 0, 0, [], false));
      }
    });
  }

  private redirectTo(searchPage: EsPage<CdxDocument | Entity | CdxTask>, currentPage: string, fieldCode: string, nodeValue: string): void {
    if (searchPage.content.length > 1) {
      const path = SearchPath.PREFIX_CDX_DATAS + SearchPath.PATH_PART_SEPARATOR + fieldCode + SearchPath.PATH_PART_SEPARATOR + SearchPath.SUFFIX_VALUE;
      const params: Params = {};
      params[path] = nodeValue;
      let searchItemService: AbstractSearchItemsService;
      let searchResultService: AbstractSearchResultService;
      let searchService: AbstractSearchService;
      let commandArray: any[] = [];
      // redirect to search
      switch (currentPage) {
        case InternalRoutes.DOCUMENTS:
          searchItemService = this.documentSearchItemService;
          searchResultService = this.documentSearchResultService as AbstractSearchResultService;
          searchService = this.gedSearchService;
          commandArray = [InternalRoutes.SEARCH + '/' + InternalRoutes.RESULT];
          break;
        case InternalRoutes.TASKS:
          searchItemService = this.taskSearchItemService;
          searchResultService = this.taskSearchResultService;
          searchService = this.wkfSearchService;
          commandArray = [InternalRoutes.WORKFLOW + '/' + InternalRoutes.SEARCH + '/' + InternalRoutes.RESULT];
          break;
        case InternalRoutes.ENTITIES:
          searchItemService = this.entitySearchItemsService;
          searchResultService = this.entitySearchResultService;
          searchService = this.gedSearchService;
          commandArray = ['/' + InternalRoutes.ENTITIES, this.currentContextService.getCurrentEntityType(), InternalRoutes.SEARCH, InternalRoutes.RESULT];
          break;
      }
      searchItemService.removeAllSearchItems();
      searchResultService.setNewPageToStore(searchPage);
      searchService.buildSearchitemsFromQueryParams(params).subscribe((searchItems: SearchItem[]) => {
        searchItems.forEach((searchItem: SearchItem) => {
          searchItemService.addItem(searchItem);
        });
        this.router.navigate(commandArray);
      });
    } else if (searchPage.content.length === 1) {
      // redirect to details
      switch (currentPage) {
        case InternalRoutes.DOCUMENTS:
          this.router.navigate(['/' + InternalRoutes.DOCUMENTS, searchPage.content[0].cdx_id, InternalRoutes.DETAILS]);
          break;
        case InternalRoutes.WORKFLOW:
          this.router.navigate(['/' + InternalRoutes.WORKFLOW, searchPage.content[0].cdx_id, InternalRoutes.DETAILS]);
          break;
        case InternalRoutes.ENTITIES:
          this.router.navigate(['/' + InternalRoutes.ENTITIES, this.currentContextService.getCurrentEntityType(), searchPage.content[0].cdx_id, InternalRoutes.DETAILS]);
          break;
      }
    } else {
      console.error('We try to redirect when we have no content in our response');
    }
  }
}
