import {HttpBackend, HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {ApplicationConfigVarNames} from './application-config-var-names';

type ApplicationConfigKeys = keyof typeof ApplicationConfigVarNames;

export type ApplicationConfig = {
  [key in ApplicationConfigKeys]?: any;
};


export function whenConfReady<T>(observable: Observable<T>){
  const configService = inject(ConfigurationService);
  return configService.whenReady(() => observable)
}

const APPLICATION_CONFIG_PATH = 'assets/config/application.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  applicationConfig$ = new BehaviorSubject<ApplicationConfig>(undefined);
  applicationConfigReady$ = this.applicationConfig$
    .pipe(filter(config => !!config));

  constructor(private httpBackend: HttpBackend) {
  }

  private _configuration: ApplicationConfig;

  private get configuration(): ApplicationConfig {
    return this._configuration;
  }

  private set configuration(value: ApplicationConfig) {
    this._configuration = value;
    this.applicationConfig$.next(value);
  }

  private get baseUrl() {
    const domain = document.location;
    return `${domain.protocol}//${domain.hostname}:${domain.port}`;
  }

  whenReady(handler: (conf: ApplicationConfig) => Observable<any>) {
    return this.applicationConfigReady$.pipe(switchMap(handler));
  }


  getApplicationConfig() {
    return this.configuration;
  }

  getConfigVariable(propertyName: ApplicationConfigVarNames) {
    const conf = this.getApplicationConfig();
    if (!conf) {
      throw new Error(`Conf not loaded : can’t get ${propertyName}`);
    }
    return conf[propertyName];
  }

  loadConfiguration(): Observable<ApplicationConfig> {
    console.log(`Loading configuration...`)
    const http: HttpClient = new HttpClient(this.httpBackend);
    return http.get<ApplicationConfig>(`${this.baseUrl}/${APPLICATION_CONFIG_PATH}`)
      .pipe(
        tap((conf) => console.log(`Configuration successfully loaded : `, conf)),
        tap(configuration => this.configuration = configuration)
      );
  }
}
