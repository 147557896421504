import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export enum MediaScreen {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  DESKTOP_LG = 'desktop-lg',
  DESKTOP_XL = 'desktop-xl',
}

@Injectable({
  providedIn: 'root'
})
export class ScreenWidthService {
  public screenWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
  public mediaBreakpoint$: BehaviorSubject<MediaScreen> = new BehaviorSubject(null);

  constructor() {
    this.init();
  }

  init() {
    this._setScreenWidth(window.innerWidth);
    this._setMediaBreakpoint(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(1000),
        takeUntilDestroyed()
      ).subscribe((evt: any) => {
      this._setScreenWidth(evt.target.innerWidth);
      this._setMediaBreakpoint(evt.target.innerWidth);
    });
  }


  private _setScreenWidth(width: number): void {
    this.screenWidth$.next(width);
  }

  private _setMediaBreakpoint(width: number): void {
    if (width < 769) {
      this.mediaBreakpoint$.next(MediaScreen.MOBILE);
    } else if (width >= 769 && width < 1024) {
      this.mediaBreakpoint$.next(MediaScreen.TABLET);
    } else if (width >= 1024 && width < 1301) {
      this.mediaBreakpoint$.next(MediaScreen.DESKTOP);
    } else if (width >= 1301 && width < 1850) {
      this.mediaBreakpoint$.next(MediaScreen.DESKTOP_LG);
    }  else {
      this.mediaBreakpoint$.next(MediaScreen.DESKTOP_XL);
    }
  }
}
