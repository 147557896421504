import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {nexiaBasketReducer} from './reducer/nexia-basket.reducer';


export const nexiaBasketStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.NEXIA_BASKET,
    reducer: nexiaBasketReducer
  })
);

