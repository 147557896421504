import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';


import {AuthenticationService} from './../authentication.service';

@Component({
  selector: 'app-auth-callback',
  template: ''
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

  subs = [];
  token;


  constructor(
    private activeRoute: ActivatedRoute,
    private actions: AuthenticationService
  ) {
  }

  ngOnInit() {
    if (this.activeRoute.fragment) {
      console.log(`FRAGMENT`)
      this.subs.push(this.activeRoute.fragment.pipe(
        map(frag => frag.match(/^(.*?)&/)),
        filter(matches => !!matches),
        map(matches => {
          const tok = matches[1].replace('access_token=', '');
          this.token = tok;
          return tok;
        }),
        switchMap(token => this.actions.validateAuthToken(token)))
        .subscribe());
    } else {
      console.log(`Pas fragment`)
      this.subs.push(this.activeRoute.queryParams.pipe(
        map(params => params.access_token),
        filter(token => !!token),
        tap(token => this.token = token),
        switchMap(token => this.actions.validateAuthToken(token))
      )
      .subscribe());
    }
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

}
