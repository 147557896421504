import { PayloadAction } from '../../../../redux/payload-action';
import { EsAuthor } from '../../../models/EsAuthor';
import { UsersActionConstant } from '../action/user.action';
import { createAction, createReducer, on, props } from '@ngrx/store';

export class UsersState {
  datas: EsAuthor[];
  loading: boolean;
  error: any;
}

export const USERS_INITIAL_STATE: UsersState = {
  datas: [],
  loading: false,
  error: null,
};



export const usersReducerOld = (state: UsersState = USERS_INITIAL_STATE, action: PayloadAction<EsAuthor[]>) => {
  switch (action.type) {
    case UsersActionConstant.LOAD_USERS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UsersActionConstant.LOAD_USERS_SUCCEEDED:
      return {
        ...state,
        datas: [...state.datas, ...action.payload],
        loading: false,
        error: null,
      };
    case UsersActionConstant.LOAD_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const loadUsersStartedAction = createAction(UsersActionConstant.LOAD_USERS_STARTED);
export const loadUsersSucceedAction = createAction(UsersActionConstant.LOAD_USERS_SUCCEEDED, props<{payload: EsAuthor[]}>());
export const loafUsersFailedAction = createAction(UsersActionConstant.LOAD_USERS_FAILED, props<{error: Error}>());

export const usersReducer = createReducer(
  USERS_INITIAL_STATE,
  on(loadUsersStartedAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  }) ),
  on(loadUsersSucceedAction, (state, {payload}) => ({
    ...state,
    datas: [...state.datas, ...payload],
    loading: false,
    error: null,
  }) ),
  on(loafUsersFailedAction, (state,{error}) => ({
    ...state,
    loading: false,
    error: error,
  }) ),
)
