import {NgModule} from '@angular/core';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {TooltipModule} from '../tooltip/tooltip.module';
import {LogoNexiaComponent} from './component/logo-nexia.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [LogoNexiaComponent],
  imports: [
    CommonModule,
    TooltipModule,
    RouterModule,
    MatMenuModule,
    TranslateModule
  ],
  exports: [
    LogoNexiaComponent
  ]
})
export class LogoNexiaModule {
}
