

export enum ApplicationConfigVarNames {
  API_LOGIN_URL = 'API_LOGIN_URL',
  API_LOGOUT_URL = 'API_LOGOUT_URL',
  API_REDIRECT_URI = 'API_REDIRECT_URI',
  API_BACK_URL = 'API_BACK_URL',
  WKF_API_BACK_URL = 'WKF_API_BACK_URL',
  WKF_API_BACK_NAME = 'WKF_API_BACK_NAME',
  API_BACK_NAME = 'API_BACK_NAME',
  API_MOCK_URL = 'API_MOCK_URL',
  SOCKET_IO_URL = 'SOCKET_IO_URL',
  MAX_MB_DOC_FILE_SIZE = 'MAX_MB_DOC_FILE_SIZE',
  MAX_MB_ATTACHMENT_FILE_SIZE = 'MAX_MB_ATTACHMENT_FILE_SIZE',
  MAX_UPLOAD_FILES = 'MAX_UPLOAD_FILES',
  DRAFT_TIMEOUT = 'DRAFT_TIMEOUT',
  GLOBAL_TIMEOUT = 'GLOBAL_TIMEOUT',
  WORKFLOW_ENABLED = 'WORKFLOW_ENABLED',
  AZURE_MSAL_AUTH_CLIENTID = 'AZURE_MSAL_AUTH_CLIENTID',
  AZURE_MSAL_AUTH_REDIRECT_URI = 'AZURE_MSAL_AUTH_REDIRECT_URI',
  AZURE_MSAL_AUTH_AUTHORITY = 'AZURE_MSAL_AUTH_AUTHORITY',
  AZURE_MSAL_GRAPH_API_URL = 'AZURE_MSAL_GRAPH_API_URL',
  AZURE_MSAL_OUTLOOK_URL = 'AZURE_MSAL_OUTLOOK_URL',
  AZURE_MSAL_SHARE_DIR_NAME ='AZURE_MSAL_SHARE_DIR_NAME'
}
