


export class DocumentDetailsConstant {

  static readonly LOAD_DETAILS_START = '[DocumentDetails].LOAD_DETAILS_START';
  static readonly LOAD_DETAILS_SUCCEEDED = '[DocumentDetails].LOAD_DETAILS_SUCCEEDED';
  static readonly LOAD_DETAILS_FAILED = '[DocumentDetails].LOAD_DETAILS_FAILED';

  static readonly LOAD_DOC_DETAILS_START = '[DocumentDetails].LOAD_DOC_DETAILS_START';
  static readonly LOAD_DOC_DETAILS_SUCCEEDED = '[DocumentDetails].LOAD_DOC_DETAILS_SUCCEEDED';
  static readonly LOAD_DOC_DETAILS_FAILED = '[DocumentDetails].LOAD_DOC_DETAILS_FAILED';

  static readonly LOAD_ATTACHMENT_START = '[DocumentDetails].LOAD_ATTACHMENT_START';
  static readonly LOAD_ATTACHMENT_SUCCEEDED = '[DocumentDetails].LOAD_ATTACHMENT_SUCCEEDED';
  static readonly LOAD_ATTACHMENT_FAILED = '[DocumentDetails].LOAD_ATTACHMENT_FAILED';

  static readonly LOAD_HISTORY_START = '[DocumentDetails].LOAD_HISTORY_START';
  static readonly LOAD_HISTORY_SUCCEEDED = '[DocumentDetails].LOAD_HISTORY_SUCCEEDED';
  static readonly LOAD_HISTORY_FAILED = '[DocumentDetails].LOAD_HISTORY_FAILED';

  static readonly UPLOAD_ATTACHMENT_START = '[DocumentDetails].UPLOAD_ATTACHMENT_START';
  static readonly UPLOAD_ATTACHMENT_SUCCEEDED = '[DocumentDetails].UPLOAD_ATTACHMENT_SUCCEEDED';
  static readonly UPLOAD_ATTACHMENT_FAILED = '[DocumentDetails].UPLOAD_ATTACHMENT_FAILED';

  static readonly DELETE_ATTACHMENT_START = '[DocumentDetails].DELETE_ATTACHMENT_START';
  static readonly DELETE_ATTACHMENT_SUCCEEDED = '[DocumentDetails].DELETE_ATTACHMENT_SUCCEEDED';
  static readonly DELETE_ATTACHMENT_FAILED = '[DocumentDetails].DELETE_ATTACHMENT_FAILED';

  static readonly UPDATE_ATTACHMENT_START = '[DocumentDetails].UPDATE_ATTACHMENT_START';
  static readonly UPDATE_ATTACHMENT_SUCCEEDED = '[DocumentDetails].UPDATE_ATTACHMENT_SUCCEEDED';
  static readonly UPDATE_ATTACHMENT_FAILED = '[DocumentDetails].UPDATE_ATTACHMENT_FAILED';

  static readonly LOAD_COMMENT_START = '[DocumentDetails].LOAD_COMMENT_START';
  static readonly LOAD_COMMENT_SUCCEEDED = '[DocumentDetails].LOAD_COMMENT_SUCCEEDED';
  static readonly LOAD_COMMENT_FAILED = '[DocumentDetails].LOAD_COMMENT_FAILED';

  static readonly ADD_COMMENT_START = '[DocumentDetails].ADD_COMMENT_START';
  static readonly ADD_COMMENT_FAILED = '[DocumentDetails].ADD_COMMENT_FAILED';

  static readonly UPDATE_COMMENT_START = '[DocumentDetails].UPDATE_COMMENT_START';
  static readonly UPDATE_COMMENT_FAILED = '[DocumentDetails].UPDATE_COMMENT_FAILED';

  static readonly DELETE_COMMENT_START = '[DocumentDetails].DELETE_COMMENT_START';
  static readonly DELETE_COMMENT_FAILED = '[DocumentDetails].DELETE_COMMENT_FAILED';

  static readonly ADD_REPLY_START = '[DocumentDetails].ADD_REPLY_START';
  static readonly ADD_REPLY_FAILED = '[DocumentDetails].ADD_REPLY_FAILED';

  static readonly UPDATE_REPLY_START = '[DocumentDetails].UPDATE_REPLY_START';
  static readonly UPDATE_REPLY_FAILED = '[DocumentDetails].UPDATE_REPLY_FAILED';

  static readonly DELETE_REPLY_START = '[DocumentDetails].DELETE_REPLY_START';
  static readonly DELETE_REPLY_FAILED = '[DocumentDetails].DELETE_REPLY_FAILED';

  static readonly LOAD_META_START = '[DocumentDetails].LOAD_META_START';
  static readonly LOAD_META_SUCCEEDED = '[DocumentDetails].LOAD_META_SUCCEEDED';
  static readonly LOAD_META_FAILED = '[DocumentDetails].LOAD_META_FAILED';

  static readonly REMOVE_ALL_SUCCEEDED = '[DocumentDetails].REMOVE_ALL_SUCCEEDED';

  static readonly UPDATE_TOKEN = '[DocumentDetails].UPDATE_TOKEN';

  static readonly LOAD_DISPLAY_BLOCK_SUCCEEDED = '[DocumentDetails].LOAD_DISPLAY_BLOCK_SUCCEEDED';

  static readonly LOAD_CURRENT_ACTIVITY_DIFF_START = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_START';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_FAILED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_FAILED';
}
