import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {MaintenanceInfoState} from './reducer/maintenance-info.reducer';


export const selectMaintenanceInfoFeature = createFeatureSelector<MaintenanceInfoState>(StoreKeys.MAINTENANCE_INFO);

export const selectMaintenanceInfoDatas = createSelector(selectMaintenanceInfoFeature, state => state.datas);

export const selectMaintenanceInfoType = createSelector(selectMaintenanceInfoDatas, state => state.type);
