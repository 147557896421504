import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ErrorTypes, Utils } from '../../../utils/utils';
import { CacheFormAction } from '../cache/action/cache-form.action';

@Injectable({
  providedIn: 'root'
})
export class CacheFormService {

  public static readonly CACHE_DEFAULT_VALUES = '___CACHE_DEFAULT_VALUES___';

  private static retrieveDataByType(value: any): any {
    if (value === null || value === undefined) {
      return null;
    } else if (value._isAMomentObject) {
      return value._d.getTime();
    } else if (value instanceof Date) {
      return value.getTime();
    } else {
      return value;
    }
  }


  public static getCacheFormValues(cacheFormKey: string, cacheForm: {[key: string]: any}): {[key: string]: any} {
    return cacheForm[cacheFormKey];
  }

  constructor(
    private cacheFormAction: CacheFormAction
  ) { }

  public addDataToCacheForm(value: {[key: string]: any}): void {
    try {
      this.cacheFormAction.addCacheFormStart();
      if (value === undefined || value === null) {
        throw Utils.createErrorEvent(ErrorTypes.DATA);
      }
      this.cacheFormAction.addCacheFormSucceeded(value);
    } catch (error) {
      this.cacheFormAction.addCacheFormFailed(error.message);
    }
  }

  public updateDataInCacheForm(formData: {[key: string]: any}): void {
    this.cacheFormAction.updateDataInCacheFormSucceeded(formData);
  }

  public removeDataFromCacheForm(formKey: string): void {
      this.cacheFormAction.removeCacheFormSucceeded(formKey);
  }

  public getUpdatedValuesFromForm(form: UntypedFormGroup): {[key: string]: any} {
    const formValues: {[key: string]: any} = {};
    if (!!form.controls) {
      Object.keys(form.controls).forEach((groupKey: string) => {
        if (!!form.controls[groupKey] && form.controls[groupKey].dirty) {
          const formGroup: UntypedFormGroup = form.controls[groupKey] as UntypedFormGroup;
          Object.keys(formGroup.controls).forEach((controlKey: string) => {
            if (!!formGroup.controls[controlKey] && formGroup.controls[controlKey].dirty) {
              if (controlKey !== 'region') {
                formValues[controlKey] = CacheFormService.retrieveDataByType(formGroup.controls[controlKey].value);
              } else {
                const entityType: string = Object.keys(formGroup.controls).find((key: string) => key !== 'region');
                formValues[controlKey] = !!formValues[controlKey] ? formValues[controlKey] : {};
                formValues[controlKey][entityType] = CacheFormService.retrieveDataByType(formGroup.controls[controlKey].value);
              }
            }
          });
        }
      });
    }
    return formValues;
  }
}
