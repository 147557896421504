import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {searchResultPaginationReducer} from './reducer/search-result-pagination.reducer';


export const searchResultPaginationStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.SEARCH_RESULT_PAGINATION,
    reducer: searchResultPaginationReducer
  })
);
