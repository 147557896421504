import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {OptionBlockComponent} from './block-components/option-block/option-block.component';
import {DecimalDataComponent} from './data-components/ged/decimal-data/decimal-data.component';
import {IntegerDataComponent} from './data-components/ged/integer-data/integer-data.component';
import {GedDataInjectorComponent} from './data-injector/ged/ged-data-injector.component';
import {LinkBlockComponent} from './block-components/link-block/link-block.component';
import {StringDataComponent} from './data-components/ged/string-data/string-data.component';
import {XmlDataComponent} from './data-components/ged/xml-data/xml-data.component';
import {DateDataComponent} from './data-components/ged/date-data/date-data.component';
import {EntityModule} from '../entity/entity.module';
import {TooltipModule} from '../tooltip/tooltip.module';
import {NumericDirective} from './numeric.directive';
import {NxI18nPipeModule} from '../nxI18nPipe/nxI18nPipe.module';
import {BooleanDataComponent} from './data-components/ged/boolean-data/boolean-data.component';
import {ListDataComponent} from './data-components/ged/list/list-data/list-data.component';
import {ListItemComponent} from './data-components/ged/list/list-item/list-item.component';
import {RegionSelectorComponent} from './block-components/components/region-selector/region-selector.component';
import {RegionDisplayComponent} from './block-components/components/region-display/region-display.component';
import {ThesaurusDataComponent} from './data-components/ged/thesaurus-data/thesaurus-data.component';
import {ButtonModule} from '../buttons/button.module';
import {XmlViewerComponent} from './xml-viewer/xml-viewer.component';
import {GedDataBlockComponent} from './block-components/data-block/ged/ged-data-block.component';
import {WkfDataBlockComponent} from './block-components/data-block/wkf/wkf-data-block.component';
import {WkfDataInjectorComponent} from './data-injector/wkf/wkf-data-injector.component';
import {TextfieldDataComponent} from './data-components/wkf/textfield-data/textfield-data.component';
import {NumberDataComponent} from './data-components/wkf/number-data/number-data.component';
import {CheckboxDataComponent} from './data-components/wkf/checkbox-data/checkbox-data.component';
import {SelectDataComponent} from './data-components/wkf/select-data/select-data.component';
import {SubTaskBlockComponent} from './block-components/custom-block/wkf/sub-task-block/sub-task-block.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    NumericDirective,
    GedDataInjectorComponent,
    WkfDataInjectorComponent,
    StringDataComponent,
    XmlDataComponent,
    DateDataComponent,
    DecimalDataComponent,
    IntegerDataComponent,
    BooleanDataComponent,
    ListDataComponent,
    ThesaurusDataComponent,
    ListItemComponent,
    GedDataBlockComponent,
    WkfDataBlockComponent,
    LinkBlockComponent,
    OptionBlockComponent,
    RegionSelectorComponent,
    RegionDisplayComponent,
    XmlViewerComponent,
    TextfieldDataComponent,
    NumberDataComponent,
    CheckboxDataComponent,
    SelectDataComponent,
    SubTaskBlockComponent
  ],
  imports: [
    // we added entityModule here in order to be seen by dataModule/linkBlock
    EntityModule,
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    NxI18nPipeModule,
    RouterModule.forChild([]),
    MatIconModule,
    ButtonModule,
    TranslateModule,
    NgxIntlTelInputModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  exports: [
    NumericDirective,
    GedDataBlockComponent,
    WkfDataBlockComponent,
    LinkBlockComponent,
    OptionBlockComponent,
    RegionSelectorComponent,
    RegionDisplayComponent,
    SubTaskBlockComponent
  ]
})
export class DataModule {
}
