import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CfgDocumentInfoAction} from '../action/cfg-document-info.action';
import {Url} from '../../../models/url';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CfgDocumentInfoService {

  application$: Observable<any> = this.configService.applicationConfigReady$

  constructor(
    private cfgDocumentInfoAction: CfgDocumentInfoAction,
    private http: HttpClient,
    private configService: ConfigurationService
  ) {
  }

  getCfgDocumentInfo(doctype: string) {
    this.cfgDocumentInfoAction.loadDocumentInfoStart();
    try {
      this.http.get(Url.getProtectedApiBaseUrl(this.configService) + Url.DOMAINS + Url.INFOS + `${doctype}`,
        {observe: 'response'})
        .subscribe((response: HttpResponse<any>) => {
          const arr: string[] = response.body[doctype];
          const docInfo = {};
          docInfo[doctype] = arr;
          this.cfgDocumentInfoAction.loadDocumentInfoSucceeded(docInfo);
        }, (response: HttpErrorResponse) => {
          this.cfgDocumentInfoAction.loadDocumentInfoFailed(response.error);
        });
    } catch (error) {
      this.cfgDocumentInfoAction.loadDocumentInfoFailed(error);
    }
  }

  removeAllCfgDocumentInfo() {
    this.cfgDocumentInfoAction.removeAllDocumentInfoSucceeded();
  }
}
