import { RouterPathInfo } from '../../../models/router-path-info';
import {
  loadRouterPathFailedAction,
  loadRouterPathStartAction,
  loadRouterPathSucceededAction,
  removeAllSucceededAction
} from '../action/router-path.action';
import { createReducer, on } from '@ngrx/store';

export class RouterPathState {
  datas: RouterPathInfo;
  loading: boolean;
  error: any;
}

export const ROUTER_PATH_INITIAL_STATE: RouterPathState = {
  datas: {
    history: [],
    closeRoute: '/search'
  },
  loading: false,
  error: null,
};


/*
export const routerPathReducerOld = (state: RouterPathState = ROUTER_PATH_INITIAL_STATE, action: PayloadAction<RouterPathInfo>): RouterPathState => {
  switch (action.type) {
    case RouterPathConstantAction.LOAD_ROUTER_PATH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RouterPathConstantAction.LOAD_ROUTER_PATH_SUCCEEDED:
      const stateRouterPathInfoCopy = {
        ...state.datas
      };
      stateRouterPathInfoCopy.history = [...stateRouterPathInfoCopy.history, ...action.payload.history];
      if (!!action.payload.closeRoute) {
        stateRouterPathInfoCopy.closeRoute = action.payload.closeRoute;
      }
      return {
        ...state,
        datas: stateRouterPathInfoCopy,
        loading: false,
        error: null,
      };
    case RouterPathConstantAction.LOAD_ROUTER_PATH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RouterPathConstantAction.REMOVE_ALL_SUCCEEDED:
      return {
        ...state,
        datas: {
          history: [],
          closeRoute: '/search'
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const routerPathReducer = createReducer(
  ROUTER_PATH_INITIAL_STATE,
  on(loadRouterPathStartAction, (state) => { return {
    ...state,
    loading: true,
    error: null,
  }}),
  on(loadRouterPathSucceededAction, (state, { payload }) => {
    const stateRouterPathInfoCopy = {
      ...state.datas
    };
    stateRouterPathInfoCopy.history = [...stateRouterPathInfoCopy.history, ...payload.history];
    if (!!payload.closeRoute) {
      stateRouterPathInfoCopy.closeRoute = payload.closeRoute;
    }
    return {
      ...state,
      datas: stateRouterPathInfoCopy,
      loading: false,
      error: null,
    };
  }),
  on(loadRouterPathFailedAction,(state, {error}) => { return {
    ...state,
    loading: false,
    error: error,
  }}),
  on(removeAllSucceededAction ,(state) => { return {
    ...state,
    datas: {
      history: [],
      closeRoute: '/search'
    },
    loading: false,
    error: null,
  }}),
);
