import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {versionListReducer} from './reducer/version-list.reducer';

export const versionListStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.VERSION_LIST,
    reducer: versionListReducer
  })
);
