import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { View } from '../../../models/view';

@Injectable({
  providedIn: 'root'
})
export class ViewActionConstant {
  static readonly ADD_VIEW_START = '[View].ADD_VIEW_START';
  static readonly ADD_VIEW_SUCCEEDED = '[View].ADD_VIEW_SUCCEEDED';
  static readonly ADD_VIEW_FAILED = '[View].ADD_VIEW_FAILED';

  static readonly REMOVE_ALL_VIEWS_START = '[View].REMOVE_ALL_VIEWS_START';
  static readonly REMOVE_ALL_VIEWS_SUCCEEDED = '[View].REMOVE_ALL_VIEWS_SUCCEEDED';
  static readonly REMOVE_ALL_VIEWS_FAILED = '[View].REMOVE_ALL_VIEWS_FAILED';
}

export const createActionAddViewStart            = createAction(ViewActionConstant.ADD_VIEW_START);
export const createActionAddViewSucceeded        = createAction(ViewActionConstant.ADD_VIEW_SUCCEEDED, props<{ payload: {[key: string]: View} }>());
export const createActionAddViewFailed            = createAction(ViewActionConstant.ADD_VIEW_FAILED, props<{error: Error}>());
export const createActionRemoveAllViewsStart     = createAction(ViewActionConstant.REMOVE_ALL_VIEWS_START);
export const createActionRemoveAllViewsSucceeded = createAction(ViewActionConstant.REMOVE_ALL_VIEWS_SUCCEEDED);
export const createActionRemoveAllViewsFailed    = createAction(ViewActionConstant.REMOVE_ALL_VIEWS_FAILED, props<{error: Error}>());


@Injectable({
  providedIn: 'root'
})
export class ViewAction {

  constructor(
      private localStore: Store
  ) {
  }

  public loadViewStart(): void {
    this.localStore.dispatch(createActionAddViewStart());
  }

  public loadViewSucceeded(mapView: {[key: string]: View}): void {
    this.localStore.dispatch(createActionAddViewSucceeded( { payload :  mapView }));
  }

  public loadViewFailed(error: any): void {
    this.localStore.dispatch(createActionAddViewFailed({error}) )

  }


  public removeAllViewsStart(): void {
    this.localStore.dispatch(createActionRemoveAllViewsStart())
  }

  public removeAllViewsSucceeded(): void {
    this.localStore.dispatch(createActionRemoveAllViewsSucceeded());
  }

  public removeAllViewsFailed(error: any): void {
    this.localStore.dispatch(createActionRemoveAllViewsFailed({error}));
  }

}
