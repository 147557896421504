import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';

export class CacheFormConstant {
  static readonly ADD_CACHE_FORM_START = '[CacheForm].ADD_CACHE_FORM_START';
  static readonly ADD_CACHE_FORM_SUCCEEDED = '[CacheForm].ADD_CACHE_FORM_SUCCEEDED';
  static readonly ADD_CACHE_FORM_FAILED = '[CacheForm].ADD_CACHE_FORM_FAILED';
  static readonly UPDATE_CACHE_FORM_SUCCEEDED = '[CacheForm].UPDATE_CACHE_FORM_SUCCEEDED';
  static readonly REMOVE_CACHE_FORM_SUCCEEDED = '[CacheForm].REMOVE_CACHE_FORM_SUCCEEDED';
}

export const addCacheFormStartAction = createAction(
  CacheFormConstant.ADD_CACHE_FORM_START
);

export const addCacheFormSucceededAction = createAction(
  CacheFormConstant.ADD_CACHE_FORM_SUCCEEDED,
  props<{ payload: { [key: string]: any } }>()
);

export const addCacheFormFailedAction = createAction(
  CacheFormConstant.ADD_CACHE_FORM_FAILED,
  props<{ error: Error }>()
);

export const updateCacheFormSucceededAction = createAction(
  CacheFormConstant.UPDATE_CACHE_FORM_SUCCEEDED,
  props<{ payload: { [key: string]: any } }>()
);

export const removeCacheFormSucceededAction = createAction(
  CacheFormConstant.REMOVE_CACHE_FORM_SUCCEEDED,
  props<{ payload: { [key: string]: any } }>()
);


@Injectable({
  providedIn: 'root'
})
export class CacheFormAction {

  constructor(private localStore: Store) {}

  public addCacheFormStart(): void {
    this.localStore.dispatch(addCacheFormStartAction());
  }

  public addCacheFormSucceeded(value: { [key: string]: any }): void {
    this.localStore.dispatch(addCacheFormSucceededAction({ payload: value }));
  }

  public addCacheFormFailed(error: Error): void {
    this.localStore.dispatch(addCacheFormFailedAction({ error }));
  }

  public updateDataInCacheFormSucceeded(formData: { [key: string]: any }): void {
    this.localStore.dispatch(updateCacheFormSucceededAction({ payload: formData }));
  }

  public removeCacheFormSucceeded(formKey: string): void {
    const formCacheToBeDeleted: { [key: string]: any } = {};
    formCacheToBeDeleted[formKey] = {};
    this.localStore.dispatch(removeCacheFormSucceededAction({ payload: formCacheToBeDeleted }));
  }
}
