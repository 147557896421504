import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { Team } from '../../../../models/team';


export class CurrentTeamConstantAction {
  static readonly LOAD_CURRENT_TEAM_START = '[CurrentTeam].LOAD_CURRENT_TEAM_START';
  static readonly LOAD_CURRENT_TEAM_SUCCEEDED = '[CurrentTeam].LOAD_CURRENT_TEAM_SUCCEEDED';
  static readonly LOAD_CURRENT_TEAM_FAILED = '[CurrentTeam].LOAD_CURRENT_TEAM_FAILED';
  static readonly REMOVE_CURRENT_TEAM = '[CurrentTeam].REMOVE_CURRENT_TEAM';
}

export const loadCurrentTeamStartAction = createAction(CurrentTeamConstantAction.LOAD_CURRENT_TEAM_START);
export const loadCurrentTeamSucceededAction = createAction(CurrentTeamConstantAction.LOAD_CURRENT_TEAM_SUCCEEDED,props<{payload:Team}>());
export const loadCurrentTeamFailedAction = createAction(CurrentTeamConstantAction.LOAD_CURRENT_TEAM_FAILED,props<{error:Error}>());
export const removeCurrentTeamAction = createAction(CurrentTeamConstantAction.REMOVE_CURRENT_TEAM);

@Injectable({
  providedIn: 'root'
})
export class CurrentTeamAction {

  constructor(private localStore: Store  ) {
  }

  public loadCurrentTeamStarted(): void {
    this.localStore.dispatch(loadCurrentTeamStartAction());
  }

  public loadCurrentTeamSucceeded(payload: Team): void {
    this.localStore.dispatch(loadCurrentTeamSucceededAction({payload}));
  }

  public loadCurrentTeamFailed(error: any) {
    this.localStore.dispatch(loadCurrentTeamFailedAction({error}));
  }

  public removeCurrentTeam() {
    this.localStore.dispatch(removeCurrentTeamAction());
  }
}
