import {Injectable} from '@angular/core';
import {View} from '../../../../models/view';
import {createAction, props, Store} from '@ngrx/store';

export class EntityViewConstant {
  static readonly ADD_VIEW_START = '[EntityView].ADD_VIEW_START';
  static readonly ADD_VIEW_SUCCEEDED = '[EntityView].ADD_VIEW_SUCCEEDED';
  static readonly ADD_VIEW_FAILED = '[EntityView].ADD_VIEW_FAILED';
}

export const loadViewStartAction = createAction(
  EntityViewConstant.ADD_VIEW_START
);

export const loadViewSucceededAction = createAction(
  EntityViewConstant.ADD_VIEW_SUCCEEDED,
  props<{ payload: { [key: string]: View } }>()
);

export const loadViewFailedAction = createAction(
  EntityViewConstant.ADD_VIEW_FAILED,
  props<{ error: Error }>()
);


@Injectable({
  providedIn: 'root'
})
export class EntityViewAction {

  constructor(private viewStore: Store) {
  }

  public loadViewStart(): void {
    this.viewStore.dispatch(loadViewStartAction());
  }

  public loadViewSucceeded(mapView: { [key: string]: View }): void {
    this.viewStore.dispatch(loadViewSucceededAction({payload: mapView}));
  }

  public loadViewFailed(error: Error): void {
    this.viewStore.dispatch(loadViewFailedAction({error}));
  }
}
