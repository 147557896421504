import { Injectable } from '@angular/core';
import {AuthenticationService} from '../../../modules/authentication/authentication.service';

import * as jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTokenService {

  public static readonly SESSION_ST_APP_TOKEN = 'app-token';

  public tokenDecode(token: string) : any | null {
    try {
      return jwtDecode(token);
    } catch (ex) {
      return null;
    }
  }

  setApplicationTokenIntoSessionStorage(token: string): boolean {
    if (sessionStorage.getItem(ApplicationTokenService.SESSION_ST_APP_TOKEN) === token) {
      return false;
    }
    if (!token) {
      sessionStorage.removeItem(ApplicationTokenService.SESSION_ST_APP_TOKEN);
    } else {
      sessionStorage.setItem(ApplicationTokenService.SESSION_ST_APP_TOKEN, token);
    }
    return true;
  }

  public getApplicationTokenFromSessionStorage(includeBearer = true): string {
    const token = sessionStorage.getItem(ApplicationTokenService.SESSION_ST_APP_TOKEN);
    if (includeBearer) {
      return AuthenticationService.BEARER + token;
    } else {
      return token;
    }
  }

  public isJwtTokenValid(token: string): boolean {
    if (!token) {
      return false;
    }
    if (this.tokenDecode(token)?.exp * 1000 < new Date().getTime()) {
      return false;
    }
    return true;
  }
}
