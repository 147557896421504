import {
  loadTableFilterFailedAction,
  loadTableFilterStartAction,
  loadTableFilterSucceededAction,
  removeTableFilterAction,
  removeTableFilterByKeyAction
} from '../action/table-filter.action';
import { createReducer, on } from '@ngrx/store';

export class TableFilterState {
  datas: { [key: string]: any };
  loading: boolean;
  error: any;
}

export const TABLE_FILTER_INITIAL_STATE: TableFilterState = {
  datas: null,
  loading: false,
  error: null
};


/*
export const tableFilterReducerOld = (
  state: TableFilterState = TABLE_FILTER_INITIAL_STATE, action: PayloadAction<{ [key: string]: any } | string>
) => {
  switch (action.type) {
    case TableFilterConstantAction.LOAD_TABLE_FILTER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TableFilterConstantAction.LOAD_TABLE_FILTER_SUCCEEDED:
      return {
        ...state,
        datas: action.payload as { [key: string]: any },
        loading: false,
        error: null,
      };
    case TableFilterConstantAction.LOAD_TABLE_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TableFilterConstantAction.REMOVE_TABLE_FILTER_BY_KEY:
      const oldFilterStore: { [key: string]: any } = {...state.datas};
      let addNewFilterStore = false;
      let newFilterStore: { [key: string]: any } = null;
      if (!!oldFilterStore && Object.keys(oldFilterStore).length && !!oldFilterStore[action.payload as string]) {
        Object.keys(oldFilterStore).forEach((oldFilterStoreKey: string) => {
          if (oldFilterStoreKey !== action.payload) {
            if (!newFilterStore) {
              newFilterStore = {};
            }
            newFilterStore[oldFilterStoreKey] = oldFilterStore[oldFilterStoreKey];
          }
        });
        addNewFilterStore = true;
      }
      return {
        ...state,
        datas: addNewFilterStore ? newFilterStore : oldFilterStore,
        loading: false,
        error: null,
      };
    case TableFilterConstantAction.REMOVE_TABLE_FILTER:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const tableFilterReducer = createReducer(
  TABLE_FILTER_INITIAL_STATE,
  on(loadTableFilterStartAction,(state) => { return {
    ...state,
    loading: true,
    error: null,
  }}),
  on(loadTableFilterSucceededAction,(state, {payload}) => { return {
    ...state,
    datas: payload as { [key: string]: any },
    loading: false,
    error: null,
  }}),
  on(loadTableFilterFailedAction,(state) => { return {
    ...state,
    loading: false,
    error: null,
  }}),
  on(removeTableFilterAction,(state) => { return {
    ...state,
    datas: null,
    loading: false,
    error: null,
  }}),
  on(removeTableFilterByKeyAction  ,(state, {payload}) => {
    const oldFilterStore: { [key: string]: any } = {...state.datas};
    let addNewFilterStore = false;
    let newFilterStore: { [key: string]: any } = null;
    if (!!oldFilterStore && Object.keys(oldFilterStore).length && !!oldFilterStore[payload as string]) {
      Object.keys(oldFilterStore).forEach((oldFilterStoreKey: string) => {
        if (oldFilterStoreKey !== payload) {
          if (!newFilterStore) {
            newFilterStore = {};
          }
          newFilterStore[oldFilterStoreKey] = oldFilterStore[oldFilterStoreKey];
        }
      });
      addNewFilterStore = true;
    }
    return {
      ...state,
      datas: addNewFilterStore ? newFilterStore : oldFilterStore,
      loading: false,
      error: null,
    }
  }),
);
