import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {CacheDraftState} from './cache-draft/reducer/cache-draft.reducer';
import {DraftDatasState} from './draft-datas/reducer/draft-datas.reducer';
import {DraftFilterState} from './draft-filter/reducer/draft-filter.reducer';
import {ListIndexationState} from './list-indexation/reducer/list-indexation.reducer';
import { IndexationDocTypesState } from './doc-types/reducer/indexation-doc-types.reducer';


export const selectCacheDraftFeature = createFeatureSelector<CacheDraftState>(StoreKeys.CACHE_DRAFT);
export const selectCacheDraftDatas = createSelector(selectCacheDraftFeature, state => state.datas);

export const selectCacheDraftDocument = createSelector(selectCacheDraftFeature, state => state.datas.draftData);

export const selectDocTypesFeature = createFeatureSelector<IndexationDocTypesState>(StoreKeys.INDEXATION_DOC_TYPES);
export const selectDocTypesData = createSelector(selectDocTypesFeature, state => state.datas);

export const selectDraftDatasFeature = createFeatureSelector<DraftDatasState>(StoreKeys.DRAFT_DATAS);
export const selectDraftDatasDatas = createSelector(selectDraftDatasFeature, state => state.datas);

export const selectDraftFilterFeature = createFeatureSelector<DraftFilterState>(StoreKeys.DRAFT_FILTERS);

export const selectDraftFilterDatas = createSelector(selectDraftFilterFeature, draftState => draftState.datas);
export const selectDraftFilterDatasFilterItems = createSelector(selectDraftFilterFeature, draftState => draftState.datas.filterItems);
export const selectDraftFilterDatasLastAgg = createSelector(selectDraftFilterFeature, draftState => draftState.datas.lastAgg);

export const selectListIndexationsFeature = createFeatureSelector<ListIndexationState>(StoreKeys.LIST_INDEXATION);
export const selectListIndexationsLoading = createSelector(selectListIndexationsFeature, state => state.loading);

export const selectListIndexationsDatas = createSelector(selectListIndexationsFeature, state => state.datas);
export const selectListIndexationsDatasContent = createSelector(selectListIndexationsDatas, state => state?.content);
export const selectListIndexationsDatasAggs = createSelector(selectListIndexationsDatas, datas => datas?.aggs);

