import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {Subscription, SubscriptionLike} from 'rxjs';
import {MaintenanceInfo, MaintenanceType} from '../../models/maintenance-info';
import {Utils} from '../../utils/utils';
import {MaintenanceInfoService} from '../../redux/maintenance-info/service/maintenance-info.service';
import {Store} from "@ngrx/store";
import {selectMaintenanceInfoDatas} from "../../redux/maintenance-info/maintenance-info-selectors";

@Component({
  selector: 'app-maintenance-info',
  templateUrl: './maintenance-info.component.html',
  styleUrls: ['./maintenance-info.component.scss']
})
export class MaintenanceInfoComponent implements OnInit, OnDestroy {
  private static readonly MAINTENANCE_PRE_INFO = 'MAINTENANCE.maintenancePre';
  private static readonly MAINTENANCE_ON_INFO = 'MAINTENANCE.maintenanceOn';
  private static readonly MAINTENANCE_OFF_INFO = 'MAINTENANCE.maintenanceOff';

  maintenanceInfo$ = this.store.select(selectMaintenanceInfoDatas);

  @HostBinding('class.maintenancePre') maintenancePre = false;
  @HostBinding('class.maintenanceOn') maintenanceOn = false;
  @HostBinding('class.maintenanceOff') maintenanceOff = false;
  public currentMaintenanceInfo: MaintenanceInfo = null;
  public translateMaintenanceMsgWithDate = false;
  public enterMaintenanceHour: string;
  public enterMaintenanceDate: string;
  public finishedMaintenanceHour: string;
  MaintenanceType = MaintenanceType;
  private subs: SubscriptionLike[] = [];
  private timeoutHandle;

  constructor(
    private maintenanceInfoService: MaintenanceInfoService,
    private store: Store
  ) {
  }

  private static checkForAddOfDateInTranslate(maintenanceDateTime: number): boolean {
    const dateNow = new Date();
    const maintenanceDate = new Date(maintenanceDateTime);
    if (dateNow.getFullYear() < maintenanceDate.getFullYear()) {
      return true;
    } else if (dateNow.getFullYear() === maintenanceDate.getFullYear() && dateNow.getMonth() < maintenanceDate.getMonth()) {
      return true;
    } else if (dateNow.getFullYear() === maintenanceDate.getFullYear() && dateNow.getMonth() === maintenanceDate.getMonth() && dateNow.getDay() < maintenanceDate.getDay()) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.subs.push(this.maintenanceInfo$.subscribe((maintenanceInfo: MaintenanceInfo) => {
      let currentMaintenanceInfo = null;
      if (!!maintenanceInfo) {
        currentMaintenanceInfo = _.cloneDeep(maintenanceInfo);
        if (!!this.timeoutHandle) {
          clearTimeout(this.timeoutHandle);
        }
        switch (maintenanceInfo.type) {
          case MaintenanceType.MAINTENANCE_PRE:
          {
            const dateNow = new Date().getTime();
            // temp until we have the date inside the socket data
            const finishDateTime: number = maintenanceInfo.date ? maintenanceInfo.date + maintenanceInfo.ttl : dateNow + maintenanceInfo.ttl;
            const timeOutTimer = finishDateTime - dateNow;
            currentMaintenanceInfo.message = MaintenanceInfoComponent.MAINTENANCE_PRE_INFO;
            if (timeOutTimer > 0) {
              const enterMaintenanceDate: Date = new Date(finishDateTime);
              this.translateMaintenanceMsgWithDate = MaintenanceInfoComponent.checkForAddOfDateInTranslate(finishDateTime);
              this.enterMaintenanceHour = enterMaintenanceDate.getHours().toLocaleString() + ':' + enterMaintenanceDate.getMinutes().toLocaleString();
              if (this.translateMaintenanceMsgWithDate) {
                this.enterMaintenanceDate = enterMaintenanceDate.toLocaleString();
                currentMaintenanceInfo.message += '.dateAndHour';
              } else {
                currentMaintenanceInfo.message += '.onlyHour';
              }
              this.timeoutHandle = this.timeOut(timeOutTimer);
            } else {
              currentMaintenanceInfo = null;
              this.onClose();
            }
            break;
          }
          case MaintenanceType.MAINTENANCE_ON:
          {
            currentMaintenanceInfo.message = MaintenanceInfoComponent.MAINTENANCE_ON_INFO;
            break;
          }
          case MaintenanceType.MAINTENANCE_OFF:
          {
            currentMaintenanceInfo.message = MaintenanceInfoComponent.MAINTENANCE_OFF_INFO;
            const finishedDate: Date = new Date();
            this.finishedMaintenanceHour = finishedDate.getHours().toLocaleString() + ':' + finishedDate.getMinutes().toLocaleString();
            break;
          }
        }
      }

      this.currentMaintenanceInfo = currentMaintenanceInfo;
      this.setMaintenanceInfoStyle();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public onClose() {
    this.maintenanceInfoService.removeMaintenanceInfo();
  }

  private timeOut(timer: number) {
    return setTimeout(() => {
      this.onClose();
    }, timer);
  }

  private setMaintenanceInfoStyle(): void {
    this.maintenanceOff = false;
    this.maintenanceOn = false;
    this.maintenancePre = false;
    if (Utils.notNullAndNotUndefined(this.currentMaintenanceInfo)) {
      switch (this.currentMaintenanceInfo.type) {
        case MaintenanceType.MAINTENANCE_ON:
          this.maintenanceOn = true;
          break;
        case MaintenanceType.MAINTENANCE_PRE:
          this.maintenancePre = true;
          break;
        case MaintenanceType.MAINTENANCE_OFF:
          this.maintenanceOff = true;
          break;
      }
    }
  }
}
