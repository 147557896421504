import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {currentContextReducer} from './reducer/current-context.reducer';
import {withSessionStoragePersistence} from "../../../redux/reducer-utils/with-session-storage-persistence";


export const currentContextStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CURRENT_CONTEXT,
    reducer: withSessionStoragePersistence(StoreKeys.CURRENT_CONTEXT, currentContextReducer)
  }))
;
