import {HttpHeaders} from '@angular/common/http';
import {ToastaService} from 'ngx-toasta';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EsPage} from '../../../../models/es-page';
import {CdxAttachment} from '../../../../models/cdx-attachment';
import {ActivityDiff, CdxActivity} from '../../../../models/cdx-activity';
import {CdxComment} from '../../../../models/cdx-comment';
import {TaskDetails} from '../../../../models/task-details';
import {CdxTask} from '../../../../models/cdx-task';
import {AbstractDetailsAction} from '../abstract-details.action';
import {Utils} from '../../../../utils/utils';
import {createAction, props, Store} from '@ngrx/store';
import {TaskDetailsConstant} from '../task-details-constants';

export const loadDetailsStartAction = createAction(
  TaskDetailsConstant.LOAD_DETAILS_START
);

export const loadDetailsSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_DETAILS_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadDetailsFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_DETAILS_FAILED,
  props<{ error: any }>()
);

export const loadTaskDetailsStartAction = createAction(
  TaskDetailsConstant.LOAD_TASK_DETAILS_START
);

export const loadTaskDetailsSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_TASK_DETAILS_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadTaskDetailsFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_TASK_DETAILS_FAILED,
  props<{ error: any }>()
);

export const loadAttachmentStartTaskAction = createAction(
  TaskDetailsConstant.LOAD_ATTACHMENT_START
);

export const loadAttachmentSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadAttachmentFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const loadHistoryStartTaskAction = createAction(
  TaskDetailsConstant.LOAD_HISTORY_START
);

export const loadHistorySucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_HISTORY_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadHistoryFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_HISTORY_FAILED,
  props<{ error: any }>()
);

export const uploadAttachmentStartTaskAction = createAction(
  TaskDetailsConstant.UPLOAD_ATTACHMENT_START
);

export const uploadAttachmentSucceededTaskAction = createAction(
  TaskDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const uploadAttachmentFailedTaskAction = createAction(
  TaskDetailsConstant.UPLOAD_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const deleteAttachmentStartTaskAction = createAction(
  TaskDetailsConstant.DELETE_ATTACHMENT_START
);

export const deleteAttachmentSucceededTaskAction = createAction(
  TaskDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const deleteAttachmentFailedTaskAction = createAction(
  TaskDetailsConstant.DELETE_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const updateAttachmentStartTaskAction = createAction(
  TaskDetailsConstant.UPDATE_ATTACHMENT_START
);

export const updateAttachmentSucceededTaskAction = createAction(
  TaskDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const updateAttachmentFailedTaskAction = createAction(
  TaskDetailsConstant.UPDATE_ATTACHMENT_FAILED,
  props<{ error: any }>()
);


export const loadCommentStartTaskAction = createAction(
  TaskDetailsConstant.LOAD_COMMENT_START
);

export const loadCommentSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_COMMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadCommentFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_COMMENT_FAILED,
  props<{ error: any }>()
);

export const addCommentStartTaskAction = createAction(
  TaskDetailsConstant.ADD_COMMENT_START
);

export const addCommentSucceededTaskAction = createAction(
  TaskDetailsConstant.ADD_COMMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const addCommentFailedTaskAction = createAction(
  TaskDetailsConstant.ADD_COMMENT_FAILED,
  props<{ error: any }>()
);

export const updateCommentStartTaskAction = createAction(
  TaskDetailsConstant.UPDATE_COMMENT_START
);

export const updateCommentSucceededTaskAction = createAction(
  TaskDetailsConstant.UPDATE_COMMENT_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const updateCommentFailedTaskAction = createAction(
  TaskDetailsConstant.UPDATE_COMMENT_FAILED,
  props<{ error: any }>()
);

export const deleteCommentStartTaskAction = createAction(
  TaskDetailsConstant.DELETE_COMMENT_START
);

// export const deleteCommentSucceededTaskAction = createAction(
//   TaskDetailsConstant.DELETE_COMMENT_SUCCEEDED,
//   props<{ payload: TaskDetails }>()
// );

export const deleteCommentFailedTaskAction = createAction(
  TaskDetailsConstant.DELETE_COMMENT_FAILED,
  props<{ error: any }>()
);

export const addReplyStartTaskAction = createAction(
  TaskDetailsConstant.ADD_REPLY_START
);

export const addReplySucceededTaskAction = createAction(
  TaskDetailsConstant.ADD_REPLY_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const addReplyFailedTaskAction = createAction(
  TaskDetailsConstant.ADD_REPLY_FAILED,
  props<{ error: any }>()
);

export const updateReplyStartTaskAction = createAction(
  TaskDetailsConstant.UPDATE_REPLY_START
);

export const updateReplySucceededTaskAction = createAction(
  TaskDetailsConstant.UPDATE_REPLY_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const updateReplyFailedTaskAction = createAction(
  TaskDetailsConstant.UPDATE_REPLY_FAILED,
  props<{ error: any }>()
);

export const deleteReplyStartTaskAction = createAction(
  TaskDetailsConstant.DELETE_REPLY_START
);

export const deleteReplySucceededTaskAction = createAction(
  TaskDetailsConstant.DELETE_REPLY_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const deleteReplyFailedTaskAction = createAction(
  TaskDetailsConstant.DELETE_REPLY_FAILED,
  props<{ error: any }>()
);

export const removeAllSucceededTaskAction = createAction(
  TaskDetailsConstant.REMOVE_ALL_SUCCEEDED
);

export const updateTokenTaskAction = createAction(
  TaskDetailsConstant.UPDATE_TOKEN,
  props<{ payload: TaskDetails }>()
);

export const loadDisplayBlockSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadCurrentActivityDiffStartTaskAction = createAction(
  TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START
);

export const loadCurrentActivityDiffSucceededTaskAction = createAction(
  TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED,
  props<{ payload: TaskDetails }>()
);

export const loadCurrentActivityDiffFailedTaskAction = createAction(
  TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED,
  props<{ error: any }>()
);


@Injectable({
  providedIn: 'root'
})
export class TaskDetailsAction extends AbstractDetailsAction {

  constructor(
    translateService: TranslateService,
    toastaService: ToastaService,
    store: Store
  ) {
    super(translateService, toastaService, store);
  }

  public loadDetailsStart(): void {
    this._dispatchType(loadDetailsStartAction());
  }

  public loadNexiaObjectDetailsStart(): void {
    this._dispatchType(loadTaskDetailsStartAction);
  }

  public loadCurrentActivityDiffStart(): void {
    this._dispatchType(loadCurrentActivityDiffStartTaskAction());
  }

  public loadDetailsSucceeded(cdxTask: CdxTask, comments: CdxComment[], attachments: CdxAttachment[], activities: EsPage<CdxActivity>): void {
    if (!cdxTask && !comments && !attachments && !activities) {
      this.loadDetailsFailed('tasks, comments, attachments and activities cannot be null or undefined');
      return;
    }
    const currentTaskDetails: TaskDetails = {
      details: cdxTask,
      comments: comments,
      attachments: attachments,
      activities: activities,
      token: cdxTask.token,
      currentActivityDiff: new ActivityDiff()
    };
    this._dispatchSuccess(loadDetailsSucceededTaskAction({payload: currentTaskDetails}));
  }

  public loadNexiaObjectDetailsSucceeded(updateDetailsData: CdxTask, loadPdf = false): void {
    const payload = new TaskDetails();
    payload.details = updateDetailsData;
    payload.loadPdf = loadPdf;
    this._dispatchSuccess(loadTaskDetailsSucceededTaskAction({payload}));
  }

  public loadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    const payload = new TaskDetails();
    payload.currentActivityDiff = currentActivityDiff;
    this._dispatchSuccess(loadCurrentActivityDiffSucceededTaskAction({payload}));
  }

  public loadDetailsFailed(error: any): void {
    this._dispatchFail(loadDetailsFailedTaskAction({error}));
  }

  public loadNexiaObjectDetailsFailed(error: any): void {
    this._dispatchFail(loadTaskDetailsFailedTaskAction({error}));
  }

  public loadCurrentActivityDiffFailed(error: any): void {
    this._dispatchFail(loadCurrentActivityDiffFailedTaskAction({error}));
  }

  public addCommentsStart(): void {
    this._dispatchType(addCommentStartTaskAction());
  }

  public addCommentsFailed(error: any): void {
    this._dispatchFail(addCommentFailedTaskAction({error}));
  }

  public updateCommentsStart(): void {
    this._dispatchType(updateCommentStartTaskAction());
  }

  public updateCommentsFailed(error: any): void {
    this._dispatchFail(updateCommentFailedTaskAction({error}));
  }

  public loadCommentStart(): void {
    this._dispatchType(loadCommentStartTaskAction());
  }

  public loadCommentSucceeded(comment: CdxComment): void {
    if (!comment) {
      this.loadCommentFailed('Comment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.comments = [comment];
    this._dispatchSuccess(loadCommentSucceededTaskAction({payload}));
  }

  public loadCommentFailed(error: any): void {
    this._dispatchFail(loadCommentFailedTaskAction({error}));
  }

  public deleteCommentStart(): void {
    this._dispatchType(deleteCommentStartTaskAction());
  }

  public deleteCommentFailed(error: any): void {
    this._dispatchFail(deleteCommentFailedTaskAction({error}));
  }

  public addReplyStart(): void {
    this._dispatchType(addReplyStartTaskAction());
  }

  public addReplyFailed(error: any): void {
    this._dispatchFail(addReplyFailedTaskAction({error}));
  }

  public updateReplyStart(): void {
    this._dispatchType(updateReplyStartTaskAction());
  }

  public updateReplyFailed(error: any): void {
    this._dispatchFail(updateReplyFailedTaskAction({error}));
  }

  public deleteReplyStart(): void {
    this._dispatchType(deleteReplyStartTaskAction());
  }

  public deleteReplyFailed(error: any): void {
    this._dispatchFail(deleteReplyFailedTaskAction({error}));
  }

  public loadHistoryStart(): void {
    this._dispatchType(loadHistoryStartTaskAction());
  }

  public loadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    if (!activities) {
      this.loadHistoryFailed('History cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.activities = activities;
    this._dispatchSuccess(loadHistorySucceededTaskAction({payload}));
  }

  public loadHistoryFailed(error: any): void {
    this._dispatchFail(loadHistoryFailedTaskAction({error}));
  }

  public loadAttachmentStart(): void {
    this._dispatchType(loadAttachmentStartTaskAction());
  }

  public loadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string = null): void {
    if (!attachment) {
      this.loadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    if (tempAttachmentId) {
      const attachmentToBeChanged: CdxAttachment = new CdxAttachment();
      attachmentToBeChanged.cdx_id = tempAttachmentId;
      payload.attachments.push(attachmentToBeChanged);
    }
    this._dispatchSuccess(loadAttachmentSucceededTaskAction({payload}));
  }

  public loadAttachmentFailed(error: any): void {
    this._dispatchFail(loadAttachmentFailedTaskAction({error}));
  }

  public uploadAttachmentStart(): void {
    this._dispatchType(uploadAttachmentStartTaskAction());
  }

  public uploadAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.uploadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(uploadAttachmentSucceededTaskAction({payload}));
  }

  public uploadAttachmentFailed(error: any): void {
    this._dispatchFail(uploadAttachmentFailedTaskAction({error}));
  }

  public deleteAttachmentStart(): void {
    this._dispatchType(deleteAttachmentStartTaskAction());
  }

  public deleteAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.deleteAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(deleteAttachmentSucceededTaskAction({payload}));
  }

  public deleteAttachmentFailed(error: any): void {
    this._dispatchFail(deleteAttachmentFailedTaskAction({error}));
  }

  public updateAttachmentStart(): void {
    this._dispatchType(updateAttachmentStartTaskAction());
  }

  public updateAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.updateAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(updateAttachmentSucceededTaskAction({payload}));
  }

  public updateAttachmentFailed(error: any): void {
    this._dispatchFail(updateAttachmentFailedTaskAction({error}));
  }

  public removeAll(): void {
    this._dispatchType(removeAllSucceededTaskAction());
  }

  public updateToken(headers: HttpHeaders): void {
    const token = headers.get('Object-Authorization');
    if (Utils.notNullAndNotUndefined(token)) {
      const payload = new TaskDetails();
      payload.token = token;
      this._dispatchSuccess(updateTokenTaskAction({payload}));
    }
  }

  public showHideInfoBlockSucceeded(isVisible: boolean): void {
    const payload = new TaskDetails();
    payload.displayInfoBlock = isVisible;
    this._dispatchSuccess(loadDisplayBlockSucceededTaskAction({payload}));
  }
}
