import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {Aggregate} from '../../../../models/aggregate';
import {DraftDocument} from '../../../../models/DraftDocument';
import {EsPage} from '../../../../models/es-page';

export class ListIndexationConstant {
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_LIST_INDEXATION_START = '[ListIndexation].LOAD_LIST_INDEXATION_START';
  static readonly LOAD_LIST_INDEXATION_SUCCEEDED = '[ListIndexation].LOAD_LIST_INDEXATION_SUCCEEDED';
  static readonly LOAD_LIST_INDEXATION_FAILED = '[ListIndexation].LOAD_LIST_INDEXATION_FAILED';

  static readonly LOAD_AGGREGATE_START = '[ListIndexation]: LOAD_AGGREGATE_START';
  static readonly LOAD_AGGREGATE_SUCCEEDED = '[ListIndexation]: LOAD_AGGREGATE_SUCCEEDED';
  static readonly LOAD_AGGREGATE_FAILED = '[ListIndexation]: LOAD_AGGREGATE_FAILED';
}

export const loadListIndexationStartedAction = createAction(
  ListIndexationConstant.LOAD_LIST_INDEXATION_START
);

export const loadListIndexationSucceededAction = createAction(
  ListIndexationConstant.LOAD_LIST_INDEXATION_SUCCEEDED,
  props<{ payload: EsPage<DraftDocument> }>()
);

export const loadListIndexationFailedAction = createAction(
  ListIndexationConstant.LOAD_LIST_INDEXATION_FAILED,
  props<{ error: Error }>()
);

export const loadAggregateStartAction = createAction(
  ListIndexationConstant.LOAD_AGGREGATE_START
);

export const loadAggregateFailedAction = createAction(
  ListIndexationConstant.LOAD_AGGREGATE_FAILED,
  props<{ error: Error }>()
);

export const loadAggregateSucceededAction = createAction(
  ListIndexationConstant.LOAD_AGGREGATE_SUCCEEDED,
  props<{ payload: EsPage<DraftDocument> }>()
);


@Injectable({
  providedIn: 'root'
})
export class ListIndexationAction {
  constructor(private localStore: Store) {
  }

  public loadStarted(): void {
    this.localStore.dispatch(loadListIndexationStartedAction());
  }

  public loadSucceeded(page: EsPage<DraftDocument>): void {
    this.localStore.dispatch(loadListIndexationSucceededAction({payload: page}));
  }

  public loadFailed(error: Error) {
    this.localStore.dispatch(loadListIndexationFailedAction({error}));
  }

  public loadAggregateStart(): void {
    this.localStore.dispatch(loadAggregateStartAction());
  }

  public loadAggregateFailed(error: Error): void {
    this.localStore.dispatch(loadAggregateFailedAction({error}));
  }

  public loadAggregateSucceeded(agg: Aggregate): void {
    const page: EsPage<DraftDocument> = new EsPage<DraftDocument>(0, [], 0, 0, [agg], false);
    this.localStore.dispatch(loadAggregateSucceededAction({payload: page}));
  }
}
