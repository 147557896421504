

export class TaskDetailsConstant {

  static readonly LOAD_DETAILS_START = '[TaskDetails].LOAD_DETAILS_START';
  static readonly LOAD_DETAILS_SUCCEEDED = '[TaskDetails].LOAD_DETAILS_SUCCEEDED';
  static readonly LOAD_DETAILS_FAILED = '[TaskDetails].LOAD_DETAILS_FAILED';

  static readonly LOAD_TASK_DETAILS_START = '[TaskDetails].LOAD_TASK_DETAILS_START';
  static readonly LOAD_TASK_DETAILS_SUCCEEDED = '[TaskDetails].LOAD_TASK_DETAILS_SUCCEEDED';
  static readonly LOAD_TASK_DETAILS_FAILED = '[TaskDetails].LOAD_TASK_DETAILS_FAILED';

  static readonly LOAD_ATTACHMENT_START = '[TaskDetails].LOAD_ATTACHMENT_START';
  static readonly LOAD_ATTACHMENT_SUCCEEDED = '[TaskDetails].LOAD_ATTACHMENT_SUCCEEDED';
  static readonly LOAD_ATTACHMENT_FAILED = '[TaskDetails].LOAD_ATTACHMENT_FAILED';

  static readonly LOAD_HISTORY_START = '[TaskDetails].LOAD_HISTORY_START';
  static readonly LOAD_HISTORY_SUCCEEDED = '[TaskDetails].LOAD_HISTORY_SUCCEEDED';
  static readonly LOAD_HISTORY_FAILED = '[TaskDetails].LOAD_HISTORY_FAILED';

  static readonly UPLOAD_ATTACHMENT_START = '[TaskDetails].UPLOAD_ATTACHMENT_START';
  static readonly UPLOAD_ATTACHMENT_SUCCEEDED = '[TaskDetails].UPLOAD_ATTACHMENT_SUCCEEDED';
  static readonly UPLOAD_ATTACHMENT_FAILED = '[TaskDetails].UPLOAD_ATTACHMENT_FAILED';

  static readonly DELETE_ATTACHMENT_START = '[TaskDetails].DELETE_ATTACHMENT_START';
  static readonly DELETE_ATTACHMENT_SUCCEEDED = '[TaskDetails].DELETE_ATTACHMENT_SUCCEEDED';
  static readonly DELETE_ATTACHMENT_FAILED = '[TaskDetails].DELETE_ATTACHMENT_FAILED';

  static readonly UPDATE_ATTACHMENT_START = '[TaskDetails].UPDATE_ATTACHMENT_START';
  static readonly UPDATE_ATTACHMENT_SUCCEEDED = '[TaskDetails].UPDATE_ATTACHMENT_SUCCEEDED';
  static readonly UPDATE_ATTACHMENT_FAILED = '[TaskDetails].UPDATE_ATTACHMENT_FAILED';

  static readonly LOAD_COMMENT_START = '[TaskDetails].LOAD_COMMENT_START';
  static readonly LOAD_COMMENT_SUCCEEDED = '[TaskDetails].LOAD_COMMENT_SUCCEEDED';
  static readonly LOAD_COMMENT_FAILED = '[TaskDetails].LOAD_COMMENT_FAILED';

  static readonly ADD_COMMENT_START = '[TaskDetails].ADD_COMMENT_START';
  static readonly ADD_COMMENT_SUCCEEDED = '[TaskDetails].ADD_COMMENT_SUCCEEDED';
  static readonly ADD_COMMENT_FAILED = '[TaskDetails].ADD_COMMENT_FAILED';

  static readonly UPDATE_COMMENT_START = '[TaskDetails].UPDATE_COMMENT_START';
  static readonly UPDATE_COMMENT_SUCCEEDED = '[TaskDetails].UPDATE_COMMENT_SUCCEEDED';
  static readonly UPDATE_COMMENT_FAILED = '[TaskDetails].UPDATE_COMMENT_FAILED';

  static readonly DELETE_COMMENT_START = '[TaskDetails].DELETE_COMMENT_START';
  // static readonly DELETE_COMMENT_SUCCEEDED = '[DocumentDetails].DELETE_COMMENT_SUCCEEDED';
  static readonly DELETE_COMMENT_FAILED = '[TaskDetails].DELETE_COMMENT_FAILED';

  static readonly ADD_REPLY_START = '[TaskDetails].ADD_REPLY_START';
  static readonly ADD_REPLY_SUCCEEDED = '[TaskDetails].ADD_REPLY_SUCCEEDED';
  static readonly ADD_REPLY_FAILED = '[TaskDetails].ADD_REPLY_FAILED';

  static readonly UPDATE_REPLY_START = '[TaskDetails].UPDATE_REPLY_START';
  static readonly UPDATE_REPLY_SUCCEEDED = '[TaskDetails].UPDATE_REPLY_SUCCEEDED';
  static readonly UPDATE_REPLY_FAILED = '[TaskDetails].UPDATE_REPLY_FAILED';

  static readonly DELETE_REPLY_START = '[TaskDetails].DELETE_REPLY_START';
  static readonly DELETE_REPLY_SUCCEEDED = '[TaskDetails].DELETE_REPLY_SUCCEEDED';
  static readonly DELETE_REPLY_FAILED = '[TaskDetails].DELETE_REPLY_FAILED';

  static readonly REMOVE_ALL_SUCCEEDED = '[TaskDetails].REMOVE_ALL_SUCCEEDED';

  static readonly UPDATE_TOKEN = '[TaskDetails].UPDATE_TOKEN';

  static readonly LOAD_DISPLAY_BLOCK_SUCCEEDED = '[TaskDetails].LOAD_DISPLAY_BLOCK_SUCCEEDED';

  static readonly LOAD_CURRENT_ACTIVITY_DIFF_START = '[TaskDetails].LOAD_CURRENT_ACTIVITY_DIFF_START';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED = '[TaskDetails].LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_FAILED = '[TaskDetails].LOAD_CURRENT_ACTIVITY_DIFF_FAILED';
}
