import {Injectable} from '@angular/core';
import {ObjectType} from '../../../models/ObjectType';
import {SearchResultPagination} from '../../../models/search-result-pagination';
import {SearchResultPaginationAction} from '../action/search-result-pagination.action';

@Injectable({
  providedIn: 'root'
})
export class SearchResultPaginationService {
  constructor(
    private searchResultActionPagination: SearchResultPaginationAction,
  ) {
  }

  public reinitSearchResultPagination(): void {
    this.searchResultActionPagination.reinitSearchResultPagination(SearchResultPagination.getInitStore());
  }

  public update(copystore: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }) {
    this.searchResultActionPagination.updateSearchResultPagination(copystore);
  }

}
