import { Field } from '../../../../models/field';

export interface WkfFieldState {
  datas: {[key: string]: Field};
  loading: boolean;
  error: any;
}

export const WKF_CFG_INITIAL_STATE: WkfFieldState = {
  datas: {},
  loading: false,
  error: null,
};


import { createReducer, on } from '@ngrx/store';
import {
  wkfAddMultipleCfgFieldFailedAction,
  wkfAddMultipleCfgFieldStartAction,
  wkfAddMultipleCfgFieldSucceededAction,
  wkfRemoveAllCfgFieldFailedAction,
  wkfRemoveAllCfgFieldStartAction,
  wkfRemoveAllCfgFieldSucceededAction
} from '../../action/const-field-actions';

export const wkfCfgFieldReducer = createReducer(
  WKF_CFG_INITIAL_STATE,
  on(wkfAddMultipleCfgFieldStartAction, wkfRemoveAllCfgFieldStartAction, ( state) => ({
    ...state,
    loading: true,
    error: null,

  })),
  on(wkfAddMultipleCfgFieldSucceededAction, ( state, { payload }) => ({
    ...state,
    datas: { ...state.datas, ...payload },
    loading: false,
    error: null,
  })),
  on(wkfRemoveAllCfgFieldSucceededAction, ( state) => ({
    ...state,
    datas: {},
    loading: false,
    error: null,
  })),
  on(wkfAddMultipleCfgFieldFailedAction, wkfRemoveAllCfgFieldFailedAction, ( state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
