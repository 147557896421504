import {DraftDatas} from '../../../../models/DraftDatas';

import {PayloadAction} from '../../../../../redux/payload-action';
import {
  addDraftOptionsAction,
  DraftDatasConstant,
  loadDraftDatasFailedAction,
  loadDraftDatasStartedAction,
  loadDraftDatasSucceededAction,
  removeDraftDatasSucceededAction,
  removeDraftOptionsAction
} from '../action/draft-datas.action';
import {createReducer, on} from '@ngrx/store';

export class DraftDatasState {
  datas: DraftDatas;
  loading: boolean;
  error: any;
}

export const CURRENT_DRAFT_DATAS_INITIAL_STATE: DraftDatasState = {
  datas: null,
  loading: false,
  error: null,
};


export const draftDatasReducerOld = (state: DraftDatasState = CURRENT_DRAFT_DATAS_INITIAL_STATE, action: PayloadAction<DraftDatas>) => {
  switch (action.type) {
    // draft datas
    case DraftDatasConstant.LOAD_DRAFT_DATAS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED:
    //   let exists = false;
    //   if (action.payload.id === undefined || state.datas.id === action.payload.id) {
    //     exists = true;
    //   }
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       id: exists ? state.datas.id : action.payload.id,
    //       isLastStep: action.payload.isLastStep !== undefined ? action.payload.isLastStep : state.datas.isLastStep
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case DraftDatasConstant.LOAD_DRAFT_DATAS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DraftDatasConstant.REMOVE_DRAFT_DATAS_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
    // // draft document
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_START:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_SUCCEEDED:
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       draftDocument: action.payload.draftDocument
    //     },
    //     loading: true,
    //     error: null,
    //   };
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_FAILED:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };
    // options :
    case DraftDatasConstant.ADD_DRAFT_OPTIONS:
      return {
        ...state,
        datas: {
          ...state.datas,
          options: action.payload.options,
          state: action.payload.state
        },
        loading: false,
        error: null,
      };
    case DraftDatasConstant.REMOVE_DRAFT_OPTIONS:
      return {
        ...state,
        datas: {
          ...state.datas,
          options: action.payload.options,
          state: action.payload.state
        },
        loading: false,
        error: null,
      };
  }
  return state;
};

export const draftDatasReducer = createReducer(
  // Initial State
  CURRENT_DRAFT_DATAS_INITIAL_STATE,

  // Start Action
  on(loadDraftDatasStartedAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Succeeded Action
  on(loadDraftDatasSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  // Failed Action
  on(loadDraftDatasFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  // Remove Succeeded Action
  on(removeDraftDatasSucceededAction, (state) => ({
    ...state,
    datas: null,
    loading: false,
    error: null,
  })),

  // Add Draft Options Action
  on(addDraftOptionsAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      options: payload.options,
      state: payload.state
    },
    loading: false,
    error: null,
  })),

  // Remove Draft Options Action
  on(removeDraftOptionsAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      options: payload.options,
      state: payload.state
    },
    loading: false,
    error: null,
  }))
);
