import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {taskSearchResultReducer} from './reducer/task-search-result.reducer';

export const taskSearchResultStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TASK_SEARCH_RESULT,
    reducer: taskSearchResultReducer
  })
);
