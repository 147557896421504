import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';


export class CurrentContextActionConstant {
  static readonly LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED';
  static readonly LOAD_CURRENT_ENTITY_TYPE_FAILED = '[CurrentContext]: LOAD_CURRENT_ENTITY_TYPE_FAILED';
  static readonly REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED';
  static readonly LOAD_CURRENT_DOMAIN_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_DOMAIN_SUCCEEDED';
  static readonly LOAD_CURRENT_DOMAIN_FAILED = '[CurrentContext]: LOAD_CURRENT_DOMAIN__FAILED';
  static readonly REMOVE_CURRENT_DOMAIN_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_DOMAIN__SUCCEEDED';
  static readonly LOAD_CURRENT_MODULE_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_MODULE_SUCCEEDED';
  static readonly LOAD_CURRENT_MODULE_FAILED = '[CurrentContext]: LOAD_CURRENT_MODULE_FAILED';
  static readonly REMOVE_CURRENT_MODULE_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_MODULE_SUCCEEDED';
}

export const loadCurrentEntityTypeSucceededAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED, props<{
  payload: string
}>());
export const loadCurrentEntityTypeFailedAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_FAILED);
export const removeCurrentEntityTypeSucceededAction = createAction(CurrentContextActionConstant.REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED);
export const removeCurrentDomainSucceededAction = createAction(CurrentContextActionConstant.REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED);
export const removeCurrentModuleSucceededAction = createAction(CurrentContextActionConstant.REMOVE_CURRENT_MODULE_SUCCEEDED);
export const loadCurrentDomainFailedAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_FAILED);
export const loadCurrentDomainSucceededAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_SUCCEEDED, props<{
  payload: string
}>());
export const loadCurrentModuleFailedAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_MODULE_FAILED);
export const loadCurrentModuleSucceededAction = createAction(CurrentContextActionConstant.LOAD_CURRENT_MODULE_SUCCEEDED, props<{
  payload: string
}>());

@Injectable({
  providedIn: 'root'
})
export class CurrentContextAction {

  constructor(private store: Store) {
  }

  public loadCurrentEntityType(entityType: string): void {
    if (!entityType) {
      this.store.dispatch(loadCurrentEntityTypeFailedAction());
    }
    this.store.dispatch(loadCurrentEntityTypeSucceededAction({payload: entityType}));
  }

  public loadCurrentDomain(currentDomain: string): void {
    if (!currentDomain) {
      this.store.dispatch(loadCurrentDomainFailedAction());
    }
    this.store.dispatch(loadCurrentDomainSucceededAction({payload: currentDomain}));
  }

  public loadCurrentModule(currentModule: string): void {
    if (!currentModule) {
      return this.store.dispatch(loadCurrentModuleFailedAction());
    }
    return this.store.dispatch(loadCurrentModuleSucceededAction({payload: currentModule}));
  }

  public removeCurrentEntityType(): void {
    this.store.dispatch(removeCurrentEntityTypeSucceededAction());
  }

  public removeCurrentDomain(): void {
    this.store.dispatch(removeCurrentDomainSucceededAction());
  }

  public removeCurrentModule() {
    this.store.dispatch(removeCurrentModuleSucceededAction());
  }
}
