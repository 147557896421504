import { Aggregate } from '../../../../models/aggregate';
import { EsPage } from '../../../../models/es-page';
import { CdxTask } from '../../../../models/cdx-task';
import {
  loadAggregateFailedAction,
  loadAggregateStartAction,
  loadAggregateSucceededAction,
  loadResultItemsFailedAction,
  loadResultItemsStartAction,
  loadResultItemsSucceededAction,
  TaskSearchResultConstantAction,
  updateTasksFailedAction,
  updateTasksStartAction,
  updateTasksSucceededAction
} from '../action/task-search-result.action';

import { createReducer, on } from '@ngrx/store';

export const TASK_SEARCH_RESULT_INITIAL_STATE: TaskSearchResultState = {
  datas: null,
  loading: false,
  loadAgg: false,
  error: null,
};

export interface TaskSearchResultState {
  datas: EsPage<CdxTask>;
  loading: boolean;
  loadAgg: boolean;
  error: any;
}


/*
export const taskSearchResultReducerOld = ( state: TaskSearchResultState = TASK_SEARCH_RESULT_INITIAL_STATE, action: PayloadAction<EsPage<CdxTask>>) => {
  switch (action.type) {
    case TaskSearchResultConstantAction.LOAD_AGGREGATE_START:
      return {
        ...state,
        loading: true,
        loadAgg: true,
        error: TaskSearchResultConstantAction.ERROR_START,
      };
    case TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_START:
    case TaskSearchResultConstantAction.UPDATE_TASKS_START:
      return {
        ...state,
        loading: true,
        error: TaskSearchResultConstantAction.ERROR_START,
      };
    case TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case TaskSearchResultConstantAction.LOAD_RESULT_ITEMS_FAILED:
      return {
        ...state,
        datas: undefined,
        loading: false,
        error: action.error,
      };
    case TaskSearchResultConstantAction.LOAD_AGGREGATE_SUCCEEDED:
      const newAgg: Aggregate = action.payload.aggs[0];
      let aggs: Aggregate[] = state.datas.aggs;
      let added = false;
      aggs = aggs.map(agg => {
        if (agg.path === newAgg.path) {
          added = true;
          return newAgg;
        }
        return agg;
      });
      if (!added) {
        aggs.push(newAgg);
      }
      const copy = {...state.datas, aggs: aggs};
      return {
        ...state,
        datas: copy,
        loading: false,
        loadAgg: false,
        error: null,
      };
    case TaskSearchResultConstantAction.LOAD_AGGREGATE_FAILED:
      return {
        ...state,
        loading: false,
        loadAgg: false,
        error: action.error,
      };
    case TaskSearchResultConstantAction.UPDATE_TASKS_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas,
          content: action.payload.content,
          numberOfElements: action.payload.numberOfElements,
          size: action.payload.size,
          page: action.payload.page
        },
        loading: false,
        error: null,
      };
    case TaskSearchResultConstantAction.UPDATE_TASKS_FAILED:
      return {
        ...state,
        loading: true,
        error: action.error,
      };
  }
  return state;
};

*/


export const taskSearchResultReducer = createReducer(
  TASK_SEARCH_RESULT_INITIAL_STATE,
  on(loadResultItemsStartAction, (state) => ({
    ...state,
    loading: true,
    error: TaskSearchResultConstantAction.ERROR_START,
  }) ),
  on(loadResultItemsSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  }) ),
  on(loadResultItemsFailedAction, (state, {error}) => ({
    ...state,
    datas: undefined,
    loading: false,
    error: error,
  }) ),
  on(loadAggregateStartAction, (state) => ({
    ...state,
    loading: true,
    loadAgg: true,
    error: TaskSearchResultConstantAction.ERROR_START,
  }) ),
  on(loadAggregateSucceededAction, (state, {payload}) => {
    const newAgg: Aggregate = payload.aggs[0];
    let aggs: Aggregate[] = state.datas.aggs;
    let added = false;
    aggs = aggs.map(agg => {
      if (agg.path === newAgg.path) {
        added = true;
        return newAgg;
      }
      return agg;
    });
    if (!added) {
      aggs.push(newAgg);
    }
    const copy = {...state.datas, aggs: aggs};

    return (
    {
    ...state,
    datas: copy,
    loading: false,
    loadAgg: false,
    error: null,
  })} ),
  on(loadAggregateFailedAction, (state, {error} ) => ({
    ...state,
    loading: false,
    loadAgg: false,
    error: error,
  }) ),
  on(updateTasksStartAction, (state) => ({
    ...state,
    loading: true,
    error: TaskSearchResultConstantAction.ERROR_START,
  }) ),
  on(updateTasksSucceededAction, (state, {payload}) => ({

    ...state,
    datas: {...state.datas,
      content: payload.content,
      numberOfElements: payload.numberOfElements,
      size: payload.size,
      page: payload.page
    },
    loading: false,
    error: null,
  }) ),
  on(updateTasksFailedAction, (state, {error}) => ({
    ...state,
    loading: true,
    error: error,
  }) ),
);
