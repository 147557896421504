import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {domainReducer} from './reducer/domain.reducer';
import {withSessionStoragePersistence} from "../../../redux/reducer-utils/with-session-storage-persistence";


export const domainStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.DOMAINS,
    reducer: withSessionStoragePersistence(StoreKeys.DOMAINS, domainReducer)
  })
);
