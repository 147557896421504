import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {gedCfgFieldReducer} from './reducer/ged/ged-cfg-field.reducer';
import {wkfCfgFieldReducer} from './reducer/wkf/wkf-cfg-field.reducer';
import {withSessionStoragePersistence} from "../../../redux/reducer-utils/with-session-storage-persistence";


export const gedCfgFieldStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.GED_CFG_FIELD,
    reducer: withSessionStoragePersistence(StoreKeys.GED_CFG_FIELD, gedCfgFieldReducer)
  })
);

export const wkfCfgFieldStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.WKF_CFG_FIELD,
    reducer: withSessionStoragePersistence(StoreKeys.WKF_CFG_FIELD, wkfCfgFieldReducer)
  })
);
