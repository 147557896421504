import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {filterReducer} from './document-filter/reducer/filter.reducer';
import {searchItemsReducer} from './document-search-item/reducer/search-items.reducer';
import {documentSearchResultReducer} from './document-search-result/reducer/document-search-result.reducer';


export const documentFilterStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.FILTERS,
    reducer: filterReducer
  })
);

export const documentSearchItemStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.SEARCH_ITEMS,
    reducer: searchItemsReducer
  })
);

export const documentSearchResultStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.DOCUMENT_SEARCH_RESULT,
    reducer: documentSearchResultReducer
  })
);
