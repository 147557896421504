import { createReducer, on } from '@ngrx/store';
import { PayloadAction } from '../../../../redux/payload-action';
import { View } from '../../../models/view';
import {
  ViewActionConstant,
  createActionAddViewStart,
  createActionAddViewSucceeded,
  createActionAddViewFailed,
  createActionRemoveAllViewsStart,
  createActionRemoveAllViewsSucceeded,
  createActionRemoveAllViewsFailed
} from '../action/view.action';


export interface ViewState {
  datas: {[key: string]: View};
  loading: boolean;
  error: any;
}

export const VIEW_INITIAL_STATE: ViewState = {
  datas: {},
  loading: false,
  error: null,
};


export const viewReducerOld = (state: ViewState = VIEW_INITIAL_STATE, action: PayloadAction<{[key: string]: View}>): ViewState => {
  switch (action.type) {
    // ADD MULTIPLE
    case ViewActionConstant.ADD_VIEW_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ViewActionConstant.ADD_VIEW_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case ViewActionConstant.ADD_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // REMOVE ALL
      case ViewActionConstant.REMOVE_ALL_VIEWS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ViewActionConstant.REMOVE_ALL_VIEWS_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case ViewActionConstant.REMOVE_ALL_VIEWS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const viewReducer = createReducer(
  VIEW_INITIAL_STATE,
  on(createActionAddViewStart,(state) =>({
  ...state,
      loading: true,
      error: null,
  })),
  on(createActionAddViewSucceeded,(state, {payload} )=>({
    ...state,
    datas: {...state.datas, ...payload},
    loading: false,
    error: null,
  })),
  on(createActionAddViewFailed,(state, {error}) =>({
    ...state,
    loading: false,
    error: error,
  })),
  on(createActionRemoveAllViewsStart,(state) =>({
    ...state,
    loading: true,
    error: null,
  })),
  on(createActionRemoveAllViewsSucceeded,(state) =>({
    ...state,
    datas: {},
    loading: false,
    error: null,
  })),
  on(createActionRemoveAllViewsFailed, (state, {error})=>({
    ...state,
    loading: false,
    error: error,
  }))
);
