import { PayloadAction } from '../../../../../redux/payload-action';
import { TeamUser } from '../../../../models/team-user';
import { Page } from '../../../../models/page';
import {
  CurrentTeamUsersPageConstantAction,
  loadCurrentTeamUsersPageFailedAction,
  loadCurrentTeamUsersPageStartAction,
  loadCurrentTeamUsersPageSuceededAction
} from '../action/current-team-users-page.action';
import { createReducer, on } from '@ngrx/store';

export class CurrentTeamUsersPageState {
  datas: Page<TeamUser>;
  loading: boolean;
  error: any;
}

export const CURRENT_TEAM_USERS_PAGE_INITIAL_STATE: CurrentTeamUsersPageState = {
  datas: null,
  loading: false,
  error: null,
};



export const currentTeamUsersPageReducerOld = (state: CurrentTeamUsersPageState = CURRENT_TEAM_USERS_PAGE_INITIAL_STATE, action: PayloadAction<Page<TeamUser>>) => {
  switch (action.type) {
    case CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CurrentTeamUsersPageConstantAction.LOAD_CURRENT_TEAM_USERS_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};




export const currentTeamUsersPageReducer = createReducer(
  CURRENT_TEAM_USERS_PAGE_INITIAL_STATE,
  on(loadCurrentTeamUsersPageStartAction,(state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCurrentTeamUsersPageSuceededAction,(state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(loadCurrentTeamUsersPageFailedAction,(state,{error}) => ({
    ...state,
    loading: false,
    error: error,
  }))
);
