import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {DomainItem} from '../../../models/domain-item';

export class DomainActionConstant {
  static readonly LOAD_DOMAIN_ITEMS_START = 'LOAD_DOMAIN_ITEMS_START';
  static readonly LOAD_DOMAIN_ITEMS_SUCCEEDED = 'LOAD_DOMAIN_ITEMS_SUCCEEDED';
  static readonly LOAD_DOMAIN_ITEMS_FAILED = 'LOAD_DOMAIN_ITEMS_FAILED';
}


export const loadDomainItemsStartAction = createAction(DomainActionConstant.LOAD_DOMAIN_ITEMS_START);
export const loadDomainItemsSucceededAction = createAction(DomainActionConstant.LOAD_DOMAIN_ITEMS_SUCCEEDED, props<{ payload: DomainItem[] }>());
export const loadDomainItemsFailedAction = createAction(DomainActionConstant.LOAD_DOMAIN_ITEMS_FAILED, props<{ error: Error }>());

@Injectable({
  providedIn: 'root'
})
export class DomainAction {

  constructor(private store: Store) {
  }

  public loadWorkspaceStarted(): void {
    this.store.dispatch(loadDomainItemsStartAction());
  }


  public loadWorkspaceSucceeded(payload: DomainItem[]): void {
    this.store.dispatch(loadDomainItemsSucceededAction({payload}));
  }

  public loadWorkspaceFailed(error: any): void {
    this.store.dispatch(loadDomainItemsFailedAction({error}));
  }

}

