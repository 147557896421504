import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {cacheFormReducer} from './cache/reducer/cache-form.reducer';


export const formStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CACHE_FORM,
    reducer: cacheFormReducer
  })
);
