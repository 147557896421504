/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, ElementRef, HostListener, Input, ComponentFactoryResolver, EmbeddedViewRef,
  ApplicationRef, Injector, ComponentRef, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
  import { TooltipComponent } from './tooltip.component';
  import { defaultOptions } from './options';

  export interface AdComponent {
    data: any;
    show: boolean;
    close: boolean;
    events: any;
  }

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {

  hideTimeoutId: number;
  destroyTimeoutId: number;
  hideAfterClickTimeoutId: number;
  createTimeoutId: number;
  showTimeoutId: number;
  componentRef: any;
  elementPosition: any;
  _showDelay: any = 200;
  _hideDelay = 0;
  _id: any;
  _options: any = {};
  _defaultOptions: any;
  _destroyDelay: number;
  componentSubscribe: any;

  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('appTooltip') tooltipValue: string;

  @Input('options') set options(value: any) {
    if (value && defaultOptions) {
      this._options = value;
    }
  }
  get options() {
    return this._options;
  }

  @Input('tooltipTitle') set tooltipTitle(value: string) {
    if (value) {
      this._options['tooltipTitle'] = value;
    }
  }

  @Input('placement') set placement(value: string) {
    if (value) {
      this._options['placement'] = value;
    }
  }

  @Input('display') set display(value: boolean) {
    if (typeof(value) === 'boolean') {
      this._options['display'] = value;
    }
  }

  @Input('display-mobile') set displayMobile(value: boolean) {
    this._options['display-mobile'] = value;
  }

  @Input('two-rows') set twoRows(value: boolean) {
    if (value) {
      this._options['twoRows'] = value;
    }
  }

  @Input('id') set id(value: any) {
    this._id = value;
  }
  get id() {
    return this._id;
  }

  get showDelay() {
    const result = this._showDelay;

    if (this.isMobile) {
      return 0;
    } else {
      return result;
    }
  }

  get hideDelay() {
      return this._hideDelay;
  }

  get isTooltipDestroyed() {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  get destroyDelay() {
    if (this._destroyDelay) {
      return this._destroyDelay;
    } else {
      return Number(this.hideDelay);
    }
  }
  set destroyDelay(value: number) {
    this._destroyDelay = value;
  }

  @Output() events: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.isDisplayOnHover === false) {
      return;
    }

    this.show();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('mousewheel', [])
  @HostListener('scroll', [])
  @HostListener('touchstart')
  @HostListener('touchmove')
  onMouseLeave(): void {
    this.destroyTooltip();
  }

  ngOnInit(): void {
    this.applyOptionsDefault(defaultOptions, this.options);
  }

  ngOnDestroy(): void {
    this.destroyTooltip({fast: true});

    if (this.componentSubscribe) {
      this.componentSubscribe.unsubscribe();
    }
  }

  getElementPosition(): void {
    this.elementPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  createTooltip(): void {
    this.clearTimeouts();
    this.getElementPosition();

    this.createTimeoutId = window.setTimeout(() => {
      this.appendComponentToBody(TooltipComponent);
    }, this.showDelay);

    this.showTimeoutId = window.setTimeout(() => {
      this.showTooltipElem();
    }, this.showDelay);
  }

  destroyTooltip(options = {fast: false}): void {
    this.clearTimeouts();

    if (this.isTooltipDestroyed === false) {

      this.hideTimeoutId = window.setTimeout(() => {
        this.hideTooltip();
      }, options.fast ? 0 : this.hideDelay);

      this.destroyTimeoutId = window.setTimeout(() => {
        if (!this.componentRef || this.isTooltipDestroyed) {
          return;
        }

        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.events.emit('hidden');
      }, options.fast ? 0 : this.destroyDelay);
    }
  }

  showTooltipElem(): void {
    this.clearTimeouts();
    (<AdComponent> this.componentRef.instance).show = true;
    this.events.emit('show');
  }

  hideTooltip(): void {
    if (!this.componentRef || this.isTooltipDestroyed) {
      return;
    }
    (<AdComponent> this.componentRef.instance).show = false;
    this.events.emit('hide');
  }

  appendComponentToBody(component: any, data: any = {}): void {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    (<AdComponent> this.componentRef.instance).data = {
      value: this.tooltipValue,
      element: this.elementRef.nativeElement,
      elementPosition: this.elementPosition,
      options: this.options
    };
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentSubscribe = (<AdComponent> this.componentRef.instance).events.subscribe((event: any) => {
      this.handleEvents(event);
    });
  }

  clearTimeouts(): void {
    if (this.createTimeoutId) {
      clearTimeout(this.createTimeoutId);
    }

    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.destroyTimeoutId) {
      clearTimeout(this.destroyTimeoutId);
    }
  }

  get isDisplayOnHover(): boolean {
    if (this.options['display'] === false) {
      return false;
    }

    if (this.options['display-mobile'] === false && this.isMobile) {
      return false;
    }

    return true;
  }

  get isMobile() {
      let check = false;
      navigator.maxTouchPoints ? check = true : check = false;
      return check;
  }

  applyOptionsDefault(defOptions, options): void {
    this._defaultOptions = Object.assign({}, defOptions);
    this.options = Object.assign(this._defaultOptions, options);
  }

  handleEvents(event: any) {
    if (event === 'shown') {
      this.events.emit('shown');
    }
  }

  public show() {
    if (!this.componentRef || this.isTooltipDestroyed) {
      this.createTooltip();
    } else if (!this.isTooltipDestroyed) {
      this.showTooltipElem();
    }
  }

  public hide() {
    this.destroyTooltip();
  }

}
