import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {menuReducer} from './reducer/menuReducer';
import {withSessionStoragePersistence} from "../../../redux/reducer-utils/with-session-storage-persistence";


export const menuStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.MENU_ITEMS,
    reducer: withSessionStoragePersistence(StoreKeys.MENU_ITEMS, menuReducer)
  })
);
