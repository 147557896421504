import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, SubscriptionLike} from 'rxjs';
import {filter, tap, withLatestFrom} from 'rxjs/operators';
import {CurrentModule} from '../../models/current-module';
import {selectCurrentContextFeature} from '../../redux/current-context/current-context-selectors';
import {CurrentContextState} from '../../redux/current-context/reducer/current-context.reducer';
import {CurrentContextService} from '../../redux/current-context/service/current-context.service';


@Injectable({providedIn: 'root'})
export class ModuleFromRouteDefinerService implements OnDestroy {

  currentContext$ = this.store.select(selectCurrentContextFeature);
  private subs: SubscriptionLike[] = [];

  constructor(
    private router: Router,
    private currentContextService: CurrentContextService,
    private store: Store
  ) {
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  public watchAndDefineModuleFromRoute(): Observable<any> {
    return this.router.events.pipe(
      filter((event) => (event instanceof NavigationEnd)),
      withLatestFrom(this.currentContext$),
      tap(([ , currentContextState]) => {
        this.setCurrentModule(currentContextState);
      }));
  }

  private setCurrentModule(currentContextState: CurrentContextState): void {
    let isWorkflow = false;
    if (this.router.url.indexOf(CurrentModule.WORKFLOW) !== -1) {
      isWorkflow = true;
    }
    if (isWorkflow) {
      if (!currentContextState || !currentContextState.datas || currentContextState.datas.currentModule !== CurrentModule.WORKFLOW) {
        this.currentContextService.setCurrentModule(CurrentModule.WORKFLOW);
      }
    } else {
      if (!currentContextState || !currentContextState.datas || currentContextState.datas.currentModule !== CurrentModule.GED) {
        this.currentContextService.setCurrentModule(CurrentModule.GED);
      }
    }
  }
}
