import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {CurrentContextState} from './reducer/current-context.reducer';


export const selectCurrentContextFeature = createFeatureSelector<CurrentContextState>(StoreKeys.CURRENT_CONTEXT);

export const selectCurrentContextDatasCurrentDomain = createSelector(selectCurrentContextFeature, state => state.datas?.currentDomain);

export const selectCurrentContextDatasCurrentEntityType= createSelector(selectCurrentContextFeature, state => state.datas?.currentEntityType);
