import { SearchItem } from '../../../../models/search-item';
import {
  addTaskSearchItemFailedAction,
  addTaskSearchItemStartAction,
  addTaskSearchItemSucceededAction,
  removeAllTaskSearchItemsSucceededAction,
  removeTaskSearchItemFailedAction,
  removeTaskSearchItemStartAction,
  removeTaskSearchItemSucceededAction
} from '../action/task-search-items.action';
import { createReducer, on } from '@ngrx/store';


export interface TaskSearchItemsState {
  datas: SearchItem[];
  loading: boolean;
  error: any;
}

export const TASK_SEARCH_ITEMS_INITIAL_STATE: TaskSearchItemsState = {
  datas: [],
  loading: false,
  error: null,
};


/*
export const taskSearchItemsReducerOld = (state: TaskSearchItemsState = TASK_SEARCH_ITEMS_INITIAL_STATE, action: PayloadAction<SearchItem>) => {
  switch (action.type) {
    case TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_START:
    case TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_SUCCEEDED:
      const max = state.datas.reduce((m, oneItem) => Math.max(m, oneItem.id), 0) + 1;
      const item: SearchItem = action.payload;
      item.id = max;
      return {
        ...state,
        datas: state.datas.concat(item),
        loading: false,
        error: null,
      };
    case TaskSearchItemsActionConstantAction.ADD_TASK_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.payload,
      };
    case TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_SUCCEEDED:
      const copy = state.datas.filter(oneItem => {
        return oneItem.id !== action.payload.id;
      });
      return {
        ...state,
        datas: copy,
        loading: false,
        error: null,
      };
    case TaskSearchItemsActionConstantAction.REMOVE_TASK_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.error,
      };
    case TaskSearchItemsActionConstantAction.REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED:
    return {
      ...state,
      datas: [],
      loading: false,
      error: null,
    };
  }
  return state;
};
*/


export const taskSearchItemsReducer = createReducer(
  TASK_SEARCH_ITEMS_INITIAL_STATE,
  on(addTaskSearchItemStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })) ,
  on(addTaskSearchItemSucceededAction, (state, {payload}) => {
    const max = state.datas.reduce((m, oneItem) => Math.max(m, oneItem.id), 0) + 1;
    const item: SearchItem = payload;
    item.id = max;
    return ({
    ...state,
    datas: state.datas.concat(item),
    loading: false,
    error: null,
  })}) ,
  on(addTaskSearchItemFailedAction, (state, {error}) => ({
    ...state,
    datas: [],
    loading: false,
    error: error,
  })) ,
  on(removeTaskSearchItemStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })) ,
  on(removeTaskSearchItemSucceededAction, (state, {payload}) => {
    const copy = state.datas.filter(oneItem => {
      return oneItem.id !== payload.id;
    });
    return ({
      ...state,
      datas: copy,
      loading: false,
      error: null,
    })
  }) ,
  on(removeTaskSearchItemFailedAction, (state, {error}) => ({
    ...state,
    datas: [],
    loading: false,
    error: error,
  })) ,
  on(removeAllTaskSearchItemsSucceededAction, (state) => ({
    ...state,
    datas: [],
    loading: false,
    error: null
  })) ,
);
