import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import {ApplicationTokenService} from '../../core/services/application-token/application-token.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  public static readonly BYPASS_HEADER = 'X-Skip-Header-Interceptor';

  constructor(
    private authenticationService: AuthenticationService,
    private applicationTokenService: ApplicationTokenService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(AuthenticationInterceptor.BYPASS_HEADER)) {
      return next.handle(req);
    }
    return next.handle(req.clone({
      setHeaders: {
        'X-Refresh-Authorization': this.authenticationService.getAuthorizationToken(),
        'Authorization': this.applicationTokenService.getApplicationTokenFromSessionStorage()
      }
    })).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (!!event.headers) {
          const headers = event.headers as HttpHeaders;
          if (!headers) {
            return;
          }
          const cacheControl: string = headers.get('Cache-Control');
          if (!cacheControl || cacheControl.indexOf('no-store') > -1) {
             let token = headers.get(AuthenticationService.APP_AUTHORIZATION_HEADER);
              if (!!token) {
                token = token.substr(AuthenticationService.BEARER_PREFIX_LENGTH);
                if (this.applicationTokenService.setApplicationTokenIntoSessionStorage(token)) {
                  this.authenticationService.clearCachedUserCredentials()
                }
              }
          }
        }
      }
    }));
  }

}
