import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {taskFormReducer} from './reducer/task-form.reducer';

export const taskFormStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TASK_FORM,
    reducer: taskFormReducer
  })
);
