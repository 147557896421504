import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {tableFilterReducer} from './reducer/table-filter.reducer';


export const tableFilterStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TABLE_FILTER,
    reducer: tableFilterReducer
  })
);
