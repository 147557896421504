import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {cfgDocumentInfoReducer} from './reducer/cfg-document-info.reducer';


export const cfgDocumentInfoStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CFG_DOCUMENT_INFO,
    reducer: cfgDocumentInfoReducer
  })
)
