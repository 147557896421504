import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {criticalDataReducer} from './reducer/critical-data.reducer';


export const criticalDataStoreConfig = StoreModule
  .forFeature(
    createFeature({
      name: StoreKeys.CRITICAL_DATA,
      reducer: criticalDataReducer
    }))
