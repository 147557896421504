import {Injectable} from '@angular/core';
import {SearchItem} from '../../../../models/search-item';
import {Aggregate} from '../../../../models/aggregate';
import {createAction, props, Store} from '@ngrx/store';
import {IFilterAction} from '../../../filter/filter-action-interface';

export class EntityFilterActionConstant {
  static readonly ADD_FILTER_ITEMS_START = '[FilterEntity].ADD_FILTER_ITEMS_START';
  static readonly ADD_FILTER_ITEMS_SUCCEEDED = '[FilterEntity].ADD_FILTER_ITEMS_SUCCEEDED';
  static readonly ADD_FILTER_ITEMS_FAILED = '[FilterEntity].ADD_FILTER_ITEMS_FAILED';

  static readonly REMOVE_FILTER_ITEM_STARTED = '[FilterEntity].REMOVE_FILTER_ITEM_STARTED';
  static readonly REMOVE_FILTER_ITEM_SUCCEEDED = '[FilterEntity].REMOVE_FILTER_ITEM_SUCCEEDED';
  static readonly REMOVE_FILTER_ITEM_FAILED = '[FilterEntity].REMOVE_FILTER_ITEM_FAILED';

  static readonly UPDATE_FILTER_ITEMS_START = '[FilterEntity].UPDATE_FILTER_ITEMS_START';
  static readonly UPDATE_FILTER_ITEMS_SUCCEEDED = '[FilterEntity].UPDATE_FILTER_ITEMS_SUCCEEDED';
  static readonly UPDATE_FILTER_ITEMS_FAILED = '[FilterEntity].UPDATE_FILTER_ITEMS_FAILED';

  static readonly REMOVE_ALL_FILTER_ITEMS_SUCCEEDED = '[FilterEntity].REMOVE_ALL_FILTER_ITEMS_SUCCEEDED';
  static readonly REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED = '[FilterEntity].REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED';
}

export const addEntityFilterItemsStartAction = createAction(
  EntityFilterActionConstant.ADD_FILTER_ITEMS_START
);

export const addEntityFilterItemsSucceededAction = createAction(
  EntityFilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const addEntityFilterItemsFailedAction = createAction(
  EntityFilterActionConstant.ADD_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const removeEntityFilterItemStartedAction = createAction(
  EntityFilterActionConstant.REMOVE_FILTER_ITEM_STARTED
);

export const removeEntityFilterItemSucceededAction = createAction(
  EntityFilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: null } }>()
);

export const removeEntityFilterItemFailedAction = createAction(
  EntityFilterActionConstant.REMOVE_FILTER_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeEntityFiltersAndKeepLastAggSucceededAction = createAction(
  EntityFilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED
);

export const removeAllEntityFilterItemsSucceededAction = createAction(
  EntityFilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED
);

export const updateEntityFilterItemsStartAction = createAction(
  EntityFilterActionConstant.UPDATE_FILTER_ITEMS_START
);

export const updateEntityFilterItemsSucceededAction = createAction(
  EntityFilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED,
  props<{ payload: { filterItems: SearchItem[]; lastAgg: Aggregate } }>()
);

export const updateEntityFilterItemsFailedAction = createAction(
  EntityFilterActionConstant.UPDATE_FILTER_ITEMS_FAILED,
  props<{ error: Error }>()
);


@Injectable({
  providedIn: 'root'
})
export class EntityFilterAction implements IFilterAction {

  constructor(private localStore: Store) {
  }

  public addFilterStarted(): void {
    this.localStore.dispatch(addEntityFilterItemsStartAction());
  }

  public addFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    this.localStore.dispatch(addEntityFilterItemsSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: agg
      }
    }));
  }

  public addFilterFailed(error: Error) {
    this.localStore.dispatch(addEntityFilterItemsFailedAction({error}));
  }

  public removeFilterStarted(): void {
    this.localStore.dispatch(removeEntityFilterItemStartedAction());
  }

  public removeFilterSucceeded(filterItem: SearchItem) {
    this.localStore.dispatch(removeEntityFilterItemSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: null
      }
    }));
  }

  public removeFilterFailed(error: Error) {
    this.localStore.dispatch(removeEntityFilterItemFailedAction({error}));
  }

  public removeFiltersAndKeepLastAgg(): void {
    this.localStore.dispatch(removeEntityFiltersAndKeepLastAggSucceededAction());
  }

  public removeAllFiltersSucceeded() {
    this.localStore.dispatch(removeAllEntityFilterItemsSucceededAction());
  }

  public updateFilterStarted() {
    this.localStore.dispatch(updateEntityFilterItemsStartAction());
  }

  public updateFilterSucceeded(filterItem: SearchItem, curAggregate: Aggregate) {
    this.localStore.dispatch(updateEntityFilterItemsSucceededAction({
      payload: {
        filterItems: [filterItem],
        lastAgg: curAggregate
      }
    }));
  }

  public updateFilterFailed(error: Error) {
    this.localStore.dispatch(updateEntityFilterItemsFailedAction({error}));
  }
}
