import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ToastaService} from 'ngx-toasta';
import {ActivityDiff, CdxActivity} from '../../../../models/cdx-activity';
import {CdxAttachment} from '../../../../models/cdx-attachment';
import {CdxComment} from '../../../../models/cdx-comment';
import {CdxDocument} from '../../../../models/cdx-document';
import {DocumentDetails} from '../../../../models/document-details';

import {EsPage} from '../../../../models/es-page';
import {Metadata} from '../../../../models/metadata';
import {Utils} from '../../../../utils/utils';
import {AbstractDetailsAction} from '../abstract-details.action';
import {DocumentDetailsConstant} from '../document-details-constants';
import {deleteReplyFailedAction, updateCommentsFailedAction} from '../entity/entity-details.action';

export const loadDetailsStartAction = createAction(DocumentDetailsConstant.LOAD_DETAILS_START);
export const loadDocDetailsStartAction = createAction(DocumentDetailsConstant.LOAD_DOC_DETAILS_START);
export const loadMetaStartAction = createAction(DocumentDetailsConstant.LOAD_META_START);
export const loadCurrentActivityDiffStartAction = createAction(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START);
export const loadDetailsSucceededAction = createAction(DocumentDetailsConstant.LOAD_DETAILS_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadDocDetailsSucceededAction = createAction(DocumentDetailsConstant.LOAD_DOC_DETAILS_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadMetaSucceededAction = createAction(DocumentDetailsConstant.LOAD_META_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadCurrentActivityDiffSucceededAction = createAction(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadDetailsFailedAction = createAction(DocumentDetailsConstant.LOAD_DETAILS_FAILED, props<{
  error: Error
}>());
export const loadDocDetailsFailedAction = createAction(DocumentDetailsConstant.LOAD_DOC_DETAILS_FAILED, props<{
  error: Error
}>());
export const loadMetaFailedAction = createAction(DocumentDetailsConstant.LOAD_META_FAILED, props<{ error: Error }>());
export const loadCurrentActivityDiffFailedAction = createAction(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED, props<{
  error: Error
}>());
export const addCommentStartAction = createAction(DocumentDetailsConstant.ADD_COMMENT_START);
export const addCommentFailedAction = createAction(DocumentDetailsConstant.ADD_COMMENT_FAILED, props<{
  error: Error
}>());
export const updateCommentStartAction = createAction(DocumentDetailsConstant.UPDATE_COMMENT_START);
export const loadCommentStartAction = createAction(DocumentDetailsConstant.LOAD_COMMENT_START);
export const loadCommentSucceededAction = createAction(DocumentDetailsConstant.LOAD_COMMENT_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadCommentFailedAction = createAction(DocumentDetailsConstant.LOAD_COMMENT_FAILED, props<{
  error: Error
}>());
export const deleteCommentStartAction = createAction(DocumentDetailsConstant.DELETE_COMMENT_START);
export const deleteCommentFailedAction = createAction(DocumentDetailsConstant.DELETE_COMMENT_FAILED, props<{
  error: Error
}>());
export const addReplyStartAction = createAction(DocumentDetailsConstant.ADD_REPLY_START);
export const addReplyFailedAction = createAction(DocumentDetailsConstant.ADD_REPLY_FAILED, props<{ error: Error }>());
export const updateReplyStartAction = createAction(DocumentDetailsConstant.UPDATE_REPLY_START);
export const updateReplyFailedAction = createAction(DocumentDetailsConstant.UPDATE_REPLY_FAILED, props<{
  error: Error
}>());
export const deleteReplyStartAction = createAction(DocumentDetailsConstant.DELETE_REPLY_START);
export const loadHistoryStartAction = createAction(DocumentDetailsConstant.LOAD_HISTORY_START);
export const loadHistorySucceededAction = createAction(DocumentDetailsConstant.LOAD_HISTORY_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadHistoryFailedAction = createAction(DocumentDetailsConstant.LOAD_HISTORY_FAILED, props<{
  error: Error
}>());
export const loadAttachmentStartAction = createAction(DocumentDetailsConstant.LOAD_ATTACHMENT_START);
export const loadAttachmentSucceededAction = createAction(DocumentDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const loadAttachmentFailedAction = createAction(DocumentDetailsConstant.LOAD_ATTACHMENT_FAILED, props<{
  error: Error
}>());
export const uploadAttachmentStartAction = createAction(DocumentDetailsConstant.UPLOAD_ATTACHMENT_START);
export const uploadAttachmentSucceededAction = createAction(DocumentDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED, props<{payload: DocumentDetails}>());
export const uploadAttachmentFailedAction = createAction(DocumentDetailsConstant.UPLOAD_ATTACHMENT_FAILED, props<{
  error: Error
}>());
export const deleteAttachmentStartAction = createAction(DocumentDetailsConstant.DELETE_ATTACHMENT_START);
export const deleteAttachmentSucceededAction = createAction(DocumentDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const deleteAttachmentFailedAction = createAction(DocumentDetailsConstant.DELETE_ATTACHMENT_FAILED, props<{
  error: Error
}>());
export const updateAttachmentStartAction = createAction(DocumentDetailsConstant.UPDATE_ATTACHMENT_START);
export const updateAttachmentSucceededAction = createAction(DocumentDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED, props<{
  payload: DocumentDetails
}>());
export const updateAttachmentFailedAction = createAction(DocumentDetailsConstant.UPDATE_ATTACHMENT_FAILED, props<{
  error: Error
}>());
export const removeAllSucceededAction = createAction(DocumentDetailsConstant.REMOVE_ALL_SUCCEEDED);
export const updateTokenAction = createAction(DocumentDetailsConstant.UPDATE_TOKEN, props<{
  payload: DocumentDetails
}>());
export const loadDisplayBlockSucceededAction = createAction(DocumentDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED, props<{
  payload: DocumentDetails
}>());


@Injectable({
  providedIn: 'root'
})
export class DocumentDetailsAction extends AbstractDetailsAction {

  constructor(
    store: Store,
    translateService: TranslateService,
    toastaService: ToastaService
  ) {
    super(translateService, toastaService, store);
  }

  public loadDetailsStart(): void {
    this._dispatchType(loadDetailsStartAction());
  }

  public loadNexiaObjectDetailsStart(): void {
    this._dispatchType(loadDocDetailsStartAction());
  }

  public loadMetaStart(): void {
    this._dispatchType(loadMetaStartAction());
  }

  public loadCurrentActivityDiffStart(): void {
    this._dispatchType(loadCurrentActivityDiffStartAction());
  }

  public loadDetailsSucceeded(document: CdxDocument, comments: CdxComment[], attachments: CdxAttachment[], activities: EsPage<CdxActivity>): void {
    if (!document && !comments && !attachments && !activities) {
      this.loadDetailsFailed('document, comments, attachments and activities cannot be null or undefined');
      return;
    }
    const currentDocumentDetails: DocumentDetails = {
      details: document,
      comments: comments,
      attachments: attachments,
      activities: activities,
      token: document.token,
      currentActivityDiff: new ActivityDiff(),
      loadPdf: undefined,
      displayInfoBlock: undefined
    };
    this._dispatchSuccess(loadDetailsSucceededAction({payload: currentDocumentDetails}));
  }


  public loadNexiaObjectDetailsSucceeded(updateDetailsData: CdxDocument, loadPdf = false): void {
    const payload = new DocumentDetails();
    payload.details = updateDetailsData;
    payload.loadPdf = loadPdf;
    this._dispatchSuccess(loadDocDetailsSucceededAction({payload}));
  }

  public loadMetaSucceeded(meta: Metadata): void {
    const payload = new DocumentDetails();
    payload.details = new CdxDocument();
    payload.details.metadata = meta;
    this._dispatchSuccess(loadMetaSucceededAction({payload}));
  }

  public loadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    const payload = new DocumentDetails();
    payload.currentActivityDiff = currentActivityDiff;
    this._dispatchSuccess(loadCurrentActivityDiffSucceededAction({payload}));
  }

  public loadDetailsFailed(error: any): void {
    this._dispatchFail(loadDetailsFailedAction({error}));
  }

  public loadNexiaObjectDetailsFailed(error: any): void {
    this._dispatchFail(loadDocDetailsFailedAction({error}));
  }

  public loadMetaFailed(error: any): void {
    this._dispatchFail(loadMetaFailedAction({error}));
  }

  public loadCurrentActivityDiffFailed(error: any): void {
    this._dispatchFail(loadCurrentActivityDiffFailedAction({error}));
  }

  public addCommentsStart(): void {
    this._dispatchType(addCommentStartAction());
  }

  public addCommentsFailed(error: any): void {
    this._dispatchFail(addCommentFailedAction({error}));
  }

  public updateCommentsStart(): void {
    this._dispatchType(updateCommentStartAction());
  }

  public updateCommentsFailed(error: any): void {
    this._dispatchFail(updateCommentsFailedAction({error}));
  }

  public loadCommentStart(): void {
    this._dispatchType(loadCommentStartAction());
  }

  public loadCommentSucceeded(comment: CdxComment): void {
    if (!comment) {
      this.loadCommentFailed('Comment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.comments = [comment];
    this._dispatchSuccess(loadCommentSucceededAction({payload}));
  }

  public loadCommentFailed(error: any): void {
    this._dispatchFail(loadCommentFailedAction({error}));
  }

  public deleteCommentStart(): void {
    this._dispatchType(deleteCommentStartAction());
  }

  public deleteCommentFailed(error: any): void {
    this._dispatchFail(deleteCommentFailedAction({error}));
  }

  public addReplyStart(): void {
    this._dispatchType(addReplyStartAction());
  }

  public addReplyFailed(error: any): void {
    this._dispatchFail(addReplyFailedAction({error}));
  }

  public updateReplyStart(): void {
    this._dispatchType(updateReplyStartAction());
  }

  public updateReplyFailed(error: any): void {
    this._dispatchFail(updateReplyFailedAction({error}));
  }

  public deleteReplyStart(): void {
    this._dispatchType(deleteReplyStartAction());
  }

  public deleteReplyFailed(error: any): void {
    this._dispatchFail(deleteReplyFailedAction({error}));
  }

  public loadHistoryStart(): void {
    this._dispatchType(loadHistoryStartAction());
  }

  public loadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    if (!activities) {
      this.loadHistoryFailed('History cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.activities = activities;
    this._dispatchSuccess(loadHistorySucceededAction({payload}));
  }

  public loadHistoryFailed(error: any): void {
    this._dispatchFail(loadHistoryFailedAction({error}));
  }

  public loadAttachmentStart(): void {
    this._dispatchType(loadAttachmentStartAction());
  }

  public loadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string = null): void {
    if (!attachment) {
      this.loadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    if (tempAttachmentId) {
      const attachmentToBeChanged: CdxAttachment = new CdxAttachment();
      attachmentToBeChanged.cdx_id = tempAttachmentId;
      payload.attachments.push(attachmentToBeChanged);
    }
    this._dispatchSuccess(loadAttachmentSucceededAction({payload}));
  }

  public loadAttachmentFailed(error: any): void {
    this._dispatchFail(loadAttachmentFailedAction({error}));
  }

  public uploadAttachmentStart(): void {
    this._dispatchType(uploadAttachmentStartAction());
  }

  public uploadAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.uploadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(uploadAttachmentSucceededAction({payload}));
  }

  public uploadAttachmentFailed(error: any): void {
    this._dispatchFail(uploadAttachmentFailedAction({error}));
  }

  public deleteAttachmentStart(): void {
    this._dispatchType(deleteAttachmentStartAction());
  }

  public deleteAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.deleteAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(deleteAttachmentSucceededAction({payload}));
  }

  public deleteAttachmentFailed(error: any): void {
    this._dispatchFail(deleteAttachmentFailedAction({error}));
  }

  public updateAttachmentStart(): void {
    this._dispatchType(updateAttachmentStartAction());
  }

  public updateAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.updateAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(updateAttachmentSucceededAction({payload}));
  }

  public updateAttachmentFailed(error: any): void {
    this._dispatchFail(updateAttachmentFailedAction({error}));
  }

  public removeAll(): void {
    this._dispatchType(removeAllSucceededAction());
  }

  public updateToken(headers: HttpHeaders): void {
    const token = headers.get('Object-Authorization');
    if (Utils.notNullAndNotUndefined(token)) {
      const payload = new DocumentDetails();
      payload.token = token;
      this._dispatchSuccess(updateTokenAction({payload}));
    }
  }

  public showHideInfoBlockSucceeded(isVisible: boolean): void {
    const payload = new DocumentDetails();
    payload.displayInfoBlock = isVisible;
    this._dispatchSuccess(loadDisplayBlockSucceededAction({payload}));
  }
}
