<div class="tooltipContainer-titleBody">
  <ng-container *ngIf="(!isTwoRows); else twoRows">
    <span class="tooltip-text oneRow-title" [innerHTML]="tooltipTitle">
    </span>

    <span class="tooltip-text oneRow-body" [innerHTML]="value">
    </span>
  </ng-container>
</div>

<ng-template #twoRows>
  <p class="tooltip-text twoRows-title" [innerHTML]="tooltipTitle">
  </p>
  <p class="tooltip-text twoRows-body" [innerHTML]="value">
  </p>
</ng-template>
