import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngrx/store';
import {ToastaService} from 'ngx-toasta';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationConfigVarNames} from '../../../../../modules/configuration/application-config-var-names';
import {ConfigurationService} from '../../../../../modules/configuration/configuration.service';
import {Field} from '../../../../models/field';
import {SearchPath} from '../../../../models/search-path';
import {WkfCfgFieldAction} from '../../action/wkf/wkf-cfg-field.action';
import {selectWkfCfgFieldFeature} from '../../field-selectors';
import {WkfFieldState} from '../../reducer/wkf/wkf-cfg-field.reducer';
import {AbstractCfgFieldService} from '../abstract-cfg-field.service';
import {GedCfgFieldService} from '../ged/ged-cfg-field.service';

@Injectable({
  providedIn: 'root'
})
export class WkfCfgFieldService extends AbstractCfgFieldService {

  wkfCfgFields$ = this.store.select(selectWkfCfgFieldFeature);
  private wkfCfgFields: WkfFieldState;

  constructor(
    http: HttpClient,
    configAction: ConfigurationService,
    toastaService: ToastaService,
    private store: Store,
    private wkfCfgFieldAction: WkfCfgFieldAction,
    private gedCfgFieldService: GedCfgFieldService
  ) {
    super(http, configAction, toastaService);
    this.wkfCfgFields$.pipe(takeUntilDestroyed()).subscribe(wkfField => this.wkfCfgFields = wkfField);
  }

  public get(fieldCode: string): Field {
    const fieldsState: WkfFieldState = this.getCfgFieldStoreState();
    return this._getStoreFieldByCode(fieldsState, fieldCode);
  }

  public loadFieldsFromPath(...paths): Observable<{ [p: string]: Field }> {
    let loadGedCfgFiedlds: Observable<{ [p: string]: Field }> = of({});
    let loadWkfCfgFiedlds: Observable<{ [p: string]: Field }> = of({});
    const gedCfgFieldsCode: string[] = [];
    const wkfCfgFieldsCode: string[] = [];
    paths.forEach((path: string) => {
      const searchPath: SearchPath = new SearchPath(path);
      searchPath.isWkf ? wkfCfgFieldsCode.push(path) : gedCfgFieldsCode.push(path);
    });
    if (gedCfgFieldsCode.length) {
      loadGedCfgFiedlds = this.gedCfgFieldService.loadFieldsFromPath(...gedCfgFieldsCode);
    }
    if (wkfCfgFieldsCode.length) {
      loadWkfCfgFiedlds = super.loadFieldsFromPath(...wkfCfgFieldsCode);
    }
    return forkJoin([loadGedCfgFiedlds, loadWkfCfgFiedlds]).pipe(
      map(([gedCfgFields, wkfCfgFields]: [{ [p: string]: Field }, { [p: string]: Field }]) => {
        return {...gedCfgFields, ...wkfCfgFields};
      })
    );
  }

  protected getApiBaseUrl(): string {
    return `${this.configService.getConfigVariable(ApplicationConfigVarNames.API_MOCK_URL)}/assets/mock/fields/wkf-fields.json`;
  }

  protected getCfgFieldStore(): Observable<WkfFieldState> {
    return this.wkfCfgFields$;
  }

  protected cfgFieldActionLoadStarted(): void {
    this.wkfCfgFieldAction.loadStarted();
  }

  protected cfgFieldActionLoadSucceeded(val: { [key: string]: Field }): void {
    this.wkfCfgFieldAction.loadSucceeded(val);
  }

  protected cfgFieldActionLoadFailed(error: any): void {
    this.wkfCfgFieldAction.loadFailed(error);
  }

  protected cfgFieldActionRemoveAllStarted(): void {
    this.wkfCfgFieldAction.removeAllStarted();
  }

  protected cfgFieldActionRemoveAllSucceeded(): void {
    this.wkfCfgFieldAction.removeAllSucceeded();
  }

  protected getCfgFieldStoreState(): WkfFieldState {
    return this.wkfCfgFields;
  }
}
