import {DOCUMENT, Location} from '@angular/common';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';

import {AccessFunction, findAuthority, NexiaAuthorities} from '../../core/models/access';
import {DomainItem} from '../../core/models/domain-item';
import {Url} from '../../core/models/url';
import {UserCredentials} from '../../core/models/UserCredentials';
import {ApplicationToken} from '../../core/services/application-token/application-token';
import {ApplicationTokenService} from '../../core/services/application-token/application-token.service';
import {Utils} from '../../core/utils/utils';
import {resetStore} from '../../redux/meta-reducers/reset-store';
import {AlertModalService} from '../alert-message/service/alert-modal.service';
import {ApplicationConfig, ConfigurationService} from '../configuration/configuration.service';
import {AuthenticationAction} from './authentication.action';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public static readonly DEFAULT_LANGUAGE = 'fr';

  public static readonly APP_AUTHORIZATION_HEADER = 'Authorization';
  public static readonly BEARER = 'Bearer ';
  public static readonly BEARER_PREFIX_LENGTH = 7 ;// AuthenticationService.BEARER.length;

  public static readonly SESSION_ST_AUTH_TOKEN = 'auth-token';
  public static readonly ROLE_WKF = 'ROLE_WKF';
  ROLE_ADMIN = 'ROLE_ADMIN';
  private applicationConfigReady$: Observable<any> = this.configService.applicationConfigReady$;
  private cachedUserCredential: UserCredentials;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private action: AuthenticationAction,
    private router: Router,
    private httpClient: HttpClient,
    private location: Location,
    public translate: TranslateService,
    private alertService: AlertModalService,
    private store: Store,
    private applicationTokenService: ApplicationTokenService,
    private configService: ConfigurationService
  ) {

  }


  /**
   * called by authenticate guard
   * @param url
   */
  public authenticate(url: string): Observable<boolean> {
    const authToken = this.getAuthorizationToken(false);
    if (!this.applicationTokenService.isJwtTokenValid(authToken)) {
      this.clearStorage();
      this.alertService.releaseError();

      this.setRedirectPage(url);

      this.redirectToAuthService(true);
      return of(false);
    }
    const appToken = this.applicationTokenService.getApplicationTokenFromSessionStorage(false);
    if (!this.applicationTokenService.isJwtTokenValid(appToken)) {
      this.setRedirectPage(url);
      return this.validateAuthToken(authToken);
    }
    this.action.loadAuthenticationSucceeded();
    return of(true);
  }

  public validateAuthToken(authToken: string): Observable<boolean> {
    console.log(`Validate auth token`)
    return this.authenticateOnApp(authToken).pipe(
      map(res => {
        if (res) {
          this.redirectTo();
          return true;
        }
        return false;
      }),
      catchError(err => {
        this.action.loadAuthenticationFailed(err);
        return of(false);
      }));
  }

  /*** ACCESSEURS sessionstorage ***/
  /**************  *****************/

  public clearStorage(): void {
    resetStore(this.store);
    sessionStorage.clear();
  }

  public setRedirectPage(url: string = null): void {
    if (!url) {
      sessionStorage.removeItem('redirectPage');
    } else {
      sessionStorage.setItem('redirectPage', url);
    }
  }

  public getRedirectPage(): string {
    return sessionStorage.getItem('redirectPage');
  }

  public getAuthorizationToken(prefix = true): string {
    const token = sessionStorage.getItem(AuthenticationService.SESSION_ST_AUTH_TOKEN);
    if (prefix) {
      return AuthenticationService.BEARER + token;
    } else {
      return token;
    }
  }

  /***************  *****************/

  public logout() {
    this.clearStorage();
    this.action.logoutSucceeded();
    this.redirectToAuthService(false);
  }

  clearCachedUserCredentials() {
    this.cachedUserCredential = null;
  }

  public getUserCredentials(): UserCredentials {
    if (!this.cachedUserCredential) {
      const token = this.getApplicationTokenFromSessionStorage(false);
      if (!token) {
        console.error('no app token to decode');
        return;
      }
      const loggedInUser: ApplicationToken = this.tokenDecodeOrError(token) as ApplicationToken;
      this.cachedUserCredential = new UserCredentials(loggedInUser.id, loggedInUser.firstname, loggedInUser.lastname, loggedInUser.sub, loggedInUser.roles);
    }
    return _.cloneDeep(this.cachedUserCredential);
  }

  public getObjectAccessFunctions(token: string): AccessFunction[] {
    if (!Utils.notNullAndNotUndefined(token)) {
      return [];
    }
    return this.tokenDecodeOrError(token).functions as AccessFunction[];
  }

  public hasObjectAccessFunction(token: string, accessFunction: AccessFunction): boolean {
    return this.getObjectAccessFunctions(token).indexOf(accessFunction) > -1;
  }

  public checkForGlobalAccessByRole(role: string): boolean {
    const globalRolesFunctions = this.getGlobalRolesFunctions();
    return !!globalRolesFunctions && globalRolesFunctions.indexOf(role) !== -1;

  }

  public checkForRoleAdmin(): boolean {
    return this.checkForGlobalAccessByRole(this.ROLE_ADMIN);
  }

  public checkForIndex(): boolean {
    return this.checkForGlobalAccessByFunction(AccessFunction.INDEX);
  }

  public hasEntityDomainGlobalAccessToIndex(entityCode: string, domainItems: DomainItem[]): boolean {
    const currentDomain: DomainItem = domainItems.find((domainItem: DomainItem) => domainItem.entityType === entityCode);
    return this.checkForIndexInDomain(currentDomain);
  }

  public checkForIndexInDomain(domain: DomainItem): boolean {
    return !!domain ? this.checkForGlobalAccessByFunction(AccessFunction.INDEX, domain) : false;
  }

  public checkForIndexTeamManagement(): boolean {
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.INDEX_TEAM, AccessFunction.INDEX_TEAM_ALL, AccessFunction.INDEX_TEAM_ADMIN]);
  }

  public checkForIndexTeamCreation(): boolean {
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.INDEX_TEAM_ALL, AccessFunction.INDEX_TEAM_ADMIN]);
  }

  public checkForDomainTeamManagement(currentDomainCode: string): boolean {
    if (!currentDomainCode) {
      return false;
    }
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.TEAM, AccessFunction.TEAM_ALL, AccessFunction.TEAM_ADMIN], currentDomainCode);
  }


  public checkForDomainAlertRead(currentDomainCode: string): boolean {
    let hasAlertsReadAccess = false;

    if (!currentDomainCode) {
      hasAlertsReadAccess = false;
    }
    else
    {
      const globalAccessFunctions = this.getGlobalAuthoritiesFunctions();
      hasAlertsReadAccess = !!globalAccessFunctions[AccessFunction.ALERTS_READ]
                             && globalAccessFunctions[AccessFunction.ALERTS_READ].some((domainCode: string) => domainCode === currentDomainCode);
    }
    return hasAlertsReadAccess;
  }

  public checkForDomainAlertEdit(currentDomainCode: string): boolean {
    let hasAlertsEditAccess = false;

    // pour avoir droit d'édition des alertes, il faut d'abord le droit de lecture des alertes
    if (!this.checkForDomainAlertRead(currentDomainCode)) {
      hasAlertsEditAccess = false;
    }
    else
    {
      const globalAccessFunctions = this.getGlobalAuthoritiesFunctions();
      hasAlertsEditAccess = !!globalAccessFunctions[AccessFunction.ALERTS_EDIT]
                          && globalAccessFunctions[AccessFunction.ALERTS_EDIT].some((domainCode: string) => domainCode === currentDomainCode);
    }
    return hasAlertsEditAccess;
  }


  /************************/
  /*** TOKEN APPLICATIF ***/

  /***********************/

  public checkForDomainTeamCreation(currentDomainCode: string): boolean {
    if (!currentDomainCode) {
      return false;
    }
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.TEAM_ALL, AccessFunction.TEAM_ADMIN], currentDomainCode);
  }

  private tokenDecodeOrError(token: string): ApplicationToken {
    let decoded = this.applicationTokenService.tokenDecode(token);
    if (decoded === null) {
      this.alertService.raiseDisconnectError();
      decoded = {};
    }
    return decoded;
  }

  public getApplicationTokenFromSessionStorage(includeBearer = true) {
    return this.applicationTokenService.getApplicationTokenFromSessionStorage(includeBearer);
  }

  private setAuthorizationTokenInSessionStorage(authToken: string = null): void {
    if (!authToken) {
      sessionStorage.removeItem(AuthenticationService.SESSION_ST_AUTH_TOKEN);
    } else {
      sessionStorage.setItem(AuthenticationService.SESSION_ST_AUTH_TOKEN, authToken);
    }
  }

  private buildLoginApiUrlWithToken(config: ApplicationConfig, authToken: string) {
    const backname = config.API_BACK_NAME.endsWith('/') || config.API_BACK_NAME.length === 0 ? config.API_BACK_NAME : `${config.API_BACK_NAME}/`;
    return `${config.API_BACK_URL}/${backname}${Url.PUBLIC_API}${Url.LOGIN}${authToken}`;
  }

  private authenticateOnApp(authToken: string): Observable<boolean> {
    return this.applicationConfigReady$
      .pipe(
        tap(() => this.setAuthorizationTokenInSessionStorage(authToken)),
        map((config: ApplicationConfig) => this.buildLoginApiUrlWithToken(config, authToken)),
        take(1),
        tap(() => console.log(`Authenticating...`)),
        switchMap(loginApiUrl => this.httpClient.get(loginApiUrl, {observe: 'response'}).pipe(
          tap(v => console.log(`retour de loginUrl:`, v)),
          map(response => {
            this.setCurrentLanguageFromHeaders(response.headers);
            this.action.loadAuthenticationSucceeded();
            return true;
          })
        )),
        catchError(response => {
          this.action.loadAuthenticationFailed(response.error);
          this.clearStorage();
          this.redirectToError(response);
          return of(false);
        })
      );
  }

  private setCurrentLanguageFromHeaders(headers: HttpHeaders): void {
    let headersLanguage: string = headers.get('content-language');
    if (!headersLanguage) {
      headersLanguage = AuthenticationService.DEFAULT_LANGUAGE;
    } else {
      headersLanguage = headersLanguage.substring(headersLanguage.indexOf('-'));
    }
    this.translate.setDefaultLang(AuthenticationService.DEFAULT_LANGUAGE);
    this.translate.use(headersLanguage);
  }

  private redirectToAuthService(login: boolean) {
    this.applicationConfigReady$
      .pipe(
        take(1)
      ).subscribe((conf: ApplicationConfig) => {
      let url: string;
      if (login) {
        let redirectUri = conf.API_REDIRECT_URI;
        if (!redirectUri) {
          redirectUri = this.document.location.origin + this.location.prepareExternalUrl('/login');
        }
        url = conf.API_LOGIN_URL + 'redirect_uri=' + redirectUri;
      } else {
        url = conf.API_LOGOUT_URL + 'post_logout_redirect_uri=' + this.document.location.origin + this.location.prepareExternalUrl('/logout');
      }
      this.document.location.href = url;
    });
  }

  private redirectTo() {
    const redirectTo = this.getRedirectPage();
    if (redirectTo) {
      this.setRedirectPage();
      if (redirectTo.includes('?')) {
        const root: string = redirectTo.substring(0, redirectTo.indexOf('?'));
        const parsedUrl: UrlTree = this.router.parseUrl(redirectTo);
        this.router.navigate([root], {queryParams: parsedUrl.queryParams});
      } else {
        this.router.navigate([redirectTo]);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  private redirectToError(error: object | string) {
    let err = error;
    if (error instanceof HttpErrorResponse) {
      if (error.error.detail) {
        err = `${error.error.status}: ${error.error.title}, ${error.error.detail}`;
      } else {
        err = error.error.message;
      }
    }
    this.router.navigate(['error'], {queryParams: {error: err}});
  }

  // INDEXATION TEAM access

  private getGlobalAccessFunctions(): { [key: string]: string[] } {
    return this.tokenDecodeOrError(this.getApplicationTokenFromSessionStorage()).functions as { [key: string]: string[] };
  }

  public getGlobalAuthoritiesFunctions(): { [key: string]: string[] } {
    return this.tokenDecodeOrError(this.getApplicationTokenFromSessionStorage()).authorities;
  }

  // DOMAIN TEAM access

  private getGlobalRolesFunctions(): string[] {
    return this.tokenDecodeOrError(this.getApplicationTokenFromSessionStorage()).roles;
  }

  private checkForGlobalAccessByFunction(access: AccessFunction, domainItem: DomainItem = null): boolean {
    const globalAccessFunctions = this.getGlobalAccessFunctions();
    if (!!globalAccessFunctions && !!globalAccessFunctions[access]) {
      if (!domainItem) {
        return true;
      } else {
        return globalAccessFunctions[access].some((domain: string) => domain === domainItem.code);
      }
    }
    return false;
  }

  public hasAuthority(authorities: NexiaAuthorities, domainCode?: string): boolean {
    return findAuthority(this.getGlobalAuthoritiesFunctions(), authorities, domainCode);
  }

  private checkIfHasTeamAccessByAccessFunction(accesses: AccessFunction[], currentDomainCode: string = null): boolean {
    let hasAccess = false;
    const globalAccessFunctions = this.getGlobalAuthoritiesFunctions();
    accesses.forEach((access: AccessFunction) => {
      if (hasAccess) {
        return;
      }
      if (!currentDomainCode) {
        hasAccess = !!globalAccessFunctions[access];
      } else {
        hasAccess = !!globalAccessFunctions[access] && globalAccessFunctions[access].some((domainCode: string) => domainCode === currentDomainCode);
      }
    });
    return hasAccess;
  }
}
