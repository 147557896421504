import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {cfgTeamReducer} from './reducer/cfg-team.reducer';

export const cfgTeamStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CFG_TEAM,
    reducer: cfgTeamReducer
  })
);
