import { PayloadAction } from '../../../../redux/payload-action';
import { DetailsVersion } from '../../../models/details-version';
import {
  loadVersionListFailed,
  loadVersionListStartedAction,
  loadVersionListSucceeded,
  removeVersionListSucceeded,
  VersionListActionConstant
} from '../action/version-list.action';
import { createReducer, on } from '@ngrx/store';

export class VersionListState {
  datas: DetailsVersion[];
  loading: boolean;
  error: any;
}

export const VERSION_LIST_INITIAL_STATE: VersionListState = {
  datas: [],
  loading: false,
  error: null,
};


export const versionListReducerOld = (state: VersionListState = VERSION_LIST_INITIAL_STATE, action: PayloadAction<DetailsVersion[]>) => {
  switch (action.type) {
    case VersionListActionConstant.LOAD_VERSION_LIST_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VersionListActionConstant.LOAD_VERSION_LIST_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case VersionListActionConstant.LOAD_VERSION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VersionListActionConstant.REMOVE_VERSION_LIST_SUCCEEDED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: null,
      };
  }
  return state;
};



export const versionListReducer = createReducer(
  VERSION_LIST_INITIAL_STATE,
  on(loadVersionListStartedAction,(state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadVersionListSucceeded,(state,{payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(loadVersionListFailed,(state,{error}) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(removeVersionListSucceeded,(state) => ({
    ...state,
    datas: [],
    loading: false,
    error: null,
  }))
)
