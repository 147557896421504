import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../../../modules/authentication/authentication.service';

import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {MenuItem} from '../../../models/menu-item';
import {Url} from '../../../models/url';
import {MenuAction} from '../action/Menu.action';
import {selectMenuItemsDatas, selectMenuItemsFeature} from '../menu-selectors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public menuItemsLoaded$ = this.store.select(selectMenuItemsFeature).pipe(map(state => state.datas != null && state.datas.length > 0));
  public menuItemDatas$ = this.store.select(selectMenuItemsDatas).pipe(takeUntilDestroyed());
  private menuItemDatas: MenuItem[];

  constructor(
    private menuAction: MenuAction,
    private http: HttpClient,
    private configAction: ConfigurationService,
    private authenticationService: AuthenticationService,
    private store: Store
  ) {
    this.menuItemDatas$.subscribe(datas => this.menuItemDatas = datas);
  }

  loadMenuItems(): Observable<boolean> {
    return of(false).pipe(
      tap(() => this.menuAction.loadStarted()),
      switchMap(() => this.http.get<MenuItem[]>(Url.getProtectedApiBaseUrl(this.configAction) + Url.PARAMS + Url.MENU).pipe(
        map(items => {
          this.menuAction.loadSucceeded(items);
          return true;
        }))),
      catchError(error => {
        this.menuAction.loadFailed(error);
        return of(false);
      })
    );
  }

  public calcAccessToDomainTeamsByCurrentDomain(currentDomainCode: string): void {
    if (!currentDomainCode) {
      return;
    }
    const storeMenuItems: MenuItem[] = _.cloneDeep(this.menuItemDatas);
    const domainTeamsMenuItem: MenuItem = storeMenuItems.find((menuItem: MenuItem) => MenuItem.getRouterPathByAction(menuItem.action) === '/domain');
    const isActive = this.authenticationService.checkForDomainTeamManagement(currentDomainCode);
    if (!!domainTeamsMenuItem) {
      domainTeamsMenuItem.active = isActive;
      this.menuAction.loadSucceeded(storeMenuItems);
    }
  }
}
