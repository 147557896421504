import {createReducer, on} from '@ngrx/store';
import {PayloadAction} from '../../../../redux/payload-action';
import {
  addCfgDocumentInfoFailedAction,
  addCfgDocumentInfoStartAction,
  addCfgDocumentInfoSucceededAction,
  CgfDocumentInfoConstant,
  removeAllCfgDocumentInfoErrorAction,
  removeAllCfgDocumentInfoStartAction,
  removeAllCfgDocumentInfoSucceededAction
} from '../action/cfg-document-info.action';

export interface DocumentInfoState {
  datas: { [key: string]: string[] };
  loading: boolean;
  error: any;
}

const MENU_INITIAL_STATE: DocumentInfoState = {
  datas: {},
  loading: false,
  error: null,
};


export const cfgDocumentInfoReducerOld = (state: DocumentInfoState = MENU_INITIAL_STATE, action: PayloadAction<{ [key: string]: string[] }>): DocumentInfoState => {
  switch (action.type) {
    // ADD MULTIPLE
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // REMOVE ALL
    case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const cfgDocumentInfoReducer = createReducer(MENU_INITIAL_STATE,
  on(addCfgDocumentInfoStartAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(addCfgDocumentInfoSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {...state.datas, ...payload},
    loading: false,
    error: null,
  })),
  on(addCfgDocumentInfoFailedAction, (state, error) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(removeAllCfgDocumentInfoStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(removeAllCfgDocumentInfoSucceededAction, (state) => ({
    ...state,
    datas: {},
    loading: false,
    error: null,
  })),
  on(removeAllCfgDocumentInfoErrorAction, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  }))
);
