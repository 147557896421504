import {Injectable} from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {ObjectType} from '../../../models/ObjectType';
import {SearchResultPagination} from '../../../models/search-result-pagination';

export class SearchResultPaginationStateConstantAction {
  static readonly SEARCH_RESULT_PAGINATION_LOAD = '[SearchResultPagination].SEARCH_RESULT_PAGINATION_LOAD';
  static readonly SEARCH_RESULT_PAGINATION_REINIT = '[SearchResultPagination].SEARCH_RESULT_PAGINATION_REINIT';
  static readonly SEARCH_RESULT_PAGINATION_UPDATE = '[SearchResultPagination].SEARCH_RESULT_PAGINATION_UPDATE';
}

export const searchResultPaginationLoadAction = createAction(SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_LOAD);
export const searchResultPaginationReinitAction = createAction(SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_REINIT , props<{payload : { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }}>() );
export const searchResultPaginationUpdateAction = createAction(SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_UPDATE, props<{payload: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }}>());

@Injectable({
  providedIn: 'root'
})
export class SearchResultPaginationAction {


  constructor(
    private localStore: Store,
  ) {
  }


  public loadSearchResultPagination() {
    this.localStore.dispatch(searchResultPaginationLoadAction());
  }

  public reinitSearchResultPagination(payload: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }) {
    this.localStore.dispatch(searchResultPaginationReinitAction({payload}));
  }


  public updateSearchResultPagination(payload: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }) {
    this.localStore.dispatch(searchResultPaginationUpdateAction({payload}));
  }
}
