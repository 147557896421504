import { Aggregate } from '../../../../models/aggregate';
import { DraftDocument } from '../../../../models/DraftDocument';
import { EsPage } from '../../../../models/es-page';
import {
  loadAggregateFailedAction,
  loadAggregateStartAction,
  loadAggregateSucceededAction,
  loadListIndexationFailedAction,
  loadListIndexationStartedAction,
  loadListIndexationSucceededAction
} from '../action/list-indexation.action';
import { createReducer, on } from '@ngrx/store';

export interface ListIndexationState {
  datas: EsPage<DraftDocument>;
  loading: boolean;
  loadAgg: boolean;
  error: any;
}

export const CURRENT_LIST_INDEXATION_INITIAL_STATE: ListIndexationState = {
  datas: null,
  loading: false,
  loadAgg: false,
  error: null,
};

/*
export const listIndexationReducerOld = (state: ListIndexationState = CURRENT_LIST_INDEXATION_INITIAL_STATE, action: PayloadAction<EsPage<DraftDocument>>) => {
  switch (action.type) {
    case ListIndexationConstant.LOAD_AGGREGATE_START:
      return {
        ...state,
        loading: true,
        loadAgg: true,
        error: ListIndexationConstant.ERROR_START,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case ListIndexationConstant.LOAD_AGGREGATE_FAILED:
      return {
        ...state,
        loading: false,
        loadAgg: false,
        error: action.error,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ListIndexationConstant.LOAD_AGGREGATE_SUCCEEDED:
      const newAgg: Aggregate = action.payload.aggs[0];
      let aggs: Aggregate[] = state.datas.aggs;
      let added = false;
      aggs = aggs.map(agg => {
        if (agg.path === newAgg.path) {
          added = true;
          return newAgg;
        }
        return agg;
      });
      if (!added) {
        aggs.push(newAgg);
      }
      const copy = {...state.datas, aggs: aggs};
      return {
        ...state,
        datas: copy,
        loading: false,
        loadAgg: false,
        error: null,
      };
  }
  return state;
};
*/

export const listIndexationReducer = createReducer(
  // Initial State
  CURRENT_LIST_INDEXATION_INITIAL_STATE,

  // Load List Indexation Start Action
  on(loadListIndexationStartedAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Load List Indexation Succeeded Action
  on(loadListIndexationSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  // Load List Indexation Failed Action
  on(loadListIndexationFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  // Load Aggregate Start Action
  on(loadAggregateStartAction, (state) => ({
    ...state,
    loading: true,
    loadAgg: true,
    error: 'ERROR_START', // Assuming 'ERROR_START' is a constant
  })),

  // Load Aggregate Failed Action
  on(loadAggregateFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    loadAgg: false,
    error,
  })),

  // Load Aggregate Succeeded Action
  on(loadAggregateSucceededAction, (state, {payload}) => {
    const newAgg: Aggregate = payload.aggs[0];
    let aggs: Aggregate[] = state.datas.aggs;
    let added = false;
    aggs = aggs.map(agg => {
      if (agg.path === newAgg.path) {
        added = true;
        return newAgg;
      }
      return agg;
    });
    if (!added) {
      aggs.push(newAgg);
    }
    const copy = {...state.datas, aggs: aggs};
    return {
      ...state,
      datas: copy,
      loading: false,
      loadAgg: false,
      error: null,
    };
  })
);
