import {createReducer, on} from '@ngrx/store';
import {Field} from '../../../../models/field';
import {
  addMultipleCfgFieldFailedAction,
  addMultipleCfgFieldStartAction,
  addMultipleCfgFieldSucceededAction,
  removeAllCfgFieldFailedAction,
  removeAllCfgFieldStartAction,
  removeAllCfgFieldSucceededAction
} from '../../action/const-field-actions';

export interface GedFieldState {
  datas: { [key: string]: Field };
  loading: boolean;
  error: any;
}

export const GED_CFG_INITIAL_STATE: GedFieldState = {
  datas: {},
  loading: false,
  error: null,
};


export const gedCfgFieldReducer = createReducer(
  GED_CFG_INITIAL_STATE,
  on(addMultipleCfgFieldStartAction, removeAllCfgFieldStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(addMultipleCfgFieldSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {...state.datas, ...payload},
    loading: false,
    error: null,
  })),
  on(removeAllCfgFieldSucceededAction, (state) => ({
    ...state,
    datas: {},
    loading: false,
    error: null,
  })),
  on(addMultipleCfgFieldFailedAction, removeAllCfgFieldFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  }))
);
