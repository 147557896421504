import {PayloadAction} from '../../../../redux/payload-action';
import {SearchResultPagination} from '../../../models/search-result-pagination';
import {ObjectType} from '../../../models/ObjectType';
import {
  searchResultPaginationLoadAction, searchResultPaginationReinitAction,
  SearchResultPaginationStateConstantAction, searchResultPaginationUpdateAction
} from '../action/search-result-pagination.action';
import { createReducer, on } from '@ngrx/store';

export class SearchResultPaginationState {
  datas: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination };
  loading: boolean;
  error: any;
}

const initStore: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination } = SearchResultPagination.getInitStore();

export const SEARCH_RESULT_PAGINATION_INITIAL_STATE: SearchResultPaginationState = {
  datas: initStore,
  loading: false,
  error: null
};


export const searchResultPaginationReducerOld = (
  state: SearchResultPaginationState = SEARCH_RESULT_PAGINATION_INITIAL_STATE, action: PayloadAction<{ -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }>
) => {
  switch (action.type) {
    case SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_LOAD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_REINIT:
    case SearchResultPaginationStateConstantAction.SEARCH_RESULT_PAGINATION_UPDATE:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
  }
  return state;
};


export const searchResultPaginationReducer = createReducer(
  SEARCH_RESULT_PAGINATION_INITIAL_STATE,
  on(searchResultPaginationLoadAction, (state) => { return {
    ...state,
    loading: false,
    error: null,
  } }),
  on(searchResultPaginationReinitAction,(state, {payload}) => { return {
    ...state,
    datas: payload,
    loading: false,
    error: null,
  } }),
  on(searchResultPaginationUpdateAction,(state, {payload}) => { return {
    ...state,
    datas: payload,
    loading: false,
    error: null,
  } }),
);
