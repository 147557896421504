import { Injectable } from '@angular/core';

import { TaskForm } from '../../../models/task-form';
import { createAction, props, Store } from '@ngrx/store';

export class TaskFormConstantAction {
  static readonly ADD_TASK_FORM_START = '[TASK_FORM].ADD_TASK_FORM_START';
  static readonly ADD_TASK_FORM_SUCCEEDED = '[TASK_FORM].ADD_TASK_FORM_SUCCEEDED';
  static readonly ADD_TASK_FORM_FAILED = '[TASK_FORM].ADD_TASK_FORM_FAILED';

  static readonly REMOVE_TASK_FORM_SUCCEEDED = '[TASK_FORM].REMOVE_TASK_FORM_SUCCEEDED';
}

export const addTaskformStartAction = createAction(TaskFormConstantAction.ADD_TASK_FORM_START);
export const addTaskformSucceededAction = createAction(TaskFormConstantAction.ADD_TASK_FORM_SUCCEEDED, props<{payload: TaskForm}>());
export const addTaskformFailedAction = createAction(TaskFormConstantAction.ADD_TASK_FORM_FAILED, props<{error: Error}>());
export const removeTaskFormSucceededAction = createAction(TaskFormConstantAction.REMOVE_TASK_FORM_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class TaskFormAction {

  constructor( private localStore: Store ) {
  }

  public loadTaskFormStart(): void {
    this.localStore.dispatch(addTaskformStartAction());
  }

  public loadTaskFormSucceeded(payload: TaskForm): void {
    this.localStore.dispatch(addTaskformSucceededAction({payload}));
  }

  public loadTaskFormFailed(error: any): void {
    this.localStore.dispatch(addTaskformFailedAction({error}));
  }

  public removeTaskFormSucceeded(): void {
    this.localStore.dispatch(removeTaskFormSucceededAction());
  }
}
