import { Injectable } from '@angular/core';
import { SearchItem } from '../../../models/search-item';
import {ISearchItemsAction} from '../search-items-action-interface';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractSearchItemsService {

  protected abstract getSearchItemsAction(): ISearchItemsAction;

  public addItem(item: SearchItem): void {
    const searchItemsAction = this.getSearchItemsAction();
    searchItemsAction.addSearchItemStarted();
    try {
      if (item === undefined || item === null) {
        throw new ErrorEvent('ERROR');
      }
      searchItemsAction.addSearchItemSucceeded(item);
    } catch (error) {
      searchItemsAction.addSearchItemFailed(error);
    }
  }

  public deleteItem(id: number | SearchItem): void {
    const searchItemsAction = this.getSearchItemsAction();
    searchItemsAction.removeSearchItemStarted();
    try {
      if (id === undefined || id === null) {
        throw new ErrorEvent('ERROR');
      }
      let searchItem;
      if (id instanceof SearchItem) {
        searchItem = id;
      } else {
        searchItem = new SearchItem(id, '', '', '', true);
      }
      searchItemsAction.removeSearchItemSucceeded(searchItem);
    } catch (error) {
      searchItemsAction.removeSearchItemFailed(error);
    }
  }

  public removeAllSearchItems(): void {
    const searchItemsAction = this.getSearchItemsAction();
    searchItemsAction.removeAllSearchItemsSucceeded();
  }
}
