import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './components/pages/pages.component';
import { HeaderComponent } from './components/header/header.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),

  ],
  declarations: [
    PagesComponent,
    HeaderComponent
  ],
  exports: [
    PagesComponent,
    HeaderComponent
  ]

})
export class LayoutModule {
}

