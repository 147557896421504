import {Injectable} from '@angular/core';
import {OperatorType, SearchItem} from '../../../models/search-item';
import {Aggregate} from '../../../models/aggregate';
import {IFilterAction} from '../filter-action-interface';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractFilterService {

  public addFilter(filterItem: SearchItem, curAggregate: Aggregate) {
    const filterAction= this.getFilterAction();
    if (!filterItem || !curAggregate) {
      console.error('filterItem / current aggregate is not defined');
      return;
    }
    if (SearchItem.getOperatorType(filterItem.operator) === OperatorType.RANGE) {
      filterAction.updateFilterStarted();
      try {
        filterAction.updateFilterSucceeded(filterItem, curAggregate);
      } catch (error) {
        filterAction.updateFilterFailed(error);
      }
    } else {
      filterAction.addFilterStarted();
      try {
        filterAction.addFilterSucceeded(filterItem, curAggregate);
      } catch (error) {
        filterAction.addFilterFailed(error);
      }
    }
  }

  public removeAllFilters() {
    const filterAction = this.getFilterAction();
    filterAction.removeAllFiltersSucceeded();
  }

  public removeFiltersAndKeepLastAgg(): void {
    const filterAction = this.getFilterAction();
    filterAction.removeFiltersAndKeepLastAgg();
  }

  public removeFilter(filterItem: SearchItem) {
    const filterAction = this.getFilterAction();
    filterAction.removeFilterStarted();
    try {
      filterAction.removeFilterSucceeded(filterItem);
    } catch (e) {
      filterAction.removeFilterFailed(e);
    }
  }

  protected abstract getFilterAction(): IFilterAction;

}
