import {NgModule} from '@angular/core';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatTreeModule} from '@angular/material/tree';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {CommonModule} from '@angular/common';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';

import {ThesaurusModalComponent} from './modal/thesaurus-modal/thesaurus-modal.component';
import {LoadableThesaurusService} from './service/loadable-thesaurus.service';
import {TreeService} from './service/tree.service';
import {ThesaurusTreeComponent} from './components/thesaurus-tree/thesaurus-tree.component';
import {TooltipModule} from '../tooltip/tooltip.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ThesaurusTreeComponent,
    ThesaurusModalComponent
  ],
    imports: [
        TooltipModule,
        CommonModule,
        CdkTreeModule,
        MatTreeModule,
        MatIconModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        TranslateModule
    ],
  providers: [
    LoadableThesaurusService,
    TreeService
  ]
})
export class ThesaurusModule {
}
