import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {AggEntitiesComponent} from './agg-entities/agg-entities.component';
import {TooltipModule} from '../tooltip/tooltip.module';
import {AggDocTypeComponent} from './agg-doc-type/agg-doc-type.component';
import {AggTermComponent} from './agg-term/agg-term.component';
import {AggSliderComponent} from './agg-slider/agg-slider.component';
import {AggTeamComponent} from './agg-team/agg-team.component';
import {AggThesaurusComponent} from './agg-thesaurus/agg-thesaurus.component';
import {NxI18nPipeModule} from '../nxI18nPipe/nxI18nPipe.module';
import {AggAuthorComponent} from './agg-author/agg-author.component';
import {GedAggSelectorComponent} from './agg-selector/ged/ged-agg-selector.component';
import {WkfAggSelectorComponent} from './agg-selector/wkf/wkf-agg-selector.component';
import {TranslateModule} from "@ngx-translate/core";
import {ClickOutsideDirective} from "../../../shared-directives/click-outside.directive";

@NgModule({
  declarations: [
    AggDocTypeComponent,
    GedAggSelectorComponent,
    WkfAggSelectorComponent,
    AggTermComponent,
    AggEntitiesComponent,
    AggSliderComponent,
    AggThesaurusComponent,
    AggTeamComponent,
    AggAuthorComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NxI18nPipeModule,
    MatNativeDateModule,
    TranslateModule,
    ClickOutsideDirective
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  exports: [
    AggDocTypeComponent,
    GedAggSelectorComponent,
    WkfAggSelectorComponent,
    AggTermComponent,
    AggEntitiesComponent,
    AggSliderComponent,
    AggThesaurusComponent,
    AggTeamComponent,
    AggAuthorComponent
  ]
})
export class AggregateModule {
}
