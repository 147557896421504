import { Injectable } from '@angular/core';
import { Page } from '../../../../models/page';
import { Team } from '../../../../models/team';
import { createAction, props, Store } from '@ngrx/store';

export class TeamsPageConstantAction {
  static readonly LOAD_TEAMS_PAGE_START = '[TeamsPage].LOAD_TEAMS_PAGE_START';
  static readonly LOAD_TEAMS_PAGE_SUCCEEDED = '[TeamsPage].LOAD_TEAMS_PAGE_SUCCEEDED';
  static readonly LOAD_TEAMS_PAGE_FAILED = '[TeamsPage].LOAD_TEAMS_PAGE_FAILED';
}

export const loadTeamsPageStartAction = createAction(TeamsPageConstantAction.LOAD_TEAMS_PAGE_START);
export const loadTeamsPageSucceedAction = createAction(TeamsPageConstantAction.LOAD_TEAMS_PAGE_SUCCEEDED, props<{payload:Page<Team>}>());
export const loadTeamsPageFailedAction = createAction(TeamsPageConstantAction.LOAD_TEAMS_PAGE_FAILED, props<{error:Error}>());

@Injectable({
  providedIn: 'root'
})
export class TeamsPageAction {

  constructor( private localStore: Store ) {
  }

  public loadTeamsPageStarted(): void {
    this.localStore.dispatch(loadTeamsPageStartAction());
  }

  public loadTeamsPageSucceeded(payload: Page<Team>): void {
    this.localStore.dispatch(loadTeamsPageSucceedAction({ payload } ));
  }

  public loadTeamsPageFailed(error: any) {
    this.localStore.dispatch(loadTeamsPageFailedAction({error}));
  }
}
