import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {routerPathReducer} from './reducer/router-path.reducer';


export const routerPathStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ROUTER_PATH,
    reducer: routerPathReducer
  })
);


