import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DialogModalComponent } from './ui/modal/dialog-modal/dialog-modal.component';
import { DebugDataComponent } from './ui/debug-data/debug-data.component';
import { TooltipModule } from './ui/tooltip/tooltip.module';
import { PdfModule } from './ui/pdf/pdf.module';
import { SinceModule } from './ui/since/since.module';
import { NxI18nPipeModule } from './ui/nxI18nPipe/nxI18nPipe.module';
import { LoaderModule } from './ui/loader/loader.module';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { DataModule } from './ui/datas/data.module';
import { LayoutModule } from './ui/layout/layout.module';
import { MenuModule } from './ui/menu/menu.module';
import { WorkspaceModule } from './ui/workspace/workspace.module';
import { AvatarModule } from './ui/avatar/avatar.module';
import { AggregateModule } from './ui/aggregate/aggregate.module';
import { CommentsModule } from './ui/comments/comments.module';
import { Txt2htmlModule } from './ui/txt2html/txt2html.module';
import { NavigationModule } from './ui/navigation/navigation.module';
import { ProgressBarModule } from './ui/progress-bar/progress-bar.module';
import { OrderByModule } from './ui/orderBy-pipe/orderBy-pipe.module';
import { CapitalizeFirstLetterModule } from './ui/capitalize-firstLetter/capitalize-firstLetter.module';
import { LocaleService } from './services/locale/locale.service';
import { UserModule } from './ui/user/user.module';
import { MaintenanceInfoComponent } from './ui/maintenance-info/maintenance-info.component';
import { ScreenWidthService } from './services/responsive/screen-width.service';
import { MobileMenuModule } from './ui/mobile-menu/mobile-menu.module';
import { ScrollEventService } from './services/event/scroll-event.service';
import { DomainSelectionModalComponent } from './ui/modal/domain-selection-modal/domain-selection-modal.component';
import { LogoNexiaModule } from './ui/logo-nexia/logo-nexia.module';
import { MobileSearchFiltersModule } from './ui/mobile-search-filters/mobile-search-filters.module';
import { ResultsCountComponent } from './ui/results-count/results-count.component';
import { ThesaurusModule } from './ui/thesaurus/thesaurus.module';
import { ButtonModule } from './ui/buttons/button.module';
import { InformationModalComponent } from './ui/modal/information-modal/information-modal.component';
import { ResultsPageSizeComponent } from './ui/results-pageSize/results-page-size.component';
import { GedResultsSortSelectorComponent } from './ui/results-sort-selector/ged/ged-results-sort-selector.component';
import { WkfResultsSortSelectorComponent } from './ui/results-sort-selector/wkf/wkf-results-sort-selector.component';
import {
  GedResultsPaginationHeaderComponent
} from './ui/results-pagination-header/ged/ged-results-pagination-header.component';
import {
  WkfResultsPaginationHeaderComponent
} from './ui/results-pagination-header/wkf/wkf-results-pagination-header.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { PiperActionsModalComponent } from './ui/modal/piper-actions-modal/piper-actions-modal.component';
import { AutocompleteComponent } from './ui/autocomplete/autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '../shared-directives/click-outside.directive';
import { OfficeCheckinModalComponentComponent } from './ui/modal/office-checkin-modal-component/office-checkin-modal-component.component';


registerLocaleData(localeFr);
registerLocaleData(localeEn);


const loadedModules = [
  SinceModule,
  NxI18nPipeModule,
  CapitalizeFirstLetterModule,
  Txt2htmlModule,
  OrderByModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TooltipModule,
  DataModule,
  RouterModule,
  NavigationModule,
  MatDialogModule,
  ThesaurusModule,
  ButtonModule
];

@NgModule({
    imports: [
        ...loadedModules,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        TranslateModule,
        ClickOutsideDirective
    ],
  declarations: [
    PaginationComponent,
    DebugDataComponent,
    DialogModalComponent,
    DomainSelectionModalComponent,
    MaintenanceInfoComponent,
    ResultsCountComponent,
    InformationModalComponent,
    ResultsPageSizeComponent,
    GedResultsSortSelectorComponent,
    WkfResultsSortSelectorComponent,
    GedResultsPaginationHeaderComponent,
    WkfResultsPaginationHeaderComponent,
    LoadingSpinnerComponent,
    PiperActionsModalComponent,
    AutocompleteComponent,
    OfficeCheckinModalComponentComponent,
  ],
  exports: [
    ...loadedModules,
    AggregateModule,
    ProgressBarModule,
    PdfModule,
    LoaderModule,
    PaginationComponent,
    DebugDataComponent,
    MaintenanceInfoComponent,
    ResultsCountComponent,
    LogoNexiaModule,
    LayoutModule,
    MenuModule,
    WorkspaceModule,
    MobileMenuModule,
    MobileSearchFiltersModule,
    AvatarModule,
    CommentsModule,
    DomainSelectionModalComponent,
    DialogModalComponent,
    InformationModalComponent,
    UserModule,
    ResultsPageSizeComponent,
    GedResultsSortSelectorComponent,
    WkfResultsSortSelectorComponent,
    GedResultsPaginationHeaderComponent,
    WkfResultsPaginationHeaderComponent,
    LoadingSpinnerComponent,
    PiperActionsModalComponent,
    AutocompleteComponent
  ],
  providers: [
    ScreenWidthService,
    ScrollEventService,
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService) => localeService.getLocale()
    }
  ]
})
export class CoreModule {
}
