import {ToastaService} from 'ngx-toasta';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDetailsAction {

  protected constructor(
    protected translateService: TranslateService,
    protected toastaService: ToastaService,
    protected store: Store
  ) {
  }

  protected _dispatchType(action: any): void {
    this.store.dispatch(action);
  }

  protected _dispatchSuccess(action: any): void {
    this.store.dispatch(action);
  }

  protected _dispatchFail(action: any, showToasta = true): void {
    this.store.dispatch(action);
    if (showToasta) {
      const dispatchType = 'TEMP'
      this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.' + dispatchType));
    }
  }
}
