import {Injectable} from '@angular/core';
import {SearchItem} from '../../../../models/search-item';
import {createAction, props, Store} from '@ngrx/store';
import {ISearchItemsAction} from '../../../search-item/search-items-action-interface';

export class EntitySearchItemsActionConstant {
  static readonly ADD_ENTITY_SEARCH_ITEM_START = 'ADD_ENTITY_SEARCH_ITEM_START';
  static readonly ADD_ENTITY_SEARCH_ITEM_SUCCEEDED = 'ADD_ENTITY_SEARCH_ITEM_SUCCEEDED';
  static readonly ADD_ENTITY_SEARCH_ITEM_FAILED = 'ADD_ENTITY_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ENTITY_SEARCH_ITEM_START = 'REMOVE_ENTITY_SEARCH_ITEM_START';
  static readonly REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED = 'REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED';
  static readonly REMOVE_ENTITY_SEARCH_ITEM_FAILED = 'REMOVE_ENTITY_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED = 'REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED';
}

export const addEntitySearchItemStartAction = createAction(
  EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_START
);

export const addEntitySearchItemSucceededAction = createAction(
  EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_SUCCEEDED,
  props<{ payload: SearchItem }>()
);

export const addEntitySearchItemFailedAction = createAction(
  EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeEntitySearchItemStartAction = createAction(
  EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_START
);

export const removeEntitySearchItemSucceededAction = createAction(
  EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED,
  props<{ payload: SearchItem }>()
);

export const removeEntitySearchItemFailedAction = createAction(
  EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_FAILED,
  props<{ error: Error }>()
);

export const removeAllEntitySearchItemsSucceededAction = createAction(
  EntitySearchItemsActionConstant.REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED
);


@Injectable({
  providedIn: 'root'
})
export class EntitySearchItemsAction implements ISearchItemsAction {

  constructor(private localStore: Store) {
  }

  public addSearchItemStarted() {
    this.localStore.dispatch(addEntitySearchItemStartAction());
  }

  public addSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch(addEntitySearchItemSucceededAction({payload: searchItem}));
  }

  public addSearchItemFailed(error: Error) {
    this.localStore.dispatch(addEntitySearchItemFailedAction({error}));
  }

  public removeSearchItemStarted() {
    this.localStore.dispatch(removeEntitySearchItemStartAction());
  }

  public removeSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch(removeEntitySearchItemSucceededAction({payload: searchItem}));
  }

  public removeSearchItemFailed(error: Error) {
    this.localStore.dispatch(removeEntitySearchItemFailedAction({error}));
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch(removeAllEntitySearchItemsSucceededAction());
  }
}
