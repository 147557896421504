import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {currentTeamUsersPageReducer} from './reducer/current-team-users-page.reducer';

export const currentTeamUsersPageStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.CURRENT_TEAM_USERS_PAGE,
    reducer: currentTeamUsersPageReducer
  })
);
