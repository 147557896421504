import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../core/models/store-keys';
import {AuthenticationState} from './authentication-state';


export const selectAuthenticationFeature = createFeatureSelector<AuthenticationState>(StoreKeys.AUTHENTICATION);


export const selectAuthenticationAuthenticated = createSelector(selectAuthenticationFeature, state => state.authenticated);

