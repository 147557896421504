import { Aggregate } from '../../../../models/aggregate';
import { EsPage } from '../../../../models/es-page';
import { CdxDocument } from '../../../../models/cdx-document';
import {
  loadAggregateFailedAction,
  loadAggregateStartAction,
  loadAggregateSucceededAction,
  loadDocumentsFailedAction,
  loadDocumentsStartAction,
  loadDocumentsSucceededAction,
  updateDocumentsFailedAction,
  updateDocumentsStartAction,
  updateDocumentsSucceededAction,
  updateDocumentSucceededAction
} from '../action/document-search-result.action';
import { createReducer, on } from '@ngrx/store';

export const RESULT_INITIAL_STATE: DocumentSearchResultState = {
  datas: null,
  loading: false,
  loadAgg: false,
  error: null,
};

export interface DocumentSearchResultState {
  datas: EsPage<CdxDocument>;
  loading: boolean;
  loadAgg: boolean;
  error: any;
}

/*
export const documentSearchResultReducerOld = (state: DocumentSearchResultState = RESULT_INITIAL_STATE, action: PayloadAction<EsPage<CdxDocument>>) => {
  switch (action.type) {
    case DocumentSearchResultConstant.LOAD_AGGREGATE_START:
      return {
        ...state,
        loading: true,
        loadAgg: true,
        error: DocumentSearchResultConstant.ERROR_START,
      };
    case DocumentSearchResultConstant.LOAD_RESULT_ITEMS_START:
    case DocumentSearchResultConstant.UPDATE_DOCUMENTS_START:
      return {
        ...state,
        loading: true,
        error: DocumentSearchResultConstant.ERROR_START,
      };
    case DocumentSearchResultConstant.LOAD_RESULT_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: Object.assign({}, action.payload, {aggs: action.payload.aggs.map(agg => new Aggregate(agg.path, agg.label, agg.buckets, agg.numberOfElements))}),
        loading: false,
        error: null,
      };
    case DocumentSearchResultConstant.LOAD_RESULT_ITEMS_FAILED:
      return {
        ...state,
        datas: undefined,
        loading: false,
        error: action.error,
      };
    case DocumentSearchResultConstant.LOAD_AGGREGATE_SUCCEEDED:
      const newAgg: Aggregate = action.payload.aggs[0];
      let aggs: Aggregate[] = state.datas.aggs;
      let added = false;
      aggs = aggs.map(agg => {
        if (agg.path === newAgg.path) {
          added = true;
          return newAgg;
        }
        return agg;
      });
      if (!added) {
        aggs.push(newAgg);
      }
      const copy = {...state.datas, aggs: aggs};
      return {
        ...state,
        datas: copy,
        loading: false,
        loadAgg: false,
        error: null,
      };
    case DocumentSearchResultConstant.UPDATE_DOCUMENT_SUCCEEDED:
      // add new cdxDoc inside state page content
      state.datas.content.splice(
        state.datas.content.map(doc => doc.cdx_id).indexOf(action.payload.content[0].cdx_id),
        1, action.payload.content[0]);
      console.log(state.datas);
      return {
        ...state,
        datas: {
          ...state.datas,
          content: state.datas.content
        },
        loading: false,
        loadAgg: false,
        error: null,
      };
    case DocumentSearchResultConstant.LOAD_AGGREGATE_FAILED:
      return {
        ...state,
        loading: false,
        loadAgg: false,
        error: action.error,
      };
    case DocumentSearchResultConstant.UPDATE_DOCUMENTS_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          content: action.payload.content,
          numberOfElements: action.payload.numberOfElements,
          size: action.payload.size,
          page: action.payload.page
        },
        loading: false,
        error: null,
      };
    case DocumentSearchResultConstant.UPDATE_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: true,
        error: action.error,
      };
  }
  return state;
};
*/

export const documentSearchResultReducer = createReducer(
  // Initial State
  RESULT_INITIAL_STATE,

  // Start Actions
  on(
    loadDocumentsStartAction,
    updateDocumentsStartAction,
    loadAggregateStartAction,
    (state) => ({
      ...state,
      loading: true,
      loadAgg: true,
      error: 'ERROR_START', // Remplir avec la valeur correcte si nécessaire
    })
  ),

  // Succeeded Actions
  on(loadDocumentsSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),

  on(updateDocumentSucceededAction, (state, {payload}) => {
    const updatedDoc = payload.content[0];
    const contentIndex = state.datas.content.findIndex(doc => doc.cdx_id === updatedDoc.cdx_id);

    if (contentIndex !== -1) {
      state.datas.content.splice(contentIndex, 1, updatedDoc);
    }

    return {
      ...state,
      datas: {
        ...state.datas,
        content: state.datas.content,
      },
      loading: false,
      loadAgg: false,
      error: null,
    };
  }),

  on(loadAggregateSucceededAction, (state, {payload}) => {
    const newAgg: Aggregate = payload.aggs[0];
    let aggs: Aggregate[] = state.datas.aggs;
    let added = false;

    aggs = aggs.map(agg => {
      if (agg.path === newAgg.path) {
        added = true;
        return newAgg;
      }
      return agg;
    });

    if (!added) {
      aggs.push(newAgg);
    }

    const copy = {...state.datas, aggs: aggs};

    return {
      ...state,
      datas: copy,
      loading: false,
      loadAgg: false,
      error: null,
    };
  }),

  on(updateDocumentsSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      content: payload.content,
      numberOfElements: payload.numberOfElements,
      size: payload.size,
      page: payload.page,
    },
    loading: false,
    error: null,
  })),

  // Failed Actions
  on(
    loadDocumentsFailedAction,
    updateDocumentsFailedAction,
    loadAggregateFailedAction,
    (state, {error}) => ({
      ...state,
      loading: false,
      loadAgg: false,
      error,
    })
  )
);
