import {View} from '../../../../models/view';
import {
  loadViewFailedAction,
  loadViewStartAction,
  loadViewSucceededAction
} from '../action/entity-view.action';
import {createReducer, on} from '@ngrx/store';

export interface EntityViewState {
  datas: { [key: string]: View };
  loading: boolean;
  error: any;
}

export const VIEW_INITIAL_STATE: EntityViewState = {
  datas: {},
  loading: false,
  error: null,
};

export const entityViewReducer = createReducer(
  // Initial State
  VIEW_INITIAL_STATE,

  // Start Actions
  on(loadViewStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Succeeded Actions
  on(loadViewSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {...state.datas, ...payload},
    loading: false,
    error: null,
  })),

  // Failed Actions
  on(loadViewFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  }))
);
