import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {TypedAction} from '@ngrx/store/src/models';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractCfgFieldAction {

  protected constructor(
    protected store: Store
  ) {
  }
  protected _dispatchType(action: TypedAction<string>): void {
    this.store.dispatch(action);
  }

  protected _dispatchSuccess(action: TypedAction<string>): void {
    this.store.dispatch(action);
  }

  protected _dispatchFail(action: TypedAction<string>): void {
    this.store.dispatch(action);
  }
}
