import { createReducer, on } from '@ngrx/store';
import { Utils } from '../../../utils/utils';
import {
  loadCriticalDataAllSucceededAction,
  loadCriticalDataSucceededAction,
  loadCriticalPdfSucceededAction,
  loadCriticalStartedAction,
  loadViewCriticalDataSucceededAction,
  removeCriticalDataSucceededAction,
  removeViewCriticalDataSucceededAction,
  setCriticalDataToNullSucceededAction
} from '../action/critical-data.action';
import { CriticalData } from '../../../models/critical.data';

export class CriticalDataState {
  datas: CriticalData;
  loading: boolean;
  error: any;
}

export const CRITICAL_DATA_INITIAL_STATE: CriticalDataState = {
  datas: null,
  loading: false,
  error: null,
};

/*
const criticalDataReducerOld = (state: CriticalDataState = CRITICAL_DATA_INITIAL_STATE, action: PayloadAction<CriticalData>) => {
  switch (action.type) {
    case CriticalDataActionConstant.LOAD_CRITICAL_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_DATA_SUCCEEDED:
      const visibleCriticalFields: string[] = Utils.notNullAndNotUndefined(state.datas) && Utils.notNullAndNotUndefined(state.datas.visibleCriticalFields) ? [...state.datas.visibleCriticalFields] : [];
      visibleCriticalFields.push(...action.payload.visibleCriticalFields);
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_VIEW_CRITICAL_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          viewCriticalFields: action.payload.viewCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_DATA_ALL_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields: action.payload.visibleCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_PDF_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalPdf: action.payload.visibleCriticalPdf
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.REMOVE_CRITICAL_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields: []
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          viewCriticalFields: []
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.SET_CRITICAL_DATA_TO_NULL_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const criticalDataReducer = createReducer(
  CRITICAL_DATA_INITIAL_STATE,
  on(loadCriticalStartedAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCriticalDataSucceededAction, (state, {payload}) => {
    const visibleCriticalFields: string[] = Utils.notNullAndNotUndefined(state.datas) && Utils.notNullAndNotUndefined(state.datas.visibleCriticalFields) ? [...state.datas.visibleCriticalFields] : [];
    visibleCriticalFields.push(...payload.visibleCriticalFields);
    return {
      ...state,
      datas: {
        ...state.datas,
        visibleCriticalFields
      },
      loading: false,
      error: null,
    };
  }),
  on(loadViewCriticalDataSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      viewCriticalFields: payload.viewCriticalFields
    },
    loading: false,
    error: null,
  })),
  on(loadCriticalDataAllSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      visibleCriticalFields: payload.visibleCriticalFields
    },
    loading: false,
    error: null,
  })),
  on(loadCriticalPdfSucceededAction, (state, {payload}) => ({
    ...state,
    datas: {
      ...state.datas,
      visibleCriticalPdf: payload.visibleCriticalPdf,
      viewCriticalPdf: payload.viewCriticalPdf
    },
    loading: false,
    error: null,
  })),
  on(removeCriticalDataSucceededAction, (state) => ({
    ...state,
    datas: {
      ...state.datas,
      visibleCriticalFields: []
    },
    loading: false,
    error: null,
  })),
  on(removeViewCriticalDataSucceededAction, state => ({
    ...state,
    datas: {
      ...state.datas,
      viewCriticalFields: []
    },
    loading: false,
    error: null,
  })),
  on(setCriticalDataToNullSucceededAction, state => ({
    ...state,
    datas: null,
    loading: false,
    error: null,
  }))
);
