import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../../models/store-keys';
import {teamsPageReducer} from './reducer/teams-page.reducer';


export const teamPageStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TEAMS_PAGE,
    reducer: teamsPageReducer
  })
);
