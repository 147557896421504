import {CanActivateFn} from '@angular/router';
import {catchError, filter, map, take} from 'rxjs/operators';
import {combineLatest, Observable, of, timeout} from "rxjs";
import {selectMenuItemsDatas} from "../redux/menu/menu-selectors";
import {selectAuthenticationAuthenticated} from "../../modules/authentication/authentication.selectors";
import {ConfigurationService} from "../../modules/configuration/configuration.service";
import {inject} from "@angular/core";
import {I18nService} from "../services/i18n/i18n.service";
import {Store} from "@ngrx/store";



function trueIfDefinedOrTimeoutPipe<T>(taskName:string, observable: Observable<T>, timeoutDelay = 5000) {
  return observable.pipe(
  map<any, boolean>(value => !!value),
  filter<boolean>(value => !!value),
    //tap(v => console.log(`${taskName} has emitted ${v}`)),
  take(1),
  timeout(timeoutDelay),
  catchError(e => {
    console.error(`Erreur timeout for task=${taskName}`, e);
    return of(false)
  }))
}

export const appReadyGuard: CanActivateFn = (route, state,
                                             configService: ConfigurationService = inject(ConfigurationService),
  i18nService: I18nService = inject(I18nService),
  store: Store = inject(Store)) => {

  //console.log(`START app ready guard for page = ${state.url}`)
  const configReady$ = trueIfDefinedOrTimeoutPipe('config', configService.applicationConfigReady$);
  const i18nReady$= trueIfDefinedOrTimeoutPipe('i18n', i18nService.i18nLoaded$);
  const menusReady$ = trueIfDefinedOrTimeoutPipe('menusReady', store.select(selectMenuItemsDatas).pipe(
    map(menus => menus != null)));

  const isAuthenticated$ = trueIfDefinedOrTimeoutPipe('authenticated',store.select(selectAuthenticationAuthenticated)
    .pipe(filter(auth => !!auth)));

  return combineLatest([configReady$, i18nReady$, menusReady$, isAuthenticated$]).pipe(
    // tap(values => console.log(`Values = `, values)),
    map(values => values.every(v => v ===true)),
    // tap((ok: boolean) => {
    //   console.log(`END is app Ready for page ${route.url} ${state.url} ? : ${ok}`)
    // })
  )
};
