import {SearchItem} from '../../../../models/search-item';
import {
  addEntitySearchItemFailedAction,
  addEntitySearchItemStartAction,
  addEntitySearchItemSucceededAction,
  removeAllEntitySearchItemsSucceededAction,
  removeEntitySearchItemFailedAction,
  removeEntitySearchItemStartAction,
  removeEntitySearchItemSucceededAction
} from '../action/entity-search-items.action';
import {createReducer, on} from '@ngrx/store';
import * as _ from 'lodash';


export interface EntitySearchItemsState {
  datas: SearchItem[];
  loading: boolean;
  error: any;
}

export const ENTITY_SEARCH_ITEMS_INITIAL_STATE: EntitySearchItemsState = {
  datas: [],
  loading: false,
  error: null,
};

export const entitySearchItemsReducer = createReducer(
  // Initial State
  ENTITY_SEARCH_ITEMS_INITIAL_STATE,

  // Start Actions
  on(
    addEntitySearchItemStartAction,
    removeEntitySearchItemStartAction,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),

  // Succeeded Actions
  on(addEntitySearchItemSucceededAction, (state, {payload}) => {
    const item: SearchItem = Object.assign(new SearchItem(payload.id, payload.keyword, payload.path, payload.value, payload.canDelete, payload.fieldType, payload.operator, payload.label, payload.oldValue), payload);
    const size = state.datas.length;
    const lastItemId = state.datas.length ? state.datas[state.datas.length - 1].id : 0;
    if (item.id === -1) {
      item.id = size > lastItemId ? size : lastItemId + 1;
      return {
        ...state,
        datas: state.datas.concat(item),
        loading: false,
        error: null,
      };
    } else {
      const itemIndex = state.datas.findIndex((stateItem) => stateItem.id === item.id);
      const datasCopy = _.cloneDeep(state.datas);
      datasCopy.splice(itemIndex, 1, item);
      return {
        ...state,
        datas: datasCopy,
        loading: false,
        error: null,
      };
    }
  }),

  // Failed Actions
  on(
    addEntitySearchItemFailedAction,
    removeEntitySearchItemFailedAction,
    (state, {error}) => ({
      ...state,
      loading: false,
      error,
    })
  ),

  // Remove Succeeded Actions
  on(removeEntitySearchItemSucceededAction, (state, {payload}) => {
    const copy = state.datas.filter(oneItem => {
      return oneItem.id !== payload.id;
    });
    return {
      ...state,
      datas: copy,
      loading: false,
      error: null,
    };
  }),

  // Remove All Succeeded Action
  on(removeAllEntitySearchItemsSucceededAction, (state) => ({
    ...state,
    datas: [],
    loading: false,
    error: null,
  }))
);
