import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { DetailsVersion } from '../../../models/details-version';

export class VersionListActionConstant {
  static readonly LOAD_VERSION_LIST_STARTED = '[VersionList]: LOAD_VERSION_LIST_STARTED';
  static readonly LOAD_VERSION_LIST_SUCCEEDED = '[VersionList]: LOAD_VERSION_LIST_SUCCEEDED';
  static readonly LOAD_VERSION_LIST_FAILED = '[VersionList]: LOAD_VERSION_LIST_FAILED';
  static readonly REMOVE_VERSION_LIST_SUCCEEDED = '[VersionList]: REMOVE_VERSION_LIST_SUCCEEDED';
}

export const loadVersionListStartedAction = createAction(VersionListActionConstant.LOAD_VERSION_LIST_STARTED);
export const loadVersionListSucceeded = createAction(VersionListActionConstant.LOAD_VERSION_LIST_SUCCEEDED, props<{ payload:DetailsVersion[] }>());
export const loadVersionListFailed = createAction(VersionListActionConstant.LOAD_VERSION_LIST_FAILED, props<{error: Error}>());
export const removeVersionListSucceeded = createAction(VersionListActionConstant.REMOVE_VERSION_LIST_SUCCEEDED);

@Injectable({
  providedIn: 'root'
})
export class VersionListAction {

  constructor(private localStore: Store) {
  }

  public loadVersionListStarted(): void {
    return this.localStore.dispatch(loadVersionListStartedAction());
  }

  public loadVersionListSucceeded(payload: DetailsVersion[]): void {
    return this.localStore.dispatch(loadVersionListSucceeded({payload}));
  }

  public loadVersionListFailed(error: any): void {
    return this.localStore.dispatch(loadVersionListFailed({error}));
  }

  public removeVersionList(): void {
    return this.localStore.dispatch(removeVersionListSucceeded());
  }
}
