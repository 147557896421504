

export class DateUtils {

  public static timestampAsHumanReadable(timestamp: number): string {
    return new Date(timestamp * 1000).toISOString();
  }

  public static timestampAsDate(timestamp: number): Date {
    return new Date(timestamp * 1000);
  }

  /** ex. "2024-04-26_10-49-31_043" */
  public static dateToHorodatage(dateRef: Date = null): string {
    const digit2 = function(a) { return (a < 10 ? '0' : '')+a; }
    const digit3 = function(a) { return (a < 100 ? '0' : '')+(a < 10 ? '0' : '')+a; }

    const date = dateRef == null ? new Date() : dateRef;
    return `${date.getFullYear()}-${digit2(date.getMonth() + 1)}-${digit2(date.getDate())}_${digit2(date.getHours())}-${digit2(date.getMinutes())}-${digit2(date.getSeconds())}_${digit3(date.getMilliseconds())}`;
  }


}
