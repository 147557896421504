import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, throwError, timeout} from 'rxjs';
import {filter, map, take, tap, timeoutWith} from 'rxjs/operators';
import {Manager, Socket} from 'socket.io-client';
import * as io from 'socket.io-client'
import {AuthenticationService} from '../../../modules/authentication/authentication.service';
import {ApplicationConfigVarNames} from '../../../modules/configuration/application-config-var-names';

import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {MaintenanceInfo} from '../../models/maintenance-info';
import {SocketData, SocketDataStatus} from '../../models/SocketData';
import {UserSocketHttpResponse} from '../../models/user-socket-http-response';
import {MaintenanceInfoService} from '../../redux/maintenance-info/service/maintenance-info.service';
import {Utils} from '../../utils/utils';

export enum Namespace {
  documents = 'documents',
  entities = 'entities',
  drafts = 'drafts',
  users = 'users'
}

export enum SocketEventName {
  connect = 'connect',
  disconnect = 'disconnect',
  authenticated = 'authenticated',

}

export enum Event {
  transaction = 'transaction',
  system = 'system',
}

export class SocketEvent {
  namespace: Namespace;
  event: string;

  constructor(namespace: Namespace, event: string) {
    this.namespace = namespace;
    this.event = event;
  }
}

class SocketForNamespace {
  constructor(public namespace: string, public socket: Socket) {
  }
}

class SocketStore {
  private sockets: SocketForNamespace[] = [];

  public tryGetSocket(namespace: string): SocketForNamespace|null {
    const socketNs = this.sockets.find(s => s.namespace == namespace)
    if (!!socketNs) {
      return socketNs.socket;
    }
    return null;
  }

  public add(namespace: string, socket: Socket) {
    this.sockets.push(new SocketForNamespace(namespace, socket))
  }
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public static readonly DEFAULT_TIMEOUT = 120000;
  public static readonly PIPER_ERRORS_TIMEOUT = 'PIPER_ERRORS.TIMEOUT';
  public static readonly PIPER_ERRORS = 'PIPER_ERRORS.';
  public static readonly TRANSACTION_ID = 'transactionId';
  public timeout;

  public currentUserEvent$: Observable<SocketData>;
  public globalUsersEvents$: Observable<SocketData>;
  private socketEvents$: BehaviorSubject<SocketEvent> = new BehaviorSubject(null);

  private socketStore = new SocketStore();

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private config: ConfigurationService,
    private maintenanceInfoService: MaintenanceInfoService,
  ) {
  }

  // tslint:disable-next-line:member-ordering
  private static getRoomSet(socket: Socket): Set<string> {
    return socket.rooms as Set<string>;
  }

  /**
   * call after authentication
   */

  public init() {
    const userId: string = '' + this.authenticationService.getUserCredentials().id;
    const currentUserObservable$: Subject<SocketData> = new Subject<SocketData>();
    const globalUsersObservable$: Subject<SocketData> = new Subject<SocketData>();
    this.join(Namespace.users, userId)
      .subscribe(socket => {
        socket.on(Event.transaction, (room: string, datas: any) => {
          if (room !== userId) {
            return;
          }
          currentUserObservable$.next(SocketData.build(Namespace.users, userId, datas));
        });
      });
    this.join(Namespace.users, '_all').subscribe(socket => {
      socket.on(Event.system, (room: string, datas: any) => {
        globalUsersObservable$.next(SocketData.build(Namespace.users, room, datas));
      });
    });
    this.currentUserEvent$ = currentUserObservable$;
    this.globalUsersEvents$ = globalUsersObservable$;
    this.setConfigTimeout();
    this.connectToGlobalUsersEvents();
  }

  /**
   * Ajoute un httpParams 'transactionId'
   * POST la requête sur httpClient
   * Attend le retour du socket user sur cet id de transaction
   * Renvoie un observable contenant l'id de l'objet créé
   *
   * @param url
   * @param body
   * @param options
   *
   * @throws timeout, après SocketService.TIMEOUT
   * @throws httpError de l'httpClient
   * @throws new Error si le socket FAILED
   */
  public post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body' | 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'text';
    withCredentials?: boolean;
    timeout?: number;
  }): Observable<UserSocketHttpResponse> {
    let defaultTimeout = this.timeout;
    if (!!options && !!options.timeout) {
      defaultTimeout = options.timeout;
    }
    const updatedOptions = this.pushTransactionId(options);
    updatedOptions.observe = 'response';
    const transactionId = (updatedOptions.params as HttpParams).get(SocketService.TRANSACTION_ID);
    return combineLatest([this.currentUserEvent$, this.httpClient.post(url, body, updatedOptions)])
      .pipe(
        filter(value => {
          return !!value[1];
        }),
        timeout({each: defaultTimeout, with:() => throwError(() => new Error(SocketService.PIPER_ERRORS_TIMEOUT))}),
        filter(
          value => (
            value[0] && value[0].datas && value[0].datas.transactionId === transactionId
          )
        ),
        map(value => {
          const socketData: SocketData = value[0];
          if (socketData.status === SocketDataStatus.FAILED) {
            throw new Error(SocketService.PIPER_ERRORS + value[0].data.type + '.' + value[0].data.action);
          }
          return new UserSocketHttpResponse(socketData.id, socketData, <unknown>value[1] as HttpResponse<any>);
        }),
        take(1)
      );
  }

  /**
   * Ajoute un httpParams 'transactionId'
   * PUT la requête sur httpClient
   * Attend le retour du socket user sur cet id de transaction
   * Renvoie un observable contenant l'id de l'objet MAJ
   *
   * @param url
   * @param body
   * @param options
   *
   * @throws timeout, après SocketService.TIMEOUT
   * @throws httpError de l'httpClient
   * @throws new Error si le socket FAILED
   */
  public put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body' | 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'text';
    withCredentials?: boolean;
  }): Observable<UserSocketHttpResponse> {

    const updatedOptions = this.pushTransactionId(options);
    const transactionId = (updatedOptions.params as HttpParams).get(SocketService.TRANSACTION_ID);
    return combineLatest([this.currentUserEvent$, this.httpClient.put(url, body, updatedOptions)])
      .pipe(
        filter(value => !!value[1]),
        timeoutWith(this.timeout, throwError(new Error(SocketService.PIPER_ERRORS_TIMEOUT))),
        filter(value => (value[0] && value[0].datas && value[0].datas.transactionId === transactionId)),
        map(value => {
          const socketData: SocketData = value[0];
          if (socketData.status === SocketDataStatus.FAILED) {
            throw new Error(SocketService.PIPER_ERRORS + value[0].data.type + '.' + value[0].data.action);
          }
          return new UserSocketHttpResponse(socketData.id, socketData, <unknown>value[1] as HttpResponse<any>);
        }),
        take(1)
      );
  }

  /**
   * Ajoute un httpParams 'transactionId'
   * DELETE la requête sur httpClient
   * Attend le retour du socket user sur cet id de transaction
   * Renvoie un observable contenant l'id de l'objet MAJ
   *
   * @param url
   * @param options
   *
   * @throws timeout, après SocketService.TIMEOUT
   * @throws httpError de l'httpClient
   * @throws new Error si le socket FAILED
   */
  public delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body' | 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'text';
    withCredentials?: boolean;
  }): Observable<UserSocketHttpResponse> {

    const updatedOptions = this.pushTransactionId(options);
    const transactionId = (updatedOptions.params as HttpParams).get(SocketService.TRANSACTION_ID);
    return combineLatest([this.currentUserEvent$, this.httpClient.delete(url, updatedOptions)])
      .pipe(
        // withLatestFrom(this.httpClient.delete(url, updatedOptions)),
        filter(value => !!value[1]),
        timeoutWith(this.timeout, throwError(new Error(SocketService.PIPER_ERRORS_TIMEOUT))),
        filter(value => (value[0] && value[0].datas && value[0].datas.transactionId === transactionId)),
        map(value => {
          const socketData: SocketData = value[0];
          if (socketData.status === SocketDataStatus.FAILED) {
            throw new Error(SocketService.PIPER_ERRORS + value[0].data.type + '.' + value[0].data.action);
          }
          return new UserSocketHttpResponse(socketData.id, socketData, <unknown>value[1] as HttpResponse<any>);
        }),
        take(1)
      );
  }

  join(namespace: Namespace, room: string): Observable<Socket> {
    return this.getSocket(namespace)
      .pipe(
        tap(socket => {
          if (SocketService.getRoomSet(socket).has(room)) {
            return;
          }
          this._join(socket, room); // retourner
          (SocketService.getRoomSet(socket)).add(room);
        })
      );
  }

  leave(namespace: Namespace, room: string): Observable<Socket> {
    return this.getSocket(namespace)
      .pipe(
        tap(socket => {
          if (!SocketService.getRoomSet(socket).has(room)) {
            return;
          }
          socket.emit('leave', room);
          SocketService.getRoomSet(socket).delete(room);
        })
      );
  }

  private setConfigTimeout(): void {
    const configGlobalTimeout = +this.config.getConfigVariable(ApplicationConfigVarNames.GLOBAL_TIMEOUT);
    if (!!configGlobalTimeout) {
      this.timeout = configGlobalTimeout;
    } else {
      this.timeout = SocketService.DEFAULT_TIMEOUT;
    }
  }

  private connectToGlobalUsersEvents(): void {
    this.globalUsersEvents$.subscribe((globalUserSocketData: SocketData) => {
      const maintenanceInfo: MaintenanceInfo = new MaintenanceInfo();
      maintenanceInfo.type = globalUserSocketData.data.type;
      maintenanceInfo.ttl = globalUserSocketData.data.ttl;
      maintenanceInfo.date = globalUserSocketData.data.date;
      this.maintenanceInfoService.loadMaintenanceInfo(maintenanceInfo);
    });
  }

  private pushTransactionId(options: any): any {
    if (!options) {
      options = {};
    }
    let params: HttpParams;
    if (!!options.params) {
      params = options.params;
    } else {
      params = new HttpParams();
    }
    const transactionId = Utils.uuid();
    // check if redirect url for passing it to isSocketMessageReady
    params = params.set(SocketService.TRANSACTION_ID, transactionId);
    options.params = params;
    return options;
  }

  private initNamespace(namespace: Namespace): Observable<Socket> {
    return new Observable(observer => {
      const socket: Socket = io(this.getSocketUrl() + '/' + namespace);
      this.socketStore.add(namespace, socket);
      socket.rooms = new Set<string>();
      socket
        .on('authenticated', () => {
          console.debug('authenticated on nsp ', namespace);
          socket.auth = true;
          SocketService.getRoomSet(socket).forEach(room => {
            this._join(socket, room);
          });
          this.socketEvents$.next(new SocketEvent(namespace, 'authenticated'));
          observer.next(socket);
        })
        .on(SocketEventName.connect, () => {
          this.socketEvents$.next(new SocketEvent(namespace, SocketEventName.connect));
          socket.emit('authenticate', {
            'Authorization': this.authenticationService.getAuthorizationToken(false)
          });
        })
        .on(SocketEventName.disconnect, () => {
          this.socketEvents$.next(new SocketEvent(namespace, SocketEventName.disconnect));
          socket.auth = false;
          //console.debug('disconnect on nsp ', namespace);
        })
        .on('reconnect', () => {
          this.socketEvents$.next(new SocketEvent(namespace, SocketEventName.disconnect));
          //console.debug('reconnect on nsp ', namespace);
          // socket.emit('authenticate', {
          //   'Authorization': this.authenticationService.getAuthorizationToken(false)
          // });
        })
        .on('reconnect_attempt', () => {
          //console.debug('reconnect_attempt on nsp ', namespace);
        })
        .on('reconnect_failed', () => {
          //console.debug('reconnect_failed on nsp ', namespace);
        })
        .on('reconnect_error', () => {
          //console.debug('reconnect_error on nsp ', namespace);
        })
        .on('reconnecting', () => {
          //console.debug('reconnecting on nsp ', namespace);
        });
    });
  }

  private getSocketUrl(): string {
    return this.config.getConfigVariable(ApplicationConfigVarNames.SOCKET_IO_URL);
  }

  private _join(socket: Socket, room: string): Socket {
    return socket.emit('join', room);
  }

  private getSocket(namespace: Namespace): Observable<Socket> {
    const manager: Manager = this.socketStore.tryGetSocket(namespace)
    if (!manager || !manager.nsps || !manager.nsps['/' + namespace.toString()]) {
      return this.initNamespace(namespace)
        .pipe(
          take(1)
        );
    }
    return combineLatest([of(manager), this.socketEvents$])
      .pipe(
        map((obs: [Manager, SocketEvent]) => [obs[0].nsps['/' + namespace.toString()], obs[1]]),
        filter((obs: [Socket, SocketEvent]) => {
          const sock: Socket = obs[0];
          return !sock.disconnected && sock.connected && sock.auth;
        }),
        map((obs: [Socket, SocketEvent]) => obs[0]),
        take(1)
      );
  }


}
