import {Injectable} from '@angular/core';
import {MaintenanceInfo} from '../../../models/maintenance-info';
import {createAction, props, Store} from '@ngrx/store';

export class MaintenanceInfoActionConstant {
  static readonly LOAD_MAINTENANCE_INFO_START = 'LOAD_MAINTENANCE_INFO_START';
  static readonly LOAD_MAINTENANCE_INFO_SUCCEEDED = 'LOAD_MAINTENANCE_INFO_SUCCEEDED';
  static readonly LOAD_MAINTENANCE_INFO_FAILED = 'LOAD_MAINTENANCE_INFO_FAILED';
  static readonly REMOVE_MAINTENANCE_INFO_SUCCEEDED = 'REMOVE_MAINTENANCE_INFO_SUCCEEDED';
}

export const loadMaintenanceInfoSucceededAction = createAction(
  MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_SUCCEEDED,
  props<{ payload: MaintenanceInfo }>()
);

export const removeMaintenanceInfoSucceededAction = createAction(
  MaintenanceInfoActionConstant.REMOVE_MAINTENANCE_INFO_SUCCEEDED
);

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInfoAction {

  constructor(private localStore: Store) {}

  public loadMaintenanceInfoSucceeded(maintenanceInfo: MaintenanceInfo): void {
    this.localStore.dispatch(loadMaintenanceInfoSucceededAction({ payload: maintenanceInfo }));
  }

  public removeMaintenanceInfoSucceeded(): void {
    this.localStore.dispatch(removeMaintenanceInfoSucceededAction());
  }
}
