import {ActionReducer, Store} from '@ngrx/store';
import {GenericActionsNames} from '../generic-actions-names';


export function canBeReset<T>(defaultValue: T, resetActionNames: string[], /* skipDefaultReducer = true */) {

  if (!!!resetActionNames || resetActionNames.length === 0) {
    throw new Error('Au moins un nom d’action de reset doit être précisé');
  }
  return (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
      return reducer(state, action);
      // if (resetActionNames.some(type => action.type === type)) {
      //   console.log(`[RESET] pour la valeur par défaut`, defaultValue);
      //   state = defaultValue;
      //   if (skipDefaultReducer) {
      //     return state;
      //   }
      // }
      // return reducer(state, action);
    };
  };
}


export function resetStore(store: Store) {
  store.dispatch({type: GenericActionsNames.RESET});
}


