import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {nexiaObjectLinksReducer} from './reducer/nexia-object-links.reducer';


export const nexiaObjectLinksStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.NEXIA_OBJECT_LINKS,
    reducer: nexiaObjectLinksReducer
  })
);

