export class CriticalData {
  viewCriticalFields: string[];
  visibleCriticalFields: string[];
  visibleCriticalPdf?: boolean;
  viewCriticalPdf?: boolean = false;
}

export function doesContainCriticalDatas(criticalData: CriticalData): boolean {
  return criticalData && (criticalData.viewCriticalPdf || (criticalData.viewCriticalFields && criticalData.viewCriticalFields.length > 0));
}
