import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {GedFieldState} from './reducer/ged/ged-cfg-field.reducer';
import {WkfFieldState} from './reducer/wkf/wkf-cfg-field.reducer';


export const selectGedCfgFieldFeature = createFeatureSelector<GedFieldState>(StoreKeys.GED_CFG_FIELD);
export const selectGedCfgFieldLoading = createSelector(selectGedCfgFieldFeature, state => state.loading);

export const selectGedCfgFieldDatas = createSelector(selectGedCfgFieldFeature, state => state.datas);

export const selectWkfCfgFieldFeature = createFeatureSelector<WkfFieldState>(StoreKeys.WKF_CFG_FIELD);
export const selectWkfCfgFieldLoading = createSelector(selectWkfCfgFieldFeature,state => state.loading);

export const selectWkfCfgFieldDatas = createSelector(selectWkfCfgFieldFeature, state => state.datas);



