import {createReducer, on} from '@ngrx/store';
import {PayloadAction} from '../../../../redux/payload-action';
import {MenuItem} from '../../../models/menu-item';
import {loadMenuItemsFailedAction, loadMenuItemsStartAction, loadMenuItemsSucceededAction, MenuActionConstant} from '../action/Menu.action';


export interface MenuState {
  datas: MenuItem[];
  loading: boolean;
  error: any;
}

export const MENU_INITIAL_STATE: MenuState = {
  datas: [],
  loading: false,
  error: null,
};


export const menuReducerOld = (state: MenuState = MENU_INITIAL_STATE, action: PayloadAction<MenuItem[]>) => {
  switch (action.type) {
    case MenuActionConstant.LOAD_MENU_ITEMS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MenuActionConstant.LOAD_MENU_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case MenuActionConstant.LOAD_MENU_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};

export const menuReducer = createReducer(MENU_INITIAL_STATE,
  on(loadMenuItemsStartAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadMenuItemsSucceededAction, (state, {payload}) => ({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(loadMenuItemsFailedAction, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  }))
);

