import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../redux/payload-action';
import {
  addMultipleCfgTeamFailedAction,
  addMultipleCfgTeamStartAction,
  addMultipleCfgTeamSuceedAction,
  CgfTeamConstantAction,
  removeAllCfgTeamAction
} from '../action/cfg-team.action';
import { createReducer, on } from '@ngrx/store';

export interface CfgTeamState {
  datas: Team[];
  loading: boolean;
  error: any;
}

export const CFG_TEAM_INITIAL_STATE: CfgTeamState = {
  datas: [],
  loading: false,
  error: null,
};



export const cfgTeamReducerOld = (state: CfgTeamState = CFG_TEAM_INITIAL_STATE, action: PayloadAction<Team[]>): CfgTeamState => {
  switch (action.type) {
    // ADD MULTIPLE
    case CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CgfTeamConstantAction.ADD_MULTIPLE_CFG_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      // REMOVE ALL
    case CgfTeamConstantAction.REMOVE_ALL_CFG_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: null,
      };
  }
  return state;
};

export const cfgTeamReducer = createReducer(
  CFG_TEAM_INITIAL_STATE,
  on(addMultipleCfgTeamStartAction,(state)=>({
    ...state,
    loading: true,
    error: null,
  })),
  on(addMultipleCfgTeamSuceedAction,(state,{payload})=>({
    ...state,
    datas: payload,
    loading: false,
    error: null,
  })),
  on(addMultipleCfgTeamFailedAction,(state,{error})=>({
    ...state,
    loading: false,
    error: error,
  })),
  on(removeAllCfgTeamAction,(state)=>({
    ...state,
    datas: [],
    loading: false,
    error: null,
  })),
);
