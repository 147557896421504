import {SearchItem} from '../../../../models/search-item';
import {SearchPath} from '../../../../models/search-path';
import {
  addSearchItemFailedAction,
  addSearchItemStartAction,
  addSearchItemSucceededAction,
  removeAllSearchItemsSucceededAction,
  removeSearchItemFailedAction,
  removeSearchItemStartAction,
  removeSearchItemSucceededAction
} from '../action/search-items.action';
import {createReducer, on} from '@ngrx/store';
import * as _ from 'lodash';


export interface SearchItemsState {
  datas: SearchItem[];
  loading: boolean;
  error: any;
}

export const SEARCH_ITEMS_INITIAL_STATE: SearchItemsState = {
  datas: [],
  loading: false,
  error: null,
};


// export const searchItemsReducerOld = (state: SearchItemsState = SEARCH_ITEMS_INITIAL_STATE, action: PayloadAction<SearchItem>) => {
//   switch (action.type) {
//     case SearchItemsActionConstant.ADD_SEARCH_ITEM_START:
//     case SearchItemsActionConstant.REMOVE_SEARCH_ITEM_START:
//       return {
//         ...state,
//         loading: true,
//         error: null,
//       };
//     case SearchItemsActionConstant.ADD_SEARCH_ITEM_SUCCEEDED:
//       const item: SearchItem = action.payload;
//       const size = state.datas.length;
//       const lastItemId = state.datas.length ? state.datas[state.datas.length - 1].id : 0;
//       if (item.id === -1) {
//         item.id = size > lastItemId ? size : lastItemId + 1;
//         return {
//           ...state,
//           datas: state.datas.concat(item),
//           loading: false,
//           error: null,
//         };
//       } else {
//         const itemIndex = state.datas.findIndex((stateItem) => stateItem.id === item.id);
//         state.datas.splice(itemIndex, 1, item);
//         return {
//           ...state,
//           loading: false,
//           error: null,
//         };
//       }
//     // const max = state.datas.reduce((m, oneItem) => Math.max(m, oneItem.id), 0) + 1;
//     // const item: SearchItem = action.payload;
//     // item.id = max;
//     // return {
//     //   ...state,
//     //   datas: state.datas.concat(item),
//     //   loading: false,
//     //   error: null,
//     // };
//     case SearchItemsActionConstant.ADD_SEARCH_ITEM_FAILED:
//       return {
//         ...state,
//         datas: [],
//         loading: false,
//         error: action.payload,
//       };
//     case SearchItemsActionConstant.REMOVE_SEARCH_ITEM_SUCCEEDED:
//       let copy;
//       if (!!action.payload.path && action.payload.value) {
//         const searchPathItemToRemove: SearchPath = new SearchPath(action.payload.path, action.payload.label ? action.payload.label : action.payload.value);
//         copy = state.datas.filter(oneItem => { // FIXME : utilité ?
//           if (searchPathItemToRemove.isMeta && searchPathItemToRemove.code === SearchPath.META_CDX_TYPE && oneItem.path) {
//             const storeItemSearchPath: SearchPath = new SearchPath(oneItem.path, oneItem.value);
//             return !(storeItemSearchPath.code === SearchPath.META_CDX_TYPE &&
//               (action.payload.label ? storeItemSearchPath.value === action.payload.label || storeItemSearchPath.value === action.payload.value :
//                 storeItemSearchPath.value === action.payload.value));
//           }
//           return oneItem.path !== action.payload.path || oneItem.value !== action.payload.value;
//         });
//       } else {
//         copy = state.datas.filter(oneItem => {
//           return oneItem.id !== action.payload.id;
//         });
//       }
//       copy.forEach((oneItem, i) => {
//         oneItem.id = i;
//       });
//       return {
//         ...state,
//         datas: copy,
//         loading: false,
//         error: null,
//       };
//     case SearchItemsActionConstant.REMOVE_SEARCH_ITEM_FAILED:
//       return {
//         ...state,
//         datas: [],
//         loading: false,
//         error: action.error,
//       };
//     case SearchItemsActionConstant.REMOVE_ALL_SEARCH_ITEMS_SUCCEEDED:
//       return {
//         ...state,
//         datas: [],
//         loading: false,
//         error: null,
//       };
//   }
//   return state;
// };

export const searchItemsReducer = createReducer(
  // Initial State
  SEARCH_ITEMS_INITIAL_STATE,

  // Start Actions
  on(
    addSearchItemStartAction,
    removeSearchItemStartAction,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),

  // Succeeded Actions
  on(addSearchItemSucceededAction, (state, {payload}) => {
    const item: SearchItem = _.cloneDeep(payload);
    const size = state.datas.length;
    const lastItemId = state.datas.length ? state.datas[state.datas.length - 1].id : 0;

    if (item.id === -1) {
      item.id = size > lastItemId ? size : lastItemId + 1;
      return {
        ...state,
        datas: state.datas.concat(item),
        loading: false,
        error: null,
      };
    } else {
      const itemIndex = state.datas.findIndex((stateItem) => stateItem.id === item.id);
      const datasCopy = _.cloneDeep(state.datas);
      datasCopy.splice(itemIndex, 1, item);
      return {
        ...state,
        datas: datasCopy,
        loading: false,
        error: null,
      };
    }
  }),

  // Failed Actions
  on(addSearchItemFailedAction, removeSearchItemFailedAction, (state, {error}) => ({
    ...state,
    datas: [],
    loading: false,
    error,
  })),

  // Remove Succeeded Actions
  on(removeSearchItemSucceededAction, (state, {payload}) => {
    let copy;
    if (!!payload.path && payload.value) {
      const searchPathItemToRemove: SearchPath = new SearchPath(payload.path, payload.label ? payload.label : payload.value);
      copy = _.cloneDeep(state.datas.filter(oneItem => {
        if (searchPathItemToRemove.isMeta && searchPathItemToRemove.code === SearchPath.META_CDX_TYPE && oneItem.path) {
          const storeItemSearchPath: SearchPath = new SearchPath(oneItem.path, oneItem.value);
          return !(storeItemSearchPath.code === SearchPath.META_CDX_TYPE &&
            (payload.label ? storeItemSearchPath.value === payload.label || storeItemSearchPath.value === payload.value :
              storeItemSearchPath.value === payload.value));
        }
        return oneItem.path !== payload.path || oneItem.value !== payload.value;
      }));
    } else {
      copy = _.cloneDeep(state.datas.filter(oneItem => oneItem.id !== payload.id));
    }

    copy.forEach((oneItem, i) => {
      oneItem.id = i;
    });

    return {
      ...state,
      datas: copy,
      loading: false,
      error: null,
    };
  }),

  // Remove All Succeeded Actions
  on(removeAllSearchItemsSucceededAction, (state) => ({
    ...state,
    datas: [],
    loading: false,
    error: null,
  }))
);
