import {ActionReducer, INIT, UPDATE} from '@ngrx/store';


const INIT_FEATURE = "@ngrx/feature/init"

export function withSessionStoragePersistence(featureName: string, reducer: ActionReducer<any>) {
  return (state, action) => {
    if ([INIT, UPDATE, INIT_FEATURE].some(type => type == action.type)) {
      try {
        const storageValue = JSON.parse(sessionStorage.getItem(featureName) || '');
        if (storageValue) {
          // console.log(`[${action.type}] - restauration depuis le sessionStorage pour la feature ${featureName} ; valeur = `, storageValue);
          return storageValue;
        } else {
          // console.log(`[${action.type}] - sessionStorage vide pour la feature ${featureName} => pas de restauration.`);
        }
      } catch (error) {
        // console.error(`Erreur lors de la récupération de la valeur pour la feature ${featureName} dans le sessionStorage`, error)
        sessionStorage.removeItem(featureName);
      }
    }
    // console.log(`1 STORAGE Persistence ${action.type}, feature=${featureName}, value=`, action)
    const value = reducer(state, action);
    sessionStorage.setItem(featureName, JSON.stringify(value));
    return value;
  };

}
