
export class CdxFile {
  cdx_id: string;
  uuid: string;
  filename: string;
  fileSize: number;
  format: string;
  type: string;
  url: any;
}

export function getIconInfo(fileName: string): CdxIcon | null {
  const extension: string = fileName.split('.').pop().toLowerCase();

  if (Object.values(VideoFileExtensions).includes(extension as any)) {
    return { icon: 'video_file', extension};
  }
  else if (Object.values(AudioFileExtensions).includes(extension as any)) {
    return { icon: 'audio_file', extension};
  }
  else if (Object.values(CompressedFolderExtensions).includes(extension as any)) {
    return { icon: 'folder_zip', extension};
  }
  else if (!Object.values(GEDPreviewableExtensions).includes(extension as any)) {
    return { icon: 'description', extension};
  }
  else {
    return null;
  }
}

export interface CdxIcon {
  icon: string;
  extension: string;
}

export enum CompressedFolderExtensions {
  _7z = "7z",
  a = "a",
  ace = "ace",
  apk = "apk",
  ar = "ar",
  arc = "arc",
  bz2 = "bz2",
  cab = "cab",
  chm = "chm",
  cpio = "cpio",
  deb = "deb",
  dmg = "dmg",
  ear = "ear",
  egg = "egg",
  epub = "epub",
  gz = "gz",
  iso = "iso",
  jar = "jar",
  lz = "lz",
  lzma = "lzma",
  lzo = "lzo",
  mar = "mar",
  pea = "pea",
  pet = "pet",
  pkg = "pkg",
  rar = "rar",
  rpm = "rpm",
  s7z = "s7z",
  sit = "sit",
  sitx = "sitx",
  shar = "shar",
  tar = "tar",
  tbz2 = "tbz2",
  tgz = "tgz",
  tlz = "tlz",
  txz = "txz",
  war = "war",
  whl = "whl",
  xpi = "xpi",
  xz = "xz",
  zip = "zip",
  zipx = "zipx",
  zst = "zst"
}

export enum VideoFileExtensions {
  _3g2 = "3g2",
  _3gp = "3gp",
  aaf = "aaf",
  asf = "asf",
  avchd = "avchd",
  avi = "avi",
  drc = "drc",
  flv = "flv",
  m2v = "m2v",
  m3u8 = "m3u8",
  m4p = "m4p",
  m4v = "m4v",
  mkv = "mkv",
  mng = "mng",
  mov = "mov",
  mp2 = "mp2",
  mp4 = "mp4",
  mpe = "mpe",
  mpeg = "mpeg",
  mpg = "mpg",
  mpv = "mpv",
  mxf = "mxf",
  nsv = "nsv",
  ogg = "ogg",
  ogv = "ogv",
  qt = "qt",
  rm = "rm",
  rmvb = "rmvb",
  roq = "roq",
  svi = "svi",
  vob = "vob",
  webm = "webm",
  wmv = "wmv",
  yuv = "yuv"
}

export enum AudioFileExtensions {
  wav = "wav",
  bwf = "bwf",
  raw = "raw",
  aiff = "aiff",
  flac = "flac",
  m4a = "m4a",
  pac = "pac",
  tta = "tta",
  wv = "wv",
  ast = "ast",
  aac = "aac",
  mp2 = "mp2",
  mp3 = "mp3",
  amr = "amr",
  s3m = "s3m",
  act = "act",
  au = "au",
  dct = "dct",
  dss = "dss",
  gsm = "gsm",
  mmf = "mmf",
  mpc = "mpc",
  oga = "oga",
  opus = "opus",
  ra = "ra",
  sln = "sln",
  vox = "vox"
}

export enum GEDPreviewableExtensions {
  jpg = "jpg",
  tiff = "tiff",
  bmp = "bmp",
  pcx = "pcx",
  gif = "gif",
  png = "png",
  raw = "raw",
  ppm = "ppm",
  jp2 = "jp2",
  jbig2 = "jbig2",
  tif = "tif",
  pgm = "pgm",
  jpeg = "jpeg",
  wbmp = "wbmp",
  pbm = "pbm",
  jb2 = "jb2",
  msg = "msg",
  pdf = "pdf",
  swf = "swf",
  html = "html",
  odt = "odt",
  ott = "ott",
  fodt = "fodt",
  sxw = "sxw",
  doc = "doc",
  docx = "docx",
  rtf = "rtf",
  wpd = "wpd",
  txt = "txt",
  ods = "ods",
  ots = "ots",
  fods = "fods",
  sxc = "sxc",
  xls = "xls",
  xlsx = "xlsx",
  csv = "csv",
  tsv = "tsv",
  odp = "odp",
  otp = "otp",
  fodp = "fodp",
  sxi = "sxi",
  ppt = "ppt",
  pptx = "pptx",
  odg = "odg",
  otg = "otg",
  fodg = "fodg",
  svg = "svg"
}
