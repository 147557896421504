import * as _ from 'lodash';
import { NexiaBasketObject } from '../../../models/nexia-basket-object';
import {
  emptyNexiaBasketItemsSucceeded,
  loadNexiaBasketItemsDetailsSucceeded,
  loadNexiaBasketItemsFailed,
  loadNexiaBasketItemsStart,
  loadNexiaBasketItemsSucceeded,
  removeErrorFromStore
} from '../action/nexia-basket.action';
import { createReducer, on } from '@ngrx/store';


export interface NexiaBasketState {
  datas: {[key: string]: NexiaBasketObject[]};
  loading: boolean;
  error: any;
}
export const NEXIA_BASKET_INITIAL_STATE: NexiaBasketState = {
  datas: null,
  loading: false,
  error: null,
};

/*
export const nexiaBasketReducerOld = (state: NexiaBasketState = NEXIA_BASKET_INITIAL_STATE, action: PayloadAction<{[key: string]: NexiaBasketObject[]}>) => {
  switch (action.type) {
    case NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_SUCCEEDED:
      let datasCopy: {[key: string]: NexiaBasketObject[]} = {};
      if (state.datas) {
        const stateBasketKeys: string[] = Object.keys(state.datas);
        const dataKeysFromPayload: string[] = Object.keys(action.payload);
        dataKeysFromPayload.forEach((dataKeyFromPayload: string) => {
        const nexiaBasketObjectsFromPaylod: NexiaBasketObject[] = action.payload[dataKeyFromPayload];
          if (stateBasketKeys.length && stateBasketKeys.indexOf(dataKeyFromPayload) > -1) {
            datasCopy = _.cloneDeep(state.datas);
            nexiaBasketObjectsFromPaylod.forEach((nexiaBasketObjectFromPaylod: NexiaBasketObject) => {
              const storeNexiaBasketObjectByKeyExists: boolean = state.datas[dataKeyFromPayload].some((storeNexiaBasketObject: NexiaBasketObject) => storeNexiaBasketObject.id === nexiaBasketObjectFromPaylod.id);
              storeNexiaBasketObjectByKeyExists ?
                datasCopy[dataKeyFromPayload] = datasCopy[dataKeyFromPayload].filter((nexiaBasketObject: NexiaBasketObject) => nexiaBasketObject.id !== nexiaBasketObjectFromPaylod.id) :
                datasCopy[dataKeyFromPayload].push(nexiaBasketObjectFromPaylod);
            });
          } else {
            datasCopy[dataKeyFromPayload] = nexiaBasketObjectsFromPaylod;
          }
        });
      } else {
        datasCopy = action.payload;
      }
      return {
        ...state,
        datas: datasCopy,
        loading: false,
        error: null,
      };
    case NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_DETAILS_SUCCEEDED:
      let datasCopyWithDetail: {[key: string]: NexiaBasketObject[]} = {};
      if (state.datas) {
        const payloadKey: string = Object.keys(action.payload)[0];
        datasCopyWithDetail = _.cloneDeep(state.datas);
        datasCopyWithDetail[payloadKey] = action.payload[payloadKey];
      } else {
        datasCopyWithDetail = action.payload;
      }
      return {
        ...state,
        datas: datasCopyWithDetail,
        loading: false,
        error: null,
      };
    case NexiaBasketActionConstantAction.LOAD_NEXIA_BASKET_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case NexiaBasketActionConstantAction.REMOVE_ERROR_FROM_STORE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case NexiaBasketActionConstantAction.EMPTY_NEXIA_BASKET_ITEMS_SUCCEEDED:
      let dataCopyWithEmptyData: {[key: string]: NexiaBasketObject[]} = {};
      const payloadKeyToEmpty: string = Object.keys(action.payload)[0];
      dataCopyWithEmptyData = _.cloneDeep(state.datas);
      dataCopyWithEmptyData[payloadKeyToEmpty] = [] ;

      return {
        ...state,
        datas: dataCopyWithEmptyData,
        loading: false,
        error: null,
      };
  }
  return state;
};
*/

export const nexiaBasketReducer = createReducer(
  NEXIA_BASKET_INITIAL_STATE,
  on(loadNexiaBasketItemsStart, (state) => { return {
    ...state,
    loading: true,
    error: null,
  }} ),
  on(loadNexiaBasketItemsSucceeded,(state, {payload}) => {
    let datasCopy: {[key: string]: NexiaBasketObject[]} = {};
    if (state.datas) {
      const stateBasketKeys: string[] = Object.keys(state.datas);
      const fromPayloadDataKeys: string[] = Object.keys(payload);
      fromPayloadDataKeys.forEach((dataKeyFromPayload: string) => {
        const fromPayloadNexiaBasketObjects: NexiaBasketObject[] = payload[dataKeyFromPayload];
        if (stateBasketKeys.length && stateBasketKeys.indexOf(dataKeyFromPayload) > -1) {
          datasCopy = _.cloneDeep(state.datas);
          fromPayloadNexiaBasketObjects.forEach((fromPayloadNexiaBasketObject: NexiaBasketObject) => {
            const storeNexiaBasketObjectByKeyExists: boolean = state.datas[dataKeyFromPayload].some((storeNexiaBasketObject: NexiaBasketObject) => storeNexiaBasketObject.id === fromPayloadNexiaBasketObject.id);
            storeNexiaBasketObjectByKeyExists ?
              datasCopy[dataKeyFromPayload] = datasCopy[dataKeyFromPayload].filter((nexiaBasketObject: NexiaBasketObject) => nexiaBasketObject.id !== fromPayloadNexiaBasketObject.id) :
              datasCopy[dataKeyFromPayload].push(fromPayloadNexiaBasketObject);
          });
        } else {
          datasCopy[dataKeyFromPayload] = fromPayloadNexiaBasketObjects;
        }
      });
    } else {
      datasCopy = payload;
    }
    return {
      ...state,
      datas: datasCopy,
      loading: false,
      error: null,
    }
  } ),
  on(loadNexiaBasketItemsDetailsSucceeded,(state, {payload}) => {
    let datasCopyWithDetail: {[key: string]: NexiaBasketObject[]} = {};
    if (state.datas) {
      const payloadKey: string = Object.keys(payload)[0];
      datasCopyWithDetail = _.cloneDeep(state.datas);
      datasCopyWithDetail[payloadKey] = payload[payloadKey];
    } else {
      datasCopyWithDetail = payload;
    }
    return {
      ...state,
      datas: datasCopyWithDetail,
      loading: false,
      error: null,
    }
 } ),
  on(loadNexiaBasketItemsFailed,(state, {error}) => {return {
    ...state,
    loading: false,
    error: error,
  }} ),
  on(removeErrorFromStore,(state) => {return {
    ...state,
    loading: false,
    error: null,
  }} ),
  on(emptyNexiaBasketItemsSucceeded,  (state, {payload}) => {
    let dataCopyWithEmptyData: {[key: string]: NexiaBasketObject[]} = {};
    const payloadKeyToEmpty: string = Object.keys(payload)[0];
    dataCopyWithEmptyData = _.cloneDeep(state.datas);
    dataCopyWithEmptyData[payloadKeyToEmpty] = [] ;
    return {
      ...state,
      datas: dataCopyWithEmptyData,
      loading: false,
      error: null,
    }
  }),
);
