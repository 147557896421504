import {Injectable} from '@angular/core';
import {EsPage} from '../../../../models/es-page';
import {Aggregate} from '../../../../models/aggregate';
import {CdxDocument} from '../../../../models/cdx-document';
import {createAction, props, Store} from '@ngrx/store';

export class DocumentSearchResultConstant {
  static readonly LOAD_RESULT_ITEMS_START = '[DocumentSearch]: LOAD_RESULTS_START';
  static readonly LOAD_RESULT_ITEMS_SUCCEEDED = '[DocumentSearch]: LOAD_RESULTS_SUCCEEDED';
  static readonly LOAD_RESULT_ITEMS_FAILED = '[DocumentSearch]: LOAD_RESULTS_FAILED';
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_AGGREGATE_START = '[DocumentSearch]: LOAD_AGGREGATE_START';
  static readonly LOAD_AGGREGATE_SUCCEEDED = '[DocumentSearch]: LOAD_AGGREGATE_SUCCEEDED';
  static readonly LOAD_AGGREGATE_FAILED = '[DocumentSearch]: LOAD_AGGREGATE_FAILED';

  static readonly UPDATE_DOCUMENTS_START = '[DocumentSearch]: UPDATE_DOCUMENTS_START';
  static readonly UPDATE_DOCUMENTS_SUCCEEDED = '[DocumentSearch]: UPDATE_DOCUMENTS_SUCCEEDED';
  static readonly UPDATE_DOCUMENTS_FAILED = '[DocumentSearch]: UPDATE_DOCUMENTS_FAILED';

  static readonly UPDATE_DOCUMENT_SUCCEEDED = '[DocumentSearch]: UPDATE_DOCUMENT_SUCCEEDED';
}

export const loadDocumentsStartAction = createAction(
  DocumentSearchResultConstant.LOAD_RESULT_ITEMS_START
);

export const loadDocumentsSucceededAction = createAction(
  DocumentSearchResultConstant.LOAD_RESULT_ITEMS_SUCCEEDED,
  props<{ payload: EsPage<CdxDocument> }>()
);

export const loadDocumentsFailedAction = createAction(
  DocumentSearchResultConstant.LOAD_RESULT_ITEMS_FAILED,
  props<{ error: Error }>()
);

export const updateDocumentsStartAction = createAction(
  DocumentSearchResultConstant.UPDATE_DOCUMENTS_START
);

export const updateDocumentsSucceededAction = createAction(
  DocumentSearchResultConstant.UPDATE_DOCUMENTS_SUCCEEDED,
  props<{ payload: EsPage<CdxDocument> }>()
);

export const updateDocumentSucceededAction = createAction(
  DocumentSearchResultConstant.UPDATE_DOCUMENT_SUCCEEDED,
  props<{ payload: EsPage<CdxDocument> }>()
);

export const updateDocumentsFailedAction = createAction(
  DocumentSearchResultConstant.UPDATE_DOCUMENTS_FAILED,
  props<{ error: Error }>()
);

export const loadAggregateStartAction = createAction(
  DocumentSearchResultConstant.LOAD_AGGREGATE_START
);

export const loadAggregateFailedAction = createAction(
  DocumentSearchResultConstant.LOAD_AGGREGATE_FAILED,
  props<{ error: Error }>()
);

export const loadAggregateSucceededAction = createAction(
  DocumentSearchResultConstant.LOAD_AGGREGATE_SUCCEEDED,
  props<{ payload: EsPage<CdxDocument> }>()
);

@Injectable({
  providedIn: 'root'
})
export class DocumentSearchResultAction {

  constructor(private localStore: Store) {
  }

  public loadDocumentsStarted() {
    this.localStore.dispatch(loadDocumentsStartAction());
  }

  public loadDocumentsSucceeded(page: EsPage<CdxDocument>) {
    this.localStore.dispatch(loadDocumentsSucceededAction({payload: page}));
  }

  public loadDocumentsFailed(error: Error) {
    this.localStore.dispatch(loadDocumentsFailedAction({error}));
  }

  public updateDocumentsStarted() {
    this.localStore.dispatch(updateDocumentsStartAction());
  }

  public updateDocumentsSucceeded(page: EsPage<CdxDocument>) {
    this.localStore.dispatch(updateDocumentsSucceededAction({payload: page}));
  }

  public updateDocumentSucceeded(document: CdxDocument) {
    const page: EsPage<CdxDocument> = new EsPage<CdxDocument>(0, [document], 0, 0, [], false);
    this.localStore.dispatch(updateDocumentSucceededAction({payload: page}));
  }

  public updateDocumentsFailed(error: Error) {
    this.localStore.dispatch(updateDocumentsFailedAction({error}));
  }

  public loadAggregateStart() {
    this.localStore.dispatch(loadAggregateStartAction());
  }

  public loadAggregateFailed(error: Error) {
    this.localStore.dispatch(loadAggregateFailedAction({error}));
  }

  public loadAggregateSucceeded(agg: Aggregate) {
    const page: EsPage<CdxDocument> = new EsPage<CdxDocument>(0, [], 0, 0, [agg], false);
    this.localStore.dispatch(loadAggregateSucceededAction({payload: page}));
  }
}

