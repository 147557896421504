import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {filter} from 'rxjs/operators';
import {SubscriptionLike} from 'rxjs';
import {InternalRoutes} from '../../../models/internal-routes';
import {CurrentContextState} from '../../../redux/current-context/reducer/current-context.reducer';
import {CurrentModule} from '../../../models/current-module';
import {AuthenticationService} from '../../../../modules/authentication/authentication.service';
import {ConfigurationService, whenConfReady} from '../../../../modules/configuration/configuration.service';
import {ApplicationConfigVarNames} from "../../../../modules/configuration/application-config-var-names";
import {Store} from "@ngrx/store";
import {selectCurrentContextFeature} from "../../../redux/current-context/current-context-selectors";
import {Router} from "@angular/router";

@Component({
  selector: 'app-logo-nexia',
  templateUrl: './logo-nexia.component.html',
  styleUrls: ['./logo-nexia.component.scss']
})
export class LogoNexiaComponent implements OnInit, OnDestroy {

  currentContext$ = this.store.select(selectCurrentContextFeature).pipe(whenConfReady);

  @Input()
  fullWhiteLogo = false;

  @Input()
  fullBlackLogo = false;

  @Input()
  firstLetterBlackLogo = false;
  public wkfEnabled = false;
  public hasWkfRole = false;
  public isGed = false;
  InternalRoutes = InternalRoutes;
  private subs: SubscriptionLike[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private configurationActions: ConfigurationService,
    private router: Router,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.wkfEnabled = this.configurationActions.getConfigVariable(ApplicationConfigVarNames.WORKFLOW_ENABLED) === 'true';
    this.hasWkfRole = this.authenticationService.checkForGlobalAccessByRole(AuthenticationService.ROLE_WKF);
    this.subs.push(this.currentContext$.pipe(
      filter((currentContext: CurrentContextState) => !!currentContext && !!currentContext.datas && !!currentContext.datas.currentModule && !currentContext.loading),
    ).subscribe((currentContextState: CurrentContextState) => {
      this.isGed = currentContextState.datas.currentModule === CurrentModule.GED;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }
}
