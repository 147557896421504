import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {documentDetailsReducer} from './reducer/document/document-details.reducer';
import {entityDetailsReducer} from './reducer/entity/entity-details.reducer';
import {taskDetailsReducer} from './reducer/task/task-details.reducer';


export const documentDetailsStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.DOCUMENT_DETAILS,
    reducer: documentDetailsReducer,
  }));

export const entityDetailsStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.ENTITY_DETAILS,
    reducer: entityDetailsReducer
  })
);

export const taskDetailsStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.TASK_DETAILS,
    reducer: taskDetailsReducer
  })
);
