import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';

export class CgfDocumentInfoConstant {
  static readonly ADD_CFG_DOCUMENT_INFO_START = 'ADD_CFG_DOCUMENT_INFO_START';
  static readonly ADD_CFG_DOCUMENT_INFO_SUCCEEDED = 'ADD_CFG_DOCUMENT_INFO_SUCCEEDED';
  static readonly ADD_CFG_DOCUMENT_INFO_FAILED = 'ADD_CFG_DOCUMENT_INFO_FAILED';

  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_START = 'REMOVE_ALL_CFG_DOCUMENT_INFO_START';
  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED';
  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED';
}

export type DocumentInfos = {
  [key: string]: string[]
}

export const addCfgDocumentInfoStartAction = createAction(CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_START);
export const addCfgDocumentInfoSucceededAction = createAction(CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_SUCCEEDED, props<{payload: DocumentInfos}>());
export const addCfgDocumentInfoFailedAction = createAction(CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_FAILED, props<{error: Error}>());
export const removeAllCfgDocumentInfoStartAction = createAction(CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_START);
export const removeAllCfgDocumentInfoSucceededAction = createAction(CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED);
export const removeAllCfgDocumentInfoErrorAction = createAction(CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED, props<{error: Error}>());

@Injectable({
  providedIn: 'root'
})
export class CfgDocumentInfoAction {

  constructor(private store: Store
  ) {
  }

  public loadDocumentInfoStart(): void {
    this.store.dispatch(addCfgDocumentInfoStartAction());
  }

  public loadDocumentInfoSucceeded(payload: DocumentInfos): void {
    this.store.dispatch(addCfgDocumentInfoSucceededAction({payload}));
  }

  public loadDocumentInfoFailed(error: any): void {
    this.store.dispatch(addCfgDocumentInfoFailedAction({error}));
  }

  public removeAllDocumentInfoStart(): void {
    this.store.dispatch(removeAllCfgDocumentInfoStartAction());
  }

  public removeAllDocumentInfoSucceeded(): void {
    this.store.dispatch(removeAllCfgDocumentInfoSucceededAction());
  }

  public removeAllDocumentInfoFailed(error: any): void {
    this.store.dispatch(removeAllCfgDocumentInfoErrorAction({error}));
  }

}
